import React, {useEffect, useState} from 'react';
import * as ApiHelper from '../../../api/ApiHelper';
import {selectToken} from '../../../features/account/accountSlice';
import handleErrors from '../../../helpers/ErrorHandler';
import {useAbortController, useAppDispatch, useAppSelector} from '../../../hooks';
import ErrorResponse from '../../../models/ErrorResponse';
import PagedData from '../../../models/PagedData';
import {SORT_BY_DATE} from '../../../models/SortType';
import {User} from '../../../models/User';
import AsyncIndicator from '../AsyncIndicator/AsyncIndicator';
import {FetchError} from '../FetchError/FetchError';
import InfoCard from '../InfoCard/InfoCard';
import UsersShort from '../UsersShort/UsersShort';
import UsersPlaceholder from '../UsersPlaceholder/UsersPlaceholder';
import {useTranslation} from "react-i18next";
import {logError} from "../../../helpers/LogHelper";

interface LimitProps {
    limit: number;
    userClickHandler: (id: string) => void;
    allUsersClickHandler: () => void;
}

export const RecentUsers: React.FC<LimitProps> = ({ limit, userClickHandler, allUsersClickHandler }: LimitProps) => {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [users, setUsers] = useState([] as User[]);
    const usersHandler = (pagedData: PagedData<User>) => {
        setFetchingState(false);
        setErrorState(false);
        setTotalRecords(pagedData.totalRecords);
        setUsers(pagedData.data);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Users data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchUsers = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getUsers(userToken, null, SORT_BY_DATE, 1, limit, controller, usersHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchUsers(), []);     // eslint-disable-line
    if (isFetching) {
        return (
            <AsyncIndicator />
        );
    }
    if (hasError) {
        return (
            <FetchError onRetry={fetchUsers} />
        );
    }
    const userComponents = users.map((user) => <UsersShort key={user.id} user={user} onClick={() => userClickHandler(user.id)} />);
    const hasData = userComponents.length > 0;
    return (
        <div className="data-list-container">
            <InfoCard contentClass="users" titleText={t("users")} captionText={t("total_users_format", {count: totalRecords})} buttonText={t("view")} onClick={allUsersClickHandler} />
            <div className="mt-4 mb-4 data-list">
                {hasData && <div className="title disable-select">{t("recent_users")}</div>}
                {hasData ? (
                    <table>
                        <tbody>
                            {userComponents}
                        </tbody>
                    </table>
                ) : (
                    <UsersPlaceholder />
                )}
            </div>
        </div>
    );
}