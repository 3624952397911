import React from "react";
import './CheckBox.css';
import CheckBoxCheckIcon from "../../../assets/svg/CheckBoxCheckIcon";

interface CheckBoxProps {
    className?: string;
    title: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = ({className = "", title, value, onChange}: CheckBoxProps) => {
    return (
        <div className={className}>
            <div className="d-flex w-100 align-items-center">
                <div className="check-box-check mx-1" onClick={() => onChange(!value)}>
                    {value && <CheckBoxCheckIcon/>}
                </div>
                <span className="check-box-label disable-select mx-1">{title}</span>
            </div>
        </div>
    );
}