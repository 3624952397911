import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import {Role} from "../../../models/Role";
import {useParams} from "react-router-dom";
import {selectToken} from "../../../features/account/accountSlice";
import React, {useEffect, useMemo, useState} from "react";
import {Record} from "../../../models/Record";
import {Comment} from "../../../models/Comment";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {StethoscopeData} from "../../../models/StethoscopeData";
import {stethoscopeTestData} from "../../../sts/StethoscopeTest";
import {WaveFile} from "wavefile";
import {StethoscopeViewer} from "../../Widgets/StethoscopeViewer/StethoscopeViewer";
import {StethoscopeDetailsTable} from "../../Widgets/StethoscopeDetailsTable/StethoscopeDetailsTable";
import {useHistory, useLocation} from "react-router";
import PagedData from "../../../models/PagedData";
import {CommentList} from "../../Widgets/CommentList/CommentList";
import {
    STETHOSCOPE_TIME_SCALE_INDEX_DEFAULT_VALUE,
    STETHOSCOPE_TIME_SCALE_SETTINGS_KEY,
    usePersistentState
} from "../../../persistance/PersistanceHelper";
import {Conclusion} from "../../Widgets/Conclusion/Conclusion";
import {Toast} from "../../Widgets/Toast/Toast";
import {useTranslation} from "react-i18next";
import {PatientInfo} from "../../Widgets/PatientInfo/PatientInfo";
import {STETHOSCOPE_COMPAND_DEFAULT_STATE} from "../../../settings";
import {logError} from "../../../helpers/LogHelper";

interface PathParams {
    recordId: string;
    tabIndex: string;
}

interface StethoscopeViewProps {
    canEditConclusion: boolean;
}

export const StethoscopeView: React.FC<StethoscopeViewProps> = ({canEditConclusion}: StethoscopeViewProps) => {
    const {t} = useTranslation();
    const isSupport = useAccessInfo(Role.Support);
    const wavFiles = useMemo(() => new Map<string, WaveFile>(), []);
    const history = useHistory();
    const location = useLocation();
    const {recordId, tabIndex} = useParams<PathParams>();
    const activeTabIndex = (Number(tabIndex ?? "1") ?? 1) - 1;
    const setActiveTabIndex = (index: number) => {
        const newLocation = location.pathname.replace(/\d+$/gm, `${index + 1}`);
        history.replace(newLocation);
    }
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [isFetching, setFetchingState] = useState(true);
    const [isCommentsFetching, setCommentsFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [comments, setComments] = useState(null as Array<Comment> | null);
    const [initialConclusion, setInitialConclusion] = useState(null as null | string);
    const [cachedConclusion, setCachedConclusion] = useState(null as null | string);
    const [isConclusionFetching, setConclusionFetching] = useState(false);
    const [showConclusionSaveSuccessToast, setShowConclusionSaveSuccessToast] = useState(false);
    const [showConclusionSaveErrorToast, setShowConclusionSaveErrorToast] = useState(false);
    const [data, setData] = useState(null as StethoscopeData | null);
    const [compand, setCompand] = useState(STETHOSCOPE_COMPAND_DEFAULT_STATE);
    const [defaultTimeScaleIndex] = usePersistentState(STETHOSCOPE_TIME_SCALE_SETTINGS_KEY, STETHOSCOPE_TIME_SCALE_INDEX_DEFAULT_VALUE);
    const [timeScaleIndex, setTimeScaleIndex] = useState(defaultTimeScaleIndex);
    const wavHandler = (fileId: string, data: Blob) => {
        data.arrayBuffer().then(buffer => {
            const file = new WaveFile();
            file.fromBuffer(new Uint8Array(buffer));
            wavFiles.set(fileId, file);
            setUpdateTrigger(updateTrigger + 1);
        });
    };
    const dataHandler = (data: StethoscopeData) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setActiveTabIndex(0);
        setData(data);
    };
    const recordHandler = (record: Record) => {
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        setInitialConclusion(record.stethoscopeStudy.stethoscopeConclusion ?? "");
        setCachedConclusion(record.stethoscopeStudy.stethoscopeConclusion ?? "");
    };
    const commentsHandler = (comments: PagedData<Comment>) => {
        setCommentsFetchingState(false);
        setComments(comments.data);
    }
    const errorHandler = (error: ErrorResponse) => {
        logError("Stethoscope data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const commentsErrorHandler = () => {
        setCommentsFetchingState(false);
        setComments(null);
    };
    const fetchWav = (fileId: string) => {
        if (process.env.NODE_ENV === 'development') {
            // For testing
            fetch(`/test/${fileId}`).then(result => result.blob().then(blob => wavHandler(fileId, blob)));
        } else {
            //Main code
            let userToken = token?.token;
            if (userToken && record) {
                ApiHelper.getRawData(userToken, record.id, fileId, controller, (blob) => wavHandler(fileId, blob), errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchData = (record: Record) => {
        if (process.env.NODE_ENV === 'development') {
            // For testing
            dataHandler(stethoscopeTestData);
        } else {
            //Main code
            let userToken = token?.token;
            if (userToken && record.stethoscopeStudy) {
                setFetchingState(true);
                ApiHelper.getStethoscopeData(userToken, record.id, record.stethoscopeStudy.stethoscopeId, controller, dataHandler, errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchComments = (recordId: string, studyId: string) => {
        let userToken = token?.token;
        if (userToken) {
            setCommentsFetchingState(true);
            ApiHelper.getComments(userToken, recordId, studyId, controller, commentsHandler, commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => {
        if (data) {
            const id = data.records[activeTabIndex]?.fileId;
            if (id) {
                if (!wavFiles.has(id)) {
                    fetchWav(id);
                }
            }
        }
    }, [activeTabIndex, data, wavFiles]);     // eslint-disable-line
    useEffect(() => fetchRecord(), [recordId]);     // eslint-disable-line
    useEffect(() => {
        if (record) {
            fetchData(record);
            fetchComments(recordId, record.stethoscopeStudy.stethoscopeId);
        }
    }, [record]);     // eslint-disable-line
    const postComment = (text: string, referenceId?: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCommentsFetchingState(true);
            ApiHelper.saveComment(userToken, recordId, record.stethoscopeStudy.stethoscopeId, text, referenceId ?? null, controller, () => fetchComments(recordId, record.stethoscopeStudy.stethoscopeId), commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    const deleteComment = (id: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCommentsFetchingState(true);
            ApiHelper.deleteComment(userToken, id, controller, () => fetchComments(recordId, record.stethoscopeStudy.stethoscopeId), commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    const conclusionSaveHandler = (text: string) => {
        setInitialConclusion(text);
        setCachedConclusion(text);
        setConclusionFetching(false);
        setShowConclusionSaveErrorToast(false);
        setShowConclusionSaveSuccessToast(false);
        setShowConclusionSaveSuccessToast(true);

    };
    const conclusionSaveErrorHandler = () => {
        setConclusionFetching(false);
        setShowConclusionSaveSuccessToast(false);
        setShowConclusionSaveErrorToast(false);
        setShowConclusionSaveErrorToast(true);
    };
    const addToConclusion = (text: string) => {
        const newText = cachedConclusion + " " + text;
        setCachedConclusion(newText);
        setTimeout(() => {
            const elements = document.getElementsByClassName("conclusion-focus");
            if (elements.length > 0) {
                const element = elements[0];
                const elementsByTagName = element.getElementsByTagName("textarea");
                if (elementsByTagName.length > 0) {
                    elementsByTagName[0].focus();
                    elementsByTagName[0].setSelectionRange(newText.length, newText.length);
                }
            }
        }, 50);
    }
    const saveConclusion = (text: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setConclusionFetching(true);
            ApiHelper.saveStethoscopeConclusion(userToken, recordId, text, controller, () => conclusionSaveHandler(text), conclusionSaveErrorHandler);
        }
    };
    const isOk = !hasError && !notFound && !isFetching;
    const activeWav = wavFiles.get(data?.records[activeTabIndex]?.fileId ?? "");
    const tabButtonClickHandler: (index: number) => void = (index) => {
        if (index !== activeTabIndex) {
            setActiveTabIndex(index);
        }
    }
    const buttons = data?.records.map((r, i) => <div key={`b-${i}`}
                                                     className={`tab-round-button ${i === activeTabIndex ? "tab-round-button-selected" : ""}`}
                                                     onClick={() => tabButtonClickHandler(i)}>{i + 1}</div>);
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && data) &&
                <div className="stethoscope-data-container">
                    <PatientInfo record={record}/>
                    {buttons &&
                        <div className="d-flex justify-content-center mt-4 mb-2">
                            {buttons}
                        </div>
                    }
                    <div
                        className="d-flex justify-content-center data-list-title my-2">{data.records[activeTabIndex].locationDescription}</div>
                    {activeWav !== undefined ?
                        <StethoscopeViewer record={record} stethoscopeRecord={data.records[activeTabIndex]}
                                           wavData={activeWav} compand={compand} timeScaleIndex={timeScaleIndex}
                                           compandChangeHandler={(state) => setCompand(state)}
                                           timeScaleIndexChangeHandler={(index) => setTimeScaleIndex(index)}/> :
                        <AsyncIndicator/>
                    }
                </div>
            }
            {(isOk && record && data) &&
                <StethoscopeDetailsTable record={record} data={data} isSupport={isSupport}/>
            }
            {(isOk && record) && (isConclusionFetching ? <AsyncIndicator/> :
                <Conclusion initialValue={initialConclusion ?? ""} cachedValue={cachedConclusion ?? ""}
                            saveHandler={canEditConclusion ? saveConclusion : undefined}
                            resetHandler={() => setCachedConclusion(initialConclusion)}/>)}
            {(isOk && showConclusionSaveSuccessToast) &&
                <Toast text={t("conclusion_saved")}/>
            }
            {(isOk && showConclusionSaveErrorToast) &&
                <Toast text={t("conclusion_saved_failed")} isError={true}/>
            }
            {isCommentsFetching && <AsyncIndicator/>}
            {!isCommentsFetching && comments &&
                <CommentList comments={comments}
                             onPostComment={postComment}
                             onDeleteComment={deleteComment}
                             onMakeConclusion={canEditConclusion ? addToConclusion : undefined}/>
            }
        </div>
    );
};

export default StethoscopeView;