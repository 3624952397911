interface NotificationLanguageDropdownProps {
    className?: string;
    initialState: string;
    disabled: boolean;
    onChange: (value: string) => void;
}

export const NotificationLanguageDropdown = ({className, initialState, disabled, onChange}: NotificationLanguageDropdownProps) => {
    return (
        <select className={`${className} disable-select`} disabled={disabled} defaultValue={initialState}
                onChange={(e) => onChange(e.target.value)}>
            <option value='en'>En</option>
            <option value='ru'>Ru</option>
            <option value='uk'>Uk</option>
        </select>
    );
}

export default NotificationLanguageDropdown;