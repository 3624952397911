import React from "react";
import './DeviceLabel.css';
import IconBattery from "./IconBattery";
import IconCertificate from "./IconCertificate";
import IconManual from "./IconManual";
import IconTemperature from "./IconTemperature";
import IconRain from "./IconRain";
import IconHuman from "./IconHuman";
import IconGarbage from "./IconGarbage";
import IconFactoryFill from "./IconFactoryFill";
import IconFactory from "./IconFactory";
import IconDcVoltage from "./IconDcVoltage";

interface DeviceLabelProps {
    voltage: string;
    power: string;
    deviceName: string;
    deviceNumber: string;
    address: string;
    year: string;
}

export const DeviceLabel: React.FC<DeviceLabelProps> = ({
                                                            voltage,
                                                            power,
                                                            deviceName,
                                                            deviceNumber,
                                                            address,
                                                            year
                                                        }: DeviceLabelProps) => {
    return (
        <div className="container-label">
            <div className="container-label-first-row">
                <IconBattery className="device-label-icon"/>
                <div className="device-label-spec">{voltage}<IconDcVoltage className="device-label-spec-icon"/>{power}</div>
                <div className="flex-grow-1">
                    <table>
                        <tbody>
                        <tr>
                            <td><div className="device-label-data-title">REF</div></td>
                            <td style={{width: "100%"}}><div className="device-label-data-value">{deviceName}</div></td>
                        </tr>
                        <tr>
                            <td><div className="device-label-data-title">SN</div></td>
                            <td><div className="device-label-data-value device-label-sn">{deviceNumber}</div></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="container-label-divider"/>
            <div className="container-label-second-row">
                <div className="d-flex flex-column align-items-center">
                    <IconCertificate className="device-label-icon"/>
                    <span className="device-label-certificate-number">UA.TR.121</span>
                </div>
                <div>
                    <IconManual className="device-label-icon"/>
                </div>
                <div style={{paddingTop: 8}}>
                    <IconTemperature className="device-label-icon"/>
                </div>
                <div style={{paddingBottom: 8}}>
                    <IconRain className="device-label-icon"/>
                </div>
                <div style={{paddingTop: 8}}>
                    <IconHuman className="device-label-icon"/>
                </div>
                <div>
                    <IconGarbage className="device-label-icon"/>
                </div>
            </div>
            <div className="container-label-divider"/>
            <div className="container-label-third-row">
                <div>
                    <IconFactoryFill className="device-label-icon"/>
                </div>
                <div className="device-label-address">{address}</div>
                <div style={{position: "relative"}}>
                    <IconFactory className="device-label-icon"/>
                    <span className="device-label-year">{year}</span>
                </div>
            </div>
        </div>
    );
}