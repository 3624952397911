import * as React from "react"
import { SVGProps } from "react"

const IconGarbage = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        xmlSpace="preserve"
        viewBox="0 0 12 12"
        {...props}
    >
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinejoin="bevel"
            d="M2.68 1.969h5.492l-.774 8.636H3.535z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.684 2c.309-.598 1.504-1 2.844-1 1.062 0 2.281.242 2.644.969"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M4.953.883h1.473v.656H4.953zM4.953 4.27h1.901v.473H4.953z"
        />
        <ellipse
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            cx={8.543}
            cy={1.919}
            rx={0.441}
            ry={0.436}
        />
        <ellipse
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            cx={8.543}
            cy={1.919}
            rx={0.441}
            ry={0.436}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M3.387 1.098h.396v.887h-.396z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M3.387 1.098h.396v.887h-.396z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FBE115"
            d="M2.246 2.09h6.027v.098H2.246z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.246 2.09h6.027v.098H2.246zM8.074 3.039h.785v.973h-.871z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M7.531 9.238c.566 0 1.027.453 1.027 1.016 0 .559-.461 1.012-1.027 1.012s-1.023-.453-1.023-1.012c0-.563.457-1.016 1.023-1.016z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.144}
            d="M7.531 9.238c.566 0 1.027.453 1.027 1.016 0 .559-.461 1.012-1.027 1.012s-1.023-.453-1.023-1.012c0-.563.457-1.016 1.023-1.016z"
        />
        <circle
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#EBEBEB"
            cx={7.531}
            cy={10.252}
            r={0.924}
        />
        <circle
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            cx={7.531}
            cy={10.252}
            r={0.924}
        />
        <circle
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            cx={7.531}
            cy={10.252}
            r={0.201}
        />
        <circle
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            cx={7.531}
            cy={10.252}
            r={0.201}
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M3.648 10.68h.383v.473h-.383z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FBE115"
            d="m.016.75 9.949 10.641"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m.016.75 9.949 10.641"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FBE115"
            d="M10.699.918.547 11.367"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="M10.699.918.547 11.367"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinejoin="bevel"
            d="M2.68 1.969h5.492l-.774 8.636H3.535z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.684 2c.309-.598 1.504-1 2.844-1 1.062 0 2.281.242 2.644.969"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M4.953.883h1.473v.656H4.953zM4.953 4.27h1.901v.473H4.953z"
        />
        <ellipse
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            cx={8.543}
            cy={1.919}
            rx={0.441}
            ry={0.436}
        />
        <ellipse
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            cx={8.543}
            cy={1.919}
            rx={0.441}
            ry={0.436}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M3.387 1.098h.396v.887h-.396z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M3.387 1.098h.396v.887h-.396z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FBE115"
            d="M2.246 2.09h6.027v.098H2.246z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.246 2.09h6.027v.098H2.246zM8.074 3.039h.785v.973h-.871z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M7.531 9.238c.566 0 1.027.453 1.027 1.016 0 .559-.461 1.012-1.027 1.012s-1.023-.453-1.023-1.012c0-.563.457-1.016 1.023-1.016z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.144}
            d="M7.531 9.238c.566 0 1.027.453 1.027 1.016 0 .559-.461 1.012-1.027 1.012s-1.023-.453-1.023-1.012c0-.563.457-1.016 1.023-1.016z"
        />
        <circle
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#EBEBEB"
            cx={7.531}
            cy={10.252}
            r={0.924}
        />
        <circle
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            cx={7.531}
            cy={10.252}
            r={0.924}
        />
        <circle
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            cx={7.531}
            cy={10.252}
            r={0.201}
        />
        <circle
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            cx={7.531}
            cy={10.252}
            r={0.201}
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M3.648 10.68h.383v.473h-.383z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FBE115"
            d="m.016.75 9.949 10.641"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m.016.75 9.949 10.641"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FBE115"
            d="M10.699.918.547 11.367"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="M10.699.918.547 11.367"
        />
    </svg>
)

export default IconGarbage
