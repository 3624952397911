import {BinoraScpEcgExtractor} from "./BinoraScpEcg";
import {BeecardiaScpEcgExtractor} from "./BeecardiaScpEcg";
import {isBeecardiaLtEcgDataFile, isBinoraLtEcgDataFile} from "../helpers/AttachmentHelper";

export function getStartVectorFromBlock(fileName : string | undefined, data : ArrayBuffer): number
{
    if (isBinoraLtEcgDataFile(fileName)) {
        const blockHeader = BinoraScpEcgExtractor.parseHeader(data);
        return  blockHeader.startVector;
    }
    if (isBeecardiaLtEcgDataFile(fileName)) {
        const blockHeader = BeecardiaScpEcgExtractor.parseHeader(data);
        return  blockHeader.startSampleNumber;
    }
    throw new Error("Unknown file type");
}

export function extractScpEcgData(fileName : string | undefined, data : ArrayBuffer, position: number) : Array<number>{
    let extractor;
    if (isBinoraLtEcgDataFile(fileName)) {
        extractor = new BinoraScpEcgExtractor(data.slice(position));
    }
    if (isBeecardiaLtEcgDataFile(fileName))
    {
        extractor = new BeecardiaScpEcgExtractor(data.slice(position));
    }
    if (extractor) {
        return  extractor.extractData();
    }
    throw new Error("Unknown file type");
}