import * as React from "react";

function SvgPortrait1x7Icon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 35 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M27,1v28H7V1z"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"/>
            <path
                fill="currentColor"
                d="M13,12.7L13,19h-1.4v-5.1h-1.2v-1.2L13,12.7zM17.5,19l-1,-1.5 -1.1,1.5h-1.6l2,-2.4 -1.9,-2.4h1.6l1,1.4 1,-1.4h1.6l-1.8,2.3L19,19h-1.6zM24.5,12.7v1L22,19h-1.6l2.2,-5.1h-2v1h-1.3v-2.2h5z"/>
        </svg>
    );
}

export default SvgPortrait1x7Icon;