import React from "react";
import {Layer, Line, Path, Rect, Stage, Text} from "react-konva";
import {useTranslation} from "react-i18next";
import {getStyledColor} from "../../../helpers/DrawHelper";
import {PX_PER_MM} from "./SpiroViewer";
import Konva from "konva";

const MAJOR_LINE_WIDTH_PX = 1.2;
const MINOR_LINE_WIDTH_PX = 1;
const DATA_LINE_WIDTH = 3;
const REFERENCE_LINE_WIDTH = 2;
const LABEL_TEXT_SIZE_MM = 3.5;
const PLOT_MARGIN_MM = 5;

interface Point {
    x: number;
    y: number;
}

interface Props {
    size: number;
    data: Array<Point>;
    reference?: Array<Array<Point>>;
    expected?: Array<Point>;
}

export const SpiroMonitor: React.FC<Props> = ({
                                                  size,
                                                  data,
                                                  reference,
                                                  expected
                                              }: Props) => {
    const {t} = useTranslation();
    const plotLeft = 2 * PLOT_MARGIN_MM * PX_PER_MM;
    const plotTop = PLOT_MARGIN_MM * PX_PER_MM;
    const plotBottom = size - PLOT_MARGIN_MM * PX_PER_MM;
    const plotRight = size;
    const plotSize = size - 2 * PLOT_MARGIN_MM * PX_PER_MM;
    const gridSize = plotSize / 16;
    const backgroundColor = getStyledColor("--spiro-background");
    const gridLineColor = getStyledColor("--spiro-grid-line");
    const labelColor = getStyledColor("--primary-text");
    const dataColor = getStyledColor("--spiro-content-1");
    const referenceColors = [
        getStyledColor("--spiro-content-2"),
        getStyledColor("--spiro-content-3"),
        getStyledColor("--spiro-content-4"),
    ];
    const expectedColor = getStyledColor("--spiro-expected");
    const labelTextSize = LABEL_TEXT_SIZE_MM * PX_PER_MM;
    const gridLines = [];
    for (let i = 1; i < 16; i++) {
        const x = plotLeft + i * gridSize;
        const y = plotTop + i * gridSize;
        const lineWidth = i % 2 === 0 ? MAJOR_LINE_WIDTH_PX : MINOR_LINE_WIDTH_PX;
        gridLines.push(<Line key={`h${i}`} points={[x, plotTop, x, plotBottom]} strokeWidth={lineWidth}
                             stroke={gridLineColor}/>);
        gridLines.push(<Line key={`v${i}`} points={[plotLeft, y, plotRight, y]} strokeWidth={lineWidth}
                             stroke={gridLineColor}/>);
    }
    const midY = plotTop + (plotBottom - plotTop) / 2;
    gridLines.push(<Line key={`vz`} points={[plotLeft, midY, plotRight, midY]} strokeWidth={4 * MAJOR_LINE_WIDTH_PX}
                         stroke={gridLineColor} opacity={0.5}/>)
    const text = new Konva.Text({fontSize: labelTextSize});
    const xLabelSize = text.measureSize(t("spiro_axis_volume")).width;
    text.remove();
    const labels = [];
    for (let i = 0; i <= 8; i++) {
        const labelY = 12 - i * 3;
        const labelX = i;
        labels.push(<Text key={`lab-y-${i}`} x={0} y={plotTop + i * 2 * gridSize - 0.5 * labelTextSize}
                          fill={labelColor} opacity={0.8}
                          fontStyle={"bold"} fontSize={labelTextSize} width={(2 * PLOT_MARGIN_MM - 2) * PX_PER_MM}
                          align={"right"}
                          text={`${labelY}`}/>)
        if (plotLeft + i * 2 * gridSize + 2 * PLOT_MARGIN_MM * PX_PER_MM < plotRight - xLabelSize) {
            labels.push(<Text key={`lab-x-${i}`} x={plotLeft + i * 2 * gridSize - PLOT_MARGIN_MM * PX_PER_MM}
                              y={plotBottom + 2 * PX_PER_MM} fill={labelColor} opacity={0.8}
                              fontStyle={"bold"} fontSize={labelTextSize} width={2 * PLOT_MARGIN_MM * PX_PER_MM}
                              align={"center"}
                              text={`${labelX}`}/>)
        }
    }
    const scaleX = gridSize / 0.5;
    const scaleY = gridSize / 1.5;
    const dataPoints = data.flatMap(p => [plotLeft + p.x * scaleX, midY - p.y * scaleY]);
    const referencePoints = reference && reference.length > 0 ? reference.map(points => points.flatMap(p => [plotLeft + p.x * scaleX, midY - p.y * scaleY])) : undefined;
    const expectedData = expected && expected.map((p, index) => {
        let s : string = "";
        if (index === 0){
            s += "M"
        } else {
            s += "L";
        }
        s += `${plotLeft + p.x * scaleX} ${midY - p.y * scaleY}`;
        if (index === expected.length - 1){
            s += "Z";
        }
        return s;
    }).join();
    return (
        <Stage width={size} height={size}>
            <Layer>
                <Rect width={size} height={size} x={0} y={0} strokeEnabled={false} fill={backgroundColor}
                      fillEnabled={true}/>
                {gridLines}
                <Line key={"data-line"} points={dataPoints} strokeWidth={DATA_LINE_WIDTH}
                      stroke={dataColor}/>
                {referencePoints && referencePoints.map((rp, i) => <Line key={`ref-line-${i}`} points={rp}
                                                                         strokeWidth={REFERENCE_LINE_WIDTH}
                                                                         stroke={referenceColors[i]} opacity={0.5}/>)}
                {expectedData && <Path x={0} y={0} data={expectedData} fill={expectedColor} opacity={0.2}/>}
                <Rect width={plotSize} height={plotSize} x={plotLeft} y={plotTop} strokeWidth={MAJOR_LINE_WIDTH_PX}
                      stroke={gridLineColor}/>
                <Text x={plotLeft} y={1 * PX_PER_MM} fill={labelColor}
                      fontStyle={"bold"} fontSize={labelTextSize} width={plotSize} align={"left"}
                      text={t("spiro_axis_flow")}/>
                <Text x={plotLeft} y={plotBottom + 2 * PX_PER_MM} fill={labelColor}
                      fontStyle={"bold"} fontSize={labelTextSize} width={plotSize} align={"right"}
                      text={t("spiro_axis_volume")}/>
                {labels}
            </Layer>
        </Stage>
    );
}