import React from 'react';
import { useEffect, useState } from 'react';
import './Toast.css';

interface ToastProps {
    text: string;
    isError?: boolean;
}


export const Toast: React.FC<ToastProps> = ({ text, isError = false }: ToastProps) => {
    const [isFired, setFired] = useState(false);
    const [isVisible, setVisibility] = useState(false);
    useEffect(() => {
        let id: any = null;
        if (!isFired) {
            if (isVisible) {
                id = setTimeout(() => {
                    setVisibility(false);
                    setFired(true);
                }, 3000);
            } else {
                id = setTimeout(() => setVisibility(true), 100);
            }
        }
        return () => {
            if (id) {
                clearTimeout(id);
            }
        }
    })
    return (
        <div className={`toast-container ${isError ? "toast-error" : ""} ${isVisible ? "toast-visible" : ""}`}>{text}</div>
    );
}