import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import PagedData from "../../../models/PagedData";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ApiHelper from "../../../api/ApiHelper";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {FetchError} from "../FetchError/FetchError";
import {ListPagination} from "../Pagination/ListPagination";
import {Divider} from "@material-ui/core";
import PatientAccessPlaceholder from "../PatientAccessPlaceholder/PatientAccessPlaceholder";
import {RecordAccessData} from "../../../models/RecordAccessData";
import RecordAccess from "../RecordAccess/RecordAccess";
import {logError} from "../../../helpers/LogHelper";

interface RecordAccessListProps {
    pageSize: number;
    isOwn: boolean;
    userClickHandler: (data: RecordAccessData) => void;
}

export const RecordAccessList: React.FC<RecordAccessListProps> = ({
                                                                      pageSize,
                                                                      isOwn,
                                                                      userClickHandler
                                                                  }: RecordAccessListProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [users, setUsers] = useState([] as RecordAccessData[]);
    const paginationClickHandler = (page: number) => {
        setFetchingState(true);
        setCurrentPage(page);
    };
    const usersHandler = (pagedData: PagedData<RecordAccessData>) => {
        setFetchingState(false);
        setErrorState(false);
        setUsers(pagedData.data);
        setCurrentPage(pagedData.page);
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Access data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchUsers = (page: number) => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecordsSharedByUser(userToken, "_", page, pageSize, controller, usersHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchUsers(currentPage), [currentPage]);     // eslint-disable-line
    if (isFetching) {
        return (
            <AsyncIndicator/>
        );
    }
    if (hasError) {
        return (
            <FetchError onRetry={() => fetchUsers(currentPage)}/>
        );
    }
    const userComponents = users.map(data => <RecordAccess key={data.id} data={data} isOwn={isOwn}
                                                           onClick={() => userClickHandler(data)}/>)
    const hasData = userComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {(hasData) ?
                    (
                        <Fragment>
                            <div className="data-list-title">{t("shared_records")}: <span>{totalRecords}</span></div>
                            <Divider/>
                            <table>
                                <thead>
                                <tr className="table-header">
                                    <th>{t("user")}</th>
                                    <th>{t("email")}</th>
                                    <th>{t("patient")}</th>
                                    <th>{t("record")}</th>
                                    <th>{t("type")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userComponents}
                                </tbody>
                            </table>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="data-list-title">{t("shared_records")}</div>
                            <Divider/>
                            <PatientAccessPlaceholder className="my-4"/>
                        </Fragment>
                    )
                }
            </div>
            {totalPages > 1 &&
            <ListPagination page={currentPage} totalPages={totalPages} clickHandler={paginationClickHandler}/>}
        </div>
    );
}