import React from "react";

function SvgStateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 8 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.952 2.282L5.13.514a.59.59 0 00-.417-.168H1.149a.581.581 0 00-.59.572v7.22c0 .315.265.571.59.571h5.387c.325 0 .589-.256.589-.571V2.687a.555.555 0 00-.173-.405zm-.689.024H5.19a.082.082 0 01-.084-.082V1.182l1.158 1.124zm.273 5.913H1.149a.082.082 0 01-.084-.081V.918c0-.046.037-.082.084-.082h3.45v1.388c0 .316.265.572.59.572H6.62v5.342a.082.082 0 01-.084.081z"
                fill="currentColor"
            />
            <path
                d="M4.113 4.087h1.61c.14 0 .252-.11.252-.245a.249.249 0 00-.252-.245h-1.61c-.14 0-.252.109-.252.245 0 .135.113.245.252.245zM4.113 5.72h1.61c.14 0 .252-.11.252-.245a.249.249 0 00-.252-.245h-1.61c-.14 0-.252.11-.252.245 0 .136.113.245.252.245zM5.73 6.864H4.112c-.14 0-.252.109-.252.245 0 .135.113.245.252.245H5.73c.14 0 .253-.11.253-.245a.249.249 0 00-.253-.245zM2.854 3.175l-.582.565-.138-.134a.256.256 0 00-.357 0 .24.24 0 000 .347l.316.307a.258.258 0 00.357 0l.761-.739c.1-.096.1-.251 0-.346a.256.256 0 00-.357 0zM2.854 4.933l-.582.565-.138-.134a.256.256 0 00-.357 0 .24.24 0 000 .346l.316.307a.258.258 0 00.357 0l.761-.738c.1-.096.1-.252 0-.346a.256.256 0 00-.357 0zM2.854 6.566l-.582.565-.138-.134a.256.256 0 00-.357 0 .24.24 0 000 .347l.316.307a.258.258 0 00.357 0l.761-.738c.1-.097.1-.252 0-.347a.256.256 0 00-.357 0z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgStateIcon;
