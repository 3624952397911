import * as React from "react";

function SvgSaveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.57 12.429A.575.575 0 0111 13a.575.575 0 01-.572-.571c0-.313.259-.572.571-.572.313 0 .571.26.571.572zm2.286 0a.575.575 0 01-.571.571.575.575 0 01-.572-.571c0-.313.26-.572.572-.572.312 0 .571.26.571.572zm1.143-2a.857.857 0 00-.857-.857H9.999l-1.214 1.214c-.33.321-.76.5-1.214.5-.456 0-.884-.179-1.215-.5L5.151 9.572H.999a.857.857 0 00-.857.857v2.857c0 .473.384.857.857.857h13.143a.857.857 0 00.857-.857v-2.857zm-2.902-5.08A.578.578 0 0011.57 5H9.285V1A.575.575 0 008.713.43H6.428A.575.575 0 005.856 1v4H3.571a.578.578 0 00-.527.348.562.562 0 00.125.625l4 4c.107.116.259.17.402.17a.547.547 0 00.401-.17l4-4c.17-.16.215-.41.125-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSaveIcon;
