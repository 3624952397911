import React from "react";
import { calculateAge } from "../../../helpers/AgeHelper";
import { formatAgeShort, formatGenderShort, formatHeight, formatWeight } from "../../../helpers/FormatHelper";
import { Patient } from "../../../models/Patient";
import {useTranslation} from "react-i18next";

interface PatientProps {
    patient: Patient;
    onClick: () => void;
}

export const PatientShort: React.FC<PatientProps> = ({ patient, onClick }: PatientProps) => {
    const {t} = useTranslation();
    return (
        <tr onClick={onClick}>
            <td>{patient.name}</td>
            <td width="1" align="center">{formatGenderShort(t, patient.gender)}</td>
            <td width="1" align="center">•</td>
            <td width="1" align="center">{formatAgeShort(calculateAge(patient.birthDate))}</td>
            <td width="1" align="center">•</td>
            <td width="1" align="center">{formatHeight(t, patient.height, patient.heightUnits)}</td>
            <td width="1" align="center">•</td>
            <td width="1" align="center">{formatWeight(t, patient.weight, patient.weightUnits)}</td>
        </tr>
    );
}