import {Col, Container, Row} from "react-bootstrap";
import qrcode_binora from '../../assets/images/qrcode_binora.png';
import qrcode_binora_lt_ecg from '../../assets/images/qrcode_binora_lt_ecg.png';
import {useTranslation} from "react-i18next";

export function MobileApp() {
    const {t} = useTranslation();
    return (
        <Container fluid>
            <Row md={2}>
                <Col>
                    <div className="d-flex flex-column w-100 align-items-center">
                        <span className={"mt-4"} style={{fontSize: "36px", fontWeight: 600}}>Binora</span>
                        <a href='https://play.google.com/store/apps/details?id=app.binora&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                           className={'w-100 text-center'}>
                            <img alt='Get it on Google Play' style={{width: "50%", maxWidth: "512px"}}
                                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <img src={qrcode_binora} alt="https://my.binora.com.ua/downloads/binora-latest.apk"
                             style={{width: "50%", maxWidth: "512px"}}/>
                        <a href='https://my.binora.com.ua/downloads/binora-latest.apk'
                           style={{fontSize: "30px", fontWeight: 400}}>{t('download_apk')}</a>
                    </div>
                </Col>
                <Col>
                    <div className="d-flex flex-column w-100 align-items-center">
                        <span className={"mt-4"} style={{fontSize: "36px", fontWeight: 600}}>Binora LT ECG</span>
                        <a href='https://play.google.com/store/apps/details?id=app.binora&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                           className={'w-100 text-center'}>
                            <img alt='Get it on Google Play' style={{width: "50%", maxWidth: "512px"}}
                                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <img src={qrcode_binora_lt_ecg} alt="https://my.binora.com.ua/downloads/binora-lt-ecg-latest.apk"
                             style={{width: "50%", maxWidth: "512px"}}/>
                        <a href='https://my.binora.com.ua/downloads/binora-lt-ecg-latest.apk'
                           style={{fontSize: "30px", fontWeight: 400}}>{t('download_apk')}</a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}