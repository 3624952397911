import {NavLink} from "react-router-dom";
import * as AppRoutes from "../../../routes";
import HeaderLogo from "./HeaderLogo";
import {Avatar} from "@material-ui/core";
import {AccountDropdown} from "./AccountDropdown";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import React from "react";
import {UserInfo} from "../../../models/UserInfo";
import {getUserPhotoUrl} from "../../../api/ApiHelper";

interface HeaderFullProps{
    links: {text: string, path: string}[];
    userInfo : UserInfo | null;
    handleProfileClick: () => void;
    handleSettingsClick: () => void;
    handleLogOutClick: () => void;
    layoutChangeListener?: (isEnoughSpace: boolean) => void;
}

export  const HeaderFull = ({links, userInfo, handleProfileClick, handleSettingsClick, handleLogOutClick, layoutChangeListener} : HeaderFullProps) => {
    const photoUrl = getUserPhotoUrl(userInfo?.photoUrl);
    const navLinkClassName = "header-link";
    const navLinks = links.map(link => <NavLink key={link.path} className={`${navLinkClassName} disable-select`} to={link.path}>{link.text}</NavLink>)
    const onLayoutChanged = (width : number) => {
        if (layoutChangeListener){
            layoutChangeListener(width < 198);
        }
    };
    return (
        <div className="header">
            <NavLink to={AppRoutes.HOME_ROUTE}><HeaderLogo sizeChangeListener={layoutChangeListener ? onLayoutChanged : undefined} /></NavLink>
            <div className="header-links">
                {navLinks}
            </div>
            <div className="header-options">
                <div className="header-profile">
                    <Avatar className="header-avatar-img" alt={userInfo?.name} src={photoUrl} onClick={handleProfileClick} />
                    <AccountDropdown userName={userInfo?.name ?? ""} onProfileClick={handleProfileClick} onSettingsClick={handleSettingsClick} onLogoutClick={handleLogOutClick}/>
                </div>
                <div className="header-options-divider" />
                <div className="header-options-dropdown">
                    <LanguageDropdown />
                </div>
            </div>
        </div>
    );
}