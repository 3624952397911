import React from "react";
import {getUserPhotoUrl} from "../../../api/ApiHelper";
import {Avatar} from "@material-ui/core";
import './PatientAccess.css';
import {Code} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {PatientAccessData} from "../../../models/PatientAccessData";

interface PatientAccessProps {
    data: PatientAccessData;
    isOwn: boolean;
    onClick: (id: string) => void;
}

const PatientAccess: React.FC<PatientAccessProps> = ({data, isOwn, onClick}: PatientAccessProps) => {
    const {t} = useTranslation();
    const photoUrl = getUserPhotoUrl(isOwn ? data.holderPhotoUrl : data.ownerPhotoUrl);
    return (
        <tr onClick={() => onClick(data.id)}>
            <td align="center" className="d-flex justify-content-center align-items-center"><Avatar
                className="user-avatar-img mx-2" alt={isOwn ? data.holderName : data.ownerName} src={photoUrl}/>{isOwn ? data.holderName : data.ownerName}</td>
            <td align="center">{isOwn ? data.holderEmail : data.ownerEmail}</td>
            <td align="center">{data.patientName}</td>
            <td align="center"><div className={"type-icon"}><Code/><span>{t("share_link")}</span></div></td>
        </tr>
    );
}

export default PatientAccess;