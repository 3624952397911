import React, {useEffect, useState} from "react";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import PagedData from "../../../models/PagedData";
import {Patient} from "../../../models/Patient";
import * as ApiHelper from '../../../api/ApiHelper';
import {SORT_BY_DATE, SORT_BY_NAME} from "../../../models/SortType";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {FetchError} from "../FetchError/FetchError";
import {PatientsLong} from "../PatientsLong/PatientsLong";
import {SearchInput} from "../SearchInput/SearchInput";
import PatientsPlaceholder from "../PatientsPlaceholder/PatientsPlaceholder";
import {ListPagination} from "../Pagination/ListPagination";
import {useTranslation} from "react-i18next";
import {AccessType} from "../../../models/AccessType";
import {SmallDoubleButton, SmallDoubleButtonSide} from "../SmallDoubleButton/SmallDoubleButton";
import {useHistory} from "react-router";
import {buildListParameters, updateListParameters} from "../../../models/ListParameters";
import {logError} from "../../../helpers/LogHelper";

interface PatientsListProps {
    tag: string;
    showTitle?: boolean;
    pageSize: number;
    userId: string | null;
    accessType: AccessType;
    patientClickHandler: (id: string) => void;
    contextMenuHandler?: (id: string, anchorX: number, anchorY: number) => void;
    userClickHandler?: (id: string) => void;
}

export const PatientsList: React.FC<PatientsListProps> = ({
                                                              tag,
                                                              showTitle = false,
                                                              pageSize,
                                                              userId,
                                                              accessType,
                                                              patientClickHandler,
                                                              contextMenuHandler,
                                                              userClickHandler
                                                          }: PatientsListProps) => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [, setController] = useAbortController();
    const token = useAppSelector(selectToken);
    const [isBackChecked, setBackChecked] = useState(false);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listParameters, setListParameters] = useState(buildListParameters(1, "", SORT_BY_DATE));
    const [patients, setPatients] = useState([] as Patient[]);
    const handleFilterChange = (filter: string) => {
        setListParameters(updateListParameters(listParameters, 1, filter, undefined));
    };
    const handleOrderChange = (order: string) => {
        setListParameters(updateListParameters(listParameters, undefined, undefined, order));
    }
    const paginationClickHandler = (page: number) => {
        setFetchingState(true);
        setListParameters(updateListParameters(listParameters, page, undefined, undefined));
    };
    const patientsHandler = (pagedData: PagedData<Patient>) => {
        setFetchingState(false);
        setErrorState(false);
        setPatients(pagedData.data);
        setListParameters(updateListParameters(listParameters, pagedData.page, undefined, undefined));
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Patients data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchPatients = (page: number, filter: string, order: string) => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            localStorage.setItem(tag, JSON.stringify({page: page, filter: filter, order: order}));
            const abortController = new AbortController();
            setController(abortController);
            ApiHelper.getPatients(userToken, userId, accessType.valueOf(), filter !== "" ? filter : null, order, page, pageSize, abortController, patientsHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => {
        if (history.action === "POP") {
            const s = localStorage.getItem(tag);
            if (s) {
                const data = JSON.parse(s);
                try {
                    const pageNumber = data.page;
                    const filter = data.filter;
                    const order = data.order;
                    if (typeof pageNumber === "number" && typeof filter === "string" && typeof order === "string") {
                        setListParameters(updateListParameters(listParameters, pageNumber, filter, order));
                    }
                } catch {
                    //do nothing
                }
            }
        }
        setBackChecked(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);
    useEffect(() => {
        if (isBackChecked) {
            fetchPatients(listParameters.page, listParameters.filter, listParameters.order);
        }
    }, [isBackChecked, listParameters.page, listParameters.filter, listParameters.order]);     // eslint-disable-line
    if (!isFetching && hasError) {
        return (
            <FetchError
                onRetry={() => fetchPatients(listParameters.page, listParameters.filter, listParameters.order)}/>
        );
    }
    const patientComponents = patients.map(patient => <PatientsLong key={patient.id} patient={patient}
                                                                    onClick={() => patientClickHandler(patient.id)}
                                                                    onContextMenuHandler={(x, y) => contextMenuHandler ? contextMenuHandler(patient.id, x, y) : null}
                                                                    onUserClick={userClickHandler}/>)
    const hasData = patientComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {showTitle &&
                    <div className="data-list-title">{t("total_patients")}: <span>{totalRecords}</span></div>
                }
                <div className="d-flex justify-content-between align-items-center">
                    <SearchInput text={listParameters.filter} onChange={handleFilterChange}/>
                    <div className="d-flex flex-grow-1" style={{minWidth: "64px"}}/>
                    <SmallDoubleButton leftButtonText={t("name")} rightButtonText={t("date")}
                                       activeButton={listParameters.order === SORT_BY_DATE ? SmallDoubleButtonSide.Right : SmallDoubleButtonSide.Left}
                                       onSwitch={(side) => handleOrderChange(side === SmallDoubleButtonSide.Right ? SORT_BY_DATE : SORT_BY_NAME)}/>
                </div>
                {isFetching && <AsyncIndicator/>}
                {!isFetching && (hasData ? (
                    <table>
                        <thead>
                        <tr className="table-header">
                            {(accessType === AccessType.Shared || (accessType === AccessType.All && userId === null)) &&
                                <th className="text-left">{t("user")}</th>
                            }
                            <th className="text-left">{t("name")}</th>
                            <th>{t("birthdate")}</th>
                            <th>{t("phone")}</th>
                            <th>{t("email")}</th>
                            <th>{t("gender")}</th>
                            <th>{t("height")}</th>
                            <th>{t("weight")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {patientComponents}
                        </tbody>
                    </table>
                ) : (
                    <PatientsPlaceholder className="my-4"/>
                ))}
            </div>
            {!isFetching && totalPages > 1 &&
                <ListPagination page={listParameters.page} totalPages={totalPages}
                                clickHandler={paginationClickHandler}/>}
        </div>
    );
}