import React from 'react';
import { formatRole } from '../../../helpers/FormatHelper';
import { User } from '../../../models/User';
import {useTranslation} from "react-i18next";

interface UsersProps {
    user: User;
    onClick: () => void;
}


const UsersShort: React.FC<UsersProps> = ({user, onClick } : UsersProps) => {
    const {t} = useTranslation();
    return (
        <tr onClick={onClick}>
            <td>{user.name}</td>
            <td width="1" align="center">{user.email}</td>
            <td width="1" align="center">{formatRole(t, user.role)}</td>
        </tr>
        );
}

export default UsersShort;