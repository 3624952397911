export interface RecordListParameters{
    page: number;
    filter: string;
    order : string;
    showState : boolean;
    showEcg : boolean;
    showStetho : boolean;
    showSpiro : boolean;
    showLtEcg : boolean;
}

export function buildRecordListParameters(page : number, filter: string, order : string, showState : boolean, showEcg : boolean, showStetho : boolean, showSpiro : boolean, showLtEcg : boolean) : RecordListParameters{
    return {
        page: page,
        filter: filter,
        order: order,
        showState: showState,
        showEcg : showEcg,
        showStetho : showStetho,
        showSpiro : showSpiro,
        showLtEcg: showLtEcg
    } as RecordListParameters;
}

export function updateRecordListParameters(parameters: RecordListParameters, page: number | undefined, filter : string | undefined, order : string | undefined, showState : boolean | undefined, showEcg : boolean | undefined, showStetho : boolean | undefined, showSpiro : boolean | undefined, showLtEcg : boolean | undefined){
    return {
        page: page !== undefined ? page : parameters.page,
        filter: filter !== undefined ? filter : parameters.filter,
        order: order !== undefined ? order : parameters.order,
        showState: showState !== undefined ? showState : parameters.showState,
        showEcg : showEcg !== undefined ? showEcg : parameters.showEcg,
        showStetho : showStetho !== undefined ? showStetho : parameters.showStetho,
        showSpiro : showSpiro !== undefined ? showSpiro : parameters.showSpiro,
        showLtEcg: showLtEcg !== undefined ? showLtEcg : parameters.showLtEcg
    } as RecordListParameters;
}