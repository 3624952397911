import * as React from "react";

function SvgHomeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#HomeIcon_svg__clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.495 14H.009V4.622L5.25 0l5.243 4.62V14h.002zm-9.916-.591h9.346V4.9L5.25.777.578 4.896v8.515l.001-.002zm6.862-1.91H3.066v-4.55h4.375v4.55zm-3.809-.593H6.87V7.543H3.632v3.363z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="HomeIcon_svg__clip0">
          <path fill="#fff" d="M0 0h10.5v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgHomeIcon;
