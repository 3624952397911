import React from "react";

function SvgEditValueIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.946 7.643h-.375V7H4.93v-.375l.776-.777 1.018 1.018-.777.777zM8.893 2.82c.06.06.053.161-.007.221L6.542 5.386c-.06.06-.16.067-.22.007-.06-.06-.054-.16.006-.221l2.344-2.344c.06-.06.16-.067.22-.007zM9.429 6.8a.21.21 0 00-.134-.194.207.207 0 00-.235.047l-.428.428a.208.208 0 00-.06.148v.843c0 .59-.483 1.072-1.072 1.072H1.929c-.59 0-1.072-.482-1.072-1.072V2.5c0-.59.482-1.071 1.072-1.071H7.5c.1 0 .2.013.301.04a.202.202 0 00.215-.054l.328-.328a.21.21 0 00.06-.194.218.218 0 00-.12-.154A1.874 1.874 0 007.5.57H1.929A1.93 1.93 0 000 2.5v5.571A1.93 1.93 0 001.929 10H7.5a1.93 1.93 0 001.929-1.929V6.8zm-.643-4.942l-4.5 4.5v1.929h1.928l4.5-4.5-1.928-1.929zm2.973.884a.65.65 0 000-.91L10.74.811a.65.65 0 00-.91 0l-.617.617 1.929 1.928.616-.616z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgEditValueIcon;
