import * as React from "react"
import { SVGProps } from "react"

const IconTemperature = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        xmlSpace="preserve"
        viewBox="0 0 7 10"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.344.324v6.805c0 .297.148.445.449.445.297 0 .445-.148.445-.445V.324c0-.297-.148-.445-.445-.445-.301 0-.449.148-.449.445z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.344.324v6.805c0 .297.148.445.449.445.297 0 .445-.148.445-.445V.324c0-.297-.148-.445-.445-.445-.301 0-.449.148-.449.445z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.746 7.035c.461 0 .84.379.84.84a.841.841 0 1 1-.84-.84z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.746 7.035c.461 0 .84.379.84.84a.841.841 0 1 1-.84-.84z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M-.051 7.285h.828l3.95-5.996h.871"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M-.051 7.285h.828l3.95-5.996h.871"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.344.324v6.805c0 .297.148.445.449.445.297 0 .445-.148.445-.445V.324c0-.297-.148-.445-.445-.445-.301 0-.449.148-.449.445z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.344.324v6.805c0 .297.148.445.449.445.297 0 .445-.148.445-.445V.324c0-.297-.148-.445-.445-.445-.301 0-.449.148-.449.445z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.746 7.035c.461 0 .84.379.84.84a.841.841 0 1 1-.84-.84z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M2.746 7.035c.461 0 .84.379.84.84a.841.841 0 1 1-.84-.84z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M-.051 7.285h.828l3.95-5.996h.871"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M-.051 7.285h.828l3.95-5.996h.871"
        />
    </svg>
)

export default IconTemperature
