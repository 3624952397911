import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {Button} from "../../Widgets/Button/Button";
import {UserAccessList} from "../../Widgets/UserAccessList/UserAccessList";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {DialogConfirmation} from "../../Widgets/DialogConfirmation/DialogConfirmation";
import {UserAccessData} from "../../../models/UserAccessData";
import {DialogGrantAccess} from "../../Widgets/DialogGrantAccess/DialogGrantAccess";
import * as ApiHelper from "../../../api/ApiHelper";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import {Record} from "../../../models/Record";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {Patient} from "../../../models/Patient";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {ShareType} from "../../../models/ShareType";
import {DialogPublicLink} from "../../Widgets/DialogPublicLink/DialogPublicLink";
import {logError} from "../../../helpers/LogHelper";

interface PathParams {
    patientId: string;
    recordId: string;
}

interface AccessControlOwnDataProps {
    isAppointment: boolean;
}

export const AccessControlOwnData: React.FC<AccessControlOwnDataProps> = ({isAppointment}: AccessControlOwnDataProps) => {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    let {patientId, recordId} = useParams<PathParams>();
    const [updateToken, setUpdateToken] = useState(1);
    const [record, setRecord] = useState(null as Record | null);
    const [patient, setPatient] = useState(null as Patient | null);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [showGrantAccessDialog, setShowGrantAccessDialog] = useState(false);
    const [showPublicLinkDialog, setShowPublicLinkDialog] = useState(false);
    const [contextUserAccessData, setContextUserAccessData] = useState(null as UserAccessData | null);
    const recordHandler = (record: Record) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
    };
    const patientHandler = (patient: Patient) => {
        setErrorState(false);
        setPatient(patient);
        setFetchingState(false);
        setNotFoundState(false);
    };
    const rejectHandler = () => {
        setFetchingState(false);
        setUpdateToken((v) => v + 1);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Access data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchPatient = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getPatient(userToken, patientId, controller, patientHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchData = () => {
        if (recordId) {
            fetchRecord();
        } else {
            if (patientId) {
                fetchPatient();
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, [recordId, patientId]);     // eslint-disable-line
    const grantAccessClick = () => {
        setShowGrantAccessDialog(true);
    };
    const handleUserClick = (data: UserAccessData) => {
        setContextUserAccessData(data);
        setShowDeleteConfirmationDialog(true)
    }
    const rejectAccess = () => {
        setShowDeleteConfirmationDialog(false);
        let userToken = token?.token;
        let dataId = contextUserAccessData?.id;
        if (userToken) {
            setFetchingState(true);
            if (contextUserAccessData?.type === ShareType.Association) {
                ApiHelper.removeAssociatedUser(userToken, contextUserAccessData?.email ?? "", controller, rejectHandler, errorHandler);
            } else {
                if (dataId) {
                    if (isAppointment) {
                        ApiHelper.rejectRecordShare(userToken, [dataId], controller, rejectHandler, errorHandler);
                    } else {
                        ApiHelper.rejectPatientShare(userToken, [dataId], controller, rejectHandler, errorHandler);
                    }
                }
            }
        }
    };
    const dialogGrantAccessCloseHandler = (refresh: boolean) => {
        setShowGrantAccessDialog(false);
        if (refresh) {
            setUpdateToken((v) => v + 1);
        }
    }
    const publicLinkClick = () => {
        setShowPublicLinkDialog(true);
    }
    const dialogPublicLinkCloseHandler = () => {
        setShowPublicLinkDialog(false);
    }
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId ?? "_1", record ? formatDateTime(record.dateTime) : null], [patientId ?? "_2", record ? (record.patientName ?? null) : (patient?.name ?? null)]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchData}/>}
            {!isFetching && notFound && <NotFound/>}
            {isOk &&
                <div className="d-flex flex-column align-items-center">
                    <div className="d-flex flex-row align-items-center justify-content-center my-4">
                        <Button className="mx-2" text={t("grant_access")}
                                highlighted={true}
                                onClick={grantAccessClick}/>
                        {isAppointment &&
                            <Button className="mx-2" text={t("public_link")}
                                    highlighted={true}
                                    onClick={publicLinkClick}/>}
                    </div>
                    <UserAccessList key={updateToken} id={isAppointment ? recordId : patientId}
                                    isAppointment={isAppointment}
                                    pageSize={DATA_LIST_PAGE_SIZE} userClickHandler={handleUserClick}/>
                </div>
            }
            {showDeleteConfirmationDialog &&
                <DialogConfirmation titleText={t("access_control")}
                                    messageText={t(contextUserAccessData?.type === ShareType.Link ? "confirm_access_reject" : "confirm_association_reject", {
                                        name: contextUserAccessData?.name ?? "",
                                        email: contextUserAccessData?.email ?? ""
                                    })}
                                    okButtonText={t("yes")} cancelButtonText={t("no")}
                                    okButtonClickHandler={rejectAccess}
                                    cancelButtonClickHandler={() => setShowDeleteConfirmationDialog(false)}/>}
            {showGrantAccessDialog &&
                <DialogGrantAccess token={token?.token ?? ""} id={isAppointment ? recordId : patientId}
                                   isAppointment={isAppointment}
                                   controller={controller}
                                   closeHandler={dialogGrantAccessCloseHandler}/>}
            {showPublicLinkDialog &&
                <DialogPublicLink token={token?.token ?? ""}
                                  id={recordId}
                                  controller={controller}
                                  closeHandler={dialogPublicLinkCloseHandler}/>}
        </div>
    );
}