import React from "react";

function SvgInfoCardMask(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 357 165"
            fill="none"
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <mask
                id="mask_svg__a"
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={357}
                height={165}
            >
                <path fill="#FF1F1F" d="M0 0h357v165H0z" />
            </mask>
            <g mask="url(#mask_svg__a)" fill="#fff">
                <ellipse opacity={0.25} cx={8} cy={15} rx={16.252} ry={27.722} />
                <path
                    d="M81.35 122.186c-25.026-2.218-51.056 18.481-60.942 29.108v12.822h335.796V23.772c-2.98-9.934-9.67-23.841-12.595 0-3.657 29.801-23.159 55.098-62.771 43.662-39.613-11.435-56.271 0-81.055 30.495-24.783 30.494-87.148 27.029-118.432 24.257z"
                    opacity={0.25}
                />
            </g>
        </svg>
    );
}
export default SvgInfoCardMask;

