import React, {useEffect, useState} from "react";
import * as ApiHelper from '../../../api/ApiHelper';
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken, selectUserInfo} from "../../../features/account/accountSlice";
import {SORT_BY_DATE} from "../../../models/SortType";
import {Patient} from "../../../models/Patient";
import ErrorResponse from "../../../models/ErrorResponse";
import PagedData from "../../../models/PagedData";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {FetchError} from "../FetchError/FetchError";
import {PatientShort} from "../PatientsShort/PatientsShort";
import InfoCard from "../InfoCard/InfoCard";
import PatientsPlaceholder from "../PatientsPlaceholder/PatientsPlaceholder";
import {Role} from '../../../models/Role';
import {useTranslation} from "react-i18next";
import {AccessType} from "../../../models/AccessType";
import {logError} from "../../../helpers/LogHelper";

interface LimitProps {
    limit: number;
    patientClickHandler: (id: string) => void;
    allPatientsClickHandler: () => void;
}

export const RecentPatients: React.FC<LimitProps> = ({ limit, patientClickHandler, allPatientsClickHandler }: LimitProps) => {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const userInfo = useAppSelector(selectUserInfo);
    const isSupport = useAccessInfo(Role.Support);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [patients, setPatients] = useState([] as Patient[]);
    const patientsHandler = (pagedData: PagedData<Patient>) => {
        setFetchingState(false);
        setErrorState(false);
        setTotalRecords(pagedData.totalRecords);
        setPatients(pagedData.data);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Patients data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchPatients = () => {
        let userToken = token?.token;
        let userId = isSupport ? null : userInfo?.id;
        if (userToken && userId !== undefined) {
            setFetchingState(true);
            ApiHelper.getPatients(userToken, userId, AccessType.Owner.valueOf(), null, SORT_BY_DATE, 1, limit, controller, patientsHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchPatients(), []);     // eslint-disable-line
    if (isFetching) {
        return (
            <AsyncIndicator />
        );
    }
    if (hasError) {
        return (
            <FetchError onRetry={fetchPatients} />
        );
    }
    const patientComponents = patients.map((patient) => <PatientShort key={patient.id} patient={patient} onClick={() => patientClickHandler(patient.id)} />);
    const hasData = patientComponents.length > 0;
    return (
        <div className="data-list-container">
            <InfoCard contentClass="patients" titleText={t("patients")} captionText={t("total_patients_format" , {count : totalRecords})} buttonText={t("view")} onClick={allPatientsClickHandler} />
            <div className="mt-4 mb-4 data-list">
                {hasData && <div className="title disable-select">{t("recent_patients")}</div>}
                {hasData ? (
                    <table>
                        <tbody>
                            {patientComponents}
                        </tbody>
                    </table>
                ) : (
                    <PatientsPlaceholder />
                )}
            </div>
        </div>
    );
}