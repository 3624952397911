import React from "react";

function SvgErrorIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.998.571A5.144 5.144 0 00.855 5.714a5.144 5.144 0 005.143 5.143 5.144 5.144 0 005.143-5.143A5.144 5.144 0 005.998.571zm.857 8.35c0 .121-.093.222-.207.222H5.362c-.12 0-.22-.1-.22-.221V7.65c0-.121.1-.221.22-.221h1.286c.114 0 .207.1.207.22v1.273zm-.013-2.303c-.007.094-.107.168-.228.168H5.376c-.128 0-.228-.074-.228-.168L5.034 2.46c0-.047.02-.094.067-.12.04-.034.1-.054.16-.054h1.474c.06 0 .12.02.16.053a.134.134 0 01.068.12l-.12 4.16z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgErrorIcon;