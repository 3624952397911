import React from "react";
import './ClickableText.css';

interface ClickableTextProps {
    text: string;
    onClick: () => void;
}

export const ClickableText: React.FC<ClickableTextProps> = ({ text, onClick }: ClickableTextProps) => {
    return (
        <div className="clickable-text" onClick={onClick}>
            {text}
        </div>
    );
}