import * as React from "react";

function SvgLandscape2x6Icon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 35 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4,2h26v20H4z"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"/>
            <path
                fill="currentColor"
                d="M14.4,13.8L14.4,15L9.7,15v-1l2.4,-2.2 0.5,-0.7 0.2,-0.5c0,-0.3 -0.1,-0.4 -0.3,-0.6l-0.8,-0.2c-0.2,0 -0.5,0 -0.7,0.2 -0.2,0 -0.4,0.2 -0.6,0.4l-1,-0.6c0.2,-0.4 0.6,-0.7 1,-0.9 0.4,-0.2 1,-0.3 1.5,-0.3 0.4,0 0.9,0 1.2,0.2 0.4,0.2 0.6,0.4 0.8,0.7 0.2,0.3 0.3,0.6 0.3,1 0,0.3 0,0.6 -0.2,1l-0.8,1 -1.5,1.3h2.7zM18.4,15l-1.1,-1.5 -1.1,1.5h-1.5l1.8,-2.4 -1.8,-2.4h1.6l1,1.4 1,-1.4L20,10.2L18,12.5 20,15h-1.6zM23.3,11c0.4,0 0.8,0.1 1,0.3 0.4,0.2 0.7,0.4 0.9,0.7 0.2,0.3 0.3,0.6 0.3,1a2,2 0,0 1,-1.2 1.9L23,15c-1,0 -1.6,-0.3 -2.1,-0.8s-0.7,-1.3 -0.7,-2.3c0,-0.7 0,-1.3 0.4,-1.9 0.2,-0.5 0.6,-0.8 1,-1.1 0.6,-0.3 1.1,-0.4 1.8,-0.4a3.9,3.9 0,0 1,1.7 0.4l-0.5,1.1a2,2 0,0 0,-1.2 -0.3c-0.5,0 -1,0.1 -1.3,0.4a2,2 0,0 0,-0.5 1.4c0.4,-0.4 1,-0.6 1.6,-0.6zM23,14c0.3,0 0.6,0 0.7,-0.2 0.2,-0.2 0.3,-0.4 0.3,-0.7 0,-0.3 0,-0.5 -0.3,-0.7 -0.1,-0.2 -0.4,-0.3 -0.7,-0.3 -0.3,0 -0.6,0.1 -0.8,0.3 -0.2,0.2 -0.3,0.4 -0.3,0.7 0,0.3 0,0.5 0.3,0.7l0.8,0.2z"/>
        </svg>
    );
}

export default SvgLandscape2x6Icon;