import React, {useEffect, useState} from 'react';
import * as ApiHelper from '../../../api/ApiHelper';
import {selectToken} from '../../../features/account/accountSlice';
import handleErrors from '../../../helpers/ErrorHandler';
import {useAbortController, useAppDispatch, useAppSelector} from '../../../hooks';
import ErrorResponse from '../../../models/ErrorResponse';
import PagedData from '../../../models/PagedData';
import {SORT_BY_DATE, SORT_BY_NAME} from '../../../models/SortType';
import {User} from '../../../models/User';
import AsyncIndicator from '../AsyncIndicator/AsyncIndicator';
import {FetchError} from '../FetchError/FetchError';
import {ListPagination} from '../Pagination/ListPagination';
import {SearchInput} from '../SearchInput/SearchInput';
import UsersLong from '../UsersLong/UsersLong';
import UsersPlaceholder from '../UsersPlaceholder/UsersPlaceholder';
import {useTranslation} from "react-i18next";
import {SmallDoubleButton, SmallDoubleButtonSide} from "../SmallDoubleButton/SmallDoubleButton";
import {useHistory} from "react-router";
import {buildListParameters, updateListParameters} from "../../../models/ListParameters";
import {logError} from "../../../helpers/LogHelper";

interface UsersListProps {
    tag: string;
    showTitle?: boolean;
    pageSize: number;
    userClickHandler: (id: string) => void;
}


export const UsersList: React.FC<UsersListProps> = ({
                                                        tag,
                                                        showTitle = false,
                                                        pageSize,
                                                        userClickHandler
                                                    }: UsersListProps) => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [, setController] = useAbortController();
    const token = useAppSelector(selectToken);
    const [isBackChecked, setBackChecked] = useState(false);
    const [isFetching, setFetchingState] = useState(false);
    const [hasError, setErrorState] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listParameters, setListParameters] = useState(buildListParameters(1, "", SORT_BY_DATE));
    const [users, setUsers] = useState([] as User[]);
    const handleFilterChange = (filter: string) => {
        setListParameters(updateListParameters(listParameters, 1, filter, undefined));
    };
    const handleOrderChange = (order: string) => {
        setListParameters(updateListParameters(listParameters,  undefined, undefined, order));
    }
    const paginationClickHandler = (page: number) => {
        setFetchingState(true);
        setListParameters(updateListParameters(listParameters,  page, undefined, undefined));
    };
    const usersHandler = (pagedData: PagedData<User>) => {
        setFetchingState(false);
        setErrorState(false);
        setUsers(pagedData.data);
        setListParameters(updateListParameters(listParameters,  pagedData.page, undefined, undefined));
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Users data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchUsers = (page: number, filter: string, order: string) => {
        let userToken = token?.token;
        if (userToken) {
            localStorage.setItem(tag, JSON.stringify({page: page, filter: filter, order: order}));
            const abortController = new AbortController();
            setController(abortController);
            setFetchingState(true);
            ApiHelper.getUsers(userToken, filter !== "" ? filter : null, order, page, pageSize, abortController, usersHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => {
        if (history.action === "POP") {
            const s = localStorage.getItem(tag);
            if (s) {
                const data = JSON.parse(s);
                try {
                    const pageNumber = data.page;
                    const filter = data.filter;
                    const order = data.order;
                    if (typeof pageNumber === "number" && typeof filter === "string" && typeof  order === "string"){
                        setListParameters(updateListParameters(listParameters,  pageNumber, filter, order));
                    }
                } catch {
                    //do nothing
                }
            }
        }
        setBackChecked(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);
    useEffect(() => {
        if (isBackChecked) {
            fetchUsers(listParameters.page, listParameters.filter, listParameters.order);
        }
    }, [isBackChecked, listParameters.page, listParameters.filter, listParameters.order]);     // eslint-disable-line
    if (!isFetching && hasError) {
        return (
            <FetchError onRetry={() => fetchUsers(listParameters.page, listParameters.filter, listParameters.order)}/>
        );
    }
    const userComponents = users.map(user => <UsersLong key={user.id} user={user}
                                                        onClick={() => userClickHandler(user.id)}/>)
    const hasData = userComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {(hasData && showTitle) &&
                    <div className="data-list-title">{t("total_users")}: <span>{totalRecords}</span></div>
                }
                <div className="d-flex justify-content-between align-items-center">
                    <SearchInput text={listParameters.filter} onChange={handleFilterChange}/>
                    <div className="d-flex flex-grow-1" style={{minWidth: "64px"}}/>
                    <SmallDoubleButton leftButtonText={t("email")} rightButtonText={t("date")}
                                       activeButton={listParameters.order === SORT_BY_DATE ? SmallDoubleButtonSide.Right : SmallDoubleButtonSide.Left}
                                       onSwitch={(side) => handleOrderChange(side === SmallDoubleButtonSide.Right ? SORT_BY_DATE : SORT_BY_NAME)}/>
                </div>
                {isFetching && <AsyncIndicator/>}
                {!isFetching && (hasData ? (
                    <table>
                        <thead>
                        <tr className="table-header">
                            <th>{t("email")}</th>
                            <th>{t("role")}</th>
                            <th>{t("name")}</th>
                            <th>{t("registration_date")}</th>
                            <th>{t("email_status")}</th>
                            <th>{t("account_status")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {userComponents}
                        </tbody>
                    </table>
                ) : (
                    <UsersPlaceholder className="my-4"/>
                ))}
            </div>
            {!isFetching && totalPages > 1 &&
                <ListPagination page={listParameters.page} totalPages={totalPages} clickHandler={paginationClickHandler}/>}
        </div>
    );
}