import React, {useEffect, useState} from "react";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";

interface DialogStethoscopeTestRecordProps{
    okButtonClickHandler: () => void;
}

export const DialogStethoscopeTestRecord = ({okButtonClickHandler} : DialogStethoscopeTestRecordProps) => {
    const {t} = useTranslation();
    const [dialogTop, setDialogTop] = useState(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    const scrollHandler = () => {
        setDialogTop(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    })
    const documentHeight = document.documentElement.offsetHeight;
    return (
        <div className="dialog-container" style={{height: documentHeight}}>
            <div className="window-modal" style={{marginTop: dialogTop}}>
                <div className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("test_record")}</div>
                <div className="window-modal-text my-2 disable-select">{t("test_record_instruction_1")}</div>
                <div className="window-modal-text my-2 disable-select">{t("test_record_instruction_2")}</div>
                <audio src={"/test/dummy_stethoscope_record.wav"} controls controlsList="nodownload"/>
                <div className="d-flex justify-content-around">
                    <Button className="mt-4" text={t("ok")} danger={true} onClick={okButtonClickHandler} />
                </div>
            </div>
        </div>
    );
};