import * as React from "react"
import {SVGProps} from "react"

const SvgRulerIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="1em" width="1em" {...props}>
        <path
            d="M7 36q-1.2 0-2.1-.9Q4 34.2 4 33V15q0-1.15.9-2.075Q5.8 12 7 12h34q1.2 0 2.1.925.9.925.9 2.075v18q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h34V15h-6.5v9h-3v-9h-6v9h-3v-9h-6v9h-3v-9H7v18Zm6.5-9h3Zm9 0h3Zm9 0h3ZM24 24Z"
            fill="currentColor"/>
    </svg>
)

export default SvgRulerIcon
