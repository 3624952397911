import {parseUint16, parseUint32} from "../helpers/BinaryHelper";
import {BaseScpEcgExtractor} from "./BaseScpEcg";

const MAX_VECTORS = 4096;
export const BLOCK_FORMAT_DRV2 = 1;
export const BLOCK_FORMAT_RAW16 = 2;
export const BLOCK_FORMAT_UINT32 = 3;

interface DataBlockHeader {
    crc16: number;
    errors: number;
    blockNumber: number;
    vectorLength: number;
    dataFormat: number;
    startVector: number;
    vectorCount: number;
}

export class BinoraScpEcgExtractor extends BaseScpEcgExtractor{

    public extractData(): Array<number> {
        const header = BinoraScpEcgExtractor.parseHeader(this.block);
        switch (header.dataFormat) {
            case BLOCK_FORMAT_DRV2:
                return this.unpackDrv(header);
            case BLOCK_FORMAT_RAW16:
                return this.unpackRaw16(header);
            case BLOCK_FORMAT_UINT32:
                return this.unpackRaw32(header);
        }
        return [];
    }

    public static parseHeader(buffer: ArrayBuffer): DataBlockHeader {
        return {
            crc16: parseUint16(buffer, 0),
            errors: parseUint16(buffer, 2),
            blockNumber: parseUint32(buffer, 4),
            vectorLength: parseUint16(buffer, 8),
            dataFormat: parseUint16(buffer, 10),
            startVector: parseUint32(buffer, 12),
            vectorCount: parseUint16(buffer, 16),
        };
    }

    private unpackDrv(header : DataBlockHeader): Array<number> {
        const pData = new Array<number>();
        this.bsDrv.blockBuffer = this.block;
        const tempData = new Array<number>(8);
        let vectors = 0;
        for (let count = 0; count < header.vectorCount; count++) {
            for (let j = 0; j < header.vectorLength; j++) {
                if (count >= 2) {
                    const secondDerivative = this.getFromHuffman();
                    tempData[j] = secondDerivative + (this.previous1[j] << 1) - this.previous2[j];
                } else if (count === 1) {
                    const firstDerivative = this.getFromHuffman();
                    tempData[j] = firstDerivative + this.previous1[j];
                } else {
                    tempData[j] = this.getBits(tempData[j], 16, this.bsDrv);
                }
                const data = this.convertUnsignedToSigned(tempData[j] & 0xFFFF, 16);
                pData.push(data);
            }

            for (let i = 0; i < 8; i++) {
                this.previous2[i] = this.previous1[i];
                this.previous1[i] = tempData[i];
            }
            vectors++;
            if (vectors >= MAX_VECTORS) break;
        }
        return pData;
    }

    private unpackRaw16(header : DataBlockHeader): Array<number> {
        const pData = new Array<number>();
        let endOfBlock = false;
        this.bsRaw.blockBuffer = this.block;
        let vectors = 0;
        for (let count = 0; count < header.vectorCount; count++) {
            for (let j = 0; j < header.vectorLength; j++) {
                let temp = 0;
                temp = this.getBits(temp, 16, this.bsRaw);
                if (temp !== Number.NaN) {
                    pData.push(temp);
                } else {
                    endOfBlock = true;
                    break;
                }
            }
            if (endOfBlock) break;
            vectors++;
            if (vectors >= MAX_VECTORS) break;
        }
        return pData;
    }

    private unpackRaw32(header : DataBlockHeader): Array<number> {
        const pData = new Array<number>();
        let vectors = 0;
        const slice = this.block.slice(18);
        for (let count = 0; count < header.vectorCount; count++) {
            pData.push(parseUint32(slice, count * 4))
            vectors++;
            if (vectors >= MAX_VECTORS) break;
        }
        return pData;
    }

}

