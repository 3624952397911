import React from 'react';
import './SmallDoubleButton.css';

export enum SmallDoubleButtonSide {
    Left,
    Right
}

interface SmallDoubleButtonProps {
    className?: string;
    leftButtonText: string;
    rightButtonText: string;
    activeButton: SmallDoubleButtonSide;
    onSwitch: (button: SmallDoubleButtonSide) => void;
}

export const SmallDoubleButton: React.FC<SmallDoubleButtonProps> = ({ className = "", leftButtonText, rightButtonText, activeButton, onSwitch }: SmallDoubleButtonProps) => {
    const handleLeftButtonClick = () => {
        if (activeButton !== SmallDoubleButtonSide.Left) {
            onSwitch(SmallDoubleButtonSide.Left);
        } 
    };
    const handleRightButtonClick = () => {
        if (activeButton !== SmallDoubleButtonSide.Right) {
            onSwitch(SmallDoubleButtonSide.Right);
        } 
    };
    return (
        <div className={`small-double-button ${className}`}>
            <div className={`small-double-button-side ${activeButton === SmallDoubleButtonSide.Left ? 'active' : ''} disable-select`} onClick={handleLeftButtonClick}>{leftButtonText}</div>
            <div className={`small-double-button-side ${activeButton === SmallDoubleButtonSide.Right ? 'active' : ''} disable-select`} onClick={handleRightButtonClick}>{rightButtonText}</div>
        </div>
    );
};
