
export default interface ErrorResponse {
    readonly Message: string;
    readonly ResultCode: number;   
}

export function makeErrorResponse(message: string, errorCode: number) : ErrorResponse {
    return {
        Message: message,
        ResultCode: errorCode
    }
}