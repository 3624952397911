import * as React from "react";

function SvgLpfIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.223 9.425a1.598 1.598 0 00.858-2.945c.04-.135.065-.266.09-.39.128-.366.284-.798.473-1.227.192-.37.414-.752.737-1.046l.004-.003c.225-.211.443-.302.642-.265l.022.003c.007 0 .03.004.11.087.493.636.747 1.322 1.016 2.052.07.182.138.37.21.56.048.181.124.36.171.465.15.45.295.904.436 1.343.418 1.304.846 2.655 1.453 3.985l.007.014c.156.316.345.603.607.92.029.072.073.13.109.173.305.364.792.654 1.33.654.177 0 .362-.032.548-.105l.01-.004a2.457 2.457 0 001.34-1.344l.004-.01a.956.956 0 00.044-.142v-.007c.003-.004.003-.008.007-.011a1.743 1.743 0 10-1.057-.284c-.003.008-.003.019-.007.022-.004.008-.004.015-.007.022a1.38 1.38 0 01-.748.745c-.197.076-.43-.03-.585-.182a.633.633 0 00-.11-.167 3.47 3.47 0 01-.511-.756c-.574-1.267-.977-2.524-1.402-3.857-.146-.454-.294-.922-.45-1.387l-.011-.03-.015-.028a1.835 1.835 0 01-.12-.323l-.007-.037-.015-.036c-.076-.189-.145-.385-.214-.57-.287-.781-.588-1.591-1.199-2.368l-.014-.019-.018-.018c-.175-.185-.4-.381-.756-.432-.548-.094-1.09.09-1.572.541-.45.414-.745.92-.974 1.362l-.014.03c-.214.482-.385.958-.527 1.361l-.01.037-.008.036c-.029.142-.054.276-.094.4a1.602 1.602 0 00-1.388 1.583 1.609 1.609 0 001.605 1.598zm10.134.363c.36 0 .653.294.653.654 0 .36-.294.654-.653.654a.653.653 0 110-1.307zM5.223 7.318a.51.51 0 01.509.509.51.51 0 01-.509.508.51.51 0 01-.508-.508.51.51 0 01.508-.508z"
        fill="currentColor"
      />
      <path
        d="M17.027 15.167H3.262V1.402a.544.544 0 10-1.09 0v13.765H.975a.544.544 0 100 1.09h1.199v1.198a.544.544 0 101.09 0v-1.198h13.764a.544.544 0 100-1.09z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLpfIcon;
