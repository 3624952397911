import * as React from "react";

function SvgHpfIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.795 6.833a1.598 1.598 0 01.857 2.946c.04.134.065.265.09.388.128.367.284.8.473 1.228.192.37.414.752.737 1.046l.004.004c.225.21.443.301.643.265l.021-.004c.008 0 .03-.003.11-.087.493-.636.748-1.322 1.016-2.052.07-.182.138-.37.211-.56.047-.181.123-.359.17-.464.15-.45.295-.905.437-1.344.417-1.304.846-2.655 1.452-3.984l.008-.015c.156-.316.345-.603.606-.919a.655.655 0 01.11-.174c.304-.363.79-.654 1.328-.654.178 0 .364.033.549.105l.01.004a2.456 2.456 0 011.34 1.344l.005.01a.969.969 0 01.043.142v.008c.004.003.004.007.007.01a1.743 1.743 0 11-1.056.284c-.004-.007-.004-.018-.008-.022-.003-.007-.003-.014-.007-.022a1.379 1.379 0 00-.748-.744c-.196-.077-.429.029-.585.181a.634.634 0 01-.109.167 3.467 3.467 0 00-.512.756c-.574 1.267-.977 2.524-1.402 3.857-.145.454-.294.923-.45 1.387l-.011.03-.015.029a1.838 1.838 0 00-.12.323l-.007.036-.014.036c-.077.19-.146.385-.215.57-.287.781-.588 1.591-1.198 2.369l-.015.018-.018.018c-.174.185-.4.381-.755.432-.549.095-1.09-.09-1.573-.541-.45-.414-.745-.919-.973-1.362l-.015-.029a18.214 18.214 0 01-.527-1.362l-.01-.036-.008-.037c-.029-.141-.054-.276-.094-.4a1.602 1.602 0 01-1.388-1.583 1.609 1.609 0 011.606-1.598zm10.133-.363c.36 0 .654-.294.654-.654a.656.656 0 00-.654-.653.653.653 0 100 1.308zM4.795 8.94a.51.51 0 00.508-.509.51.51 0 00-.508-.508.51.51 0 00-.509.508c0 .28.23.509.509.509z"
        fill="currentColor"
      />
      <path
        d="M16.599 15.167H2.833V1.402a.544.544 0 10-1.09 0v13.765H.546a.544.544 0 100 1.09h1.199v1.198a.544.544 0 101.09 0v-1.198h13.765a.544.544 0 100-1.09z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgHpfIcon;
