import React, {useEffect, useState} from "react";
import {PX_PER_MM} from "../EcgViewer/EcgViewer";
import {getStyledColor} from "../../../helpers/DrawHelper";
import {Layer, Line, Rect, Stage, Text} from "react-konva";
import {getStethoscopeChannelNames} from "../../../sts/WavUtils";
import {KonvaEventObject} from "konva/types/Node";
import {PlaybackControlButton} from "./PlaybackControlButton";
import {CHANNEL_NAME_HEIGHT_MM, MM_PER_CHANNEL, NUMBER_OF_CHANNELS} from "./StethoscopeViewer";
import {useTranslation} from "react-i18next";
import {Vector2d} from "konva/types/types";

const MAJOR_LINE_WIDTH_PX = 1.2;
const MINOR_LINE_WIDTH_PX = 1;
const CHANNEL_NAME_TEXT_HEIGHT_MM = 3.5;
const LEGEND_OPACITY = 0.75;
const OVERLAY_OPACITY = 0.25;
const DATA_LINE_WIDTH = 1.5;

interface Props {
    width: number;
    height: number;
    firstSample: number;
    lastSample: number;
    numberOfSamples: number;
    timeScale: number;
    sampleRate: number;
    drawPoints: Array<Array<number>>;
    activeChannel: number;
    cursorPosition: number;
    isPlaying: boolean;
    playbackControlButtonClickHandler: (channel: number) => void;
    positionChangeHandler: (monitorX: number, monitorY: number) => void;
    showSaveRegion: boolean;
    saveRegionWidth: number | null;
    savePositionChangeListener: (position: number) => void;
    moveRegionLeft: boolean;
    moveRegionRight: boolean;
}

const REGION_MOVE_INTERVAL = 100;
const REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD = 30;

export const StethoscopeMonitor: React.FC<Props> = ({
                                                        width,
                                                        height,
                                                        firstSample,
                                                        lastSample,
                                                        numberOfSamples,
                                                        timeScale,
                                                        sampleRate,
                                                        drawPoints,
                                                        activeChannel,
                                                        cursorPosition,
                                                        isPlaying,
                                                        playbackControlButtonClickHandler,
                                                        positionChangeHandler,
                                                        showSaveRegion,
                                                        saveRegionWidth,
                                                        savePositionChangeListener,
                                                        moveRegionLeft,
                                                        moveRegionRight
                                                    }: Props) => {
    const {t} = useTranslation();
    const [saveRegionPosition, setSaveRegionPosition] = useState(null as number | null);
    const adjustSaveRegionPosition = (position: number) => {
        let adjustedPosition = Math.min(position, numberOfSamples - (saveRegionWidth ?? 0));
        adjustedPosition = Math.max(adjustedPosition, 0);
        return adjustedPosition;
    };
    const getRegionMoveStep = (multiplier: number) => {
        return 1 / timeScale * sampleRate * multiplier;
    };
    useEffect(() => {
        if (showSaveRegion && saveRegionWidth) {
            let start: number;
            if (saveRegionPosition !== null) {
                start = adjustSaveRegionPosition(saveRegionPosition);
            } else {
                const center = firstSample + (lastSample - firstSample) / 2;
                start = adjustSaveRegionPosition(center - saveRegionWidth / 2);
            }
            savePositionChangeListener(start);
            setSaveRegionPosition(start);
        } else {
            setSaveRegionPosition(null);
        }
    }, [showSaveRegion, saveRegionWidth]); // eslint-disable-line
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (moveRegionLeft && saveRegionPosition) {
            let count = 0;
            let multiplier = 1;
            let position = saveRegionPosition;
            position = adjustSaveRegionPosition(position - getRegionMoveStep(multiplier));
            savePositionChangeListener(position);
            setSaveRegionPosition(position);
            interval = setInterval(() => {
                if (count > REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD) {
                    count = 0;
                    multiplier *= 2;
                } else {
                    count++;
                }
                position = adjustSaveRegionPosition(position - getRegionMoveStep(multiplier));
                savePositionChangeListener(position);
                setSaveRegionPosition(position);
            }, REGION_MOVE_INTERVAL);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [moveRegionLeft]); // eslint-disable-line
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (moveRegionRight && saveRegionPosition) {
            let count = 0;
            let multiplier = 1;
            let position = saveRegionPosition;
            position = adjustSaveRegionPosition(position + getRegionMoveStep(multiplier));
            savePositionChangeListener(position);
            setSaveRegionPosition(position);
            interval = setInterval(() => {
                if (count > REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD) {
                    count = 0;
                    multiplier *= 2;
                } else {
                    count++;
                }
                position = adjustSaveRegionPosition(position + getRegionMoveStep(multiplier));
                savePositionChangeListener(position);
                setSaveRegionPosition(position);
            }, REGION_MOVE_INTERVAL);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [moveRegionRight]); // eslint-disable-line
    let base = null;
    let border = null;
    let majorGridLines = null;
    let minorGridLines = null;
    let names = null;
    let channels = null;
    let cursor = null;
    let activeChannelOverlay = null;
    let saveRegion = null;
    if (width > 0 && height > 0) {
        const backgroundColor = getStyledColor("--ecg-background");
        const gridLineColor = getStyledColor("--ecg-grid-line");
        const contentColors = [getStyledColor("--ecg-content-1"), getStyledColor("--ecg-content-2"), getStyledColor("--ecg-content-3")];
        const saveRegionColor = getStyledColor("--active-secondary-background");
        base = <Rect width={width} height={height} x={0} y={0} strokeEnabled={false} fill={backgroundColor}
                     fillEnabled={true}/>
        border =
            <Rect width={width} height={height} x={0} y={0} strokeWidth={MAJOR_LINE_WIDTH_PX} stroke={gridLineColor}/>
        majorGridLines = [];
        minorGridLines = [];
        const gridStep = 5 * PX_PER_MM;
        let iX = 1;
        for (let x = gridStep; x < width; x += gridStep) {
            if (iX % 2 === 0) {
                majorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iX++;
        }
        let iY = 1;
        for (let y = gridStep; y < height; y += gridStep) {
            if (iY % 2 === 0) {
                majorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iY++;
        }
        channels = drawPoints.map((points, i) => <Line key={`l-${i}`} points={points} strokeWidth={DATA_LINE_WIDTH}
                                                       stroke={contentColors[i % contentColors.length]}/>);
        names = getStethoscopeChannelNames(t).map((name, i) => {
            const offsetY = (i * MM_PER_CHANNEL + (CHANNEL_NAME_HEIGHT_MM - CHANNEL_NAME_TEXT_HEIGHT_MM) / 2) * PX_PER_MM;
            return <Text key={`nm-${i}`} x={5 * PX_PER_MM} y={offsetY} text={name}
                         fill={contentColors[i]} opacity={LEGEND_OPACITY} fontStyle={"bold"}
                         fontSize={CHANNEL_NAME_TEXT_HEIGHT_MM * PX_PER_MM}/>
        });
        if (!showSaveRegion) {
            let cursorColor = getStyledColor("--stethoscope-cursor");
            cursor = <Line key={"cursor"} strokeWidth={2 * MAJOR_LINE_WIDTH_PX} stroke={cursorColor}
                           points={[cursorPosition, 0, cursorPosition, NUMBER_OF_CHANNELS * MM_PER_CHANNEL * PX_PER_MM]}
                           opacity={LEGEND_OPACITY}/>
            let overlayColor = getStyledColor("--stethoscope-overlay");
            activeChannelOverlay =
                <Rect key={"overlay"} x={0} width={width} y={activeChannel * MM_PER_CHANNEL * PX_PER_MM}
                      height={MM_PER_CHANNEL * PX_PER_MM} fillEnabled={true} strokeEnabled={false}
                      fill={overlayColor} opacity={OVERLAY_OPACITY}/>
        }
        const dragBoundFunc = (pos: Vector2d) => {
            const regionStart = adjustSaveRegionPosition(firstSample + pos.x / PX_PER_MM / timeScale * sampleRate);
            savePositionChangeListener(regionStart);
            setSaveRegionPosition(regionStart);
            const newX = (regionStart - firstSample) / sampleRate * timeScale * PX_PER_MM;
            return {
                x: newX,
                y: 0
            }
        };
        if (showSaveRegion) {
            let y = 0;
            let h = height;
            let x = 0;
            let w = width;
            if (saveRegionWidth !== null && saveRegionPosition !== null) {
                x = (saveRegionPosition - firstSample) / sampleRate * timeScale * PX_PER_MM;
                w = saveRegionWidth / sampleRate * timeScale * PX_PER_MM;
            }
            saveRegion =
                <Rect x={x} y={y} width={w} height={h} strokeEnabled={false} fill={saveRegionColor} opacity={0.35}
                      fillEnabled={true} draggable={true} dragBoundFunc={dragBoundFunc}/>
        }
    }
    const layerClickHandler = (e: KonvaEventObject<MouseEvent>) => {
        if (!showSaveRegion) {
            const x = e.evt.offsetX;
            const y = e.evt.offsetY;
            positionChangeHandler(x, y);
        }
    };
    const handlePlaybackControlButtonClick = (index: number) => {
        playbackControlButtonClickHandler(index);
    };
    return (
        <div style={{position: "relative"}}>
            <Stage width={width} height={height}>
                <Layer onMouseDown={layerClickHandler}>
                    {base ?? base}
                    {minorGridLines && minorGridLines}
                    {majorGridLines && majorGridLines}
                    {border && border}
                    {names}
                    {channels && channels}
                    {cursor && cursor}
                    {activeChannelOverlay && activeChannelOverlay}
                    {saveRegion && saveRegion}
                </Layer>
            </Stage>
            {!showSaveRegion &&
            <div style={{position: "absolute", right: 16, top: 16}} onClick={() => handlePlaybackControlButtonClick(0)}>
                <PlaybackControlButton isPlaying={activeChannel === 0 && isPlaying}/>
            </div>
            }
            {!showSaveRegion &&
            <div style={{position: "absolute", right: 16, top: 16 + MM_PER_CHANNEL * PX_PER_MM}}
                 onClick={() => handlePlaybackControlButtonClick(1)}>
                <PlaybackControlButton isPlaying={activeChannel === 1 && isPlaying}/>
            </div>
            }
            {!showSaveRegion &&
            <div style={{position: "absolute", right: 16, top: 16 + 2 * MM_PER_CHANNEL * PX_PER_MM}}
                 onClick={() => handlePlaybackControlButtonClick(2)}>
                <PlaybackControlButton isPlaying={activeChannel === 2 && isPlaying}/>
            </div>
            }
        </div>
    );
}