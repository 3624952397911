import {DocumentInfo} from "../../../models/DocumentInfo";
import React from "react";
import './DocumentItem.css';
import IconDocument from "./IconDocument";


interface DocumentItemProps{
    info : DocumentInfo;
}

export const DocumentItem: React.FC<DocumentItemProps> = ({info}: DocumentItemProps) => {
    return(
        // eslint-disable-next-line react/jsx-no-target-blank
        <a className="document-item my-2" href={info.url} target="_blank"><IconDocument/> {info.name}</a>
    );
}