import React from "react";

function SvgSearchIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.34 5.667c0 1.93-1.57 3.5-3.5 3.5-1.929 0-3.5-1.57-3.5-3.5s1.571-3.5 3.5-3.5c1.93 0 3.5 1.57 3.5 3.5zm4 6.5c0-.266-.108-.524-.288-.704l-2.68-2.68A5.498 5.498 0 005.841.167c-3.04 0-5.5 2.462-5.5 5.5a5.498 5.498 0 008.617 4.532l2.68 2.672a.98.98 0 00.703.297c.547 0 1-.454 1-1z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgSearchIcon;
