import React from "react";
import {useHistory} from "react-router-dom";
import {PATIENTS_ROUTE, USERS_ROUTE} from "../../../routes";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {PatientsList} from "../../Widgets/PatientsList/PatientsList";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {AccessType} from "../../../models/AccessType";

function Patients() {
    const history = useHistory();
    const patientClickHandler = (id: string) => {
        history.push(`${PATIENTS_ROUTE}/${id}`);
    };
    const userClickHandler = (id: string) => {
        history.push(`${USERS_ROUTE}/${id}`);
    };
    return (
        <div>
            <Breadcrumbs/>
            <PatientsList tag="patients" pageSize={DATA_LIST_PAGE_SIZE} userId={null} accessType={AccessType.All}
                          patientClickHandler={patientClickHandler} userClickHandler={userClickHandler}/>
        </div>
    );
}

export default Patients;