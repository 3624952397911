
export class CacheFile{

    private readonly fileHandle : FileSystemFileHandle;

    private data: ArrayBuffer | null;

    constructor(fileHandle : FileSystemFileHandle) {
        this.fileHandle = fileHandle;
        this.data = null;
    }

    public async readData(params?: { start : number, count : number}) : Promise<ArrayBuffer> {
        const data = await this.getData();
        if (params) {
            return data.slice(params.start, params.start + params.count);
        } else {
            return data;
        }
    }

    public async getSize(){
        const data = await this.getData();
        return data.byteLength;
    }

    private async getData(): Promise<ArrayBuffer> {
        if (!this.data){
            const file = await this.fileHandle.getFile();
            this.data = await file.arrayBuffer();
        }
        return this.data;
    }

}