import React, {forwardRef, KeyboardEvent, useState} from 'react';
import './InputBox.css';
import SvgPasswordVisibilityOffIcon from "../../../assets/svg/PasswordVisibilityOffIcon";
import SvgPasswordVisibilityOnIcon from "../../../assets/svg/PasswordVisibilityOnIcon";

interface InputBoxProps {
    className?: string;
    title: string;
    initialValue: string;
    hasError?: boolean;
    onChange: (value: string) => void;
    onEnterPress? : () => void;
}

export const PasswordBox = forwardRef<HTMLInputElement, InputBoxProps>((props, ref) => {
    const [isPasswordVisible, setPasswordVisibility] = useState(false)
    const keyDownHandler = (e : KeyboardEvent<HTMLInputElement>) => {
        if (props.onEnterPress && e.key === "Enter"){
            props.onEnterPress();
        }
    }
    const onPasswordVisibilityToggle = () => {
        setPasswordVisibility(!isPasswordVisible);
    }
    return (
        <div className={`input-box ${props.hasError ? "error" : ""} ${props.className ?? ""}`}>
            <div className="input-box-container">
                <span className="disable-select">{props.title}</span>
                <input ref={ref} type={isPasswordVisible ? "text" : "password"} defaultValue={props.initialValue} onChange={(e) => props.onChange(e.target.value)} onKeyDown={keyDownHandler}/>
            </div>
            <div className="input-box-icon input-box-icon-toggle" onClick={onPasswordVisibilityToggle}>
                {isPasswordVisible ? <SvgPasswordVisibilityOffIcon/> : <SvgPasswordVisibilityOnIcon/>}
            </div>
        </div>
    );
});