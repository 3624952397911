import React from "react";
import {Role} from "../../../models/Role";
import {useTranslation} from "react-i18next";
import {formatRole} from "../../../helpers/FormatHelper";

interface RoleDropdownProps {
    className?: string;
    initialState: Role;
    disabled: false;
    onChange: (value: Role) => void;
}


export const RoleDropdown: React.FC<RoleDropdownProps> = ({ className = "", initialState, onChange }: RoleDropdownProps) => {
    const {t} = useTranslation();
    let defaultValue;
    switch (initialState) {
        case Role.Support:
            defaultValue = "s";
            break;
        case Role.Admin:
            defaultValue = "a";
            break;
        default:
            defaultValue = "d";
            break;
    }
    const onRoleChange = (v: string) => {
        switch (v) {
            case "s":
                onChange(Role.Support);
                break;
            case "a":
                onChange(Role.Admin);
                break;
            default:
                onChange(Role.Doctor);
                break;
        }
    }
    return (
        <select className={className} disabled={false} defaultValue={defaultValue} onChange={(e) => onRoleChange(e.target.value)}>
            <option value='d'>{formatRole(t, Role.Doctor)}</option>
            <option value='s'>{formatRole(t, Role.Support)}</option>
            <option value='a'>{formatRole(t, Role.Admin)}</option>
        </select>
    );
}