import React from "react";
import {formatDateTime, formatPatientName} from "../../../helpers/FormatHelper";
import {Record} from '../../../models/Record';
import {StudyIcon, StudyType} from "../StudyIcon/StudyIcon";
import {useTranslation} from "react-i18next";

interface RecordProps {
    record: Record;
    onClick: () => void;
    onStudyClick: (type: StudyType) => void;
}

export const RecordsShort: React.FC<RecordProps> = ({record, onClick, onStudyClick}: RecordProps) => {
    const {t} = useTranslation();
    return (
        <tr onClick={onClick}>
            <td style={{whiteSpace: "normal"}}>{formatPatientName(t, record.patientId === null, record.patientName)}</td>
            <td align="center" width="200px" style={{whiteSpace: "normal"}}>
                <div style={{minWidth: "120x"}}>
                    {formatDateTime(record.dateTime)}
                </div>
            </td>
            <td width="200" align="center">
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-center">
                        <StudyIcon type={StudyType.State} isVisible={record.state !== null} onClick={() => onStudyClick(StudyType.State)}/>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <StudyIcon type={StudyType.Ecg} isVisible={record.ecgStudy !== null} onClick={() => onStudyClick(StudyType.Ecg)}/>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <StudyIcon type={StudyType.Spiro} isVisible={record.spiroStudy !== null} onClick={() => onStudyClick(StudyType.Spiro)}/>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <StudyIcon type={StudyType.Stethoscope} isVisible={record.stethoscopeStudy !== null} onClick={() => onStudyClick(StudyType.Stethoscope)}/>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <StudyIcon type={StudyType.LtEcg} isVisible={record.ltEcgStudy !== null} onClick={() => onStudyClick(StudyType.LtEcg)}/>
                    </div>
                </div>
            </td>
        </tr>
    );
}