import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import {Button} from "../../Widgets/Button/Button";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {DialogConfirmation} from "../../Widgets/DialogConfirmation/DialogConfirmation";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import {UserAccessData} from "../../../models/UserAccessData";
import {AssociatedUserAccessList} from "../../Widgets/AssociatedUserAccessList/AssociatedUserAccessList";
import * as ApiHelper from "../../../api/ApiHelper";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import {
    DialogGrantAssociatedUserAccess
} from "../../Widgets/DialogGrantAssociatedUserAccess/DialogGrantAssociatedUserAccess";
import {PatientAccessList} from "../../Widgets/PatientAccessList/PatientAccessList";
import {RecordAccessList} from "../../Widgets/RecordAccessList/RecordAccessList";
import {PatientAccessData} from "../../../models/PatientAccessData";
import {RecordAccessData} from "../../../models/RecordAccessData";
import {logError} from "../../../helpers/LogHelper";

export function AccessControlSettings() {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const [updateToken, setUpdateToken] = useState(1);
    const [isFetching, setFetchingState] = useState(false);
    const [hasError, setErrorState] = useState(false);
    const [showRejectConfirmationDialog, setShowRejectConfirmationDialog] = useState(false);
    const [showRefuseConfirmationDialog, setShowRefuseConfirmationDialog] = useState(false);
    const [showPatientRejectConfirmationDialog, setShowPatientRejectConfirmationDialog] = useState(false);
    const [showRecordRejectConfirmationDialog, setShowRecordRejectConfirmationDialog] = useState(false);
    const [showGrantAccessDialog, setShowGrantAccessDialog] = useState(false);
    const [contextUserAccessData, setContextUserAccessData] = useState(null as UserAccessData | null);
    const [contextPatientUserAccessData, setContextPatientUserAccessData] = useState(null as PatientAccessData | null);
    const [contextRecordUserAccessData, setContextRecordUserAccessData] = useState(null as RecordAccessData | null);
    const errorHandler = (error: ErrorResponse) => {
        logError("Access data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const successHandler = () => {
        setFetchingState(false);
        setUpdateToken((v) => v + 1);
    };
    const grantAccessClick = () => {
        setShowGrantAccessDialog(true);
    };
    const dialogGrantAccessCloseHandler = (refresh: boolean) => {
        setShowGrantAccessDialog(false);
        if (refresh) {
            setUpdateToken((v) => v + 1);
        }
    }
    const handleUserByClick = (data: UserAccessData) => {
        setContextUserAccessData(data);
        setShowRejectConfirmationDialog(true)
    };
    const handleUserWithClick = (data: UserAccessData) => {
        setContextUserAccessData(data);
        setShowRefuseConfirmationDialog(true)
    };
    const handlePatientUserClick = (data: PatientAccessData) => {
        setContextPatientUserAccessData(data);
        setShowPatientRejectConfirmationDialog(true)
    };
    const handleRecordUserClick = (data: RecordAccessData) => {
        setContextRecordUserAccessData(data);
        setShowRecordRejectConfirmationDialog(true)
    };
    const rejectAccess = () => {
        setShowRejectConfirmationDialog(false);
        let userToken = token?.token;
        let email = contextUserAccessData?.email;
        if (userToken && email) {
            setFetchingState(true);
            ApiHelper.removeAssociatedUser(userToken, email, controller, successHandler, errorHandler);
        }
    };
    const refuseAccess = () => {
        setShowRefuseConfirmationDialog(false);
        let userToken = token?.token;
        let email = contextUserAccessData?.email;
        if (userToken && email) {
            setFetchingState(true);
            ApiHelper.refuseAssociatedUser(userToken, email, controller, successHandler, errorHandler);
        }
    };
    const rejectPatientAccess = () => {
        setShowPatientRejectConfirmationDialog(false);
        let userToken = token?.token;
        let id = contextPatientUserAccessData?.id;
        if (userToken && id) {
            setFetchingState(true);
            ApiHelper.rejectPatientShare(userToken, [id], controller, successHandler, errorHandler);
        }
    };
    const rejectRecordAccess = () => {
        setShowRecordRejectConfirmationDialog(false);
        let userToken = token?.token;
        let id = contextRecordUserAccessData?.id;
        if (userToken && id) {
            setFetchingState(true);
            ApiHelper.rejectRecordShare(userToken, [id], controller, successHandler, errorHandler);
        }
    };
    const isOk = !hasError && !isFetching;
    return <div>
        <Breadcrumbs/>
        {isFetching && <AsyncIndicator/>}
        {!isFetching && hasError && <FetchError/>}
        {isOk &&
            <div className="d-flex flex-column align-items-center">
                <Button className="my-4" text={t("grant_access")}
                        highlighted={true}
                        onClick={grantAccessClick}/>
                <AssociatedUserAccessList key={`by-${updateToken}`} id={null} isOwn={true}
                                          pageSize={DATA_LIST_PAGE_SIZE} userClickHandler={handleUserByClick}/>
                <div className="my-2"/>
                <AssociatedUserAccessList key={`with-${updateToken}`} id={null} isOwn={false}
                                          pageSize={DATA_LIST_PAGE_SIZE} userClickHandler={handleUserWithClick}/>
                <div className="my-2"/>
                <PatientAccessList key={`patient-${updateToken}`} isOwn={true}
                                   pageSize={DATA_LIST_PAGE_SIZE} userClickHandler={handlePatientUserClick}/>
                <div className="my-2"/>
                <RecordAccessList key={`records-${updateToken}`} isOwn={true}
                                  pageSize={DATA_LIST_PAGE_SIZE} userClickHandler={handleRecordUserClick}/>
            </div>
        }
        {showRejectConfirmationDialog &&
            <DialogConfirmation titleText={t("access_control")} messageText={t("confirm_association_reject", {
                name: contextUserAccessData?.name ?? "",
                email: contextUserAccessData?.email ?? ""
            })}
                                okButtonText={t("yes")} cancelButtonText={t("no")}
                                okButtonClickHandler={rejectAccess}
                                cancelButtonClickHandler={() => setShowRejectConfirmationDialog(false)}/>}
        {showRefuseConfirmationDialog &&
            <DialogConfirmation titleText={t("access_control")} messageText={t("confirm_association_refuse", {
                name: contextUserAccessData?.name ?? "",
                email: contextUserAccessData?.email ?? ""
            })}
                                okButtonText={t("yes")} cancelButtonText={t("no")}
                                okButtonClickHandler={refuseAccess}
                                cancelButtonClickHandler={() => setShowRefuseConfirmationDialog(false)}/>}
        {showPatientRejectConfirmationDialog &&
            <DialogConfirmation titleText={t("access_control")} messageText={t("confirm_access_reject", {
                name: contextPatientUserAccessData?.holderName ?? "",
                email: contextPatientUserAccessData?.holderEmail ?? ""
            })}
                                okButtonText={t("yes")} cancelButtonText={t("no")}
                                okButtonClickHandler={rejectPatientAccess}
                                cancelButtonClickHandler={() => setShowPatientRejectConfirmationDialog(false)}/>}
        {showRecordRejectConfirmationDialog &&
            <DialogConfirmation titleText={t("access_control")} messageText={t("confirm_access_reject", {
                name: contextRecordUserAccessData?.holderName ?? "",
                email: contextRecordUserAccessData?.holderEmail ?? ""
            })}
                                okButtonText={t("yes")} cancelButtonText={t("no")}
                                okButtonClickHandler={rejectRecordAccess}
                                cancelButtonClickHandler={() => setShowRecordRejectConfirmationDialog(false)}/>}
        {showGrantAccessDialog &&
            <DialogGrantAssociatedUserAccess token={token?.token ?? ""}
                                             controller={controller}
                                             closeHandler={dialogGrantAccessCloseHandler}/>}
    </div>;
}