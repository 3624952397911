import {NavLink} from "react-router-dom";
import * as AppRoutes from "../../../routes";
import HeaderLogo from "./HeaderLogo";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import React from "react";

interface GuestHeaderFullProps {
    layoutChangeListener?: (isEnoughSpace: boolean) => void;
}

export const GuestHeaderFull = ({layoutChangeListener}: GuestHeaderFullProps) => {
    const onLayoutChanged = (width: number) => {
        if (layoutChangeListener) {
            layoutChangeListener(width < 198);
        }
    };
    return (
        <div className="header">
            <NavLink to={AppRoutes.HOME_ROUTE}><HeaderLogo
                sizeChangeListener={layoutChangeListener ? onLayoutChanged : undefined}/></NavLink>
            <div className="header-links"/>
            <div className="header-options">
                <div className="header-options-dropdown header-options-dropdown-guest">
                    <LanguageDropdown/>
                </div>
            </div>
        </div>
    );
}