import * as React from "react";

function SvgCalculationIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            {...props}
        >
            <path d="M13 7.5h5v2h-5v-2m0 7h5v2h-5v-2M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m0 16V5H5v14h14M11 6v5H6V6h5m-1 4V7H7v3h3m1 3v5H6v-5h5m-1 4v-3H7v3h3Z" fill="currentColor"/>
        </svg>
    )
}

export default SvgCalculationIcon