import React, {forwardRef, KeyboardEvent} from 'react';
import './InputBox.css';

interface InputBoxProps {
    className?: string;
    inputType: string;
    title: string;
    initialValue: string;
    hasError?: boolean;
    onChange: (value: string) => void;
    icon?: JSX.Element | null;
    onEnterPress? : () => void;
}

export const InputBox = forwardRef<HTMLInputElement, InputBoxProps>((props, ref) => {
    const keyDownHandler = (e : KeyboardEvent<HTMLInputElement>) => {
        if (props.onEnterPress && e.key === "Enter"){
            props.onEnterPress();
        }
    }
    return (
        <div className={`input-box ${props.hasError ? "error" : ""} ${props.className ?? ""}`}>
            <div className="input-box-container">
                <span className="disable-select">{props.title}</span>
                <input ref={ref} type={props.inputType} defaultValue={props.initialValue} onChange={(e) => props.onChange(e.target.value)} onKeyDown={keyDownHandler}/>
            </div>
            <div className="input-box-icon">
                {props.icon}
            </div>
        </div>
    );
});