import {EcgCalculationV3, STATUS_NOISY, STATUS_NOT_ENOUGH_DATA, STATUS_OK} from "../../../models/EcgCalculationV3";
import {EcgCalculationDetailedData} from "./EcgCalculationDetailedData";
import {useTranslation} from "react-i18next";
import React from "react";
import {EcgCalculationBriefData} from "./EcgCalculationBriefData";

interface Props {
    calculation?: EcgCalculationV3;
}

export function EcgCalculationData(props: Props) {
    const {t} = useTranslation();
    let text = t("calculation_not_available")
    if (props.calculation) {
        if (props.calculation.status === STATUS_OK) {
            return (<div className="d-flex flex-column justify-content-between">
                <div>
                    <div className="calculation-table-title text-center">{t("calculation_brief")}</div>
                    <EcgCalculationBriefData calculation={props.calculation}/>
                </div>
                <div>
                    <div className="calculation-table-title text-center">{t("calculation_detailed")}</div>
                    <EcgCalculationDetailedData calculation={props.calculation}/>
                </div>
            </div>);
        }
        if (props.calculation.status === STATUS_NOT_ENOUGH_DATA) {
            text = t("not_enough_data_for_calculation");
        }
        if (props.calculation.status === STATUS_NOISY) {
            text = t("calculation_failed_due_to_noisy_data");
        }
    }
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className={"placeholder-text my-4"}>{text}</div>
        </div>);
}