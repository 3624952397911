import React, {useEffect, useMemo, useState} from "react";
import {Group, Layer, Line, Rect, Stage, Text} from "react-konva";
import {EcgData} from "../../../models/EcgData";
import {calculateLeads, getLeadName, getNumberOfLeads} from "../../../ecg/EcgHelper";
import {getStyledColor} from "../../../helpers/DrawHelper";
import {filterEcgData} from "../../../ecg/EcgFilter";
import {PX_PER_MM} from "./EcgViewer";
import {PlotPointsBuilder} from "../../../helpers/PlotPointsBuilder";
import {useTranslation} from "react-i18next";
import {Vector2d} from "konva/types/types";
import {KonvaEventObject} from "konva/types/Node";

const MM_PER_LEAD = 20;

const MAJOR_LINE_WIDTH_PX = 1.2;
const MINOR_LINE_WIDTH_PX = 1;
const MILLI_VOLT_LINE_WIDTH_PX = 2;
const MILLI_VOLT_TEXT_SIZE_MM = 2.5;
const SCALE_DATA_TEXT_SIZE_MM = 3.5;
const RULER_DATA_TEXT_SIZE_MM = 3.5;
const LEGEND_OPACITY = 0.75;
const DATA_LINE_WIDTH = 1.5;

interface Point {
    x: number;
    y: number | null;
}

interface Props {
    width: number;
    numberOfSamples: number;
    samplesPerScreen: number;
    firstSample: number;
    lastSample: number;
    ecgData: EcgData;
    mvScale: number;
    timeScale: number;
    lpf: boolean;
    hpf: boolean;
    showSaveRegion: boolean;
    saveRegionWidth: number | null;
    savePositionChangeListener: (position: number) => void;
    moveRegionLeft: boolean;
    moveRegionRight: boolean;
    ruler: boolean;
    onScrollPositionChanged: ((position: number) => void);
}

const REGION_MOVE_INTERVAL = 100;
const REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD = 30;

const X_SCROLL_REGION = 15;
const Y_SCROLL_REGION = 5;

export const EcgMonitor: React.FC<Props> = ({
                                                width,
                                                numberOfSamples,
                                                samplesPerScreen,
                                                firstSample,
                                                lastSample,
                                                ecgData,
                                                mvScale,
                                                timeScale,
                                                lpf,
                                                hpf,
                                                showSaveRegion,
                                                saveRegionWidth,
                                                savePositionChangeListener,
                                                moveRegionLeft,
                                                moveRegionRight,
                                                ruler,
                                                onScrollPositionChanged
                                            }: Props) => {
    const {t} = useTranslation();
    const [rulerStart, setRulerStart] = useState(null as Point | null);
    const [rulerEnd, setRulerEnd] = useState(null as Point | null);
    const [rulerActive, setRulerActive] = useState(false);
    useEffect(() => {
        const listener = () => {
            setRulerActive(false);
        };
        document.addEventListener('mouseup', listener);
        return () => {
            document.removeEventListener('mouseup', listener);
        }
    })
    useEffect(() => {
        if (!ruler) {
            setRulerStart(null);
            setRulerEnd(null);
            setRulerActive(false);
        }
    }, [ruler]);
    const [saveRegionPosition, setSaveRegionPosition] = useState(null as number | null);
    const adjustSaveRegionPosition = (position: number) => {
        let adjustedPosition = Math.min(position, numberOfSamples - (saveRegionWidth ?? 0));
        adjustedPosition = Math.max(adjustedPosition, 0);
        return adjustedPosition;
    };
    const getRegionMoveStep = (multiplier: number) => {
        return 1 / timeScale * ecgData.header.sampleRate * multiplier;
    };
    useEffect(() => {
        if (showSaveRegion && saveRegionWidth) {
            let start: number;
            if (saveRegionPosition !== null) {
                start = adjustSaveRegionPosition(saveRegionPosition);
            } else {
                const center = firstSample + (lastSample - firstSample) / 2;
                start = adjustSaveRegionPosition(center - saveRegionWidth / 2);
            }
            savePositionChangeListener(start);
            setSaveRegionPosition(start);
        } else {
            setSaveRegionPosition(null);
        }
    }, [showSaveRegion, saveRegionWidth]);  // eslint-disable-line
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (moveRegionLeft && saveRegionPosition) {
            let count = 0;
            let multiplier = 1;
            let position = saveRegionPosition;
            position = adjustSaveRegionPosition(position - getRegionMoveStep(multiplier));
            savePositionChangeListener(position);
            setSaveRegionPosition(position);
            interval = setInterval(() => {
                if (count > REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD) {
                    count = 0;
                    multiplier *= 2;
                } else {
                    count++;
                }
                position = adjustSaveRegionPosition(position - getRegionMoveStep(multiplier));
                savePositionChangeListener(position);
                setSaveRegionPosition(position);
            }, REGION_MOVE_INTERVAL);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [moveRegionLeft]);  // eslint-disable-line
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (moveRegionRight && saveRegionPosition) {
            let count = 0;
            let multiplier = 1;
            let position = saveRegionPosition;
            position = adjustSaveRegionPosition(position + getRegionMoveStep(multiplier));
            savePositionChangeListener(position);
            setSaveRegionPosition(position);
            interval = setInterval(() => {
                if (count > REGION_MOVE_STEP_MULTIPLIER_UPDATE_PERIOD) {
                    count = 0;
                    multiplier *= 2;
                } else {
                    count++;
                }
                position = adjustSaveRegionPosition(position + getRegionMoveStep(multiplier));
                savePositionChangeListener(position);
                setSaveRegionPosition(position);
            }, REGION_MOVE_INTERVAL);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [moveRegionRight]); // eslint-disable-line
    const isEasi = (ecgData.header.sensorConfig ?? 0) === 1;
    const numberOfLeads = useMemo(() => getNumberOfLeads(isEasi, ecgData.header.adcChannels), [ecgData.header.adcChannels]); // eslint-disable-line
    const height = useMemo(() => (numberOfLeads ?? 0) * MM_PER_LEAD * PX_PER_MM, [numberOfLeads]); // eslint-disable-line
    const filteredLeadsData = useMemo(() => {
        const filteredData = filterEcgData(ecgData.header.adcChannels, ecgData.ecg, lpf, hpf);
        const leadsData = new Array<Array<number | null>>();
        for (let i = 0; i < numberOfSamples; i++) {
            const input = new Array<number | null>();
            for (let j = 0; j < ecgData.header.adcChannels; j++) {
                input.push(filteredData[i * ecgData.header.adcChannels + j]);
            }
            leadsData.push(calculateLeads(isEasi, ecgData.header.adcChannels, input));
        }
        return leadsData;
    }, [numberOfSamples, ecgData, lpf, hpf]); // eslint-disable-line
    const mvScaledData = useMemo(() => {
        const scaleFactor = -1 * mvScale * PX_PER_MM / ecgData.header.adcScale;
        return filteredLeadsData.map(samples => samples.map(sample => (sample !== null ? sample * scaleFactor : null)));
    }, [filteredLeadsData, ecgData, mvScale]); // eslint-disable-line
    const timeScaleFactor = useMemo(() => timeScale * PX_PER_MM / ecgData.header.sampleRate, [timeScale, ecgData]); // eslint-disable-line
    const timeScaledData = useMemo(() => {
        const points = new Array<Array<Point>>();
        for (let i = 0; i < (numberOfLeads ?? 0); i++) {
            const builder = new PlotPointsBuilder();
            for (let j = 0; j < numberOfSamples; j++) {
                builder.addData(j * timeScaleFactor, mvScaledData[j][i]);
            }
            points.push(builder.build());
        }
        return points;
    }, [mvScaledData, timeScaleFactor, numberOfLeads, numberOfSamples]); // eslint-disable-line
    const drawPoints = useMemo(() => {
        const startX = firstSample * timeScaleFactor;
        const endX = lastSample * timeScaleFactor;
        const points = new Array<Array<number>>();
        for (let i = 0; i < (numberOfLeads ?? 0); i++) {
            let previousPoints = new Array<number>();
            const channelPoints = new Array<number>();
            const offsetY = (i + 0.5) * MM_PER_LEAD * PX_PER_MM;
            const channel = timeScaledData[i];
            let isFirst = true;
            for (let j = 0; j < channel.length; j++) {
                const point = channel[j];
                if (point.x >= startX && point.x <= endX) {
                    if (isFirst) {
                        channelPoints.push(...previousPoints)
                        isFirst = false;
                    }
                    channelPoints.push(point.x - startX, point.y !== null ? point.y + offsetY : Number.NaN);
                }
                if (point.x > endX) {
                    channelPoints.push(point.x - startX, point.y !== null ? point.y + offsetY : Number.NaN);
                    if (point.y !== null) {
                        break;
                    }
                }
                if (point.x < startX){
                    if (point.y !== null){
                        previousPoints = new Array<number>();
                    }
                    previousPoints.push(point.x - startX, point.y !== null ? point.y + offsetY : Number.NaN);
                }
            }
            points.push(channelPoints);
        }
        return points;
    }, [firstSample, lastSample, timeScaledData, timeScaleFactor, numberOfLeads]); // eslint-disable-line
    const [dataPoints, nullPoints] = useMemo(() => {
        const dataPoints = new Array<Array<Array<number>>>();
        const nullPoints = new Array<Array<Array<number>>>();
        for (let i = 0; i < (numberOfLeads ?? 0); i++) {
            const offsetY = (i + 0.5) * MM_PER_LEAD * PX_PER_MM;
            dataPoints.push(new Array<Array<number>>());
            nullPoints.push(new Array<Array<number>>());
            let lastValuedPoint: Array<number> | undefined = undefined;
            let dataPath = new Array<number>();
            let nullPath = new Array<number>();
            for (let j = 0; j < drawPoints[i].length; j += 2) {
                const x = drawPoints[i][j];
                const y = drawPoints[i][j + 1];
                if (!isNaN(y)) {
                    dataPath.push(x, y);
                    lastValuedPoint = new Array<number>(x, y);
                    if (nullPath.length > 0){
                        nullPath[2] = x;
                        nullPath[3] = y;
                        nullPoints[i].push(nullPath);
                        nullPath = new Array<number>();
                    }
                } else {
                    if (dataPath.length >= 4){
                        dataPoints[i].push(dataPath);
                    }
                    if (nullPath.length === 0) {
                        if (lastValuedPoint){
                            nullPath.push(...lastValuedPoint);
                        } else {
                            nullPath.push(x, offsetY);
                        }
                        nullPath.push(x, offsetY);
                    } else {
                        nullPath[2] = x;
                        nullPath[3] = offsetY;
                    }
                    dataPath = new Array<number>();
                }
            }
            if (dataPath.length >= 4){
                dataPoints[i].push(dataPath);
            }
            if (nullPath.length >= 4){
                nullPoints[i].push(nullPath);
            }
        }
        return [dataPoints, nullPoints];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawPoints]);
    let base = null;
    let border = null;
    let majorGridLines = null;
    let minorGridLines = null;
    let scaleInfo = null;
    let milliVolts = null;
    let leadsDataLines = null;
    let leadsNullLines = null;
    let saveRegion = null;
    let rulerData = null;
    let rulerInfo = null;
    let rulerText = null;
    if (numberOfLeads && width > 0 && height > 0) {
        const backgroundColor = getStyledColor("--ecg-background");
        const gridLineColor = getStyledColor("--ecg-grid-line");
        const contentColors = [getStyledColor("--ecg-content-1"), getStyledColor("--ecg-content-2"), getStyledColor("--ecg-content-3")];
        const nullColor = getStyledColor("--ecg-content-null");
        const scaleDataColor = getStyledColor("--primary-text");
        const rulerDataColor = getStyledColor("--primary-text");
        const saveRegionColor = getStyledColor("--active-secondary-background");
        base = <Rect width={width} height={height} x={0} y={0} strokeEnabled={false} fill={backgroundColor}
                     fillEnabled={true}/>
        border =
            <Rect width={width} height={height} x={0} y={0} strokeWidth={MAJOR_LINE_WIDTH_PX} stroke={gridLineColor}/>
        majorGridLines = [];
        minorGridLines = [];
        const gridStep = 5 * PX_PER_MM;
        let iX = 1;
        for (let x = gridStep; x < width; x += gridStep) {
            if (iX % 2 === 0) {
                majorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`h${iX}`} points={[x, 0, x, height]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iX++;
        }
        let iY = 1;
        for (let y = gridStep; y < height; y += gridStep) {
            if (iY % 2 === 0) {
                majorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MAJOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            } else {
                minorGridLines.push(<Line key={`v${iY}`} points={[0, y, width, y]} strokeWidth={MINOR_LINE_WIDTH_PX}
                                          stroke={gridLineColor}/>)
            }
            iY++;
        }
        let scaleData = t("ecg_scale_data_format", {mvScale: mvScale.toFixed(1), timeScale: timeScale.toFixed(1)});
        if (isEasi){
            scaleData = `EASI     ${scaleData}`;
        }
        const scaleDataTextSize = SCALE_DATA_TEXT_SIZE_MM * PX_PER_MM;
        scaleInfo = <Text x={0} y={height - 0.75 * PX_PER_MM - scaleDataTextSize} fill={scaleDataColor} opacity={0.5}
                          fontStyle={"bold"} fontSize={scaleDataTextSize} width={width - 5 * PX_PER_MM} align={"right"}
                          text={scaleData}/>;
        const mvX1 = 0;
        const mvY1 = 0;
        const mvX2 = 1.5 * PX_PER_MM;
        const mvY2 = -mvScale * PX_PER_MM;
        const mvX3 = 3.5 * PX_PER_MM;
        const mvX4 = 5 * PX_PER_MM;
        milliVolts = [];
        for (let lead = 0; lead < numberOfLeads; lead++) {
            const name = getLeadName(lead);
            const offsetY = (lead + 0.5) * MM_PER_LEAD * PX_PER_MM;
            const color = contentColors[lead % contentColors.length];
            milliVolts.push(
                <Group key={`mvg-${lead}`}>
                    <Line key={`mv-${lead}`}
                          points={[mvX1, mvY1 + offsetY, mvX2, mvY1 + offsetY, mvX2, mvY2 + offsetY, mvX3, mvY2 + offsetY, mvX3, mvY1 + offsetY, mvX4, mvY1 + offsetY]}
                          strokeWidth={MILLI_VOLT_LINE_WIDTH_PX} opacity={LEGEND_OPACITY}
                          stroke={color}/>
                    <Text key={`mvt-${lead}`} x={0} width={5 * PX_PER_MM} y={offsetY + 2 * PX_PER_MM} text={name}
                          fill={color} opacity={LEGEND_OPACITY} fontStyle={"bold"}
                          fontSize={MILLI_VOLT_TEXT_SIZE_MM * PX_PER_MM} align={"center"}/>
                </Group>
            );
        }
        leadsDataLines = dataPoints.flatMap((lines, lead) => lines.map((points, i) => <Line key={`ld-${lead}-${i}`} points={points} strokeWidth={DATA_LINE_WIDTH}
                                                       stroke={contentColors[lead % contentColors.length]}/>));
        leadsNullLines = nullPoints.flatMap((lines, lead) => lines.map((points, i) => <Line key={`ln-${lead}-${i}`} points={points} strokeWidth={DATA_LINE_WIDTH}
                                                                                        stroke={nullColor}/>));
        const dragBoundFunc = (pos: Vector2d) => {
            const regionStart = adjustSaveRegionPosition(firstSample + pos.x / PX_PER_MM / timeScale * ecgData.header.sampleRate);
            savePositionChangeListener(regionStart);
            setSaveRegionPosition(regionStart);
            const newX = (regionStart - firstSample) / ecgData.header.sampleRate * timeScale * PX_PER_MM;
            return {
                x: newX,
                y: 0
            }
        };
        if (showSaveRegion) {
            let y = 0;
            let h = height;
            let x = 0;
            let w = width;
            if (saveRegionWidth !== null && saveRegionPosition !== null) {
                x = (saveRegionPosition - firstSample) / ecgData.header.sampleRate * timeScale * PX_PER_MM;
                w = saveRegionWidth / ecgData.header.sampleRate * timeScale * PX_PER_MM;
            }
            saveRegion =
                <Rect x={x} y={y} width={w} height={h} strokeEnabled={false} fill={saveRegionColor} opacity={0.35}
                      fillEnabled={true} draggable={true} dragBoundFunc={dragBoundFunc}/>
        }
        if (ruler && rulerStart && rulerEnd) {
            const x1 = (rulerStart.x - firstSample) / ecgData.header.sampleRate * timeScale * PX_PER_MM;
            const y1 = rulerStart.y ?? 0;
            const x2 = (rulerEnd.x - firstSample) / ecgData.header.sampleRate * timeScale * PX_PER_MM;
            const y2 = rulerEnd.y ?? 0;
            const w = x2 - x1;
            const h = y2 - y1;
            const ms = Math.round(Math.abs(rulerStart.x - rulerEnd.x) / ecgData.header.sampleRate * 1000);
            const bpm = Math.round(60 / (ms / 1000));
            const mkv = Math.round(Math.abs(y1 - y2) / PX_PER_MM / mvScale * 1000);
            const text = `${ms} ${t('units_ms')}${(bpm >= 20 && bpm <= 300) ? ` (${bpm} ${t('units_bpm')})` : ''}   ${mkv} ${t('units_mkv')}`;
            const tw = 300;
            let tx = w > 0 ? x1 : (x1 - tw);
            let align = w > 0 ? 'left' : 'right';
            let ty = h < 0 ? (y1 + 2 * PX_PER_MM) : (y1 - RULER_DATA_TEXT_SIZE_MM * PX_PER_MM);
            if (ty < RULER_DATA_TEXT_SIZE_MM * PX_PER_MM) {
                ty = Math.max(y1, y2) + 2 * PX_PER_MM;
            }
            if (ty > height - RULER_DATA_TEXT_SIZE_MM * PX_PER_MM) {
                ty = Math.min(y1, y2) - RULER_DATA_TEXT_SIZE_MM * PX_PER_MM;
            }
            const rulerDataMouseEnterHandler = (evt: KonvaEventObject<MouseEvent>) => {
                if (!rulerActive) {
                    const container = evt.target?.getStage()?.container();
                    if (container) {
                        container.style.cursor = "move";
                    }
                }
            };
            const rulerDataMouseLeaveHandler = (evt: KonvaEventObject<MouseEvent>) => {
                const container = evt.target?.getStage()?.container();
                if (container) {
                    container.style.cursor = "crosshair";
                }
            };
            const rulerDataDragBoundFunc = (pos: Vector2d) => {
                let posX = pos.x;
                let posY = pos.y;
                let xd1 = firstSample + pos.x / PX_PER_MM / timeScale * ecgData.header.sampleRate;
                let yd1 = pos.y;
                let xd2 = xd1 + (rulerEnd.x - rulerStart.x);
                let yd2 = yd1 + ((rulerEnd.y ?? 0) - (rulerStart.y ?? 0));
                const leftOverScreenX = Math.min(xd1, xd2);
                const rightOverScreenX = Math.max(xd1, xd2) - numberOfSamples;
                if (leftOverScreenX < 0){
                    xd1 -= leftOverScreenX;
                    xd2 -= leftOverScreenX;
                    posX -= leftOverScreenX / ecgData.header.sampleRate * timeScale * PX_PER_MM;
                }
                if (rightOverScreenX > 0){
                    xd1 -= rightOverScreenX;
                    xd2 -= rightOverScreenX;
                    posX -= rightOverScreenX / ecgData.header.sampleRate * timeScale * PX_PER_MM;
                }
                const topOverScreenY = Math.min(yd1, yd2);
                const bottomOverScreenY = Math.max(yd1, yd2) - height;
                if (topOverScreenY < 0) {
                    yd1 -= topOverScreenY;
                    yd2 -= topOverScreenY;
                    posY -= topOverScreenY;
                }
                if (bottomOverScreenY > 0) {
                    yd1 -= bottomOverScreenY;
                    yd2 -= bottomOverScreenY;
                    posY -= bottomOverScreenY;
                }
                const start: Point = {
                    x: xd1,
                    y: yd1
                };
                const end: Point = {
                    x: xd2,
                    y: yd2
                };
                setRulerStart(start);
                setRulerEnd(end);
                return {
                    x: posX,
                    y: posY
                };
            };
            rulerData =
                <Rect x={x1} y={y1} width={w} height={h} strokeEnabled={true} dashEnabled={true} dash={[5, 5]}
                      strokeWidth={1} stroke={rulerDataColor} opacity={0.75}
                      draggable={!rulerActive}
                      dragBoundFunc={rulerDataDragBoundFunc}
                      onMouseEnter={rulerDataMouseEnterHandler}
                      onMouseLeave={rulerDataMouseLeaveHandler}
                      onMouseMove={rulerDataMouseEnterHandler}
                      onMouseDown={(e) => e.cancelBubble = true}/>;
            rulerInfo =
                <Text x={tx} width={tw} y={ty} text={text}
                      fill={rulerDataColor} opacity={0.75} fontStyle={"bold"}
                      fontSize={RULER_DATA_TEXT_SIZE_MM * PX_PER_MM} align={align}/>;
            rulerText = <Text x={5 * PX_PER_MM} y={height - 0.75 * PX_PER_MM - scaleDataTextSize} fill={scaleDataColor}
                              opacity={0.5}
                              fontStyle={"bold"} fontSize={scaleDataTextSize} width={width - 5 * PX_PER_MM}
                              align={"left"}
                              text={text}/>;
        }
    }
    const mouseDownHandler = (evt: KonvaEventObject<MouseEvent>) => {
        if (ruler) {
            setRulerEnd(null);
            setRulerStart({
                x: firstSample + evt.evt.offsetX / timeScale / PX_PER_MM * ecgData.header.sampleRate,
                y: evt.evt.offsetY
            });
            setRulerActive(true);
        }
    };
    const mouseMoveHandler = (evt: KonvaEventObject<MouseEvent>) => {
        if (ruler && rulerStart && rulerActive) {
            setRulerEnd({
                x: firstSample + evt.evt.offsetX / timeScale / PX_PER_MM * ecgData.header.sampleRate,
                y: evt.evt.offsetY
            });
            if (evt.evt.offsetX < X_SCROLL_REGION * PX_PER_MM) {
                onScrollPositionChanged(Math.max(0, firstSample - X_SCROLL_REGION / timeScale / PX_PER_MM * ecgData.header.sampleRate));
            }
            if (evt.evt.offsetX > (width - X_SCROLL_REGION * PX_PER_MM)) {
                onScrollPositionChanged(Math.min(numberOfSamples - samplesPerScreen, firstSample + X_SCROLL_REGION / timeScale / PX_PER_MM * ecgData.header.sampleRate));
            }
            if (evt.evt.clientY < Y_SCROLL_REGION * PX_PER_MM) {
                window.scrollBy(0, -Y_SCROLL_REGION * PX_PER_MM);
            }
            if (evt.evt.clientY > (window.innerHeight - 2 * Y_SCROLL_REGION * PX_PER_MM)) {
                window.scrollBy(0, Y_SCROLL_REGION * PX_PER_MM);
            }
        }
    };
    const mouseEnterHandler = (evt: KonvaEventObject<MouseEvent>) => {
        if (ruler) {
            const container = evt.target?.getStage()?.container();
            if (container) {
                container.style.cursor = "crosshair";
            }
        }
    };
    const mouseLeaveHandler = (evt: KonvaEventObject<MouseEvent>) => {
        const container = evt.target?.getStage()?.container();
        if (container) {
            container.style.cursor = "default";
        }
    };
    return (
        <Stage width={width} height={height}>
            <Layer onMouseDown={mouseDownHandler}
                   onMouseMove={mouseMoveHandler}
                   onMouseEnter={mouseEnterHandler}
                   onMouseLeave={mouseLeaveHandler}>
                {base ?? base}
                {minorGridLines && minorGridLines}
                {majorGridLines && majorGridLines}
                {border && border}
                {milliVolts && milliVolts}
                {leadsDataLines && leadsDataLines}
                {leadsNullLines && leadsNullLines}
                {scaleInfo && scaleInfo}
                {rulerText && rulerText}
                {rulerData && rulerData}
                {rulerInfo && rulerInfo}
                {saveRegion && saveRegion}
            </Layer>
        </Stage>
    );
}