import {Record} from "../../../models/Record";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {LtEcgAttachmentsData} from "../../../models/LtEcgAttachmentsData";
import {
    isBeecardiaLtEcgDataFile,
    isBinoraLtEcgDataFile,
    isBinoraLtEcgIndexFile,
    isLtEcgDataFile,
    isLtEcgEventsFile
} from "../../../helpers/AttachmentHelper";
import {
    getBeecardiaEcgDataOffset,
    getBeecardiaEcgDataSize,
    LtEcgFileHeader,
    parseFileHeader
} from "../../../models/LtEcgFileHeader";
import {CacheFile} from "../../../local_cache/CacheFile";
import {LtEcgData} from "../../../models/LtEcgData";
import {requestLtEcgFile} from "../../../api/ApiHelper";
import {useTranslation} from "react-i18next";
import {useAbortController} from "../../../hooks";
import {parseUint32} from "../../../helpers/BinaryHelper";
import {extractScpEcgData, getStartVectorFromBlock} from "../../../lt_ecg/ScpEcgUtils";
import AsyncIndicatorWithText from "../AsyncIndicatorWithText/AsyncIndicatorWithText";
import {FetchError} from "../FetchError/FetchError";
import {PatientInfo} from "../PatientInfo/PatientInfo";
import {LtEcgViewer} from "../LtEcgViewer/LtEcgViewer";
import {EventFileData, parseEventFile, parseQrs, QrsData} from "../../../lt_ecg/Events";
import {logError} from "../../../helpers/LogHelper";

const TIME_TABLE_LENGTH = 16384;
const BINORA_LTE_DATA_OFFSET = 2048;
const LTE_DATA_BLOCK_SIZE = 512;

const BEECARDIA_LTE_HEADER_SIZE = 504;

interface DataRequest {
    firstSample: number;
    lastSample: number;
}

interface EcgDataParams {
    offset: number;
    size: number;
    blocksInFile: number;
}

interface LtEcgRecordViewProps {
    record: Record;
    ltEcgAttachments: LtEcgAttachmentsData;
}

function binarySearch(nums: number[], target: number): number {
    if (target === 0) {
        return 0;
    }
    let left: number = 0;
    let right: number = nums.length - 1;
    while (left <= right) {
        const mid: number = Math.floor((left + right) / 2);
        if (nums[mid] === target) return mid;
        if (target < nums[mid]) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
    }
    return right;
}

export const LtEcgRecordView: React.FC<LtEcgRecordViewProps> = ({record, ltEcgAttachments}: LtEcgRecordViewProps) => {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const [isFetching, setFetchingState] = useState(null as string | null | undefined);
    const [hasError, setErrorState] = useState(false);
    const recFile = useMemo(() => ltEcgAttachments?.attachments.find(a => isLtEcgDataFile(a.fileName)), [ltEcgAttachments]);
    const tableFile = useMemo(() => ltEcgAttachments?.attachments.find(a => isBinoraLtEcgIndexFile(a.fileName)), [ltEcgAttachments]);
    const eventsFile = useMemo(() => ltEcgAttachments?.attachments.find(a => isLtEcgEventsFile(a.fileName)), [ltEcgAttachments]);
    const [fileHeader, setFileHeader] = useState(undefined as LtEcgFileHeader | undefined);
    const [recData, setRecData] = useState(undefined as CacheFile | undefined);
    const [eventsData, setEventsData] = useState(undefined as EventFileData | undefined | null);
    const [ecgDataParams, setEcgDataParams] = useState(undefined as EcgDataParams | undefined);
    const [indexTable, setIndexTable] = useState(undefined as number[] | undefined);
    const [ecgData, setEcgData] = useState(undefined as LtEcgData | undefined);
    const blocksInIndex = useMemo(() => Math.floor((ecgDataParams?.blocksInFile ?? 0) / TIME_TABLE_LENGTH) + 1, [ecgDataParams]);
    const [dataRequest, setDataRequest] = useState({firstSample: -1, lastSample: -1} as DataRequest);
    useEffect(() => {
        if (recFile) {
            setFetchingState(t('caching'));
            requestLtEcgFile(recFile.link, recFile.fileName, recFile.uid, false, controller, progress => {
                if (progress instanceof CacheFile) {
                    setRecData(progress);
                } else {
                    setFetchingState(t('caching_progress', {progress: Math.floor(progress)}));
                }
            }, error => {
                logError("Lt Ecg data file reading error", error);
                setErrorState(true);
                setFetchingState(undefined);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recFile]);
    useEffect(() => {
        if (recData) {
            setFetchingState(t('indexing'));
            if (eventsFile) {
                requestLtEcgFile(eventsFile.link, eventsFile.fileName, eventsFile.uid, false, controller, progress => {
                    if (progress instanceof CacheFile) {
                        progress.readData().then(data => {
                            const eventFileData = parseEventFile(data);
                            setEventsData(eventFileData);
                        })
                    }
                }, error => {
                    logError("Lt Ecg events file reading error", error);
                    setErrorState(true);
                    setFetchingState(undefined);
                });
            } else {
                setEventsData(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recData]);
    useEffect(() => {
        if (recData && eventsData !== undefined) {
            if (isBinoraLtEcgDataFile(recFile?.fileName)) {
                recData.getSize().then(size => {
                    setEcgDataParams({
                        offset: BINORA_LTE_DATA_OFFSET,
                        size: size - BINORA_LTE_DATA_OFFSET,
                        blocksInFile: (size - BINORA_LTE_DATA_OFFSET) / LTE_DATA_BLOCK_SIZE
                    });
                }).catch(error => {
                    logError("Lt Ecg data file size request error", error);
                    setErrorState(true);
                    setFetchingState(undefined);
                });
            }
            if (isBeecardiaLtEcgDataFile(recFile?.fileName)) {
                recData.readData({start: 0, count: BEECARDIA_LTE_HEADER_SIZE}).then(data => {
                    const offset = getBeecardiaEcgDataOffset(data);
                    const size = getBeecardiaEcgDataSize(data);
                    setEcgDataParams({offset: offset, size: size, blocksInFile: size / LTE_DATA_BLOCK_SIZE});
                }).catch(error => {
                    logError("Lt Ecg data file read error", error);
                    setErrorState(true);
                    setFetchingState(undefined);
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recData, eventsData]);
    useEffect(() => {
        if (recData && ecgDataParams) {
            let headerSize = 0;
            if (isBinoraLtEcgDataFile(recFile?.fileName)) {
                headerSize = BINORA_LTE_DATA_OFFSET;
            }
            if (isBeecardiaLtEcgDataFile(recFile?.fileName)) {
                headerSize = BEECARDIA_LTE_HEADER_SIZE;
            }
            recData.readData({start: 0, count: headerSize}).then(data => {
                const header = parseFileHeader(recFile?.fileName, data);
                setFileHeader(header);
            }).catch(error => {
                logError("Lt Ecg data file read error", error);
                setErrorState(true);
                setFetchingState(undefined);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recData, ecgDataParams]);
    useEffect(() => {
        if (recData && ecgDataParams) {
            setFetchingState(t('indexing'));
            if (tableFile) {
                requestLtEcgFile(tableFile.link, tableFile.fileName, tableFile.uid, true, controller, progress => {
                    if (progress instanceof CacheFile) {
                        progress.readData().then(data => {
                            const table = new Array<number>();
                            let offset = 0;
                            while (offset < 65536) {
                                const sample = parseUint32(data, offset);
                                if (offset > 0 && sample === 0) {
                                    break;
                                }
                                table.push(sample);
                                offset += 4;
                            }
                            setIndexTable(table);
                        });
                    }
                }, error => {
                    logError("Lt Ecg index file read error", error);
                })
            } else {
                const promise = async () => {
                    let dataOffset = ecgDataParams.offset;
                    const dataLimit = dataOffset + ecgDataParams.size;
                    const table = new Array<number>();
                    while (dataOffset < dataLimit) {
                        const data = await recData.readData({start: dataOffset, count: 18});
                        table.push(getStartVectorFromBlock(recFile?.fileName, data));
                        dataOffset += blocksInIndex * LTE_DATA_BLOCK_SIZE;
                    }
                    setIndexTable(table);
                }
                promise().catch(error => {
                    logError("Lt Ecg index file build error", error);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableFile, recData, ecgDataParams]);
    useEffect(() => {
        if (recData && fileHeader && indexTable && ecgDataParams && dataRequest && dataRequest.firstSample >= 0 && dataRequest.lastSample >= 0) {
            const ecgPos = binarySearch(indexTable, dataRequest.firstSample);
            const ecgFirstBlock = ecgPos * blocksInIndex;
            const firstSampleInData = indexTable[ecgPos];
            let count = 0;
            for (let i = ecgPos; i < indexTable.length; i++) {
                count += blocksInIndex;
                if (indexTable[i] > dataRequest.lastSample) {
                    break;
                }
            }
            let qrs : Array<QrsData> | undefined = undefined;
            if (eventsData) {
                if (eventsData.qrsCount > 0) {
                    const qrsPos = binarySearch(eventsData.qrsIndexTable, dataRequest.firstSample);
                    const qrsFirstIndex = qrsPos * eventsData.qrsInIndex;
                    let qrsCount = 0;
                    for (let i = qrsPos; i < eventsData.qrsIndexTable.length; i++) {
                        qrsCount += eventsData.qrsInIndex;
                        if (eventsData.qrsIndexTable[i] > dataRequest.lastSample) {
                            break;
                        }
                    }
                    qrs = parseQrs(eventsData, qrsFirstIndex, qrsCount, dataRequest.firstSample, dataRequest.lastSample);
                }
            }
            if (ecgFirstBlock !== -1 && count > 0) {
                recData.readData({
                    start: ecgDataParams.offset + ecgFirstBlock * LTE_DATA_BLOCK_SIZE,
                    count: count * LTE_DATA_BLOCK_SIZE
                }).then(data => {
                    if (data) {
                        const channelCount = fileHeader.sensorMetadata.channelCount;
                        const vectors = new Array<number>();
                        let position = 0;
                        while (position + LTE_DATA_BLOCK_SIZE < data.byteLength) {
                            const extractedData = extractScpEcgData(recFile?.fileName, data, position);
                            vectors.push(...extractedData);
                            position += LTE_DATA_BLOCK_SIZE;
                        }
                        const channelsData = new Array<Array<number>>();
                        for (let i = 0; i < channelCount; i++) {
                            channelsData.push(new Array<number>());
                        }
                        let sample = firstSampleInData;
                        for (let i = 0; i < vectors.length; i += channelCount) {
                            if (sample >= dataRequest.firstSample) {
                                for (let j = 0; j < channelCount; j++) {
                                    channelsData[j].push(vectors[i + j]);
                                }
                            }
                            if (sample > dataRequest.lastSample) {
                                break;
                            }
                            sample++;
                        }
                        setEcgData({
                            ecg: channelsData,
                            qrs: qrs ?? []
                        });
                    }
                }).catch(error => {
                    logError("Lt Ecg data file read error", error);
                    setErrorState(true);
                    setFetchingState(undefined);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recFile, fileHeader, indexTable, ecgDataParams, dataRequest]);
    useEffect(() => {
        if (isFetching !== undefined && fileHeader && indexTable && ecgDataParams) {
            setFetchingState(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileHeader, indexTable, ecgDataParams]);
    const dataRequestHandler = (firstSample: number, lastSample: number) => {
        setDataRequest({firstSample, lastSample});
    };
    const isOk = !hasError && isFetching === undefined;
    return (
        <Fragment>
            {isFetching !== undefined && <AsyncIndicatorWithText text={isFetching}/>}
            {isFetching === undefined && hasError && <FetchError/>}
            {(isOk && record) &&
                <div className="ecg-data-container">
                    <PatientInfo record={record}/>
                    {fileHeader &&
                        <LtEcgViewer ecgData={ecgData} header={fileHeader}
                                     numberOfSamples={fileHeader.record.samplesInRecord}
                                     dataRequestCallback={dataRequestHandler}/>
                    }
                </div>
            }
        </Fragment>
    );
}