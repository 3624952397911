import React from "react";

function SvgEcgIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#IconEcg_svg__clip0)" fill="currentColor">
                <path d="M9.3.346H1.447c-.307 0-.552.23-.552.516v7.334c0 .286.245.515.552.515H9.3c.307 0 .552-.229.552-.515V.862C9.791.575 9.546.346 9.3.346zM9.177 8.08H1.57V.976h7.608v7.105z" />
                <path d="M2.183 5.33h.982c.122 0 .184-.056.184-.114l.307-1.031.43 2.235c0 .114.122.171.244.171.123 0 .185-.114.185-.171V5.33h.552c.122 0 .184-.056.184-.171l.307-2.063.674 3.61c0 .115.123.172.185.172.122 0 .184-.057.184-.172l.43-1.49h.183l.246.401c.06.058.122.115.184.115.061 0 .122-.057.184-.115l.245-.343h.307c.123 0 .184-.058.184-.172 0-.115-.061-.172-.184-.172h-.368c-.062 0-.123.057-.184.114l-.184.115-.123-.23c-.061-.057-.123-.114-.184-.114h-.43a.844.844 0 00-.245.172l-.123.688-.736-3.782c0-.115-.123-.172-.184-.172-.123 0-.184.057-.184.172l-.491 3.094H4.27L3.9 3.325c0-.114-.123-.171-.184-.171s-.184.057-.184.114l-.49 1.72h-.86c-.123 0-.184.056-.184.171 0 .115.061.172.184.172z" />
            </g>
            <defs>
                <clipPath id="IconEcg_svg__clip0">
                    <path
                        fill="#fff"
                        transform="translate(.895 .346)"
                        d="M0 0h8.896v8.308H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgEcgIcon;
