import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {Button} from "../Button/Button";
import * as ApiHelper from "../../../api/ApiHelper";

interface DialogPublicLinkProps {
    token: string;
    id: string;
    controller: AbortController;
    closeHandler: () => void;
}

export const DialogPublicLink: React.FC<DialogPublicLinkProps> = ({
                                                                      token,
                                                                      id,
                                                                      controller,
                                                                      closeHandler
                                                                  }: DialogPublicLinkProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [isFetching, setFetchingState] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null as string | null);
    const [dialogTop, setDialogTop] = useState(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    const [publicLink, setPublicLink] = useState(null as string | null);
    const [linkCopied, setLinkCopied] = useState(false);
    const linkHandler = (link: string) => {
        setPublicLink(link);
        setFetchingState(false);
    };
    useEffect(() => {
        ApiHelper.getPublicLink(token, id, controller, linkHandler, errorHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const scrollHandler = () => {
        setDialogTop(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    });
    const keyDownHandler = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            closeHandler();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        }
    });
    const errorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setErrorMessage(t("connection_problem"));
            setFetchingState(false);
        }
    };
    const copyClickHandler = () => {
        if (publicLink) {
            navigator.clipboard.writeText(publicLink).then(() => {
                setLinkCopied(true);
                setTimeout(() => setLinkCopied(false), 3000);
            });
        }
    };
    const documentHeight = document.documentElement.offsetHeight;
    return (
        <div className="dialog-container" style={{height: documentHeight}}>
            <div className="window-modal" style={{marginTop: dialogTop}}>
                <div
                    className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("public_link")}</div>
                {isFetching ? (
                    <AsyncIndicator/>
                ) : (
                    <Fragment>
                        {errorMessage &&
                            <div className="window-modal-error d-flex justify-content-center align-items-center my-2">
                                {errorMessage}
                            </div>}
                        {publicLink &&
                            <Fragment>
                                <div
                                    className="window-modal-text my-2 disable-select">{t("public_link_description")}</div>
                                <div className="window-modal-text mt-2 text-break">{publicLink}</div>
                                <div className="window-modal-secondary-text my-4" style={{opacity: `${linkCopied ? '1' : '0'}`}}>{t('link_copied')}</div>
                            </Fragment>
                        }
                    </Fragment>)
                }
                <div className="d-flex justify-content-around mt-2">
                    {publicLink &&
                        <Button text={t("copy")} danger={false} onClick={copyClickHandler}/>
                    }
                    <Button text={t("ok")} danger={false} onClick={closeHandler}/>
                </div>
            </div>
        </div>
    );
}