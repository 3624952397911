import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import themes from './themes';
import { GOOGLE_CLIENT_ID } from './settings';
import {GoogleOAuthProvider} from "@react-oauth/google";


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <GoogleOAuthProvider  clientId={GOOGLE_CLIENT_ID}>
                    <I18nextProvider i18n={i18n}>
                        <ThemeSwitcherProvider defaultTheme="light" themeMap={themes} insertionPoint={"inject-styles-here"}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <App />
                            </MuiPickersUtilsProvider>
                        </ThemeSwitcherProvider>
                    </I18nextProvider>
                </GoogleOAuthProvider >
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
