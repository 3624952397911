import React from "react";
import {useTranslation} from "react-i18next";
import {UserAccessData} from "../../../models/UserAccessData";
import UserAccess from "../UserAccess/UserAccess";
import {Divider} from "@material-ui/core";

interface OwnerUserInfoProps {
    user: UserAccessData
}

export const OwnerUserInfo: React.FC<OwnerUserInfoProps> = ({user}: OwnerUserInfoProps) => {
    const {t} = useTranslation();
    return (
        <div className="data-list-container">
            <div className="data-list">
                <div className="data-list-title">{t("data_owner")}</div>
                <Divider/>
                <table>
                    <thead>
                    <tr className="table-header">
                        <th>{t("name")}</th>
                        <th>{t("email")}</th>
                        <th>{t("type")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <UserAccess key={user.id} data={user} showType={true}
                                onClick={() => {}}/>
                    </tbody>
                </table>
            </div>
        </div>
    );
}