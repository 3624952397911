import * as React from "react";

function SvgPrintIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.428 13.285V11h8v2.286h-8zm0-5.714V1.857h5.714v1.428c0 .473.384.857.858.857h1.428v3.429h-8zm10.286.571a.575.575 0 01-.572.572.575.575 0 01-.571-.572c0-.312.259-.571.572-.571.312 0 .57.259.57.571zm1.143 0c0-.937-.777-1.714-1.714-1.714h-.572V4.142c0-.473-.268-1.125-.607-1.464l-1.357-1.357c-.34-.34-.991-.607-1.465-.607h-6a.857.857 0 00-.857.857v4.857h-.571C.776 6.428 0 7.205 0 8.142v3.715c0 .151.134.285.285.285h2v1.429c0 .473.384.857.857.857h8.572a.857.857 0 00.857-.857v-1.429h2a.294.294 0 00.286-.285V8.142z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPrintIcon;
