import * as React from "react"
import { SVGProps } from "react"

const IconBattery = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 10 5"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M.246.563v3.816c0 .375.188.566.567.566h6.964c.379 0 .566-.191.566-.566V.563c0-.379-.188-.571-.566-.571H.813c-.379 0-.567.192-.567.571z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M8.344 1.723v1.492c0 .191.098.285.285.285h.559c.188 0 .281-.094.281-.285V1.723c0-.192-.094-.285-.281-.285h-.559c-.188 0-.285.093-.285.285z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M8.344 1.723v1.492c0 .191.098.285.285.285h.559c.188 0 .281-.094.281-.285V1.723c0-.192-.094-.285-.281-.285h-.559c-.188 0-.285.093-.285.285z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M1.238 1.438h1.125v2.063H1.238z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.567}
            d="M1.238 1.438h1.125v2.063H1.238z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M3.695 1.438H4.82v2.063H3.695z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.567}
            d="M3.695 1.438H4.82v2.063H3.695z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M6.152 1.438h1.125v2.063H6.152z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.567}
            d="M6.152 1.438h1.125v2.063H6.152z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M.246.563v3.816c0 .375.188.566.567.566h6.964c.379 0 .566-.191.566-.566V.563c0-.379-.188-.571-.566-.571H.813c-.379 0-.567.192-.567.571z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M8.344 1.723v1.492c0 .191.098.285.285.285h.559c.188 0 .281-.094.281-.285V1.723c0-.192-.094-.285-.281-.285h-.559c-.188 0-.285.093-.285.285z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M8.344 1.723v1.492c0 .191.098.285.285.285h.559c.188 0 .281-.094.281-.285V1.723c0-.192-.094-.285-.281-.285h-.559c-.188 0-.285.093-.285.285z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M1.238 1.438h1.125v2.063H1.238z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.567}
            d="M1.238 1.438h1.125v2.063H1.238z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M3.695 1.438H4.82v2.063H3.695z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.567}
            d="M3.695 1.438H4.82v2.063H3.695z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M6.152 1.438h1.125v2.063H6.152z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.567}
            d="M6.152 1.438h1.125v2.063H6.152z"
        />
    </svg>
)

export default IconBattery
