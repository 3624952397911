import PlayIcon from "../../../assets/svg/PlayIcon";
import PauseIcon from "../../../assets/svg/PauseIcon";
import './PlaybackControlButton.css';
import React from "react";

interface Props {
    isPlaying: boolean;
}

export const PlaybackControlButton: React.FC<Props> = ({isPlaying}: Props) => {
    return <div className="stethoscope-playback-control-button">{isPlaying ? <PauseIcon/> : <PlayIcon/>}</div>
}
