import { UserToken } from "../models/UserToken";


const TOKEN_KEY = "tkn";
const TOKEN_VALID_UNTIL_KEY = "exp";

export function loadToken() : UserToken | null {
    let token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        let validUntil = localStorage.getItem(TOKEN_VALID_UNTIL_KEY);
        if (validUntil) {
            return {
                token: token,
                tokenValidUntil : Number(validUntil)
            };
        }
    }
    return null;
}

export function saveToken(token: UserToken | null) {
    if (token) {
        localStorage.setItem(TOKEN_KEY, token.token);
        localStorage.setItem(TOKEN_VALID_UNTIL_KEY, `${token.tokenValidUntil}`);
    } else {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(TOKEN_VALID_UNTIL_KEY);
    }
}