const CLICKABLE_FILE_EXTENSIONS = [".jpg", ".jpeg", ".png", ".bmp", ".pdf"];

export function isClickableAttachment(fileName? : string){
    const name = fileName?.toLowerCase();
    return CLICKABLE_FILE_EXTENSIONS.some(e => name && name.endsWith(e));
}

export function isLtEcgDataFile(fileName? : string){
    return isBinoraLtEcgDataFile(fileName) || isBeecardiaLtEcgDataFile(fileName);
}

export function isBinoraLtEcgDataFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".rec");
}

export function isBinoraLtEcgIndexFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".tbl");
}

export function isBeecardiaLtEcgDataFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".sig");
}

export function isLtEcgEventsFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".wz3");
}

export function isPdfFile(fileName? : string){
    return fileName && fileName.toLowerCase().endsWith(".pdf");
}