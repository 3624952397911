import React from "react";
import './DoubleButton.css';

export enum DoubleButtonSide {
    Left,
    Right
}

interface DoubleButtonProps {
    className?: string;
    leftButtonText: string;
    rightButtonText: string;
    activeButton: DoubleButtonSide;
    onSwitch: (button: DoubleButtonSide) => void;
    onClick: (button: DoubleButtonSide) => void;
}

export const DoubleButton: React.FC<DoubleButtonProps> = ({ className = "", leftButtonText, rightButtonText, activeButton, onSwitch, onClick }: DoubleButtonProps) => {
    const handleLeftButtonClick = () => {
        if (activeButton === DoubleButtonSide.Left) {
            onClick(DoubleButtonSide.Left);
        } else {
            onSwitch(DoubleButtonSide.Left);
        }
    };
    const handleRightButtonClick = () => {
        if (activeButton === DoubleButtonSide.Right) {
            onClick(DoubleButtonSide.Right);
        } else {
            onSwitch(DoubleButtonSide.Right);
        }
    };
    return (
        <div className={`double-button ${className}`}>
            <div className={`double-button-side ${activeButton === DoubleButtonSide.Left ? 'active' : ''} disable-select`} onClick={handleLeftButtonClick}>{leftButtonText}</div>
            <div className={`double-button-side ${activeButton === DoubleButtonSide.Right ? 'active' : ''} disable-select`} onClick={handleRightButtonClick}>{rightButtonText}</div>
        </div>
    );
};
