import React from "react";
import {useTranslation} from "react-i18next";
import {TIME_SCALES} from "../../../ecg/EcgScales";

interface EcgTimeScaleDropdownProps {
    className?: string;
    initialValue: number;
    onChange: (value: number) => void;
}

export const EcgTimeScaleDropdown: React.FC<EcgTimeScaleDropdownProps> = ({ className = "", initialValue, onChange }: EcgTimeScaleDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} defaultValue={initialValue} onChange={(e) => onChange(Number(e.target.value))}>
            {TIME_SCALES.map((s, i) => <option key={`ecg-time-${i}`} value={i}>{t("time_scale_format", {timeScale: s.toFixed(1)})}</option>)}
        </select>
    );
}