import {Record} from "../../../models/Record";
import {
    formatAgeFull,
    formatDateTime, formatDurationMsText,
    formatGenderFull,
    formatHeight,
    formatPatientName, formatTimestamp, formatWeight
} from "../../../helpers/FormatHelper";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props{
    record: Record;
    isSupport: boolean;
    handleViewEcgClick: () => void;
    handleDownloadEcgClick: () => void;
    handleViewSpiroClick: () => void;
    handleDownloadSpiroClick: () => void;
    handleViewStethoscopeClick: () => void;
    handleDownloadStethoscopeClick: () => void;
    handleViewLtEcgClick?: () => void;
}

export function RecordDetailsTable(props: Props){
    const {t} = useTranslation();
    return (
        <div className="d-flex justify-content-center my-4">
            <div className="details-table">
                <table>
                    <tbody>
                    <tr>
                        <th>{t("name")}</th>
                        <td>{formatPatientName(t, props.record.patientId === null, props.record.patientName)}</td>
                    </tr>
                    <tr>
                        <th>{t("date")}</th>
                        <td>{formatDateTime(props.record.dateTime)}</td>
                    </tr>
                    <tr>
                        <th>{t("gender")}</th>
                        <td>{formatGenderFull(t, props.record.gender)}</td>
                    </tr>
                    <tr>
                        <th>{t("age")}</th>
                        <td>{formatAgeFull(t, props.record.age)}</td>
                    </tr>
                    <tr>
                        <th>{t("height")}</th>
                        <td>{formatHeight(t, props.record.height, props.record.heightUnits)}</td>
                    </tr>
                    <tr>
                        <th>{t("weight")}</th>
                        <td>{formatWeight(t, props.record.weight, props.record.weightUnits)}</td>
                    </tr>
                    {(props.record.state && props.record.state.temperature) ?
                        (<tr>
                            <th>{t("temperature")}</th>
                            <td>{props.record.state.temperature}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.spO2) ?
                        (<tr>
                            <th>{t("sp_o_2")}</th>
                            <td>{props.record.state.spO2}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.bloodPressure) ?
                        (<tr>
                            <th>{t("blood_pressure")}</th>
                            <td>{props.record.state.bloodPressure}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.glucose) ?
                        (<tr>
                            <th>{t("glucose")}</th>
                            <td>{props.record.state.glucose}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.cholesterol) ?
                        (<tr>
                            <th>{t("cholesterol")}</th>
                            <td>{props.record.state.cholesterol}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.anamnesis) ?
                        (<tr>
                            <th>{t("anamnesis")}</th>
                            <td>{props.record.state.anamnesis}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.drugTherapy) ?
                        (<tr>
                            <th>{t("drug_therapy")}</th>
                            <td>{props.record.state.drugTherapy}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.complaints) ?
                        (<tr>
                            <th>{t("complaints")}</th>
                            <td>{props.record.state.complaints}</td>
                        </tr>) : null
                    }
                    {(props.record.state && props.record.state.notes) ?
                        (<tr>
                            <th>{t("notes")}</th>
                            <td>{props.record.state.notes}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.record.state && props.record.state.stateTimestamp) ?
                        (<tr>
                            <th>{t("state_timestamp")}</th>
                            <td>{formatTimestamp(props.record.state.stateTimestamp)}</td>
                        </tr>) : null
                    }
                    {(props.record.ecgStudy && props.record.ecgStudy.ecgId) ?
                        (<tr>
                            <th>{t("ecg")}</th>
                            <td>
                                <div className="d-flex justify-content-around align-items-center">
                                    <div className="table-button disable-select"
                                         onClick={props.handleViewEcgClick}>{t("view")}
                                    </div>
                                    {props.isSupport && <div className="table-button  disable-select"
                                                       onClick={props.handleDownloadEcgClick}>{t("download")}</div>}
                                </div>
                            </td>
                        </tr>) : null
                    }
                    {(props.record.ecgStudy && props.record.ecgStudy.ecgTimestamp) ?
                        (<tr>
                            <th>{t("ecg_timestamp")}</th>
                            <td>{formatTimestamp(props.record.ecgStudy.ecgTimestamp)}</td>
                        </tr>) : null
                    }
                    {(props.record.ecgStudy && props.record.ecgStudy.deviceClass) ?
                        (<tr>
                            <th>{t("device_name")}</th>
                            <td>{props.record.ecgStudy.deviceClass}</td>
                        </tr>) : null
                    }
                    {(props.record.ecgStudy && props.record.ecgStudy.deviceNumber) ?
                        (<tr>
                            <th>{t("device_number")}</th>
                            <td>{props.record.ecgStudy.deviceNumber}</td>
                        </tr>) : null
                    }
                    {(props.record.spiroStudy && props.record.spiroStudy.spiroId) ?
                        (<tr>
                            <th>{t("spiro")}</th>
                            <td>
                                <div className="d-flex justify-content-around align-items-center">
                                    <div className="table-button disable-select"
                                         onClick={props.handleViewSpiroClick}>{t("view")}
                                    </div>
                                    {props.isSupport && <div className="table-button  disable-select"
                                                       onClick={props.handleDownloadSpiroClick}>{t("download")}</div>}
                                </div>
                            </td>
                        </tr>) : null
                    }
                    {(props.record.spiroStudy && props.record.spiroStudy.spiroTimestamp) ?
                        (<tr>
                            <th>{t("spiro_timestamp")}</th>
                            <td>{formatTimestamp(props.record.spiroStudy.spiroTimestamp)}</td>
                        </tr>) : null
                    }
                    {(props.record.spiroStudy && props.record.spiroStudy.deviceClass) ?
                        (<tr>
                            <th>{t("device_name")}</th>
                            <td>{props.record.spiroStudy.deviceClass}</td>
                        </tr>) : null
                    }
                    {(props.record.spiroStudy && props.record.spiroStudy.deviceNumber) ?
                        (<tr>
                            <th>{t("device_number")}</th>
                            <td>{props.record.spiroStudy.deviceNumber}</td>
                        </tr>) : null
                    }
                    {(props.record.stethoscopeStudy && props.record.stethoscopeStudy.stethoscopeId) ?
                        (<tr>
                            <th>{t("stethoscope")}</th>
                            <td>
                                <div className="d-flex justify-content-around align-items-center">
                                    <div className="table-button disable-select"
                                         onClick={props.handleViewStethoscopeClick}>{t("view")}
                                    </div>
                                    {props.isSupport && <div className="table-button  disable-select"
                                                       onClick={props.handleDownloadStethoscopeClick}>{t("download")}</div>}
                                </div>
                            </td>
                        </tr>) : null
                    }
                    {(props.record.stethoscopeStudy && props.record.stethoscopeStudy.stethoscopeTimestamp) ?
                        (<tr>
                            <th>{t("stethoscope_timestamp")}</th>
                            <td>{formatTimestamp(props.record.stethoscopeStudy.stethoscopeTimestamp)}</td>
                        </tr>) : null
                    }
                    {(props.record.stethoscopeStudy && props.record.stethoscopeStudy.deviceClass) ?
                        (<tr>
                            <th>{t("device_name")}</th>
                            <td>{props.record.stethoscopeStudy.deviceClass}</td>
                        </tr>) : null
                    }
                    {(props.record.stethoscopeStudy && props.record.stethoscopeStudy.deviceNumber) ?
                        (<tr>
                            <th>{t("device_number")}</th>
                            <td>{props.record.stethoscopeStudy.deviceNumber}</td>
                        </tr>) : null
                    }
                    {(props.isSupport && props.handleViewLtEcgClick) ?
                        (<tr>
                            <th>{t("lt_ecg")}</th>
                            <td>
                                <div className="d-flex justify-content-around align-items-center">
                                    <div className="table-button disable-select"
                                         onClick={props.handleViewLtEcgClick}>{t("view")}
                                    </div>
                                </div>
                            </td>
                        </tr>) : null
                    }
                    {(props.record.ltEcgStudy && props.record.ltEcgStudy.recordDurationMs) ?
                        (<tr>
                            <th>{t("duration")}</th>
                            <td>{formatDurationMsText(t, props.record.ltEcgStudy.recordDurationMs)}</td>
                        </tr>) : null
                    }
                    {(props.record.ltEcgStudy && props.record.ltEcgStudy.ltEcgTimestamp) ?
                        (<tr>
                            <th>{t("lt_ecg_timestamp")}</th>
                            <td>{formatTimestamp(props.record.ltEcgStudy.ltEcgTimestamp)}</td>
                        </tr>) : null
                    }
                    {(props.record.ltEcgStudy && props.record.ltEcgStudy.deviceClass) ?
                        (<tr>
                            <th>{t("device_name")}</th>
                            <td>{props.record.ltEcgStudy.deviceClass}</td>
                        </tr>) : null
                    }
                    {(props.record.ltEcgStudy && props.record.ltEcgStudy.deviceNumber) ?
                        (<tr>
                            <th>{t("device_number")}</th>
                            <td>{props.record.ltEcgStudy.deviceNumber}</td>
                        </tr>) : null
                    }
                    {props.isSupport ?
                        (<tr>
                            <th>{t("last_changed")}</th>
                            <td>{formatTimestamp(props.record.recordTimestamp)}</td>
                        </tr>) : null
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}