import React from "react";
import { useState } from "react";
import SvgEditDateIcon from "../../../assets/svg/EditDateIcon";
import { formatBirthdate } from "../../../helpers/FormatHelper";
import { DatePicker } from "@material-ui/pickers";
import './BirthDatePicker.css';

interface DatePickerProps {
    date: Date;
    onChange: (date: Date) => void;
    disabled: boolean;
    className?: string;
}

export const BirthDatePicker: React.FC<DatePickerProps> = ({ date, onChange, disabled, className = "" }: DatePickerProps) => {
    const [isOpen, setOpen] = useState(false);
    const handleClick = () => {
        if (!disabled) {
            setOpen(true);
        }
    }
    const datePickerChangeHandler = (v: any) => {
        const d: Date | null = v?.toDate();
        if (d) {
            onChange(d);
        }
    };
    return (
        <div className="birth-date-picker-container">
            <div className={`${className} d-flex justify-content-between align-items-center`} onClick={handleClick}>
                <div>{formatBirthdate(date)}</div>
                <SvgEditDateIcon className={`${disabled ? "hidden" : ""}`} />
            </div>
            <DatePicker open={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} style={{ position: "absolute", width: "1px", height: "1px", visibility: "hidden" }} margin="normal" id="date-picker-dialog" disableFuture value={date} onChange={datePickerChangeHandler} />
        </div>
    );
}