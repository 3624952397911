import React from "react";
import {formatConfirmationState} from "../../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";

interface ConfirmationStatusDropdownProps {
    className?: string;
    initialState: boolean;
    disabled: boolean;
    onChange: (value: boolean) => void;
}

export const ConfirmationStatusDropdown: React.FC<ConfirmationStatusDropdownProps> = ({ className = "", initialState, disabled, onChange }: ConfirmationStatusDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} disabled={disabled} defaultValue={initialState ? "t" : "f"} onChange={(e) => onChange(e.target.value === 't')}>
            <option value='t'>{formatConfirmationState(t, true)}</option>
            <option value='f'>{formatConfirmationState(t, false)}</option>
        </select>
    );
}