import SvgInfoCardMask from '../../../assets/svg/InfoCardMask';
import './InfoCard.css';
import React from "react";

interface InfoCardProps {
    contentClass: string;
    titleText: string;
    captionText: string;
    buttonText: string;
    onClick: () => void;
}

const InfoCard: React.FC<InfoCardProps> = ({ contentClass, titleText, captionText, buttonText, onClick }: InfoCardProps) => {
    return (
        <div className={`infocard-body ${contentClass}`} >
            <SvgInfoCardMask />
            <div className="infocard-container">
                <div className="infocard-content">
                    <div className="infocard-title disable-select">{titleText}</div>
                    <div className="infocard-caption disable-select">{captionText}</div>
                </div>
                <div className="infocard-button disable-select" onClick={onClick}>{buttonText}</div>
            </div>
        </div>
    );
}

export default InfoCard;