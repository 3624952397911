import * as React from "react"
import { SVGProps } from "react"

const IconHuman = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 14 10"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M8.758 1.43h1.098v.539H8.758z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.668.121h8.512v8.352H2.668z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFF"
            d="M3.234.688h7.375v7.223H3.234z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M6.922 5.105h.019-.019l-.863 2.379h-.75l.902-2.402V3.445l-.93 1.508-.472-.312L6.16 2.355h1.43l1.43 2.266-.493.305-.875-1.481v1.637l.907 2.402h-.754l-.864-2.379zM6.895 1.211c.289 0 .523.227.523.504 0 .281-.234.504-.523.504s-.524-.223-.524-.504c0-.277.234-.504.524-.504zM1.676 2.602h.574v3.391h-.574zM.52 4.027h1.156v.574H.52zM11.672 2.602h.574v3.391h-.574zM12.246 4.027h1.156v.574h-1.156zM8.758 1.43h1.098v.539H8.758z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.668.121h8.512v8.352H2.668z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFF"
            d="M3.234.688h7.375v7.223H3.234z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M6.922 5.105h.019-.019l-.863 2.379h-.75l.902-2.402V3.445l-.93 1.508-.472-.312L6.16 2.355h1.43l1.43 2.266-.493.305-.875-1.481v1.637l.907 2.402h-.754l-.864-2.379zM6.895 1.211c.289 0 .523.227.523.504 0 .281-.234.504-.523.504s-.524-.223-.524-.504c0-.277.234-.504.524-.504zM1.676 2.602h.574v3.391h-.574zM.52 4.027h1.156v.574H.52zM11.672 2.602h.574v3.391h-.574zM12.246 4.027h1.156v.574h-1.156z"
        />
    </svg>
)

export default IconHuman
