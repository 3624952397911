import {parseString, parseUint32} from "../helpers/BinaryHelper";

export interface LtEcgPatientInfo{
    patientId : string;
    patientCodeName : string;
    gender : number;
    birthday : string;
    height : number;
    weight : number;
}

// height = ((uint16_t) (floatHeight * 100) << 8) | heightUnits
// weight = ((uint16_t) (floatWeight * 100) << 8) | weightUnits

export function parsePatientInfo(buffer: ArrayBuffer, offset? : number) : LtEcgPatientInfo{
    const data = buffer.slice(offset ?? 0);
    return {
        patientId : parseString(data, 0, 48),
        patientCodeName: parseString(data, 48, 48),
        gender: parseUint32(data, 96),
        birthday: parseString(data, 100, 16),
        height: parseUint32(data, 116),
        weight: parseUint32(data, 120)
    }
}