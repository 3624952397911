import * as React from "react";

function SvgTestWavIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.022 1.62a.857.857 0 00-1.107-.822L5.486 3.083a.871.871 0 00-.607.822v8.634a4.177 4.177 0 00-1.714-.348c-.974 0-2.858.455-2.858 1.714s1.884 1.714 2.858 1.714c.973 0 2.857-.455 2.857-1.714v-6.33l6.857-2.117v4.795a4.178 4.178 0 00-1.714-.348c-.974 0-2.858.455-2.858 1.714 0 1.26 1.884 1.715 2.858 1.715.973 0 2.857-.456 2.857-1.715v-10z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTestWavIcon;
