import * as React from "react";

function SvgLandscape1x7Icon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
    height="1em"
    viewBox="0 0 35 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
>
            <path
                d="M4,2h26v20H4z"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"/>
            <path
                fill="currentColor"
                d="M13,8.7L13,15h-1.4L11.6,9.9h-1.2L10.4,8.7L13,8.7zM17.5,15l-1,-1.5 -1.1,1.5h-1.6l2,-2.4 -1.9,-2.4h1.6l1,1.4 1,-1.4h1.6l-1.8,2.3L19,15h-1.6zM24.5,8.7v1L22,15h-1.6l2.2,-5.1h-2v1h-1.3L19.3,8.7h5z"/>
        </svg>
);
}

export default SvgLandscape1x7Icon;