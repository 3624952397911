export interface ListParameters{
    page: number;
    filter: string;
    order : string;
}

export function buildListParameters(page : number, filter: string, order : string) : ListParameters{
    return {
        page: page,
        filter: filter,
        order: order
    } as ListParameters;
}

export function updateListParameters(parameters: ListParameters, page: number | undefined, filter : string | undefined, order : string | undefined){
    return {
        page: page !== undefined ? page : parameters.page,
        filter: filter !== undefined ? filter : parameters.filter,
        order: order !== undefined ? order : parameters.order
    } as ListParameters;
}