import * as React from "react"
import { SVGProps } from "react"

const IconFactory = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="M2.246 3.906 1.109 6.785v4.758h9.481L10.598.754 8.41.758l-.004 5.371-1-2.188-1.281 2.598-1.258-2.633-1.222 2.598z"
        />
    </svg>
)

export default IconFactory
