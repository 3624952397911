import {Layer, Rect, Stage} from "react-konva";
import React from "react";
import {getStyledColor} from "../../../helpers/DrawHelper";

const WIDTH = 120;
const HEIGHT = 12;

interface Props{
    zScore : number;
}

export function ZScoreView(props : Props){
    const radius = HEIGHT / 2;
    const scale = (WIDTH - 2 * radius) / 10;
    const greenZoneColor = getStyledColor("--z-score-green-zone-color");
    const redZoneColor = getStyledColor("--z-score-red-zone-color");
    const dotColor = getStyledColor("--z-score-dot-color");
    const zScore = Math.max(-5, Math.min(5, props.zScore));
    return (
        <Stage width={WIDTH} height={HEIGHT}>
            <Layer>
                <Rect x={0} y={0} width={WIDTH} height={HEIGHT} cornerRadius={radius}
                      fill={redZoneColor}/>
                <Rect x={(5 - 1.645) * scale} y={0} width={2 * 1.645 * scale + 2 * radius} height={HEIGHT} cornerRadius={radius}
                      fill={greenZoneColor}/>
                <Rect x={(5 + zScore) * scale} y={0} width={2 * radius} height={HEIGHT} cornerRadius={radius}
                      fill={dotColor}/>
            </Layer>
        </Stage>
    )
}