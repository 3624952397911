import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {selectUserInfo} from "../../../features/account/accountSlice";
import {useAppSelector} from "../../../hooks";
import {SHARED_RECORDS_ROUTE} from "../../../routes";
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {RecordsList} from "../../Widgets/RecordsList/RecordsList";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {AccessType} from "../../../models/AccessType";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {useTranslation} from "react-i18next";
import {StudyType} from "../../Widgets/StudyIcon/StudyIcon";

function SharedRecords() {
    const {t} = useTranslation();
    const history = useHistory();
    const userInfo = useAppSelector(selectUserInfo);
    const {toggleMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [shareRecordId, setShareRecordId] = useState(null as string | null);
    const recordClickHandler = (id: string) => {
        history.push(`${SHARED_RECORDS_ROUTE}/${id}`);
    };
    const studyClickHandler = (id: string, type: StudyType) => {
        switch (type) {
            case StudyType.State:
                recordClickHandler(id);
                break;
            case StudyType.Ecg:
                history.push(`${SHARED_RECORDS_ROUTE}/${id}/ecg`)
                break;
            case StudyType.Stethoscope:
                history.push(`${SHARED_RECORDS_ROUTE}/${id}/stethoscope`)
                break;
            case StudyType.Spiro:
                history.push(`${SHARED_RECORDS_ROUTE}/${id}/spiro`)
                break;
            case StudyType.LtEcg:
                recordClickHandler(id);
                break;
        }
    };
    const contextMenuHandler = (id: string, x: number, y: number) => {
        setAnchorPoint({x: x, y: y});
        setShareRecordId(id);
        toggleMenu(true);
    };
    const shareClickHandler = () => {
        history.push(`${SHARED_RECORDS_ROUTE}/${shareRecordId}/share`);
    };
    return (
        <div>
            <Breadcrumbs/>
            <RecordsList tag="shared_records" pageSize={DATA_LIST_PAGE_SIZE} userId={userInfo?.id ?? null} accessType={AccessType.Shared}
                         patientId={null}
                         recordClickHandler={recordClickHandler} contextMenuHandler={contextMenuHandler} studyClickHandler={studyClickHandler}/>
            <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                            onClose={() => toggleMenu(false)} menuClassName={"context-menu"}>
                <MenuItem onClick={shareClickHandler}>{t("access_control")}</MenuItem>
            </ControlledMenu>
        </div>
    );
}

export default SharedRecords;