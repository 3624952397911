import * as React from "react"

function SvgMoveRightIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            {...props}
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M6.41 6L5 7.41 9.58 12 5 16.59 6.41 18l6-6z" fill="currentColor"/>
            <path d="M13 6l-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6z" fill="currentColor" />
        </svg>
    )
}

export default SvgMoveRightIcon
