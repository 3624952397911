import React from "react";
import { useHistory } from "react-router";
import * as AppRoutes from '../../../routes';
import { Button } from "../../Widgets/Button/Button";
import {useTranslation} from "react-i18next";
import {AccountSettings} from "../../Widgets/AccountSettings/AccountSettings";


function Reset() {
    const {t} = useTranslation();
    const history = useHistory();
    const handleSignInClick = () => {
        history.replace(AppRoutes.SIGN_IN_ROUTE);
    };
    return (
        <div className="window-modal-container">
            <div className="window-modal">
                <div className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("password_reset_title")}</div>
                <div className="window-modal-text my-2">{t("password_reset_description")}</div>
                <div className="d-flex justify-content-center">
                    <div className="window-modal-button">
                        <Button className="mt-4" text={t("sign_in")} highlighted={true} onClick={handleSignInClick} />
                    </div>
                </div>
                <div className='mt-4'/>
                <AccountSettings/>
            </div>
        </div>
    );
}

export default Reset;