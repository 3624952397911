import {StudyType} from "./StudyIcon";
import React from "react";
import {useTranslation} from "react-i18next";
import SvgStateIcon from "../../../assets/svg/StateIcon";
import SvgEcgIcon from "../../../assets/svg/EcgIcon";
import SvgSpiroIcon from "../../../assets/svg/SpiroIcon";
import SvgStethoscopeIcon from "../../../assets/svg/StethoscopeIcon";
import "./StudyIconPushButton.css";

interface StudyIconPushButtonProps {
    type: StudyType;
    hasText?: boolean;
    pressed : boolean;
    onClick: () => void;
}

export const StudyIconPushButton: React.FC<StudyIconPushButtonProps> = ({type, hasText = false, pressed, onClick}: StudyIconPushButtonProps) => {
    const {t} = useTranslation();
    let icon;
    let text;
    switch (type) {
        case StudyType.State:
            icon = <SvgStateIcon/>;
            text = t("state");
            break;
        case StudyType.Ecg:
            icon = <SvgEcgIcon/>;
            text = t("ecg");
            break;
        case StudyType.Spiro:
            icon = <SvgSpiroIcon/>;
            text = t("spiro");
            break;
        case StudyType.Stethoscope:
            icon = <SvgStethoscopeIcon/>;
            text = t("stethoscope");
            break;
        case StudyType.LtEcg:
            icon = <SvgEcgIcon/>;
            text = t("lt_ecg");
            break;
        default:
            icon = null;
            text = "";
            break;
    }
    const clickHandler = (e: any) => {
        e.stopPropagation();
        onClick();
    };
    return (
        <div className={`study-icon-pb-container ${pressed ? "" : "active"}`} onClick={clickHandler}>
            {icon && icon}
            {(hasText && text) && <span className="no-select">{text}</span>}
        </div>
    );
}
