export const LEAD_I = 0;
export const LEAD_II = 1;
export const LEAD_III = 2;
export const LEAD_AVR = 3;
export const LEAD_AVL = 4;
export const LEAD_AVF = 5;
export const LEAD_V1 = 6;
export const LEAD_V2 = 7;
export const LEAD_V3 = 8;
export const LEAD_V4 = 9;
export const LEAD_V5 = 10;
export const LEAD_V6 = 11;

export const PP_AMPL = 0;
export const PN_AMPL = 1;
export const PP_AREA = 2;
export const PN_AREA = 3;
export const QRS_DEF = 4;
export const QRS_NET = 5;
export const QRS_AREA_VALUE = 6;
export const QRS_AREA_PERCENT = 7;
export const Q_AMPL = 8;
export const Q_DUR = 9;
export const Q_EQU = 10;
export const R_AMPL = 11;
export const R_DUR = 12;
export const R2_AMPL = 13;
export const R2_DUR = 14;
export const S_AMPL = 15;
export const S_DUR = 16;
export const S2_AMPL = 17;
export const ST_BIG_AMPL = 18;
export const ST_MID_AMPL = 19;
export const ST_END_AMPL = 20;
export const TP_AMPL = 21;
export const TN_AMPL = 22;
export const R_MAX = 23;
export const S_MIN = 24;
export const DELTA_WAVE = 25;
export const STT_D2X = 26;
export const TMIN = 27;
export const LEAD_NOISE = 28;

export interface EcgQrsParameters{

    number : number;
    samplePosition : number;
    rr : number;
    hr : number;
    pDur : number;
    pqDur : number;
    qrsDur : number;
    qtDur : number;
    qtC : number;
    pAxis : number;
    qrsAxis : number;
    tAxis : number;
    paramTable : number[][];
}