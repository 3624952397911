import {parseString} from "../helpers/BinaryHelper";

export interface LtEcgDeviceInfo{
    deviceName : string;
    deviceNumber : string;
    firmwareVersion : string;
}

export function parseDeviceInfo(buffer: ArrayBuffer, offset? : number) : LtEcgDeviceInfo{
    const data = buffer.slice(offset ?? 0);
    return {
        deviceName: parseString(data, 0, 24),
        deviceNumber: parseString(data, 24, 24),
        firmwareVersion: parseString(data, 48, 32)
    }
}