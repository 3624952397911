import './CommentView.css';
import React from "react";
import ReplyIcon from "../../../assets/svg/ReplyIcon";

interface ReferenceCommentViewProps{
    id: string;
    user: string;
    text: string;
    timestamp: string;
    clip: boolean;
}

export const ReferenceCommentView: React.FC<ReferenceCommentViewProps> = ({ user, text, timestamp, clip }: ReferenceCommentViewProps) => {
    return (
        <div className="d-flex flex-column align-items-stretch">
            <div className="d-flex flex-row align-self-start mx-3">
                <span className="comments-secondary comments-reference"><ReplyIcon/> {user}</span>
            </div>
            <div className="my-1 py-2 px-3 comments-border comments-border-reference">
                <span className={`comments-primary comments-reference ${clip ? "comments-clip" : ""}`}>{text}</span>
            </div>
            <div className="align-self-end mx-3">
                <span className="comments-secondary comments-reference">{timestamp}</span>
            </div>
        </div>
    );
}