export enum WeightUnits {
    Grams = 1,
    Kilograms = 2,
    Pounds = 3
}

export function parseWeightUnits(units: string): WeightUnits {
    if (units === WeightUnits[WeightUnits.Grams]) {
        return WeightUnits.Grams;
    }
    if (units === WeightUnits[WeightUnits.Pounds]) {
        return WeightUnits.Pounds;
    }
    return WeightUnits.Kilograms;
}