import React, {useState} from "react";
import {Button} from "../../Widgets/Button/Button";
import {ClickableText} from "../../Widgets/ClickableText/ClickableText";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {logout, resetUserInfo, selectToken} from "../../../features/account/accountSlice";
import * as ApiHelper from '../../../api/ApiHelper';
import Loader from "../../Widgets/Loader/Loader";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {DASHBOARD_ROUTE, SIGN_IN_ROUTE} from "../../../routes";
import {AccountSettings} from "../../Widgets/AccountSettings/AccountSettings";

function NotConfirmed() {
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const [resendAvailable, setResendAvailable] = useState(true);
    const [fetchingState, setFetchingState] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null as string | null);
    const handleResendSuccess = () => {
        setFetchingState(false);
        setResendAvailable(false);
        setErrorMessage(null);
    };
    const errorHandler = () => {
        setFetchingState(false);
        setResendAvailable(false);
        setErrorMessage(t("connection_problem"));
    };
    const handleContinueClick = () => {
        dispatch(resetUserInfo());
        history.push(DASHBOARD_ROUTE);
    };
    const handleCancelClick = () => {
        dispatch(logout());
        history.push(SIGN_IN_ROUTE);

    };
    const handleSendAgainClick = () => {
        if (token !== null) {
            setFetchingState(true);
            ApiHelper.resendEmail(token.token, i18n.language, controller, handleResendSuccess, errorHandler);
        }
    };
    if (fetchingState) {
        return (
            <Loader />
        );
    }
    return (
        <div className="window-modal-container">
            <div className="window-modal">
                <div className="window-modal-title pb-4 d-flex justify-content-center">{t("confirm_email_title")}</div>
                <div className="window-modal-text my-2">{t("confirm_email_description")}</div>
                {resendAvailable &&
                    <div>
                        <div className="window-modal-text mt-4">{t("did_not_receive_letter")}</div>
                        <div className="d-flex justify-content-center mt-2">
                            <ClickableText text={t("resend_confirmation_letter")} onClick={handleSendAgainClick} />
                        </div>
                    </div>

                }
                {errorMessage &&
                    <div className="window-modal-error d-flex justify-content-center align-items-center">{errorMessage}</div>
                }
                <div className="d-flex justify-content-center">
                    <div className="window-modal-button">
                        <Button className="mt-4" text={t("continue")} onClick={handleContinueClick} />
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="window-modal-button">
                        <Button className="mt-4" text={t("cancel")} onClick={handleCancelClick} />
                    </div>
                </div>
                <div className='mt-4'/>
                <AccountSettings/>
            </div>
        </div>
    );
}

export default NotConfirmed;