const HOLD_TIMEOUT = 8000;
const GAIN_DELAY = 600;
const IN_BUF_LENGTH = 1024;

const MAX_OUTPUT_AMPLITUDE = 0.98;
const GOAL_AMPLITUDE = 0.8;
const MIN_DIVISOR = 0.1;
const MAX_DIVISOR = 0.9;

const A_DOWN = 0.99991667;
const A_UP = 0.995012479;
const B_UP = 1 - A_UP;


interface StethoData {
    historyBuf: number[];
    historyIndex: number;
    maxRealTime: number;
    maxSmoothed: number;
    testOut: number;
    maxTimeout: number;
}

export function amplify(data: number[]): number[] {
    const stData: StethoData = {
        historyBuf: new Array<number>(),
        historyIndex: 0,
        maxRealTime: 0.9,
        maxSmoothed: 0,
        testOut: 0,
        maxTimeout: GAIN_DELAY
    };
    for (let i = 0; i < IN_BUF_LENGTH; i++) {
        stData.historyBuf.push(0);
    }
    const result = new Array<number>();
    for (let i = 0; i < data.length; i++) {
        const inputData = data[i];
        stData.historyBuf[stData.historyIndex] = inputData;
        stData.historyIndex = (stData.historyIndex + 1) & (IN_BUF_LENGTH - 1);
        if (stData.maxRealTime < inputData) {
            stData.maxRealTime = inputData;
            stData.maxTimeout = HOLD_TIMEOUT;
        }
        if (stData.maxTimeout > 0) {
            stData.maxTimeout--;
            stData.maxSmoothed = stData.maxSmoothed * A_UP + stData.maxRealTime * B_UP;
        } else {
            stData.maxSmoothed = stData.maxSmoothed * A_DOWN;
            stData.maxRealTime = stData.maxSmoothed;
        }
        const tDiv = Math.max(MIN_DIVISOR, Math.min(MAX_DIVISOR, stData.maxSmoothed));
        const gain = GOAL_AMPLITUDE / tDiv;
        stData.testOut = gain * stData.historyBuf[(IN_BUF_LENGTH - 1) & (stData.historyIndex + IN_BUF_LENGTH - GAIN_DELAY)];
        stData.testOut = Math.max(-MAX_OUTPUT_AMPLITUDE, Math.min(MAX_OUTPUT_AMPLITUDE, stData.testOut));
        result.push(stData.testOut);
    }
    return result;
}
