import {parseUint32} from "../helpers/BinaryHelper";


const SCPECG_BLOCK_LENGTH = 512;

const bitMask = [
    0,
    0x00000001, 0x00000003, 0x00000007, 0x0000000f, 0x0000001f, 0x0000003f, 0x0000007f,
    0x000000ff,
    0x000001ff, 0x000003ff, 0x000007ff, 0x00000fff, 0x00001fff, 0x00003fff, 0x00007fff,
    0x0000ffff
];

interface BitStream {
    blockBuffer: ArrayBuffer;
    bufferIndex: number;
    bitBuffer: number;
    bitsEmpty: number;
    bitsValued: number;
    vectors: number;
    dataFormat: number;
    enfOfBlock: boolean;
}

export abstract class BaseScpEcgExtractor{

    protected readonly block: ArrayBuffer;

    protected readonly previous1 = new Array<number>(8);
    protected readonly previous2 = new Array<number>(8);

    protected readonly bsRaw: BitStream;
    protected readonly bsDrv: BitStream;

    public constructor(buffer: ArrayBuffer) {
        this.block = buffer;
        this.bsDrv = this.resetBlocks();
        this.bsRaw = this.resetBlocks();
        this.bsRaw.bitsEmpty = 32;
        this.bsDrv.bitsEmpty = 32;
        this.bsDrv.dataFormat = 0;
        this.bsRaw.dataFormat = 0;
    }

    public abstract extractData(): Array<number>;

    protected resetBlocks(): BitStream {
        return {
            blockBuffer: new ArrayBuffer(0),
            bufferIndex: 20,
            bitsEmpty: 32,
            bitBuffer: 0,
            bitsValued: 0,
            vectors: 0,
            enfOfBlock: false,
            dataFormat: 0
        };
    }

    protected getFromHuffman(): number {
        let bitVal = 0;
        let ones = 0;
        do { // read prefix
            bitVal = this.getBits(bitVal, 1, this.bsDrv);
            if (bitVal === 0) {
                break;
            }
            ones++;
        } while (bitVal && ones < 8);
        if (ones === 0) {
            return 0;
        }
        if (ones < 8) {
            bitVal = this.getBits(bitVal, ones, this.bsDrv);
            if (!this.checkBit(bitVal, ones - 1)) {
                bitVal = 1 + (bitVal | (0xffff << ones));
            }
            return this.convertUnsignedToSigned(bitVal);
        }
        if (ones === 8) {
            bitVal = this.getBits(bitVal, 1, this.bsDrv);
            bitVal = this.getBits(bitVal, 16, this.bsDrv);
            bitVal = this.convertUnsignedToSigned(bitVal);
            if (bitVal < 0) {
                bitVal++;
            }
        }
        return this.convertUnsignedToSigned(bitVal);
    }

    protected getBits(data: number, nBits: number, pBS: BitStream): number {
        if (nBits <= pBS.bitsValued) {
            data = pBS.bitBuffer & bitMask[nBits];
            pBS.bitBuffer >>= nBits;
            pBS.bitsValued -= nBits;
        } else {
            if (pBS.bufferIndex >= SCPECG_BLOCK_LENGTH) {
                pBS.enfOfBlock = true;
                return Number.NaN;
            }
            const canDoBits = pBS.bitsValued;
            data = pBS.bitBuffer & bitMask[canDoBits];
            nBits -= canDoBits;
            pBS.bitBuffer = parseUint32(pBS.blockBuffer.slice(pBS.bufferIndex), 0);
            data |= (pBS.bitBuffer & bitMask[nBits]) << canDoBits;
            pBS.bitBuffer >>= nBits;
            pBS.bitsValued = 32 - nBits;
            pBS.bufferIndex += 4;
        }
        return data;
    }

    protected checkBit(v: number, bit: number): boolean {
        return (v & (0x01 << (bit))) !== 0;
    }

    protected convertUnsignedToSigned(unsignedValue : number, bitLength : number = 16) : number{
        const maxValue = (1 << (bitLength - 1)) - 1;
        return unsignedValue <= maxValue ? unsignedValue : unsignedValue - (1 << bitLength);
    }
}

