import React, {useEffect, useState} from "react";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import * as ApiHelper from '../../../api/ApiHelper';
import handleErrors from '../../../helpers/ErrorHandler';
import {FetchError} from "../../Widgets/FetchError/FetchError";
import {Button} from "../../Widgets/Button/Button";
import {Toast} from "../../Widgets/Toast/Toast";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {useTranslation} from "react-i18next";
import {NotificationSettingsData} from "../../../models/NotificationSettingsData";
import NotificationLanguageDropdown from "../../Widgets/NotificationLanguageDropdown/NotificationLanguageDropdown";
import {OnOffDropdown} from "../../Widgets/OnOffDropdown/OnOffDropdown";
import {logError} from "../../../helpers/LogHelper";

export const NotificationSettings = () => {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [hasSaveError, setSaveErrorState] = useState(false);
    const [keyIndex, setKeyIndex] = useState(0);
    const [settings, setSettings] = useState(null as NotificationSettingsData | null);
    const [editLanguage, setEditLanguage] = useState(null as string | null);
    const [editDataShare, setEditDataShare] = useState(null as boolean | null);
    const [editDataReject, setEditDataReject] = useState(null as boolean | null);
    const [editDataRefuse, setEditDataRefuse] = useState(null as boolean | null);
    const initFields = (settings: NotificationSettingsData) => {
        setKeyIndex(keyIndex + 1);
        setEditLanguage(settings.language);
        setEditDataShare(settings.dataShare);
        setEditDataReject(settings.dataReject);
        setEditDataRefuse(settings.dataRefuse);
    };
    const settingsHandler = (settings: NotificationSettingsData) => {
        setErrorState(false);
        setSaveErrorState(false);
        setSettings(settings);
        initFields(settings);
        setFetchingState(false);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Notification settings fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const saveErrorHandler = (error: ErrorResponse) => {
        logError("Notification setting save error", error);
        if (!handleErrors(error, dispatch)) {
            setSaveErrorState(false);
            setSaveErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchSettings = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getNotificationSettings(userToken, controller, settingsHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchSettings(), []);     // eslint-disable-line

    const isChanged = settings?.language !== editLanguage
        || settings?.dataShare !== editDataShare
        || settings?.dataReject !== editDataReject
        || settings?.dataRefuse !== editDataRefuse;
    const saveSettings = () => {
        let userToken = token?.token;
        if (userToken && editLanguage !== null && editDataShare !== null && editDataReject !== null && editDataRefuse !== null) {
            setFetchingState(true);
            ApiHelper.setNotificationSettings(userToken, editLanguage, editDataShare, editDataReject, editDataRefuse, controller, settingsHandler, saveErrorHandler);
        }
    };
    const isValid = editLanguage !== null && editDataShare !== null && editDataReject !== null && editDataRefuse !== null;
    const isOk = !hasError && !isFetching;
    return (
        <div>
            <Breadcrumbs/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchSettings}/>}
            {(isOk && settings) &&
            <div className="d-flex justify-content-center my-4">
                <div className="details-table">
                    <table>
                        <tbody>
                        <tr>
                            <th>{t("notification_language")}</th>
                            <td>
                                <NotificationLanguageDropdown key={`field-lang-${keyIndex}`}
                                                              className="input-dropdown" disabled={false}
                                                              initialState={settings.language}
                                                              onChange={setEditLanguage}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("data_share_notification")}</th>
                            <td>
                                <OnOffDropdown key={`field-share-${keyIndex}`}
                                               className="input-dropdown" disabled={false}
                                               initialState={settings.dataShare} onChange={setEditDataShare}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("data_reject_notification")}</th>
                            <td>
                                <OnOffDropdown key={`field-reject-${keyIndex}`}
                                               className="input-dropdown" disabled={false}
                                               initialState={settings.dataReject} onChange={setEditDataReject}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("data_refuse_notification")}</th>
                            <td>
                                <OnOffDropdown key={`field-refuse-${keyIndex}`}
                                               className="input-dropdown" disabled={false}
                                               initialState={settings.dataRefuse} onChange={setEditDataRefuse}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            }
            {(isOk && hasSaveError) &&
            <Toast text={t("connection_problem")} isError={true}/>
            }
            {(isOk && settings) &&
            <div className="d-flex justify-content-center">
                <div className="details-controls d-flex justify-content-around my-2">
                    <div className="d-flex justify-content-between">
                        <Button className="mr-2" text={t("save_changes")} enabled={isValid && isChanged}
                                highlighted={true}
                                onClick={() => {
                                    saveSettings()
                                }}/>
                        <Button className="ml-2" text={t("discard")} onClick={() => initFields(settings)}/>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default NotificationSettings;