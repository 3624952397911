import * as React from "react"
import { SVGProps } from "react"

const IconManual = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 12 10"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="m1.258 2.461.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m1.258 2.461.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M6.25 1.984v6.2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M1.258 2.461c.07.055.449.379 1.676.203 1.355-.195 1.527-1.652 3.316-.68"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M1.258 2.461c.07.055.449.379 1.676.203 1.355-.195 1.527-1.652 3.316-.68"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M1.285 8.77c.098.027.41.219 1.637.043 1.355-.192 1.539-1.598 3.328-.629"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M1.285 8.77c.098.027.41.219 1.637.043 1.355-.192 1.539-1.598 3.328-.629"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="m9.117 4.605.09.04a4.171 4.171 0 0 0-.031.414c-.016.488-.024.851-.024 1.086 0 .215.012.402.035.559a.173.173 0 0 0 .07.125c.039.023.133.039.277.051l.258.023v.145a31.397 31.397 0 0 0-1.93 0v-.146l.328-.023c.13-.008.208-.024.24-.047.027-.023.047-.105.054-.242.012-.215.02-.508.02-.883 0-.262-.008-.461-.02-.602-.004-.05-.027-.089-.066-.109-.039-.023-.129-.035-.266-.035h-.289v-.145c.309-.027.539-.054.684-.082.148-.023.336-.066.57-.129zM8.82 3.5a.55.55 0 0 1 .301.082c.082.059.125.125.125.203s-.043.145-.125.199a.548.548 0 0 1-.301.082.559.559 0 0 1-.309-.082c-.081-.054-.124-.121-.124-.199s.043-.144.125-.203A.555.555 0 0 1 8.82 3.5z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m9.117 4.605.09.04a4.171 4.171 0 0 0-.031.414c-.016.488-.024.851-.024 1.086 0 .215.012.402.035.559a.173.173 0 0 0 .07.125c.039.023.133.039.277.051l.258.023v.145a31.397 31.397 0 0 0-1.93 0v-.146l.328-.023c.13-.008.208-.024.24-.047.027-.023.047-.105.054-.242.012-.215.02-.508.02-.883 0-.262-.008-.461-.02-.602-.004-.05-.027-.089-.066-.109-.039-.023-.129-.035-.266-.035h-.289v-.145c.309-.027.539-.054.684-.082.148-.023.336-.066.57-.129zM8.82 3.5a.55.55 0 0 1 .301.082c.082.059.125.125.125.203s-.043.145-.125.199a.548.548 0 0 1-.301.082.559.559 0 0 1-.309-.082c-.081-.054-.124-.121-.124-.199s.043-.144.125-.203A.555.555 0 0 1 8.82 3.5z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="m11.246 2.516-.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m11.246 2.516-.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M6.25 2.039v6.199"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M11.246 2.516c-.074.058-.453.379-1.676.203-1.355-.192-1.531-1.653-3.32-.68"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M11.246 2.516c-.074.058-.453.379-1.676.203-1.355-.192-1.531-1.653-3.32-.68"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M11.219 8.824c-.098.027-.414.219-1.637.047-1.355-.195-1.543-1.601-3.332-.633"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M11.219 8.824c-.098.027-.414.219-1.637.047-1.355-.195-1.543-1.601-3.332-.633"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="m1.258 2.461.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m1.258 2.461.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M6.25 1.984v6.2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M1.258 2.461c.07.055.449.379 1.676.203 1.355-.195 1.527-1.652 3.316-.68"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M1.258 2.461c.07.055.449.379 1.676.203 1.355-.195 1.527-1.652 3.316-.68"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M1.285 8.77c.098.027.41.219 1.637.043 1.355-.192 1.539-1.598 3.328-.629"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M1.285 8.77c.098.027.41.219 1.637.043 1.355-.192 1.539-1.598 3.328-.629"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="m9.117 4.605.09.04a4.171 4.171 0 0 0-.031.414c-.016.488-.024.851-.024 1.086 0 .215.012.402.035.559a.173.173 0 0 0 .07.125c.039.023.133.039.277.051l.258.023v.145a31.397 31.397 0 0 0-1.93 0v-.146l.328-.023c.13-.008.208-.024.24-.047.027-.023.047-.105.054-.242.012-.215.02-.508.02-.883 0-.262-.008-.461-.02-.602-.004-.05-.027-.089-.066-.109-.039-.023-.129-.035-.266-.035h-.289v-.145c.309-.027.539-.054.684-.082.148-.023.336-.066.57-.129zM8.82 3.5a.55.55 0 0 1 .301.082c.082.059.125.125.125.203s-.043.145-.125.199a.548.548 0 0 1-.301.082.559.559 0 0 1-.309-.082c-.081-.054-.124-.121-.124-.199s.043-.144.125-.203A.555.555 0 0 1 8.82 3.5z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m9.117 4.605.09.04a4.171 4.171 0 0 0-.031.414c-.016.488-.024.851-.024 1.086 0 .215.012.402.035.559a.173.173 0 0 0 .07.125c.039.023.133.039.277.051l.258.023v.145a31.397 31.397 0 0 0-1.93 0v-.146l.328-.023c.13-.008.208-.024.24-.047.027-.023.047-.105.054-.242.012-.215.02-.508.02-.883 0-.262-.008-.461-.02-.602-.004-.05-.027-.089-.066-.109-.039-.023-.129-.035-.266-.035h-.289v-.145c.309-.027.539-.054.684-.082.148-.023.336-.066.57-.129zM8.82 3.5a.55.55 0 0 1 .301.082c.082.059.125.125.125.203s-.043.145-.125.199a.548.548 0 0 1-.301.082.559.559 0 0 1-.309-.082c-.081-.054-.124-.121-.124-.199s.043-.144.125-.203A.555.555 0 0 1 8.82 3.5z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="m11.246 2.516-.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="m11.246 2.516-.027 6.312"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M6.25 2.039v6.199"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M11.246 2.516c-.074.058-.453.379-1.676.203-1.355-.192-1.531-1.653-3.32-.68"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M11.246 2.516c-.074.058-.453.379-1.676.203-1.355-.192-1.531-1.653-3.32-.68"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M11.219 8.824c-.098.027-.414.219-1.637.047-1.355-.195-1.543-1.601-3.332-.633"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            strokeLinecap="round"
            d="M11.219 8.824c-.098.027-.414.219-1.637.047-1.355-.195-1.543-1.601-3.332-.633"
        />
    </svg>
)

export default IconManual
