import {Abort, EmailNotConfirmed, TokenRejected, UserBlocked} from "../api/ErrorCodes";
import { logout, updateAccountStatus } from "../features/account/accountSlice";
import { AccountStatus } from "../models/AccountStatus";
import ErrorResponse from "../models/ErrorResponse";
import { AppDispatch } from "../store";

export default function handleErrors(error: ErrorResponse, dispatch: AppDispatch): boolean {
    if (error.ResultCode === Abort){
        return true;
    }
    if (error.ResultCode === TokenRejected) {
        dispatch(logout());
        return true;
    }
    if (error.ResultCode === EmailNotConfirmed) {
        dispatch(updateAccountStatus(AccountStatus.NotConfirmed));
        return true;
    }
    if (error.ResultCode === UserBlocked) {
        dispatch(updateAccountStatus(AccountStatus.Blocked));
        return true;
    }
    return false;
}