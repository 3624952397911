import * as React from "react";

function SvgDownloadWavIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.863 6.787V0L4.797 1.804v3.891a2.068 2.068 0 00-.493-.196 2.639 2.639 0 00-1.674.147C1.46 6.141.844 7.31 1.255 8.25c.205.473.632.815 1.197.968.194.048.4.076.604.076.355 0 .721-.076 1.07-.223.55-.234.998-.62 1.259-1.087.183-.326.26-.668.238-.995v-4.58l4.402-1.31v4.586c-.587-.32-1.386-.358-2.14-.043-1.17.494-1.785 1.663-1.375 2.603.289.663 1.004 1.038 1.813 1.038.344 0 .71-.07 1.06-.218.548-.233.997-.62 1.258-1.087.227-.39.3-.798.222-1.19zM9.083 14.69V13.23h-.006v-2.114c-.249.06-.499.092-.754.092-1.059 0-1.951-.522-2.317-1.37a2.774 2.774 0 01-.1-.276c-.01.021-.022.038-.033.06-.322.575-.865 1.048-1.53 1.325-.3.125-.61.207-.926.245v2.668H3.41v.832H.833L6.25 20l5.417-5.31H9.083z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDownloadWavIcon;
