import React, {Fragment, useMemo} from "react";
import {Layer, Rect, Stage} from "react-konva";
import {getStyledColor} from "../../../helpers/DrawHelper";
import {Vector2d} from "konva/types/types";
import {KonvaEventObject} from "konva/types/Node";

const SCROLL_BAR_HEIGHT = 12;
const CORNER_RADIUS = 4;
const MIN_THUMB_WIDTH = 2;

interface Props {
    width: number;
    position: number;
    max: number;
    screen: number;
    onScrollPositionChanged?: ((position: number) => void) | null;
}

export const HorizontalScrollbar: React.FC<Props> = ({
                                                         width,
                                                         position,
                                                         max,
                                                         screen,
                                                         onScrollPositionChanged = null
                                                     }: Props) => {
    const trackColor = getStyledColor("--scroll-bar-track");
    const thumbColor = getStyledColor("--scroll-bar-thumb");
    const thumbPosition = useMemo(() => width / max * position, [width, max, position]);
    const thumbWidth = useMemo(() => Math.max(MIN_THUMB_WIDTH, width / max * screen), [width, max, screen]);
    const dragBoundFunc = (pos: Vector2d) => {
        const scrollWidth = width - thumbWidth;
        const maxScroll = max - screen;
        const boundX = Math.round(Math.min(Math.max(0, pos.x), scrollWidth));
        const newPosition = Math.floor(boundX / scrollWidth * maxScroll);
        const newX = newPosition / maxScroll * scrollWidth;
        if (onScrollPositionChanged) {
            onScrollPositionChanged(newPosition);
        }
        return {
            x: newX,
            y: 0
        }
    };
    const onTrackClick = (evt: KonvaEventObject<MouseEvent>) => {
        if (onScrollPositionChanged) {
            const scrollWidth = width - thumbWidth;
            const maxScroll = max - screen;
            const x = evt.evt.offsetX;
            const clickPosition = Math.floor(x / scrollWidth * maxScroll);
            if (clickPosition < position) {
                onScrollPositionChanged(clickPosition);
            }
            if (clickPosition > position) {
                onScrollPositionChanged(clickPosition - screen);
            }
        }
    }
    return (
        <Fragment>
            <Stage width={width} height={SCROLL_BAR_HEIGHT}>
                <Layer>
                    <Rect x={0} y={0} width={width} height={SCROLL_BAR_HEIGHT} cornerRadius={CORNER_RADIUS}
                          fill={trackColor} onClick={onTrackClick}/>
                    <Rect x={thumbPosition} y={0} width={thumbWidth} height={SCROLL_BAR_HEIGHT}
                          cornerRadius={CORNER_RADIUS}
                          fill={thumbColor} draggable={true} dragBoundFunc={dragBoundFunc}/>
                </Layer>
            </Stage>
        </Fragment>
    );
}