import * as React from "react";

function SvgLandscape4x3Icon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 35 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4,2h26v20H4z"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"/>
            <path
                fill="currentColor"
                d="M15.2,13.7h-1L14.2,15h-1.4v-1.3L9.4,13.7v-1l3,-4h1.5l-2.7,3.8h1.6v-1.2h1.4v1.2h1v1.2zM19,15l-1,-1.5 -1.1,1.5h-1.6l1.9,-2.4 -1.8,-2.4h1.6l1,1.4 1,-1.4h1.5l-1.8,2.3 2,2.5h-1.7zM24,11.3c0.5,0 1,0.3 1.2,0.6 0.3,0.3 0.4,0.7 0.4,1.2 0,0.3 0,0.7 -0.2,1a2,2 0,0 1,-1 0.7c-0.3,0.2 -0.8,0.3 -1.4,0.3 -0.4,0 -0.8,0 -1.3,-0.2 -0.4,0 -0.8,-0.3 -1,-0.5l0.5,-1a2.8,2.8 0,0 0,1.8 0.5c0.4,0 0.7,0 0.9,-0.2l0.3,-0.6c0,-0.5 -0.4,-0.8 -1.2,-0.8h-0.7v-1l1.3,-1.4L21,9.9L21,8.7h4.4v1l-1.5,1.6z"/>
        </svg>
    );
}

export default SvgLandscape4x3Icon;