import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";

interface DialogConfirmationProps {
    titleText: string;
    messageText: string;
    okButtonText: string;
    cancelButtonText: string;
    okButtonClickHandler: () => void;
    cancelButtonClickHandler: () => void;
}

export const DialogConfirmation: React.FC<DialogConfirmationProps> = ({ titleText, messageText, okButtonText, cancelButtonText, okButtonClickHandler, cancelButtonClickHandler }: DialogConfirmationProps) => {
    const [dialogTop, setDialogTop] = useState(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    const scrollHandler = () => {
        setDialogTop(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    });
    const keyDownHandler = (e : KeyboardEvent) => {
        if (e.key === "Enter"){
            okButtonClickHandler();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        }
    });
    const documentHeight = document.documentElement.offsetHeight;    
    return (
        <div className="dialog-container" style={{height: documentHeight}}>
            <div className="window-modal" style={{marginTop: dialogTop}}>
                <div className="window-modal-title disable-select pb-4 d-flex justify-content-center">{titleText}</div>
                <div className="window-modal-text my-2 disable-select">{messageText}</div>
                <div className="d-flex justify-content-around">
                        <Button className="mt-4" text={okButtonText} danger={true} onClick={okButtonClickHandler} />
                        <Button className="mt-4" text={cancelButtonText} onClick={cancelButtonClickHandler} />
                </div>
            </div>
        </div>
    );
}