import {parseUint16} from "../helpers/BinaryHelper";

export interface LtEcgSensorMetadata {
    samplingRate : number;
    unitsPerMv : number;
    channelCount : number;
    sensorConfig : number;
    sensorMode : number;
    filterId : number;
}

export function parseSensorMetadata(buffer: ArrayBuffer, offset?: number): LtEcgSensorMetadata{
    const data = buffer.slice(offset ?? 0);
    return {
        samplingRate: parseUint16(data, 0),
        unitsPerMv: parseUint16(data, 2),
        channelCount: parseUint16(data, 4),
        sensorConfig: parseUint16(data, 6),
        sensorMode: parseUint16(data, 8),
        filterId: parseUint16(data, 10)
    }
}