import {TFunction} from "react-i18next";

const NORMALIZATION_LEVEL = 0.9;
const K_MU = 10;

export function normalizeChannel(data: number[]) {
    let max = 0;
    for (let i = 0; i < data.length; i++) {
        max = Math.max(max, Math.abs(data[i]));
    }
    let kN = NORMALIZATION_LEVEL / max;
    for (let i = 0; i < data.length; i++) {
        data[i] *= kN;
    }
}

export function compandData(y: number) {
    return Math.sign(y) * Math.log(1 + K_MU * Math.abs(y)) / Math.log(1 + K_MU);
}

export function getStethoscopeChannelNames(t: TFunction<"translation">) {
    return [t("stethoscope_extended"), t("stethoscope_bell"), t("stethoscope_diaphragm")];
}