import './AsyncIndicator.css';
import SvgSmallLogo from "../../../assets/svg/SmallLogo";
import React from 'react';

export default function AsyncIndicator() {
    return (
        <div className="async-indicator-container">
            <div className="async_indicator-logo">
                <SvgSmallLogo />
            </div>
        </div>
    );
}