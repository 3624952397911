import {LtEcgRecordInfo, parseRecord} from "./LtEcgRecordInfo";
import {LtEcgPatientInfo, parsePatientInfo} from "./LtEcgPatientInfo";
import {LtEcgDeviceInfo, parseDeviceInfo} from "./LtEcgDeviceInfo";
import {LtEcgSensorMetadata, parseSensorMetadata} from "./LtEcgSensorMetadata";
import {parseInt32, parseString, parseUint16, parseUint32, parseUint64} from "../helpers/BinaryHelper";
import moment from "moment";
import {isBeecardiaLtEcgDataFile, isBinoraLtEcgDataFile} from "../helpers/AttachmentHelper";

export interface LtEcgFileHeader {
    fileVersion: string;
    record: LtEcgRecordInfo;
    patient: LtEcgPatientInfo;
    device: LtEcgDeviceInfo;
    sensorMetadata: LtEcgSensorMetadata;
}

export function parseFileHeader(fileName: string | undefined, data: ArrayBuffer): LtEcgFileHeader {
    if (isBinoraLtEcgDataFile(fileName)) {
        return parseBinoraFileHeader(data);
    }
    if (isBeecardiaLtEcgDataFile(fileName)) {
        return parseBeecardiaFileHeader(data);
    }
    throw new Error("Unknown file type");
}

function parseBinoraFileHeader(buffer: ArrayBuffer, offset?: number): LtEcgFileHeader {
    const data = buffer.slice(offset ?? 0);
    return {
        fileVersion: parseString(data, 0, 16),
        record: parseRecord(data, 16),
        patient: parsePatientInfo(data, 176),
        device: parseDeviceInfo(data, 300),
        sensorMetadata: parseSensorMetadata(data, 380)
    }
}

export function getBeecardiaEcgDataOffset(buffer: ArrayBuffer, offset?: number) : number{
    const data = buffer.slice(offset ?? 0);
    return parseInt32(data, 40);
}

export function getBeecardiaEcgDataSize(buffer: ArrayBuffer, offset?: number) : number{
    const data = buffer.slice(offset ?? 0);
    return parseInt32(data, 44);
}

function parseBeecardiaFileHeader(buffer: ArrayBuffer, offset?: number): LtEcgFileHeader {
    const data = buffer.slice(offset ?? 0);
    let pos = 0;
    const formatVersion = parseString(data, pos, 32);
    pos += 32;
    pos += 4;
    pos += 4;
    pos += 4;
    pos += 4;
    pos += 4;
    pos += 4;
    pos += 4;
    pos += 4;
    const unixSeconds = parseUint64(data, pos);
    pos += 8;
    pos += 4;
    const recordDuration = parseUint32(data, pos);
    pos += 4;
    const samplingRate = parseInt32(data, pos);
    pos += 4;
    const numberOfSamples = parseInt32(data, pos);
    pos += 4;
    const unitsPerMillivolt = parseInt32(data, pos);
    pos += 4;
    pos += 2;
    const numberOfLeads = parseUint16(data, pos);
    pos += 2;
    pos += 16 * 16;
    const deviceClass = parseString(data, pos, 24);
    pos += 24;
    const deviceNumber = parseString(data, pos, 24);
    pos += 24;
    const recordId = parseString(data, pos, 44);
    pos += 44;
    const deviceId = parseString(data, pos, 44);
    const dateTime = moment.unix(unixSeconds);
    return {
        fileVersion: formatVersion,
        record: {
            recordDuration: recordDuration,
            recordReadStatus: 0,
            recordStartDelay: 0,
            referenceLocalTime: dateTime.format(),
            samplesInRecord: numberOfSamples,
            stopRecordReason: 0,
            recordId: recordId,
            recordTimeout: 0,
            recordLengthBlocks: 0,
            ownerId: deviceId,
            keyEventsCount: 0
        },
        device: {
            deviceName: deviceClass,
            deviceNumber: deviceNumber,
            firmwareVersion: ""
        },
        patient: {
            patientId: "",
            birthday: "",
            patientCodeName: "",
            gender: 0,
            height: 0,
            weight: 0
        },
        sensorMetadata: {
            channelCount: numberOfLeads,
            samplingRate: samplingRate,
            unitsPerMv: unitsPerMillivolt,
            sensorMode: 0,
            sensorConfig: 0,
            filterId: 0
        }
    }
}