import React from "react";
import { formatGenderFull } from "../../../helpers/FormatHelper";
import { Gender, parseGender } from "../../../models/Gender";
import {useTranslation} from "react-i18next";

interface GenderDropdownProps {
    className?: string;
    initialValue: Gender;
    disabled: boolean;
    onChange: (value: Gender) => void;
}

export const GenderDropdown: React.FC<GenderDropdownProps> = ({ className = "", initialValue, disabled, onChange }: GenderDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} disabled={disabled} defaultValue={Gender[initialValue]} onChange={(e) => onChange(parseGender(e.target.value))}>
            <option value={Gender[Gender.Undefined]}>{formatGenderFull(t, Gender.Undefined)}</option>
            <option value={Gender[Gender.Male]}>{formatGenderFull(t, Gender.Male)}</option>
            <option value={Gender[Gender.Female]}>{formatGenderFull(t, Gender.Female)}</option>
        </select>
    );
}