import React, {useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import * as ApiHelper from "../../../api/ApiHelper";
import * as AppRoutes from '../../../routes';
import {useAbortController} from "../../../hooks";
import {InputBox} from "../../Widgets/InputBox/InputBox";
import {Button} from "../../Widgets/Button/Button";
import SvgMailIcon from "../../../assets/svg/MailIcon";
import Loader from "../../Widgets/Loader/Loader";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import {GOOGLE_RECAPTHCA_KEY} from "../../../settings";
import {AccountSettings} from "../../Widgets/AccountSettings/AccountSettings";

function Restore() {
    const {t, i18n} = useTranslation();
    const [controller] = useAbortController();
    const history = useHistory();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [isFetching, setFetchingState] = useState(false);
    const [email, setEmail] = useState('');
    const [hasBadField, setHasBadField] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null as string | null);
    const handleEmailChange = (value: string) => setEmail(value);
    const successHandler = () => {
        setFetchingState(false);
        history.replace(AppRoutes.RESET_ROUTE);
    };
    const errorHandler = () => {
        setFetchingState(false);
        setErrorMessage(t("connection_problem"));
    };
    const handleRestoreClick = () => {
        if (email.trim() === '') {
            setHasBadField(true);
            setErrorMessage(t("enter_email"));
            return;
        }
        setHasBadField(false);
        setErrorMessage(null);
        recaptchaRef.current?.executeAsync().then(token => {
            if (token) {
                setFetchingState(true);
                ApiHelper.restorePassword(email, i18n.language, controller, successHandler, errorHandler)
            }
        });
    };
    const handleCancelClick = () => {
        history.replace(AppRoutes.SIGN_IN_ROUTE);
    }
    if (isFetching) {
        return (
            <Loader/>
        );
    } else {
        return (
            <div className="window-modal-container">
                <div className="window-modal">
                    <div
                        className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("password_reset_title")}</div>
                    <div className="window-modal-error d-flex justify-content-center align-items-center my-2">
                        {errorMessage ? errorMessage : ""}
                    </div>
                    <InputBox className="my-2" title={t("email")} inputType="email" initialValue={email}
                              hasError={hasBadField} onChange={handleEmailChange} onEnterPress={handleRestoreClick}
                              icon={<SvgMailIcon/>}/>
                    <div className="d-flex justify-content-center">
                        <div className="window-modal-button">
                            <Button className="mt-4" text={t("reset")} highlighted={true} onClick={handleRestoreClick}/>
                        </div>
                        <div className='mx-2'/>
                        <div className="window-modal-button">
                            <Button className="mt-4" text={t("cancel")} highlighted={false} onClick={handleCancelClick}/>
                        </div>
                    </div>
                    <div className='mt-4'/>
                    <AccountSettings/>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={GOOGLE_RECAPTHCA_KEY}/>
            </div>
        );
    }
}

export default Restore;