import React, {useEffect, useState} from "react";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import PagedData from "../../../models/PagedData";
import {Record} from "../../../models/Record";
import * as ApiHelper from '../../../api/ApiHelper';
import {SORT_BY_DATE, SORT_BY_NAME} from "../../../models/SortType";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {FetchError} from "../FetchError/FetchError";
import {RecordsLong} from "../RecordsLong/RecordsLong";
import {SearchInput} from "../SearchInput/SearchInput";
import RecordsPlaceholder from "../RecordsPlaceholder/RecordsPlaceholder";
import {ListPagination} from "../Pagination/ListPagination";
import {SmallDoubleButton, SmallDoubleButtonSide} from "../SmallDoubleButton/SmallDoubleButton";
import {useTranslation} from "react-i18next";
import {AccessType} from "../../../models/AccessType";
import {StudyType} from "../StudyIcon/StudyIcon";
import {useHistory} from "react-router";
import {buildRecordListParameters, updateRecordListParameters} from "../../../models/RecordListParameters";
import {StudyIconPushButton} from "../StudyIcon/StudyIconPushButton";
import {logError} from "../../../helpers/LogHelper";

interface RecordsListProps {
    tag: string;
    showTitle?: boolean;
    pageSize: number;
    userId: string | null;
    accessType: AccessType;
    patientId: string | null;
    recordClickHandler: (id: string) => void;
    studyClickHandler: (id: string, type: StudyType) => void;
    contextMenuHandler?: (id: string, anchorX: number, anchorY: number) => void;
    hasControls?: boolean;
    userClickHandler?: (id: string) => void;
}

export const RecordsList: React.FC<RecordsListProps> = ({
                                                            tag,
                                                            showTitle = false,
                                                            pageSize,
                                                            userId,
                                                            accessType,
                                                            patientId,
                                                            recordClickHandler,
                                                            studyClickHandler,
                                                            contextMenuHandler,
                                                            hasControls = true,
                                                            userClickHandler
                                                        }: RecordsListProps) => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [, setController] = useAbortController();
    const token = useAppSelector(selectToken);
    const [isBackChecked, setBackChecked] = useState(false);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [listParameters, setListParameters] = useState(buildRecordListParameters(1, "", SORT_BY_DATE, true, true, true, true, true));
    const [records, setRecords] = useState([] as Record[]);
    const handleFilterChange = (filter: string) => {
        setListParameters(updateRecordListParameters(listParameters, 1, filter, undefined, undefined, undefined, undefined, undefined, undefined));
    };
    const handleOrderChange = (order: string) => {
        setListParameters(updateRecordListParameters(listParameters, undefined, undefined, order, undefined, undefined, undefined, undefined, undefined));
    }
    const paginationClickHandler = (page: number) => {
        setListParameters(updateRecordListParameters(listParameters, page, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    };
    const recordsHandler = (pagedData: PagedData<Record>) => {
        setFetchingState(false);
        setErrorState(false);
        setRecords(pagedData.data);
        setListParameters(updateRecordListParameters(listParameters, pagedData.page, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        setTotalPages(pagedData.totalPages);
        setTotalRecords(pagedData.totalRecords);
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Records data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            setErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchRecords = (page: number, filter: string, order: string, showState: boolean, showEcg: boolean, showStetho: boolean, showSpiro: boolean, showLtEcg: boolean) => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            localStorage.setItem(tag, JSON.stringify({
                page: page,
                filter: filter,
                order: order,
                showState: showState,
                showEcg: showEcg,
                showStetho: showStetho,
                showSpiro: showSpiro,
                showLtEcg: showLtEcg
            }));
            const abortController = new AbortController();
            setController(abortController);
            ApiHelper.getRecords(userToken, userId, accessType, patientId, filter !== "" ? filter : null, order, showState, showEcg, showStetho, showSpiro, showLtEcg, page, pageSize, abortController, recordsHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => {
        if (history.action === "POP") {
            const s = localStorage.getItem(tag);
            if (s) {
                const data = JSON.parse(s);
                try {
                    const pageNumber = data.page;
                    const filter = data.filter;
                    const order = data.order;
                    const showState = data.showState;
                    const showEcg = data.showEcg;
                    const showStetho = data.showStetho;
                    const showSpiro = data.showSpiro;
                    const showLtEcg = data.showLtEcg;
                    if (typeof pageNumber === "number" && typeof filter === "string" && typeof order === "string") {
                        setListParameters(updateRecordListParameters(listParameters, pageNumber, filter, order, showState, showEcg, showStetho, showSpiro, showLtEcg));
                    }
                } catch {
                    //do nothing
                }
            }
        }
        setBackChecked(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);
    useEffect(() => {
        if (isBackChecked) {
            fetchRecords(listParameters.page, listParameters.filter, listParameters.order, listParameters.showState, listParameters.showEcg, listParameters.showStetho, listParameters.showSpiro, listParameters.showLtEcg);
        }
    }, [isBackChecked, listParameters.page, listParameters.filter, listParameters.order, listParameters.showState, listParameters.showEcg, listParameters.showStetho, listParameters.showSpiro, listParameters.showLtEcg]);     // eslint-disable-line
    if (!isFetching && hasError) {
        return (
            <FetchError
                onRetry={() => fetchRecords(listParameters.page, listParameters.filter, listParameters.order, listParameters.showState, listParameters.showEcg, listParameters.showStetho, listParameters.showSpiro, listParameters.showLtEcg)}/>
        );
    }
    const recordsComponents = records.map(record => <RecordsLong key={record.id} record={record}
                                                                 onClick={() => recordClickHandler(record.id)}
                                                                 onContextMenuHandler={(x, y) => contextMenuHandler ? contextMenuHandler(record.id, x, y) : null}
                                                                 onStudyClick={(type) => studyClickHandler(record.id, type)}
                                                                 onUserClick={userClickHandler}/>)
    const hasData = recordsComponents.length > 0;
    return (
        <div className="data-list-container">
            <div className="data-list">
                {showTitle &&
                    <div className="data-list-title">{t("total_records")}: <span>{totalRecords}</span></div>
                }
                {hasControls &&
                    <div className="d-flex justify-content-between align-items-center">
                        <SearchInput text={listParameters.filter} onChange={handleFilterChange}/>
                        <div className="d-flex flex-grow-1" style={{minWidth: "64px"}}/>
                        <SmallDoubleButton leftButtonText={t("name")} rightButtonText={t("date")}
                                           activeButton={listParameters.order === SORT_BY_DATE ? SmallDoubleButtonSide.Right : SmallDoubleButtonSide.Left}
                                           onSwitch={(side) => handleOrderChange(side === SmallDoubleButtonSide.Right ? SORT_BY_DATE : SORT_BY_NAME)}/>
                    </div>
                }
                {isFetching && <AsyncIndicator/>}
                {!isFetching &&
                    <table>
                        <thead>
                        <tr className="table-header">
                            {(accessType === AccessType.Shared || (accessType === AccessType.All && userId === null)) &&
                                <th rowSpan={2}>{t("user")}</th>
                            }
                            <th rowSpan={2} colSpan={2}>{t("patient")}</th>
                            <th rowSpan={2}>{t("date")}</th>
                            <th colSpan={5} style={{width: "0.1%", whiteSpace: "nowrap"}}>{t("studies")}</th>
                        </tr>
                        <tr className="table-header">
                            <th align="center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <StudyIconPushButton type={StudyType.State} hasText={true}
                                                         pressed={listParameters.showState} onClick={() => {
                                        setListParameters(updateRecordListParameters(listParameters, undefined, undefined, undefined, !listParameters.showState, undefined, undefined, undefined, undefined));
                                    }}/>
                                </div>
                            </th>
                            <th align="center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <StudyIconPushButton type={StudyType.Ecg} hasText={true}
                                                         pressed={listParameters.showEcg} onClick={() => {
                                        setListParameters(updateRecordListParameters(listParameters, undefined, undefined, undefined, undefined, !listParameters.showEcg, undefined, undefined, undefined));
                                    }}/>
                                </div>
                            </th>
                            <th align="center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <StudyIconPushButton type={StudyType.Spiro} hasText={true}
                                                         pressed={listParameters.showSpiro} onClick={() => {
                                        setListParameters(updateRecordListParameters(listParameters, undefined, undefined, undefined, undefined, undefined, undefined, !listParameters.showSpiro, undefined));
                                    }}/>
                                </div>
                            </th>
                            <th align="center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <StudyIconPushButton type={StudyType.Stethoscope} hasText={true}
                                                         pressed={listParameters.showStetho}
                                                         onClick={() => {
                                                             setListParameters(updateRecordListParameters(listParameters, undefined, undefined, undefined, undefined, undefined, !listParameters.showStetho, undefined, undefined));
                                                         }}/>
                                </div>
                            </th>
                            <th align="center">
                                <div className="d-flex align-items-center justify-content-center">
                                    <StudyIconPushButton type={StudyType.LtEcg} hasText={true}
                                                         pressed={listParameters.showLtEcg} onClick={() => {
                                        setListParameters(updateRecordListParameters(listParameters, undefined, undefined, undefined, undefined, undefined, undefined, undefined, !listParameters.showLtEcg));
                                    }}/>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {recordsComponents}
                        </tbody>
                    </table>
                }
                {!isFetching && !hasData &&
                    <RecordsPlaceholder className="my-4"/>
                }
            </div>
            {!isFetching && totalPages > 1 &&
                <ListPagination page={listParameters.page} totalPages={totalPages}
                                clickHandler={paginationClickHandler}/>}
        </div>
    );
}