import * as React from "react"
import { SVGProps } from "react"

const IconDcVoltage = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 5 3"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.283}
            d="M.809.996h3.246M.809 1.793h.812M2.023 1.793h.813M3.238 1.793h.809M.809.996h3.246M.809 1.793h.812M2.023 1.793h.813M3.238 1.793h.809"
        />
    </svg>
)

export default IconDcVoltage
