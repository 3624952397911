import React from "react";
import { getHeightUnitsName } from "../../../helpers/FormatHelper";
import { HeightUnits, parseHeightUnits } from "../../../models/HeightUnits";
import {useTranslation} from "react-i18next";

interface HeightUnitsDropdownProps {
    className?: string;
    initialValue: HeightUnits;
    disabled: boolean;
    onChange: (value: HeightUnits) => void;
}

export const HeightUnitsDropdown: React.FC<HeightUnitsDropdownProps> = ({ className = "", initialValue, disabled, onChange }: HeightUnitsDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} disabled={disabled} defaultValue={HeightUnits[initialValue]} onChange={(e) => onChange(parseHeightUnits(e.target.value))}>
            <option value={HeightUnits[HeightUnits.Centimeters]}>{getHeightUnitsName(t, HeightUnits.Centimeters)}</option>
            <option value={HeightUnits[HeightUnits.Feet]}>{getHeightUnitsName(t, HeightUnits.Feet)}</option>
            <option value={HeightUnits[HeightUnits.Inches]}>{getHeightUnitsName(t, HeightUnits.Inches)}</option>
        </select>
    );
}