import * as React from "react";

function SvgPortrait1x12Icon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 35 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >

            <path
                d="M27,1v28H7V1z"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"/>
            <path
                fill="currentColor"
                d="M12.1,12.7L12.1,19h-1.4v-5.1L9.4,13.9v-1.2h2.7zM16.1,19l-1,-1.5 -1.2,1.5h-1.5l1.9,-2.4 -1.8,-2.4L14,14.2l1,1.4 1.1,-1.4h1.5l-1.8,2.3 1.9,2.5L16,19zM20.1,12.7L20.1,19h-1.5v-5.1h-1.2v-1.2L20,12.7zM25.4,17.8L25.4,19h-4.8v-1l2.5,-2.2 0.5,-0.7 0.1,-0.5c0,-0.3 0,-0.4 -0.2,-0.6l-0.8,-0.2c-0.3,0 -0.5,0 -0.7,0.2 -0.3,0 -0.4,0.2 -0.6,0.4l-1,-0.6c0.2,-0.4 0.5,-0.7 1,-0.9 0.4,-0.2 0.9,-0.3 1.5,-0.3 0.4,0 0.8,0 1.2,0.2l0.8,0.7c0.2,0.3 0.3,0.6 0.3,1 0,0.3 0,0.6 -0.2,1 -0.2,0.2 -0.4,0.6 -0.9,1l-1.4,1.3h2.7z"/>

        </svg>
    );
}

export default SvgPortrait1x12Icon;
