import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {TextBox} from "../InputBox/TextBox";
import {Button} from "../Button/Button";
import './Conclusion.css';

interface ConclusionProps{
    initialValue : string;
    cachedValue: string;
    saveHandler?: (conclusion : string) => void;
    resetHandler?: () => void;
}

export const Conclusion: React.FC<ConclusionProps> = ({initialValue, cachedValue, saveHandler, resetHandler} : ConclusionProps) => {
    const {t} = useTranslation();
    const conclusionInputRef = useRef<HTMLDivElement>(null);
    const [conclusionText, setConclusionText] = useState(cachedValue);
    useEffect(() => {
        setConclusionText(cachedValue);
        setVersion(version + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cachedValue]);
    const [version, setVersion] = useState(0);
    const resetConclusionText = () => {
        if (resetHandler){
            resetHandler();
        }
        setConclusionText(initialValue);
        setVersion(version + 1);
    };
    return (
        <div className="d-flex flex-column">
            <div ref={conclusionInputRef} className="d-flex flex-column align-items-stretch conclusion-focus">
                <TextBox key={`tb-${version}`} className="my-2 input-box-textarea input-box-comment" initialValue={conclusionText}
                         title={t("conclusion")} placeholder={saveHandler ? t('enter_conclusion') : t('no_conclusion')}
                         onChange={setConclusionText} enabled={saveHandler !== undefined}/>
            </div>
            {saveHandler &&
            <div className="d-flex justify-content-center">
                <div className="details-controls d-flex justify-content-around my-2">
                    <Button className={"mx-2"} text={t("delete")} danger={true} enabled={conclusionText !== ""}
                            onClick={() => saveHandler("")}/>
                    <Button className={"mx-2"} text={t("save")} enabled={conclusionText !== initialValue}
                            highlighted={true}
                            onClick={() => saveHandler(conclusionText)}/>
                    <Button className={"mx-2"} text={t("cancel")} enabled={conclusionText !== initialValue} onClick={resetConclusionText}/>
                </div>
            </div>
            }
        </div>
    );
}