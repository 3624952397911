import React from "react";
import {useTranslation} from "react-i18next";
import {GRID_COLORS} from "../../../ecg/EcgGridColors";


interface ReportGridColorDropdownProps {
    className?: string;
    initialValue: number;
    onChange: (value: number) => void;
}

export const ReportGridColorDropdown: React.FC<ReportGridColorDropdownProps> = ({ className = "", initialValue, onChange }: ReportGridColorDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} defaultValue={initialValue} onChange={(e) => onChange(Number(e.target.value))}>
            {GRID_COLORS.map((s, i) => <option key={`report-grid-color-${i}`} value={i}>{t(s)}</option>)}
        </select>
    );
}