import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router";
import {selectToken} from "../../../features/account/accountSlice";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import {Patient} from "../../../models/Patient";
import * as ApiHelper from '../../../api/ApiHelper';
import handleErrors from '../../../helpers/ErrorHandler';
import {DATA_LIST_PAGE_SIZE} from "../../../settings";
import {useHistory} from "react-router-dom";
import {formatTimestamp} from "../../../helpers/FormatHelper";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import {InputWrapper} from "../../Widgets/InputWrapper/InputWrapper";
import {GenderDropdown} from "../../Widgets/GenderDropdown/GenderDropdown";
import {ConfirmationStatusDropdown} from "../../Widgets/ConfirmationStatusDropdown/ConfirmationStatusDropdown";
import {HeightUnitsDropdown} from "../../Widgets/HeightUnitsDropdown/HeightUnitsDropdown";
import {WeightUnitsDropdown} from "../../Widgets/WeighUnitsDropdown/WeightUnitsDropdown";
import {HeightUnits} from "../../../models/HeightUnits";
import {WeightUnits} from "../../../models/WeightUnits";
import {Gender} from "../../../models/Gender";
import {Button} from "../../Widgets/Button/Button";
import {Toast} from "../../Widgets/Toast/Toast";
import {BirthDatePicker} from "../../Widgets/BirthDatePicker/BirthDatePicker";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {RecordsList} from "../../Widgets/RecordsList/RecordsList";
import {Role} from "../../../models/Role";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {DialogConfirmation} from "../../Widgets/DialogConfirmation/DialogConfirmation";
import * as ErrorCodes from "../../../api/ErrorCodes";
import NotFound from "../../Widgets/NotFound/NotFound";
import {useTranslation} from "react-i18next";
import {ControlledMenu, MenuItem, useMenuState} from "@szhsin/react-menu";
import {AccessType} from "../../../models/AccessType";
import {StudyType} from "../../Widgets/StudyIcon/StudyIcon";
import {logError} from "../../../helpers/LogHelper";

interface PathParams {
    patientId: string;
}

interface PatientsDetailsProps {
    userId: string | null;
    canShare: boolean;
    canEdit: boolean;
    accessType: AccessType;
}

export const PatientDetails: React.FC<PatientsDetailsProps> = ({
                                                                   userId,
                                                                   canShare,
                                                                   canEdit,
                                                                   accessType
                                                               }: PatientsDetailsProps) => {
    const {t} = useTranslation();
    let {patientId} = useParams<PathParams>();
    const [controller] = useAbortController();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const isSupport = useAccessInfo(Role.Support);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [hasSaveError, setSaveErrorState] = useState(false);
    const [keyIndex, setKeyIndex] = useState(0);
    const [patient, setPatient] = useState(null as Patient | null);
    const [editName, setEditName] = useState(null as string | null);
    const [editGender, setEditGender] = useState(null as Gender | null);
    const [editPhone, setEditPhone] = useState(null as string | null);
    const [editPhoneStatus, setEditPhoneStatus] = useState(null as boolean | null);
    const [editEmail, setEditEmail] = useState(null as string | null);
    const [editEmailStatus, setEditEmailStatus] = useState(null as boolean | null);
    const [editBirthDate, setEditBirthDate] = useState(null as Date | null);
    const [editHeight, setEditHeight] = useState(null as number | null);
    const [editHeightUnits, setEditHeightUnits] = useState(null as HeightUnits | null);
    const [editWeight, setEditWeight] = useState(null as number | null);
    const [editWeightUnits, setEditWeightUnits] = useState(null as WeightUnits | null);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const recordClickHandler = (id: string) => {
        history.push(`${history.location.pathname}/${id}`);
    };
    const studyClickHandler = (id: string, type: StudyType) => {
        switch (type) {
            case StudyType.State:
                recordClickHandler(id);
                break;
            case StudyType.Ecg:
                history.push(`${history.location.pathname}/${id}/ecg`)
                break;
            case StudyType.Stethoscope:
                history.push(`${history.location.pathname}/${id}/stethoscope`)
                break;
            case StudyType.Spiro:
                history.push(`${history.location.pathname}/${id}/spiro`)
                break;
            case StudyType.LtEcg:
                recordClickHandler(id);
                break;
        }
    };
    const {toggleMenu, ...menuProps} = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
    const [shareRecordId, setShareRecordId] = useState(null as string | null);
    const contextMenuHandler = (id: string, x: number, y: number) => {
        setAnchorPoint({x: x, y: y});
        setShareRecordId(id);
        toggleMenu(true);
    };
    const shareClickHandler = () => {
        history.push(`${history.location.pathname}/${shareRecordId}/share`);
    };
    const initFields = (patient: Patient) => {
        setKeyIndex(keyIndex + 1);
        setEditName(patient.name);
        setEditGender(patient.gender);
        setEditPhone(patient.phone);
        setEditPhoneStatus(patient.phoneConfirmed);
        setEditEmail(patient.email);
        setEditEmailStatus(patient.emailConfirmed);
        setEditBirthDate(patient.birthDate);
        setEditHeight(patient.height);
        setEditHeightUnits(patient.heightUnits);
        setEditWeight(patient.weight);
        setEditWeightUnits(patient.weightUnits);
    };
    const patientHandler = (patient: Patient) => {
        setErrorState(false);
        setSaveErrorState(false);
        setPatient(patient);
        initFields(patient);
        setFetchingState(false);
        setNotFoundState(false);
    };
    const deleteHandler = () => {
        setErrorState(false);
        setNotFoundState(false);
        setSaveErrorState(false);
        setFetchingState(false);
        history.goBack();
    }
    const errorHandler = (error: ErrorResponse) => {
        logError("Patient data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const saveErrorHandler = (error: ErrorResponse) => {
        logError("Patient data save error", error);
        if (!handleErrors(error, dispatch)) {
            setSaveErrorState(false);
            setSaveErrorState(true);
            setFetchingState(false);
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getPatient(userToken, patientId, controller, patientHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [patientId]);     // eslint-disable-line
    const nameValidator = (value: string) => {
        if (value.trim() === "") {
            return t("enter_name");
        }
        return null;
    };
    const heightValidator = (value: string) => {
        if (value.trim() === "") {
            return t("enter_height");
        }
        let n = Number(value);
        if (Number.isNaN(n)) {
            return t("invalid_format");
        }
        if (n <= 0) {
            return t("wrong_email");
        }
        return null;
    }
    const weightValidator = (value: string) => {
        if (value.trim() === "") {
            return t("enter_weight");
        }
        let n = Number(value);
        if (Number.isNaN(n)) {
            return t("invalid_format");
        }
        if (n <= 0) {
            return t("wrong_email");
        }
        return null;
    }

    const isChanged = patient?.name !== editName
        || patient?.gender !== editGender
        || patient?.phone !== editPhone
        || patient?.phoneConfirmed !== editPhoneStatus
        || patient?.email !== editEmail
        || patient?.emailConfirmed !== editEmailStatus
        || patient?.birthDate !== editBirthDate
        || patient?.height !== editHeight
        || patient?.heightUnits !== editHeightUnits
        || patient.weight !== editWeight
        || patient.weightUnits !== editWeightUnits;
    const savePatient = () => {
        let userToken = token?.token;
        if (userToken && patient && editName !== null && editGender !== null && editPhone !== null && editPhoneStatus !== null && editEmail !== null && editEmailStatus !== null && editBirthDate !== null && editHeight !== null && editHeightUnits !== null && editWeight !== null && editWeightUnits !== null) {
            setFetchingState(true);
            const now = new Date().getTime();
            const newPatient: Patient = {
                id: patient.id,
                name: editName,
                email: editEmail,
                emailConfirmed: editEmailStatus,
                phone: editPhone,
                phoneConfirmed: editPhoneStatus,
                birthDate: editBirthDate,
                birthDateTimestamp: patient.birthDate !== editBirthDate ? now : patient.birthDateTimestamp,
                gender: editGender,
                genderTimestamp: patient.gender !== editGender ? now : patient.genderTimestamp,
                weight: editWeight,
                weightUnits: editWeightUnits,
                weightTimestamp: patient.weight !== editWeight || patient.weightUnits !== editWeightUnits ? now : patient.weightTimestamp,
                height: editHeight,
                heightUnits: editHeightUnits,
                heightTimestamp: patient.height !== editHeight || patient.heightUnits !== editHeightUnits ? now : patient.heightTimestamp,
                patientTimestamp: now,
                serverTimestamp: patient.serverTimestamp,
                recordState: patient.recordState,
                ownerInfo: null
            }
            ApiHelper.savePatient(userToken, newPatient, controller, patientHandler, saveErrorHandler);
        }
    };
    const deletePatient = () => {
        setShowDeleteConfirmationDialog(false);
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.deletePatient(userToken, patientId, controller, deleteHandler, saveErrorHandler)
        }
    };
    const accessControlHandler = () => {
        history.push(`${history.location.pathname}/share`);
    }
    const isValid = editName !== null && editGender !== null && editPhone !== null && editPhoneStatus !== null && editEmail !== null && editEmailStatus !== null && editBirthDate !== null && editHeight !== null && editHeightUnits !== null && editWeight !== null && editWeightUnits !== null;
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <div>
            <Breadcrumbs data={new Map([[patientId, patient?.name ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && patient) &&
                <div className="d-flex justify-content-center my-4">
                    <div className="details-table">
                        <table>
                            <tbody>
                            <tr>
                                <th>{t("name")}</th>
                                <td>
                                    <InputWrapper key={`field-name-${keyIndex}`} className="input-text" type="text"
                                                  disabled={!canEdit}
                                                  value={editName ?? ""} onChange={(v) => {
                                        setEditName(v)
                                    }} validator={nameValidator}/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t("gender")}</th>
                                <td><GenderDropdown key={`field-gender-${keyIndex}`} disabled={!canEdit}
                                                    className="input-dropdown"
                                                    initialValue={editGender ?? Gender.Undefined} onChange={(v) => {
                                    setEditGender(v)
                                }}/></td>
                            </tr>
                            {isSupport ?
                                (<tr>
                                    <th>{t("gender_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(patient.genderTimestamp)}</td>
                                </tr>) : null
                            }
                            <tr>
                                <th>{t("phone")}</th>
                                <td><InputWrapper key={`field-phone-${keyIndex}`} disabled={!canEdit}
                                                  className="input-text" type="text"
                                                  value={editPhone ?? ""} onChange={(v) => {
                                    setEditPhone(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("phone_status")}</th>
                                <td><ConfirmationStatusDropdown key={`field-phone-status-${keyIndex}`}
                                                                className="input-dropdown" disabled={!canEdit}
                                                                initialState={editPhoneStatus ?? false}
                                                                onChange={(v) => {
                                                                    setEditPhoneStatus(v)
                                                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("email")}</th>
                                <td><InputWrapper key={`field-email-${keyIndex}`} disabled={!canEdit}
                                                  className="input-text" type="text"
                                                  value={editEmail ?? ""} onChange={(v) => {
                                    setEditEmail(v)
                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("email_status")}</th>
                                <td><ConfirmationStatusDropdown key={`field-email-status-${keyIndex}`}
                                                                className="input-dropdown" disabled={!canEdit}
                                                                initialState={editEmailStatus ?? false}
                                                                onChange={(v) => {
                                                                    setEditEmailStatus(v)
                                                                }}/></td>
                            </tr>
                            <tr>
                                <th>{t("birthdate")}</th>
                                <td><BirthDatePicker key={`field-birthdate-${keyIndex}`} disabled={!canEdit}
                                                     className="input-text"
                                                     date={editBirthDate ?? new Date()} onChange={(v) => {
                                    setEditBirthDate(v)
                                }}/></td>
                            </tr>
                            {isSupport ?
                                (<tr>
                                    <th>{t("birthdate_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(patient.birthDateTimestamp)}</td>
                                </tr>) : null
                            }
                            <tr>
                                <th>{t("height")}</th>
                                <td><InputWrapper key={`field-height-${keyIndex}`} className="input-text" type="text"
                                                  disabled={!canEdit}
                                                  value={`${editHeight ?? 170}`} onChange={(v) => {
                                    setEditHeight(v !== null ? Number(v) : null)
                                }} validator={heightValidator}/></td>
                            </tr>
                            <tr>
                                <th>{t("height_units")}</th>
                                <td><HeightUnitsDropdown key={`field-height-units-${keyIndex}`}
                                                         className="input-dropdown" disabled={!canEdit}
                                                         initialValue={editHeightUnits ?? HeightUnits.Centimeters}
                                                         onChange={(v) => {
                                                             setEditHeightUnits(v)
                                                         }}/></td>
                            </tr>
                            {isSupport ?
                                (<tr>
                                    <th>{t("height_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(patient.heightTimestamp)}</td>
                                </tr>) : null
                            }
                            <tr>
                                <th>{t("weight")}</th>
                                <td><InputWrapper key={`field-weight-${keyIndex}`} className="input-text" type="text"
                                                  disabled={!canEdit}
                                                  value={`${editWeight ?? 70}`} onChange={(v) => {
                                    setEditWeight(v !== null ? Number(v) : null)
                                }} validator={weightValidator}/></td>
                            </tr>
                            <tr>
                                <th>{t("weight_units")}</th>
                                <td><WeightUnitsDropdown key={`field-weight-units-${keyIndex}`}
                                                         className="input-dropdown" disabled={!canEdit}
                                                         initialValue={editWeightUnits ?? WeightUnits.Kilograms}
                                                         onChange={(v) => {
                                                             setEditWeightUnits(v)
                                                         }}/></td>
                            </tr>
                            {isSupport ?
                                (<tr>
                                    <th>{t("weight_timestamp")}</th>
                                    <td className="text-left">{formatTimestamp(patient.weightTimestamp)}</td>
                                </tr>) : null
                            }
                            {isSupport ?
                                (<tr>
                                    <th>{t("last_changed")}</th>
                                    <td className="text-left">{formatTimestamp(patient.patientTimestamp)}</td>
                                </tr>) : null
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            {(isOk && hasSaveError) &&
                <Toast text={t("connection_problem")} isError={true}/>
            }
            {(isOk && patient && canShare) &&
                <div className="d-flex justify-content-center mb-4">
                    <Button className="mx-2" text={t("access_control")} onClick={accessControlHandler}/>
                </div>
            }
            {(isOk && canEdit && patient) &&
                <Fragment>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-between">
                            <Button className="mr-2" text={t("save_changes")} enabled={isValid && isChanged}
                                    highlighted={true}
                                    onClick={() => {
                                        savePatient()
                                    }}/>
                            <Button className="ml-2" text={t("discard")} onClick={() => initFields(patient)}/>
                        </div>
                    </div>
                    {isSupport &&
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("delete")} danger={true} onClick={() => {
                                setShowDeleteConfirmationDialog(true)
                            }}/>
                        </div>
                    }
                </Fragment>
            }
            {(isOk && patient) &&
                <div className="my-4">
                    <RecordsList tag="patient_details" showTitle={true} userId={userId} accessType={accessType} patientId={patientId}
                                 pageSize={DATA_LIST_PAGE_SIZE}
                                 recordClickHandler={recordClickHandler} hasControls={false}
                                 contextMenuHandler={canShare ? contextMenuHandler : undefined}
                                 studyClickHandler={studyClickHandler}/>
                </div>
            }
            {showDeleteConfirmationDialog &&
                <DialogConfirmation titleText={t("delete_patient_title")} messageText={t("delete_patient_description")}
                                    okButtonText={t("delete")} cancelButtonText={t("cancel")}
                                    okButtonClickHandler={deletePatient}
                                    cancelButtonClickHandler={() => setShowDeleteConfirmationDialog(false)}/>}
            {canShare &&
                <ControlledMenu {...menuProps} anchorPoint={anchorPoint}
                                onClose={() => toggleMenu(false)}>
                    <MenuItem onClick={shareClickHandler}>{t("share")}</MenuItem>
                </ControlledMenu>
            }
        </div>
    );
};

export default PatientDetails;