import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import ColorSchemeDropdown from "../ColorSchemeDropdown/ColorSchemeDropdown";
import React from "react";
import './AccountSettings.css';

export function AccountSettings(){
    return (
        <div className='d-flex justify-content-around account-settings-container'>
            <LanguageDropdown className='account-settings-dropdown' isFull={true}/>
            <ColorSchemeDropdown className='account-settings-dropdown' isFull={true}/>
        </div>
    );
}