import React from "react";
import SvgSmallLogo from "../../../assets/svg/SmallLogo";
import './FetchError.css';
import {useTranslation} from "react-i18next";

interface FetchErrorProps {
    message? : string;
    onRetry?: (() => void) | null;
}

export const FetchError: React.FC<FetchErrorProps> = ({message = undefined, onRetry = null } : FetchErrorProps) => {
    const {t} = useTranslation();
    return (
        <div className="fetch-error-container">
            <div className="fetch-error">
                <SvgSmallLogo />
                <div className="fetch-error-title mt-4">{message ?? t("data_fetching_error")}</div>
                {onRetry && <div className="fetch-error-link mt-4" onClick={onRetry}>{t("retry")}</div>}
            </div>
        </div>
        );
}
