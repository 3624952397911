import { useTranslation } from "react-i18next";
import {useThemeSwitcher} from "react-css-theme-switcher";

interface ColorSchemeDropdownProps {
    className?: string;
    isFull?: boolean;
}

export const ColorSchemeDropdown = ({className, isFull}  : ColorSchemeDropdownProps) => {
    const { t } = useTranslation();
    const { switcher, themes, currentTheme } = useThemeSwitcher();
    const handleSchemeChange = (value: string) => {
        switcher({theme: value === themes.dark ? themes.dark : themes.light});
    }
    return (
        <select className={`${className} disable-select`} defaultValue={currentTheme} onChange={(e) => handleSchemeChange(e.target.value)}>
            <option value={themes.light}>{isFull ? t("color_scheme_light_full") : t('color_scheme_light')}</option>
            <option value={themes.dark}>{isFull ? t("color_scheme_dark_full") : t('color_scheme_dark')}</option>
        </select>
    );
}

export default ColorSchemeDropdown;