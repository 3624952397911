import {parseString, parseUint32} from "../helpers/BinaryHelper";

export interface LtEcgRecordInfo{
    recordId : string;
    referenceLocalTime : string;
    recordStartDelay : number;
    ownerId : string;
    samplesInRecord : number;
    recordTimeout : number;
    recordDuration : number;
    recordLengthBlocks : number;

    stopRecordReason : number;
    keyEventsCount : number;
    recordReadStatus : number;
}

export function parseRecord(buffer: ArrayBuffer, offset?: number) : LtEcgRecordInfo{
    const data = buffer.slice(offset ?? 0);
    return {
        recordId: parseString(data,0,48),
        referenceLocalTime: parseString(data, 48, 32),
        recordStartDelay: parseUint32(data, 80),
        ownerId: parseString(data, 84, 48),
        samplesInRecord: parseUint32(data, 132),
        recordTimeout: parseUint32(data, 136),
        recordDuration: parseUint32(data, 140),
        recordLengthBlocks: parseUint32(data, 144),
        stopRecordReason: parseUint32(data, 148),
        keyEventsCount: parseUint32(data, 152),
        recordReadStatus: parseUint32(data, 156)
    }
}