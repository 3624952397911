import React from "react";
import { formatBirthdate, formatGenderFull, formatHeight, formatWeight } from "../../../helpers/FormatHelper";
import { Patient } from "../../../models/Patient";
import {useTranslation} from "react-i18next";
import {Avatar} from "@material-ui/core";
import {getUserPhotoUrl} from "../../../api/ApiHelper";

interface PatientProps {
    patient: Patient;
    onClick: () => void;
    onContextMenuHandler: (anchorX: number, anchorY: number) => void;
    onUserClick?: (userId: string) => void;
}

export const PatientsLong: React.FC<PatientProps> = ({ patient, onClick, onContextMenuHandler, onUserClick }: PatientProps) => {
    const {t} = useTranslation();
    const contextMenuHandler = (event : any) => {
        event.preventDefault();
        onContextMenuHandler(event.clientX, event.clientY);
    };
    const userClickHandler = (e : React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (onUserClick && patient.ownerInfo?.ownerId){
            onUserClick(patient.ownerInfo.ownerId);
        }
    };
    return (
        <tr onClick={onClick} onContextMenu={contextMenuHandler}>
            {patient.ownerInfo &&
                <td className="d-flex justify-content-start align-items-center text-left">
                    <Avatar className="user-avatar-img mx-2" alt={patient.ownerInfo.ownerName} src={getUserPhotoUrl(patient.ownerInfo.ownerPhotoUrl)}/>
                    <div className={`text-tooltip ${onUserClick ? "clickable" : ""}`} onClick={userClickHandler}>{patient.ownerInfo.ownerName}<span>{patient.ownerInfo.ownerEmail}</span></div>
                </td>
            }
            <td className="text-left">{patient.name}</td>
            <td align="center">{formatBirthdate(patient.birthDate)}</td>
            <td align="center">{patient.phone}</td>
            <td align="center">{patient.email}</td>
            <td align="center">{formatGenderFull(t, patient.gender)}</td>
            <td align="center">{formatHeight(t, patient.height, patient.heightUnits)}</td>
            <td align="center">{formatWeight(t, patient.weight, patient.weightUnits)}</td>
        </tr>
    );
}