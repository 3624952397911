import Logger from "idb-logger";
import moment from "moment";

export const logger = new Logger({
    maxRecords: 200
});

export function logError(message: string, reason : any){
    logger.error(message, reason.message ?? reason).then();
}

export function logInfo(message: string, payload? : any){
    logger.log(message, payload).then();
}

export function downloadLogs(){
    logger.download({
        name: `binora_web_${moment(Date.now()).format('YYYY-MM-DD_HH-mm-ss')}`,
        keys: ["msg"]
    }).then();
}