import {useTranslation} from "react-i18next";

interface LanguageDropdownProps {
    className?: string;
    isFull? : boolean;
}

export const LanguageDropdown = ({className, isFull}: LanguageDropdownProps) => {
    const {i18n} = useTranslation();
    const handleLanguageChange = (value: string) => {
        i18n.changeLanguage(value).then(() => {
        });
    }
    return (
        <select className={`${className} disable-select`} defaultValue={i18n.language}
                onChange={(e) => handleLanguageChange(e.target.value)}>
            <option value='en'>{isFull ? "English" : "En"}</option>
            <option value='uk'>{isFull ? "Українська" : "Uk"}</option>
            <option value='pl'>{isFull ? "Polski" : "Pl"}</option>
            <option value='ru'>{isFull ? "Русский" : "Ru"}</option>
        </select>
    );
}

export default LanguageDropdown;