import React, {useLayoutEffect, useRef} from "react";
import {useThemeSwitcher} from "react-css-theme-switcher";
import SvgFullLogoDark from "../../../assets/svg/FullLogoDark";
import SvgFullLogoLight from "../../../assets/svg/FullLogoLight";

interface HeaderLogoProps{
    sizeChangeListener? : (width : number, height : number) => void;
}

export const HeaderLogo = ({sizeChangeListener } : HeaderLogoProps) => {
    const { themes, currentTheme } = useThemeSwitcher();
    const ref = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        function onResize() {
            if (sizeChangeListener && ref.current){
                sizeChangeListener(ref.current.offsetWidth, ref.current.offsetHeight);
            }
        }
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    let logo;
    if (currentTheme === themes.dark) {
        logo = <SvgFullLogoDark />;
    }
    if (currentTheme === themes.light || !logo) {
        logo = <SvgFullLogoLight />;
    }
    return (
        <div style={{ width: "100%", height: "48px" }} ref={ref}>
            {logo}
        </div>
    );
}

export default HeaderLogo;