import {useTranslation} from "react-i18next";
import {useAbortController, useAccessInfo, useAppDispatch, useAppSelector} from "../../../hooks";
import {Role} from "../../../models/Role";
import {useHistory, useLocation} from "react-router";
import {useParams} from "react-router-dom";
import {selectToken} from "../../../features/account/accountSlice";
import React, {useEffect, useState} from "react";
import {Record} from "../../../models/Record";
import {Comment} from "../../../models/Comment";
import {SpiroData} from "../../../models/SpiroData";
import PagedData from "../../../models/PagedData";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {formatDateTime} from "../../../helpers/FormatHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {Conclusion} from "../../Widgets/Conclusion/Conclusion";
import {Toast} from "../../Widgets/Toast/Toast";
import {CommentList} from "../../Widgets/CommentList/CommentList";
import {SpiroDetailsTable} from "../../Widgets/SpiroDetailsTable/SpiroDetailsTable";
import {spiroTestData} from "../../../spr/SpiroTest";
import {SpiroViewer} from "../../Widgets/SpiroViewer/SpiroViewer";
import StarIcon from "../../../assets/svg/StarIcon";
import {PatientInfo} from "../../Widgets/PatientInfo/PatientInfo";
import {logError} from "../../../helpers/LogHelper";

interface PathParams {
    recordId: string;
    tabIndex: string;
}

interface SpiroViewProps {
    canEditConclusion: boolean;
}

export const SpiroView: React.FC<SpiroViewProps> = ({canEditConclusion}: SpiroViewProps) => {
    const {t} = useTranslation();
    const isSupport = useAccessInfo(Role.Support);
    const history = useHistory();
    const location = useLocation();
    const {recordId, tabIndex} = useParams<PathParams>();
    const activeTabIndex = Number(tabIndex ?? "0") ?? 0;
    const setActiveTabIndex = (index: number) => {
        const newLocation = location.pathname.replace(/\d+$/gm, `${index}`);
        history.replace(newLocation);
    }
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [isFetching, setFetchingState] = useState(true);
    const [isCommentsFetching, setCommentsFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [comments, setComments] = useState(null as Array<Comment> | null);
    const [initialConclusion, setInitialConclusion] = useState(null as null | string);
    const [cachedConclusion, setCachedConclusion] = useState(null as null | string);
    const [isConclusionFetching, setConclusionFetching] = useState(false);
    const [showConclusionSaveSuccessToast, setShowConclusionSaveSuccessToast] = useState(false);
    const [showConclusionSaveErrorToast, setShowConclusionSaveErrorToast] = useState(false);
    const [data, setData] = useState(null as SpiroData | null);
    const dataHandler = (data: SpiroData) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setActiveTabIndex(0);
        setData(data);
    };
    const recordHandler = (record: Record) => {
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        setInitialConclusion(record.spiroStudy.spiroConclusion ?? "");
        setCachedConclusion(record.spiroStudy.spiroConclusion ?? "");
    };
    const commentsHandler = (comments: PagedData<Comment>) => {
        setCommentsFetchingState(false);
        setComments(comments.data);
    }
    const errorHandler = (error: ErrorResponse) => {
        logError("Spiro data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const commentsErrorHandler = () => {
        setCommentsFetchingState(false);
        setComments(null);
    };
    const fetchData = (record: Record) => {
        if (process.env.NODE_ENV === 'development') {
            //For testing
            dataHandler(spiroTestData);
        } else {
            //Main code
            let userToken = token?.token;
            if (userToken && record.spiroStudy) {
                setFetchingState(true);
                ApiHelper.getSpiroData(userToken, record.id, record.spiroStudy.spiroId, controller, dataHandler, errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchRecord = () => {
        let userToken = token?.token;
        if (userToken) {
            setFetchingState(true);
            ApiHelper.getRecord(userToken, recordId, controller, recordHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    const fetchComments = (recordId: string, studyId: string) => {
        let userToken = token?.token;
        if (userToken) {
            setCommentsFetchingState(true);
            ApiHelper.getComments(userToken, recordId, studyId, controller, commentsHandler, commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchRecord(), [recordId]);     // eslint-disable-line
    useEffect(() => {
        if (record) {
            fetchData(record);
            fetchComments(recordId, record.spiroStudy.spiroId);
        }
    }, [record]);     // eslint-disable-line
    const postComment = (text: string, referenceId?: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCommentsFetchingState(true);
            ApiHelper.saveComment(userToken, recordId, record.spiroStudy.spiroId, text, referenceId ?? null, controller, () => fetchComments(recordId, record.spiroStudy.spiroId), commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    const deleteComment = (id: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCommentsFetchingState(true);
            ApiHelper.deleteComment(userToken, id, controller, () => fetchComments(recordId, record.spiroStudy.spiroId), commentsErrorHandler);
        } else {
            setErrorState(true);
        }
    };
    const conclusionSaveHandler = (text: string) => {
        setInitialConclusion(text);
        setConclusionFetching(false);
        setShowConclusionSaveErrorToast(false);
        setShowConclusionSaveSuccessToast(false);
        setShowConclusionSaveSuccessToast(true);

    };
    const conclusionSaveErrorHandler = () => {
        setConclusionFetching(false);
        setShowConclusionSaveSuccessToast(false);
        setShowConclusionSaveErrorToast(false);
        setShowConclusionSaveErrorToast(true);
    };
    const addToConclusion = (text: string) => {
        const newText = cachedConclusion + " " + text;
        setCachedConclusion(newText);
        setTimeout(() => {
            const elements = document.getElementsByClassName("conclusion-focus");
            if (elements.length > 0) {
                const element = elements[0];
                const elementsByTagName = element.getElementsByTagName("textarea");
                if (elementsByTagName.length > 0) {
                    elementsByTagName[0].focus();
                    elementsByTagName[0].setSelectionRange(newText.length, newText.length);
                }
            }
        }, 50);
    }
    const saveConclusion = (text: string) => {
        let userToken = token?.token;
        if (userToken && record) {
            setCachedConclusion(text);
            setConclusionFetching(true);
            ApiHelper.saveSpiroConclusion(userToken, recordId, text, controller, () => conclusionSaveHandler(text), conclusionSaveErrorHandler);
        }
    };
    const isOk = !hasError && !notFound && !isFetching;
    const tabButtonClickHandler: (index: number) => void = (index) => {
        if (index !== activeTabIndex) {
            setActiveTabIndex(index);
        }
    }
    const buttons = [
        <div key={`b-0`} className={`tab-round-button ${activeTabIndex === 0 ? "tab-round-button-selected" : ""}`}
             onClick={() => tabButtonClickHandler(0)}>
            <StarIcon/>
        </div>
    ];
    const recordButtons = data?.records.map((r, i) => <div key={`b-${i + 1}`}
                                                     className={`tab-round-button ${activeTabIndex === i + 1 ? "tab-round-button-selected" : ""}`}
                                                     onClick={() => tabButtonClickHandler(i + 1)}>{i + 1}</div>);
    if (recordButtons && recordButtons.length > 1) {
        buttons.push(...recordButtons);
    }
    return (
        <div>
            <Breadcrumbs
                data={new Map([[recordId, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && data) &&
                <div className="spiro-data-container">
                    <PatientInfo record={record}/>
                    {buttons &&
                        <div className="d-flex justify-content-center mt-4 mb-2">
                            {buttons}
                        </div>
                    }
                    <SpiroViewer spiroData={data} activeRecordIndex={activeTabIndex} recordId={record.id}
                                 studyId={record.spiroStudy.spiroId} isSupport={isSupport}/>
                </div>
            }
            {(isOk && record && data) &&
                <SpiroDetailsTable record={record} data={data} isSupport={isSupport}/>
            }
            {(isOk && record) && (isConclusionFetching ? <AsyncIndicator/> :
                <Conclusion initialValue={initialConclusion ?? ""} cachedValue={cachedConclusion ?? ""}
                            saveHandler={canEditConclusion ? saveConclusion : undefined}
                            resetHandler={() => setCachedConclusion(initialConclusion)}/>)}
            {(isOk && showConclusionSaveSuccessToast) &&
                <Toast text={t("conclusion_saved")}/>
            }
            {(isOk && showConclusionSaveErrorToast) &&
                <Toast text={t("conclusion_saved_failed")} isError={true}/>
            }
            {isCommentsFetching && <AsyncIndicator/>}
            {!isCommentsFetching && comments &&
                <CommentList comments={comments}
                             onPostComment={postComment}
                             onDeleteComment={deleteComment}
                             onMakeConclusion={canEditConclusion ? addToConclusion : undefined}/>
            }
        </div>
    );
}
