import * as React from "react";

function SvgLandscapeIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 35 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4,2h26v20H4z"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"/>
        </svg>
    );
}

export default SvgLandscapeIcon;