export class WavPlayer{

    private context : AudioContext;
    private position : number;
    private startTime : number;
    private sampleRate : number;
    private node : AudioBufferSourceNode | null;
    private timer : number | null;

    private positionChangeListener : ((postion : number) => void) | null;
    private playbackEndListener : (() => void) | null;

    constructor(context: AudioContext) {
        this.context = context;
        this.position = 0;
        this.startTime = 0;
        this.sampleRate = 8000;
        this.node = null;
        this.timer = null;
        this.positionChangeListener = null;
        this.playbackEndListener = null;
    }

    setSampleRate(sampleRate : number){
        this.sampleRate = sampleRate;
    }

    setPositionChangeListener(listener : ((position : number) => void) | null){
        this.positionChangeListener = listener;
    }

    setPlaybackEndListener(listener : (() => void) | null){
        this.playbackEndListener = listener;
    }

    private notifyPositionChange(){
        if (this.positionChangeListener){
            this.positionChangeListener(this.position + Math.floor((this.context.currentTime - this.startTime) * this.sampleRate));
        }
    }

    private notifyPlaybackEnd(){
        if (this.playbackEndListener) {
            this.playbackEndListener();
        }
    }

    play(samples : number[], position : number){
        this.position = position < samples.length ? position : 0;
        let buffer = this.context.createBuffer(1, samples.length, this.sampleRate);
        let channel = buffer.getChannelData(0);
        for (let i = 0; i < samples.length; i++) {
            channel[i] = samples[i];
        }
        this.node = this.context.createBufferSource();
        this.node.buffer = buffer;
        this.node.connect(this.context.destination);
        this.node.onended = () => {
            this.stop();
            this.notifyPlaybackEnd();
            this.notifyPositionChange()
        };
        this.node.start(0, this.position / this.sampleRate);
        this.startTime = this.context.currentTime;
        this.timer = window.setInterval(() => {
            this.notifyPositionChange()
        }, 50);
    }

    stop(){
        if (this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
        if (this.node){
            this.node.onended = null;
            this.node.stop();
            this.notifyPositionChange();
            this.node = null;
        }
    }
}