 export enum HeightUnits {
    Centimeters = 1,
    Inches = 2,
    Feet = 3
}

export function parseHeightUnits(units: string): HeightUnits {
    if (units === HeightUnits[HeightUnits.Feet]) {
        return HeightUnits.Feet;
    }
    if (units === HeightUnits[HeightUnits.Inches]) {
        return HeightUnits.Inches;
    }
    return HeightUnits.Centimeters;
}