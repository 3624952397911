import './LtEcgMonitor.css';
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {formatLtEcgTime, parseZonedDateTime} from "../../../helpers/FormatHelper";
import SvgLpfIcon from "../../../assets/svg/LpfIcon";
import SvgHpfIcon from "../../../assets/svg/HpfIcon";
import SvgZoomOutVerticalIcon from "../../../assets/svg/ZoomOutVerticalIcon";
import SvgZoomInVerticalIcon from "../../../assets/svg/ZoomInVerticalIcon";
import SvgZoomOutHorizontalIcon from "../../../assets/svg/ZoomOutHorizontalIcon";
import SvgZoomInHorizontalIcon from "../../../assets/svg/ZoomInHorizontalIcon";
import {useTranslation} from "react-i18next";
import {MV_SCALES, TIME_SCALES} from "../../../ecg/EcgScales";
import {
    ECG_MV_SCALE_INDEX_DEFAULT_VALUE,
    ECG_MV_SCALE_SETTINGS_KEY,
    ECG_TIME_SCALE_INDEX_DEFAULT_VALUE,
    ECG_TIME_SCALE_SETTINGS_KEY,
    usePersistentState
} from "../../../persistance/PersistanceHelper";
import SvgRulerIcon from "../../../assets/svg/RulerIcon";
import {LtEcgMonitor} from "./LtEcgMonitor";
import {LtEcgData} from '../../../models/LtEcgData';
import {LtEcgFileHeader} from "../../../models/LtEcgFileHeader";
import {SvgScrollBackwardIcon} from "../../../assets/svg/SvgScrollBackward";
import {SvgScrollForwardIcon} from "../../../assets/svg/SvgScrollForwardIcon";
import {LtEcgHorizontalScrollbar} from "../LtEcgHorizontalScrollbar/LtEcgHorizontalScrollbar";

export const PX_PER_MM = 3;

interface Props {
    ecgData: LtEcgData | undefined;
    header: LtEcgFileHeader;
    numberOfSamples: number;
    dataRequestCallback: (first: number, last: number) => void;
}

export const LtEcgViewer: React.FC<Props> = ({ecgData, header, numberOfSamples, dataRequestCallback}: Props) => {
    const {t} = useTranslation();
    const container = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [defaultMvScaleIndex] = usePersistentState(ECG_MV_SCALE_SETTINGS_KEY, ECG_MV_SCALE_INDEX_DEFAULT_VALUE);
    const [defaultTimeScaleIndex] = usePersistentState(ECG_TIME_SCALE_SETTINGS_KEY, ECG_TIME_SCALE_INDEX_DEFAULT_VALUE);
    const [mvScaleIndex, setMvScaleIndex] = useState(defaultMvScaleIndex);
    const [timeScaleIndex, setTimeScaleIndex] = useState(defaultTimeScaleIndex);
    const mvScale = useMemo(() => MV_SCALES[mvScaleIndex], [mvScaleIndex]); // eslint-disable-line
    const timeScale = useMemo(() => TIME_SCALES[timeScaleIndex], [timeScaleIndex]); // eslint-disable-line
    const [lpf, setLpf] = useState(true);
    const [hpf, setHpf] = useState(true);
    const [ruler, setRuler] = useState(false);
    const samplesPerScreen = useMemo(() => width / PX_PER_MM / timeScale * header.sensorMetadata.samplingRate, [width, timeScale, header]); // eslint-disable-line
    const lastSample = useMemo(() => Math.min(scrollPosition + (scrollPosition > samplesPerScreen / 2 ? samplesPerScreen / 2 : samplesPerScreen), numberOfSamples - 1), [scrollPosition, samplesPerScreen, numberOfSamples]); // eslint-disable-line
    const firstSample = useMemo(() => Math.max(lastSample - samplesPerScreen, 0), [lastSample, samplesPerScreen]); // eslint-disable-line
    const referenceTime = useMemo(() => header ? parseZonedDateTime(header.record.referenceLocalTime) + header.record.recordStartDelay : 0, [header]);
    useEffect(() => {
        dataRequestCallback(firstSample, lastSample);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstSample, lastSample]);
    useLayoutEffect(() => {
        setWidth(container.current?.clientWidth ?? 0);
    }, [container.current?.clientWidth]); // eslint-disable-line
    const resizeEventListener = () => setWidth(container.current?.clientWidth ?? 0);
    useEffect(() => {
        window.addEventListener("resize", resizeEventListener);
        return (() => {
            window.removeEventListener("resize", resizeEventListener)
        });
    });
    const isMaxVerticalZoom = mvScaleIndex === MV_SCALES.length - 1;
    const isMinVerticalZoom = mvScaleIndex === 0;
    const isMaxHorizontalZoom = timeScaleIndex === TIME_SCALES.length - 1;
    const isMinHorizontalZoom = timeScaleIndex === 0;
    const scrollBackward = () => {
        setScrollPosition(() => Math.max(0, firstSample - samplesPerScreen * 1.25));
    };
    const scrollForward = () => {
        setScrollPosition(() => Math.min(numberOfSamples - samplesPerScreen / 2, firstSample + samplesPerScreen * 1.25));
    };
    return (
        <div className={"lt-ecg-viewer mt-4"} ref={container}>
            {width > 0 &&
                <LtEcgMonitor numberOfSamples={numberOfSamples} samplesPerScreen={samplesPerScreen}
                              firstSample={firstSample} lastSample={lastSample}
                              ecgData={ecgData} header={header} width={width} mvScale={mvScale} timeScale={timeScale}
                              lpf={lpf}
                              hpf={hpf} ruler={ruler}
                              onScrollPositionChanged={(position) => setScrollPosition(position)}/>}
            {width > 0 &&
                <div>
                    <div
                        className="d-flex justify-content-between my-2 lt-ecg-viewer-duration">
                        <span>{formatLtEcgTime(referenceTime + firstSample / header.sensorMetadata.samplingRate * 1000)}</span>
                        <span>{formatLtEcgTime(referenceTime + lastSample / header.sensorMetadata.samplingRate * 1000)}</span>
                    </div>
                    {samplesPerScreen < numberOfSamples &&
                        <LtEcgHorizontalScrollbar width={width} position={firstSample} max={numberOfSamples}
                                                  screen={samplesPerScreen}
                                                  onScrollPositionChanged={(position) => setScrollPosition(position)}
                                                  samplingRate={header.sensorMetadata.samplingRate}
                                                  referenceTime={referenceTime}/>
                    }
                </div>
            }
            {width > 0 &&
                <div className="d-flex justify-content-center my-4">
                    <div className="d-flex justify-content-center mr-2">
                        <div className={`lt-ecg-viewer-button ${lpf ? "active" : ""}`} onClick={() => setLpf(!lpf)}>
                            <SvgLpfIcon/><span>{t("hpf_tooltip")}</span></div>
                        <div className={`lt-ecg-viewer-button ${hpf ? "active" : ""}`} onClick={() => setHpf(!hpf)}>
                            <SvgHpfIcon/><span>{t("lpf_tooltip")}</span></div>
                        <div className={`lt-ecg-viewer-button ${isMaxVerticalZoom ? "active" : ""}`}
                             onClick={() => setMvScaleIndex(Math.min(MV_SCALES.length - 1, mvScaleIndex + 1))}>
                            <SvgZoomOutVerticalIcon/><span>{t("mv_zoom_out_tooltip")}</span></div>
                        <div className={`lt-ecg-viewer-button ${isMinVerticalZoom ? "active" : ""}`}
                             onClick={() => setMvScaleIndex(Math.max(0, mvScaleIndex - 1))}>
                            <SvgZoomInVerticalIcon/><span>{t("mv_zoom_in_tooltip")}</span></div>
                        <div className={`lt-ecg-viewer-button ${isMaxHorizontalZoom ? "active" : ""}`}
                             onClick={() => setTimeScaleIndex(Math.min(TIME_SCALES.length - 1, timeScaleIndex + 1))}>
                            <SvgZoomOutHorizontalIcon/><span>{t("time_zoom_out_tooltip")}</span></div>
                        <div className={`lt-ecg-viewer-button ${isMinHorizontalZoom ? "active" : ""}`}
                             onClick={() => setTimeScaleIndex(Math.max(0, timeScaleIndex - 1))}>
                            <SvgZoomInHorizontalIcon/><span>{t("time_zoom_in_tooltip")}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mx-2">
                        <div className={`lt-ecg-viewer-button`}
                             onClick={scrollBackward}>
                            <SvgScrollBackwardIcon/><span>{t("scroll_backward")}</span>
                        </div>
                        <div className={`lt-ecg-viewer-button`}
                             onClick={scrollForward}>
                            <SvgScrollForwardIcon/><span>{t("scroll_forward")}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mx-2">
                        <div className={`lt-ecg-viewer-button ${ruler ? "active" : ""}`}
                             onClick={() => setRuler(!ruler)}>
                            <SvgRulerIcon/><span>{t("ruler_tooltip")}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}