import './SpiroViewer.css';
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {formatTimestamp} from "../../../helpers/FormatHelper";
import SvgPrintIcon from "../../../assets/svg/PrintIcon";
import SvgSaveIcon from "../../../assets/svg/SaveIcon";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import {downloadSpiroPdf, printSpiroPdf} from "../../../api/ApiHelper";
import {Toast} from "../Toast/Toast";
import {useTranslation} from "react-i18next";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import {SpiroData} from "../../../models/SpiroData";
import {SpiroReportSettings} from '../../../models/SpiroReportSettings';
import LandscapeIcon from "../../../assets/svg/LandscapeIcon";
import PortraitIcon from "../../../assets/svg/PortraitIcon";
import {SpiroMonitor} from "./SpiroMonitor";
import {makeSpiroChartData} from "./SpiroChartData";
import {SpiroResultsTable} from "./SpiroResultsTable";
import CancelIconSvg from "../../../assets/svg/CancelIcon";
import CheckIconSvg from "../../../assets/svg/CheckIcon";
import {useHistory, useLocation} from "react-router";
import {makeSpiroConclusion} from "../../../spr/SpiroConclusion";

export const PX_PER_MM = 3;

const VIEW = 0;
const SAVE = 1;
const PRINT = 2;

interface Props {
    spiroData: SpiroData;
    activeRecordIndex: number;
    recordId: string;
    studyId: string;
    isSupport: boolean;
}

export const SpiroViewer: React.FC<Props> = ({spiroData, activeRecordIndex, recordId, studyId, isSupport}: Props) => {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const history = useHistory();
    const location = useLocation();
    const [buttonMode, setButtonMode] = useState(VIEW);
    const [isLandscape, setLandscape] = useState(false);
    const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState(false);
    const [hasPdfError, setPdfErrorState] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const plots = useMemo(() => {
        return spiroData.records.map(r => makeSpiroChartData(spiroData.header.sampleRate, spiroData.header.unitsPerLiter, r.volumes));
    }, [spiroData]);
    const bestRecordIndex = useMemo(() => {
        let idx = 0;
        let bestIdx = 0;
        let fvc = Number.MIN_VALUE;
        for (const record of spiroData.records) {
            if (record.result.fvcResult) {
                if (record.result.fvcResult.fvc.measured > fvc) {
                    fvc = record.result.fvcResult.fvc.measured;
                    bestIdx = idx;
                }
            }
            idx++;
        }
        return bestIdx;
    }, [spiroData]);
    const recordIndex = useMemo(() => {
        if (activeRecordIndex > 0){
            return activeRecordIndex - 1;
        } else {
            return bestRecordIndex;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRecordIndex, bestRecordIndex, spiroData]);
    if (recordIndex < 0 && recordIndex >= spiroData.records.length){
        const newLocation = location.pathname.replace(/\d+$/gm, '0');
        history.replace(newLocation);
    }
    const data = useMemo(() => {
        return plots[recordIndex];
    }, [plots, recordIndex]);
    const reference = useMemo(() => {
        const array = [];
        if (activeRecordIndex > 0) {
            array.push(plots[bestRecordIndex]);
        } else {
            for (let i = 0; i < plots.length; i++) {
                if (i !== bestRecordIndex) {
                    array.push(plots[i]);
                }
            }
        }
        return array;
    }, [plots, activeRecordIndex, bestRecordIndex]);
    const expected = useMemo(() => {
        const result = spiroData.records[recordIndex].result;
        const fvc = result.fvcResult?.fvc?.predicted;
        const fev1 = result.fvcResult?.fev1?.predicted;
        const fef2575 = result.fvcResult?.fef2575?.predicted;
        const pef = result.fvcResult?.pef?.predicted;
        if (fvc && fev1 && fef2575 && pef){
            const points = [];
            points.push({x: 0, y: 0});
            points.push({x: 0.12 * fev1, y: pef});
            points.push({x: fvc / 2, y: fef2575});
            points.push({x: fvc, y: 0});
            return points;
        }
        return undefined;
    }, [spiroData, recordIndex]);
    useLayoutEffect(() => {
        setWidth(container.current?.clientWidth ?? 0);
    }, [container.current?.clientWidth]); // eslint-disable-line
    const resizeEventListener = () => setWidth(container.current?.clientWidth ?? 0);
    useEffect(() => {
        window.addEventListener("resize", resizeEventListener);
        return (() => {
            window.removeEventListener("resize", resizeEventListener)
        });
    });
    const notifyPdfGenerationInProgress = () => {
        setPdfErrorState(false);
        setPdfGenerationInProgress(false);
        setPdfGenerationInProgress(true);
    }
    const pdfErrorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setPdfErrorState(false);
            setPdfGenerationInProgress(false);
            setPdfErrorState(true);
        }
    };
    const applySaveSettings = () => {
        if (token) {
            const settings = {
                recordId: recordId,
                studyId: studyId,
                isLandscape: isLandscape,
                language: t("ui_language")
            } as SpiroReportSettings;
            const name = formatTimestamp(Date.now());
            notifyPdfGenerationInProgress();
            if (buttonMode === SAVE) {
                downloadSpiroPdf(token.token, settings, name, controller, () => {
                }, pdfErrorHandler);
            }
            if (buttonMode === PRINT) {
                printSpiroPdf(token.token, settings, controller, () => {
                }, pdfErrorHandler);
            }
            setButtonMode(VIEW);
        }
    };
    const interpretation = makeSpiroConclusion(t, spiroData.briefResult);
    return (
        <div className="d-flex flex-column justify-content-center align-self-stretch">
            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center">
                <div className={"spiro-viewer mx-4 my-4 align-self-start"} ref={container}>
                    {width > 0 &&
                        <SpiroMonitor size={width} data={data} reference={reference} expected={expected}/>
                    }
                </div>
                <SpiroResultsTable data={spiroData} activeRecordIndex={activeRecordIndex} isSupport={isSupport}/>
            </div>
            <div className='align-self-center interpretation-title'>{t('interpretation')}</div>
            <div className='align-self-center interpretation-text'>{interpretation}</div>
            {width > 0 && buttonMode !== VIEW &&
                <div
                    className="d-flex justify-content-center mt-4">{t(buttonMode === SAVE ? "setup_save_options" : "setup_print_options")}</div>
            }
            {width > 0 &&
                <div className="d-flex justify-content-center my-4">
                    {buttonMode === VIEW &&
                        <div className="d-flex justify-content-center ml-2">
                            <div className="spiro-viewer-button" onClick={() => setButtonMode(PRINT)}>
                                <SvgPrintIcon/><span>{t("print_tooltip")}</span></div>
                            <div className="spiro-viewer-button" onClick={() => setButtonMode(SAVE)}>
                                <SvgSaveIcon/><span>{t("download_tooltip")}</span>
                            </div>
                        </div>
                    }
                    {buttonMode !== VIEW &&
                        <div className="d-flex justify-content-center">
                            <div className="d-flex justify-content-center mx-2">
                                <div className={`spiro-viewer-button ${isLandscape ? "active" : ""}`}
                                     onClick={() => setLandscape(true)}>
                                    <LandscapeIcon/><span>{t("landscape")}</span></div>
                                <div className={`spiro-viewer-button ${!isLandscape ? "active" : ""}`}
                                     onClick={() => setLandscape(false)}>
                                    <PortraitIcon/><span>{t("portrait")}</span></div>
                            </div>
                            <div className="d-flex justify-content-center ml-2">
                                <div className={"spiro-viewer-button"} onClick={() => setButtonMode(VIEW)}>
                                    <CancelIconSvg/><span>{t("cancel")}</span></div>
                                <div className={"spiro-viewer-button"} onClick={applySaveSettings}>
                                    <CheckIconSvg/><span>{t("apply")}</span></div>
                            </div>
                        </div>
                    }
                </div>
            }
            {pdfGenerationInProgress &&
                <Toast text={t("pdf_generation_in_progress")} isError={false}/>
            }
            {hasPdfError &&
                <Toast text={t("error_pdf_generation")} isError={true}/>
            }
        </div>
    );
}