import React from "react";
import {useHistory} from "react-router";
import {useAccessInfo} from "../../../hooks";
import {Role} from "../../../models/Role";
import {MY_PATIENTS_ROUTE, MY_RECORDS_ROUTE, PATIENTS_ROUTE, RECORDS_ROUTE, USERS_ROUTE} from "../../../routes";
import {RECENT_LIST_PAGE_SIZE} from "../../../settings";
import {RecentPatients} from "../../Widgets/RecentPatients/RecentPatients";
import {RecentRecords} from "../../Widgets/RecentRecords/RecentRecords";
import {RecentUsers} from "../../Widgets/RecentUsers/RecentUsers";
import './Dashboard.css';
import {StudyType} from "../../Widgets/StudyIcon/StudyIcon";

function Dashboard() {
    const history = useHistory();
    const isSupport = useAccessInfo(Role.Support);
    const allPatientsClickHandler = () => {
        history.push(isSupport ? PATIENTS_ROUTE : MY_PATIENTS_ROUTE);
    };
    const allRecordsClickHandler = () => {
        history.push(isSupport ? RECORDS_ROUTE : MY_RECORDS_ROUTE);
    };
    const allUsersClickHandler = () => {
        history.push(USERS_ROUTE);
    };
    const patientClickHandler = (id: string) => {
        history.push(`${isSupport ? PATIENTS_ROUTE : MY_PATIENTS_ROUTE}/${id}`);
    };
    const recordClickHandler = (id: string) => {
        history.push(`${isSupport ? RECORDS_ROUTE : MY_RECORDS_ROUTE}/${id}`);
    };
    const studyClickHandler = (id: string, type: StudyType) => {
        switch (type) {
            case StudyType.State:
                recordClickHandler(id);
                break;
            case StudyType.Ecg:
                history.push(`${isSupport ? RECORDS_ROUTE : MY_RECORDS_ROUTE}/${id}/ecg`)
                break;
            case StudyType.Stethoscope:
                history.push(`${isSupport ? RECORDS_ROUTE : MY_RECORDS_ROUTE}/${id}/stethoscope`)
                break;
            case StudyType.Spiro:
                history.push(`${isSupport ? RECORDS_ROUTE : MY_RECORDS_ROUTE}/${id}/spiro`)
                break;
            case StudyType.LtEcg:
                recordClickHandler(id);
                break;
        }
    };
    const userClickHandler = (id: string) => {
        history.push(`${USERS_ROUTE}/${id}`);
    };
    return (
        <div className="dashboard-container">
            <div className="dashboard-item">
                <RecentRecords limit={RECENT_LIST_PAGE_SIZE} recordClickHandler={recordClickHandler}
                               allRecordsClickHandler={allRecordsClickHandler} studyClickHandler={studyClickHandler}/>
            </div>
            <div className="dashboard-item">
                <RecentPatients limit={RECENT_LIST_PAGE_SIZE} patientClickHandler={patientClickHandler}
                                allPatientsClickHandler={allPatientsClickHandler}/>
            </div>
            {isSupport &&
            <div className="dashboard-item">
                <RecentUsers limit={RECENT_LIST_PAGE_SIZE} userClickHandler={userClickHandler}
                             allUsersClickHandler={allUsersClickHandler}/>
            </div>
            }
        </div>
    );
}

export default Dashboard;