import {matchPath, useHistory, useLocation} from 'react-router';
import SvgHomeIcon from '../../../assets/svg/HomeIcon';
import * as AppRoutes from '../../../routes';
import './Breadcrumbs.css';
import {TFunction, useTranslation} from "react-i18next";
import React from "react";

interface BreadcrumbsProps {
    data?: Map<string, string | null>;
}

function buildBreadcrumbs(t: TFunction<"translation">, path: string) {
    let breadcrumbs: Array<{ name: string, route: string, needToReplace: boolean }> = [];
    let pathData;
    pathData = matchPath(path, {
        path: AppRoutes.MY_PATIENTS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string }>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string }>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/share`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: t("access_control"), route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/share`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/share`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("access_control"), route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/share`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("ecg"), route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/lt-ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("lt_ecg"), route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/lt-ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/spiro/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("spiro"), route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/spiro`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string, tabIndex: string}>(path, {
        path: `${AppRoutes.MY_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_patients"), route: AppRoutes.MY_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("stethoscope"), route: `${AppRoutes.MY_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/stethoscope/${pathData.params.tabIndex}`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath(path, {
        path: AppRoutes.SHARED_PATIENTS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string }>(path, {
        path: `${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string }>(path, {
        path: `${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/share`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: t("access_control"), route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/share`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("ecg"), route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/lt-ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("lt_ecg"), route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/lt-ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/spiro/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("spiro"), route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/spiro`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string, tabIndex: string}>(path, {
        path: `${AppRoutes.SHARED_PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_patients"), route: AppRoutes.SHARED_PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("stethoscope"), route: `${AppRoutes.SHARED_PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/stethoscope/${pathData.params.tabIndex}`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath(path, {
        path: AppRoutes.MY_RECORDS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_records"), route: AppRoutes.MY_RECORDS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.MY_RECORDS_ROUTE}/:recordId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_records"), route: AppRoutes.MY_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.MY_RECORDS_ROUTE}/:recordId/share`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_records"), route: AppRoutes.MY_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("access_control"), route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}/share`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.MY_RECORDS_ROUTE}/:recordId/ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_records"), route: AppRoutes.MY_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("ecg"), route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}/ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.MY_RECORDS_ROUTE}/:recordId/lt-ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_records"), route: AppRoutes.MY_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("lt_ecg"), route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}/lt-ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.MY_RECORDS_ROUTE}/:recordId/spiro/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_records"), route: AppRoutes.MY_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("spiro"), route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}/spiro`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string, tabIndex: string }>(path, {
        path: `${AppRoutes.MY_RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("my_records"), route: AppRoutes.MY_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("stethoscope"), route: `${AppRoutes.MY_RECORDS_ROUTE}/${pathData.params.recordId}/stethoscope/${pathData.params.tabIndex}`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath(path, {
        path: AppRoutes.SHARED_RECORDS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_records"), route: AppRoutes.SHARED_RECORDS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_records"), route: AppRoutes.SHARED_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/share`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_records"), route: AppRoutes.SHARED_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("access_control"), route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}/share`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_records"), route: AppRoutes.SHARED_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("ecg"), route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}/ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/lt-ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_records"), route: AppRoutes.SHARED_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("lt_ecg"), route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}/lt-ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/spiro/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_records"), route: AppRoutes.SHARED_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("spiro"), route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}/spiro`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string, tabIndex: string }>(path, {
        path: `${AppRoutes.SHARED_RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("shared_records"), route: AppRoutes.SHARED_RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("stethoscope"), route: `${AppRoutes.SHARED_RECORDS_ROUTE}/${pathData.params.recordId}/stethoscope/${pathData.params.tabIndex}`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath(path, {
        path: AppRoutes.PATIENTS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("patients"), route: AppRoutes.PATIENTS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string }>(path, {
        path: `${AppRoutes.PATIENTS_ROUTE}/:patientId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("patients"), route: AppRoutes.PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("patients"), route: AppRoutes.PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        return breadcrumbs;
    }

    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("patients"), route: AppRoutes.PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("ecg"), route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/lt-ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("patients"), route: AppRoutes.PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("lt_ecg"), route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/lt-ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string }>(path, {
        path: `${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/spiro/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("patients"), route: AppRoutes.PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("spiro"), route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/spiro`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ patientId: string, recordId: string, tabIndex: string }>(path, {
        path: `${AppRoutes.PATIENTS_ROUTE}/:patientId/:recordId/stethoscope/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("patients"), route: AppRoutes.PATIENTS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.patientId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}`, needToReplace: true });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("stethoscope"), route: `${AppRoutes.PATIENTS_ROUTE}/${pathData.params.patientId}/${pathData.params.recordId}/stethoscope/${pathData.params.tabIndex}`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath(path, {
        path: AppRoutes.RECORDS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("records"), route: AppRoutes.RECORDS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.RECORDS_ROUTE}/:recordId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("records"), route: AppRoutes.RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        return breadcrumbs;
    }

    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.RECORDS_ROUTE}/:recordId/ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("records"), route: AppRoutes.RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("ecg"), route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}/ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.RECORDS_ROUTE}/:recordId/lt-ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("records"), route: AppRoutes.RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("lt_ecg"), route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}/lt-ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string }>(path, {
        path: `${AppRoutes.RECORDS_ROUTE}/:recordId/spiro/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("records"), route: AppRoutes.RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("spiro"), route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}/spiro`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ recordId: string, tabIndex: string }>(path, {
        path: `${AppRoutes.RECORDS_ROUTE}/:recordId/stethoscope/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("records"), route: AppRoutes.RECORDS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.recordId, route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}`, needToReplace: true });
        breadcrumbs.push({ name: t("stethoscope"), route: `${AppRoutes.RECORDS_ROUTE}/${pathData.params.recordId}/stethoscope/${pathData.params.tabIndex}`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath<{ token: string }>(path, {
        path: `${AppRoutes.GUEST_ROUTE}/:token`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: pathData.params.token, route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}`, needToReplace: true });
        return breadcrumbs;
    }

    pathData = matchPath<{ token: string }>(path, {
        path: `${AppRoutes.GUEST_ROUTE}/:token/ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: pathData.params.token, route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}`, needToReplace: true });
        breadcrumbs.push({ name: t("ecg"), route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}/ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ token: string }>(path, {
        path: `${AppRoutes.GUEST_ROUTE}/:token/lt-ecg`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: pathData.params.token, route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}`, needToReplace: true });
        breadcrumbs.push({ name: t("lt_ecg"), route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}/lt-ecg`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ token: string }>(path, {
        path: `${AppRoutes.GUEST_ROUTE}/:token/spiro/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: pathData.params.token, route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}`, needToReplace: true });
        breadcrumbs.push({ name: t("spiro"), route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}/spiro`, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ token: string, tabIndex: string }>(path, {
        path: `${AppRoutes.GUEST_ROUTE}/:token/stethoscope/:tabIndex`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: pathData.params.token, route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}`, needToReplace: true });
        breadcrumbs.push({ name: t("stethoscope"), route: `${AppRoutes.GUEST_ROUTE}/${pathData.params.token}/stethoscope/${pathData.params.tabIndex}`, needToReplace: false });
        return breadcrumbs;
    }

    pathData = matchPath(path, {
        path: AppRoutes.USERS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("users"), route: AppRoutes.USERS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath<{ userId: string }>(path, {
        path: `${AppRoutes.USERS_ROUTE}/:userId`,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("users"), route: AppRoutes.USERS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: pathData.params.userId, route: `${AppRoutes.USERS_ROUTE}/${pathData.params.userId}`, needToReplace: true });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.PROFILE_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("profile"), route: AppRoutes.PROFILE_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.SETTINGS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("settings"), route: AppRoutes.SETTINGS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.ACCESS_CONTROL_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("settings"), route: AppRoutes.SETTINGS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: t("access_control"), route: AppRoutes.ACCESS_CONTROL_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.NOTIFICATION_SETTINGS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("settings"), route: AppRoutes.SETTINGS_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: t("notification_settings"), route: AppRoutes.NOTIFICATION_SETTINGS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.ADMIN_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("administration"), route: AppRoutes.ADMIN_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.STATISTIC_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("administration"), route: AppRoutes.ADMIN_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: t("statistic"), route: AppRoutes.STATISTIC_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.REMOVE_DEMO_DATA_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("administration"), route: AppRoutes.ADMIN_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: t("demo_data"), route: AppRoutes.REMOVE_DEMO_DATA_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    pathData = matchPath(path, {
        path: AppRoutes.REMOVE_INACTIVE_USERS_ROUTE,
        exact: true
    });
    if (pathData) {
        breadcrumbs.push({ name: t("administration"), route: AppRoutes.ADMIN_ROUTE, needToReplace: false });
        breadcrumbs.push({ name: t("inactive_users"), route: AppRoutes.REMOVE_INACTIVE_USERS_ROUTE, needToReplace: false });
        return breadcrumbs;
    }
    return breadcrumbs;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({data = new Map() }: BreadcrumbsProps) => {
    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const breadcrumbs = buildBreadcrumbs(t, location.pathname);
    const breadcrumbsComponents = breadcrumbs.flatMap((bc, i) => [<span key={`bc-d-${i}`} className="breadcrumbs-divider">/</span>, <span key={`bc-${bc.route}`} className={`breadcrumbs-link ${i === breadcrumbs.length - 1 ? "breadcrumbs-active" : ""}`} onClick={() => history.push(bc.route)}>{bc.needToReplace ? (data.has(bc.name) ? data.get(bc.name) : null) ?? "..." : bc.name}</span>])
    return (
        <div className="breadcrumbs">
            <div className="breadcrumbs-link d-flex" onClick={() => history.push("")}><SvgHomeIcon /> {t("home")}</div>
            {breadcrumbsComponents}
        </div>
    );
}