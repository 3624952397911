import React from "react";
import './ListPagination.css';

interface ListPaginationProps {
    page: number;
    totalPages: number;
    clickHandler: (page: number) => void;
}

export const ListPagination: React.FC<ListPaginationProps> = ({ page, totalPages, clickHandler }: ListPaginationProps) => {
    return (
        <div className="list-pagination">
            <div className={`list-pagination-item ${page > 1 ? "" : "hidden"} disable-select`} onClick={() => clickHandler(1)}>&#8810;</div>
            <div className={`list-pagination-item ${page > 1 ? "" : "hidden"} disable-select`} onClick={() => clickHandler(page - 1)}>&lt;</div>
            <div className={`list-pagination-item ${page - 2 > 1 ? "" : "hidden"} disable-select`} onClick={() => clickHandler(page - 2)}>{page - 2}</div>
            <div className={`list-pagination-item ${page - 1 > 1 ? "" : "hidden"} disable-select`} onClick={() => clickHandler(page - 1)}>{page - 1}</div>
            <div className={`list-pagination-item active disable-select`}>{page}</div>
            <div className={`list-pagination-item ${page + 1 <= totalPages ? "" : "hidden"} disable-select`} onClick={() => clickHandler(page + 1)}>{page + 1}</div>
            <div className={`list-pagination-item ${page + 2 <= totalPages ? "" : "hidden"} disable-select`} onClick={() => clickHandler(page + 2)}>{page + 2}</div>
            <div className={`list-pagination-item ${page < totalPages ? "" : "hidden"} disable-select`} onClick={() => clickHandler(page + 1)}>&gt;</div>
            <div className={`list-pagination-item ${page < totalPages ? "" : "hidden"} disable-select`} onClick={() => clickHandler(totalPages)}>&#8811;</div>
        </div>
    );
}