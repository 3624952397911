import {useAbortController, useAppDispatch} from "../../../hooks";
import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {Record} from "../../../models/Record";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import * as ErrorCodes from "../../../api/ErrorCodes";
import * as ApiHelper from "../../../api/ApiHelper";
import AsyncIndicator from "../../Widgets/AsyncIndicator/AsyncIndicator";
import {Breadcrumbs} from "../../Widgets/Breadcrumbs/Breadcrumbs";
import {formatDateTime} from "../../../helpers/FormatHelper";
import {FetchError} from "../../Widgets/FetchError/FetchError";
import NotFound from "../../Widgets/NotFound/NotFound";
import {StethoscopeData} from "../../../models/StethoscopeData";
import {stethoscopeTestData} from "../../../sts/StethoscopeTest";
import {WaveFile} from "wavefile";
import {StethoscopeViewer} from "../../Widgets/StethoscopeViewer/StethoscopeViewer";
import {StethoscopeDetailsTable} from "../../Widgets/StethoscopeDetailsTable/StethoscopeDetailsTable";
import {useHistory, useLocation} from "react-router";
import {
    STETHOSCOPE_TIME_SCALE_INDEX_DEFAULT_VALUE,
    STETHOSCOPE_TIME_SCALE_SETTINGS_KEY,
    usePersistentState
} from "../../../persistance/PersistanceHelper";
import {Conclusion} from "../../Widgets/Conclusion/Conclusion";
import {PatientInfo} from "../../Widgets/PatientInfo/PatientInfo";
import {STETHOSCOPE_COMPAND_DEFAULT_STATE} from "../../../settings";
import {logError} from "../../../helpers/LogHelper";

interface PathParams {
    token: string;
    tabIndex: string;
}

export const GuestStethoscopeView: React.FC = () => {
    const wavFiles = useMemo(() => new Map<string, WaveFile>(), []);
    const history = useHistory();
    const location = useLocation();
    const {token, tabIndex} = useParams<PathParams>();
    const activeTabIndex = (Number(tabIndex ?? "1") ?? 1) - 1;
    const setActiveTabIndex = (index: number) => {
        const newLocation = location.pathname.replace(/\d+$/gm, `${index + 1}`);
        history.replace(newLocation);
    }
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [isFetching, setFetchingState] = useState(true);
    const [hasError, setErrorState] = useState(false);
    const [notFound, setNotFoundState] = useState(false);
    const [record, setRecord] = useState(null as Record | null);
    const [initialConclusion, setInitialConclusion] = useState(null as null | string);
    const [data, setData] = useState(null as StethoscopeData | null);
    const [compand, setCompand] = useState(STETHOSCOPE_COMPAND_DEFAULT_STATE);
    const [defaultTimeScaleIndex] = usePersistentState(STETHOSCOPE_TIME_SCALE_SETTINGS_KEY, STETHOSCOPE_TIME_SCALE_INDEX_DEFAULT_VALUE);
    const [timeScaleIndex, setTimeScaleIndex] = useState(defaultTimeScaleIndex);
    const wavHandler = (fileId: string, data: Blob) => {
        data.arrayBuffer().then(buffer => {
            const file = new WaveFile();
            file.fromBuffer(new Uint8Array(buffer));
            wavFiles.set(fileId, file);
            setUpdateTrigger(updateTrigger + 1);
        });
    };
    const dataHandler = (data: StethoscopeData) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setActiveTabIndex(0);
        setData(data);
    };
    const recordHandler = (record: Record) => {
        setErrorState(false);
        setNotFoundState(false);
        setRecord(record);
        setInitialConclusion(record.stethoscopeStudy.stethoscopeConclusion ?? "");
    };
    const errorHandler = (error: ErrorResponse) => {
        logError("Stethoscope data fetch error", error);
        if (!handleErrors(error, dispatch)) {
            if (error.ResultCode === ErrorCodes.NotFound) {
                setNotFoundState(true);
                setErrorState(false);
            } else {
                setErrorState(true);
                setNotFoundState(false);
            }
            setFetchingState(false);
        }
    };
    const fetchWav = (fileId: string) => {
        if (process.env.NODE_ENV === 'development') {
            // For testing
            fetch(`/test/${fileId}`).then(result => result.blob().then(blob => wavHandler(fileId, blob)));
        } else {
            //Main code
            if (record) {
                ApiHelper.getRawDataWithToken(token, fileId, controller, (blob) => wavHandler(fileId, blob), errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchData = (record: Record) => {
        if (process.env.NODE_ENV === 'development') {
            // For testing
            dataHandler(stethoscopeTestData);
        } else {
            //Main code
            if (record.stethoscopeStudy) {
                setFetchingState(true);
                ApiHelper.getStethoscopeDataWithToken(token, record.stethoscopeStudy.stethoscopeId, controller, dataHandler, errorHandler);
            } else {
                setErrorState(true);
            }
        }
    };
    const fetchRecord = () => {
        setFetchingState(true);
        ApiHelper.getRecordWithToken(token, controller, recordHandler, errorHandler);
    };
    useEffect(() => {
        if (data) {
            const id = data.records[activeTabIndex]?.fileId;
            if (id) {
                if (!wavFiles.has(id)) {
                    fetchWav(id);
                }
            }
        }
    }, [activeTabIndex, data, wavFiles]);     // eslint-disable-line
    useEffect(() => fetchRecord(), [token]);     // eslint-disable-line
    useEffect(() => {
        if (record) {
            fetchData(record);
        }
    }, [record]);     // eslint-disable-line
    const isOk = !hasError && !notFound && !isFetching;
    const activeWav = wavFiles.get(data?.records[activeTabIndex]?.fileId ?? "");
    const tabButtonClickHandler: (index: number) => void = (index) => {
        if (index !== activeTabIndex) {
            setActiveTabIndex(index);
        }
    }
    const buttons = data?.records.map((r, i) => <div key={`b-${i}`}
                                                     className={`tab-round-button ${i === activeTabIndex ? "tab-round-button-selected" : ""}`}
                                                     onClick={() => tabButtonClickHandler(i)}>{i + 1}</div>);
    return (
        <div>
            <Breadcrumbs
                data={new Map([[token, record ? formatDateTime(record.dateTime) : null], [record?.patientId ?? "_", record?.patientName ?? null]])}/>
            {isFetching && <AsyncIndicator/>}
            {!isFetching && hasError && <FetchError onRetry={fetchRecord}/>}
            {!isFetching && notFound && <NotFound/>}
            {(isOk && record && data) &&
                <div className="stethoscope-data-container">
                    <PatientInfo record={record}/>
                    {buttons &&
                        <div className="d-flex justify-content-center mt-4 mb-2">
                            {buttons}
                        </div>
                    }
                    <div
                        className="d-flex justify-content-center data-list-title my-2">{data.records[activeTabIndex].locationDescription}</div>
                    {activeWav !== undefined ?
                        <StethoscopeViewer record={record} stethoscopeRecord={data.records[activeTabIndex]}
                                           wavData={activeWav} compand={compand} timeScaleIndex={timeScaleIndex}
                                           compandChangeHandler={(state) => setCompand(state)}
                                           timeScaleIndexChangeHandler={(index) => setTimeScaleIndex(index)}/> :
                        <AsyncIndicator/>
                    }
                </div>
            }
            {(isOk && record && data) &&
                <StethoscopeDetailsTable record={record} data={data} isSupport={false}/>
            }
            {(isOk && record) &&
                <Conclusion initialValue={initialConclusion ?? ""} cachedValue={initialConclusion ?? ""}/>}
        </div>
    );
};

export default GuestStethoscopeView;