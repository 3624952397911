import React from "react";

function SvgMailIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.188 5.602a.232.232 0 00-.08-.175c-.762-.667-.77-.747-4.556-3.51C8.102 1.59 7.29.856 6.688.856c-.603 0-1.408.726-1.865 1.06C1.036 4.68 1.03 4.76.267 5.426a.232.232 0 00-.08.175v7.094c0 .639.523 1.161 1.161 1.161h10.679c.638 0 1.16-.522 1.16-1.16V5.601zM9.096 9.845c-.537.392-1.45 1.226-2.409 1.226-.95 0-1.842-.82-2.408-1.226a211.597 211.597 0 01-2.503-1.828.223.223 0 01-.043-.319l.275-.377a.232.232 0 01.327-.044c.573.421 1.356.994 2.488 1.814.45.326 1.262 1.052 1.864 1.052.603 0 1.415-.726 1.865-1.052 1.132-.813 1.915-1.393 2.488-1.814a.232.232 0 01.327.044l.275.377a.223.223 0 01-.043.32 211.91 211.91 0 01-2.503 1.827z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgMailIcon;
