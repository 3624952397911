import moment from "moment";
import { Age } from "../models/Age";

export function calculateAge(reference: Date): Age {
    let now = moment();
    let ref = moment(reference);
    return {
        years: now.diff(ref, 'years'),
        months: now.diff(ref, 'months') % 12,
        days: 0
    };
}