import React from 'react';
import {formatAccountState, formatBirthdate, formatConfirmationState, formatRole} from '../../../helpers/FormatHelper';
import {User} from '../../../models/User';
import {useTranslation} from "react-i18next";
import {CheckBox} from "../CheckBox/CheckBox";


interface UsersProps {
    user: User;
    onClick: () => void;
    isChecked?: boolean;
    onCheck?: (isChecked: boolean) => void;
}

const UsersLong: React.FC<UsersProps> = ({ user, onClick, isChecked, onCheck }: UsersProps) => {
    const {t} = useTranslation();
    return (
        <tr onClick={onCheck ? undefined : onClick}>
            {onCheck &&
                <td align="center">
                    <CheckBox title={""} value={isChecked ?? false} onChange={onCheck}/>
                </td>
            }
            <td align="center" onClick={onCheck ? onClick : undefined}>{user.email}</td>
            <td align="center" onClick={onCheck ? onClick : undefined}>{formatRole(t, user.role)}</td>
            <td align="center" onClick={onCheck ? onClick : undefined}>{user.name}</td>
            <td align="center" onClick={onCheck ? onClick : undefined}>{formatBirthdate(user.registrationDate)}</td>
            <td align="center" onClick={onCheck ? onClick : undefined}>{formatConfirmationState(t, user.emailConfirmed)}</td>
            <td align="center" onClick={onCheck ? onClick : undefined}>{formatAccountState(t, user.accountActive)}</td>
        </tr>
        );
}

export default UsersLong;