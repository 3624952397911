import * as React from "react";

function SvgPlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.122 4.987c.19-.104.19-.276 0-.38L.971.077C.78-.028.627.064.627.279v9.036c0 .215.153.307.344.202l8.151-4.53z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPlayIcon;
