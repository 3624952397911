import React from "react";
import {useTranslation} from "react-i18next";
import {formatAccountState} from "../../../helpers/FormatHelper";

interface AccountStatusDropdownProps {
    className?: string;
    initialState: boolean;
    disabled: boolean;
    onChange: (value: boolean) => void;
}

export const AccountStatusDropdown: React.FC<AccountStatusDropdownProps> = ({ className = "", initialState, disabled, onChange }: AccountStatusDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} disabled={disabled} defaultValue={initialState ? "t" : "f"} onChange={(e) => onChange(e.target.value === 't')}>
            <option value='t'>{formatAccountState(t, true)}</option>
            <option value='f'>{formatAccountState(t, false)}</option>
        </select>
    );
}