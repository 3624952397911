import {parseInt16, parseUint16, parseUint32, parseUint8} from "../helpers/BinaryHelper";
import {BaseScpEcgExtractor} from "./BaseScpEcg";

const MAX_VECTORS = 4096;

const BEECARDIA_DATA_RAW16 = 1;
const BEECARDIA_DATA_DRV2 = 3;
const BEECARDIA_DATA_DRV2_16BIT = 6;
const BEECARDIA_DATA_UINT32 = 2;

interface BlockHeader {
    crc: number;
    blockSize: number;
    blockNumber: number;
    startSampleNumber: number;
    samplesInBlock: number;
    numberOfChannels: number;
    dataPackingMethod: number;
    leadOffStatus: number;
}

export class BeecardiaScpEcgExtractor extends BaseScpEcgExtractor {

    public static parseHeader(buffer: ArrayBuffer): BlockHeader {
        return {
            crc: parseUint16(buffer, 0),
            blockSize: parseInt16(buffer, 2),
            blockNumber: parseUint32(buffer, 4),
            startSampleNumber: parseUint32(buffer, 8),
            samplesInBlock: parseInt16(buffer, 12),
            numberOfChannels: parseUint8(buffer, 14),
            dataPackingMethod: parseUint8(buffer, 15),
            leadOffStatus: parseInt16(buffer, 16),
        };
    }

    public extractData(): Array<number> {
        const header = BeecardiaScpEcgExtractor.parseHeader(this.block);
        this.bsDrv.bufferIndex -= 2;
        this.bsRaw.bufferIndex -= 2;
        switch (header.dataPackingMethod) {
            case BEECARDIA_DATA_DRV2:
                return this.unpackDrv(header,12);
            case BEECARDIA_DATA_DRV2_16BIT:
                return this.unpackDrv(header,16);
            case BEECARDIA_DATA_RAW16:
                return this.unpackRaw16(header);
            case BEECARDIA_DATA_UINT32:
                return this.unpackRaw32(header);
        }
        return [];
    }

    private unpackDrv(header : BlockHeader, nbits: number): Array<number> {
        const pData = new Array<number>();
        this.bsDrv.blockBuffer = this.block;
        const tempData = new Array<number>(8);
        let vectors = 0;
        for (let count = 0; count < header.samplesInBlock; count++) {
            for (let j = 0; j < header.numberOfChannels; j++) {
                if (count >= 2) {
                    const secondDerivative = this.getFromHuffman();
                    tempData[j] = secondDerivative + (this.previous1[j] << 1) - this.previous2[j];
                } else if (count === 1) {
                    const firstDerivative = this.getFromHuffman();
                    tempData[j] = firstDerivative + this.previous1[j];
                } else {
                    tempData[j] = this.getBits(tempData[j], nbits, this.bsDrv);
                }
                tempData[j] = this.convertUnsignedToSigned(tempData[j] & 0xFFFF, 16);
                tempData[j] = this.convertUnsignedToSigned(tempData[j], nbits);
                pData.push(tempData[j]);
            }
            for (let i = 0; i < 8; i++) {
                this.previous2[i] = this.previous1[i];
                this.previous1[i] = tempData[i];
            }
            vectors++;
            if (vectors >= MAX_VECTORS) break;
        }
        return pData;
    }

    private unpackRaw16(header : BlockHeader): Array<number> {
        const pData = new Array<number>();
        let endOfBlock = false;
        this.bsRaw.blockBuffer = this.block;
        let vectors = 0;
        for (let count = 0; count < header.samplesInBlock; count++) {
            for (let j = 0; j < header.numberOfChannels; j++) {
                let temp = 0;
                temp = this.getBits(temp, 16, this.bsRaw);
                if (temp !== Number.NaN) {
                    pData.push(temp);
                } else {
                    endOfBlock = true;
                    break;
                }
            }
            if (endOfBlock) break;
            vectors++;
            if (vectors >= MAX_VECTORS) break;
        }
        return pData;
    }

    private unpackRaw32(header : BlockHeader): Array<number> {
        const pData = new Array<number>();
        let vectors = 0;
        const slice = this.block.slice(18);
        for (let count = 0; count < header.samplesInBlock; count++) {
            pData.push(parseUint32(slice, count * 4))
            vectors++;
            if (vectors >= MAX_VECTORS) break;
        }
        return pData;
    }
}