import {EcgCalculationMetadata} from "./EcgCalculationMetadata";
import {EcgQrsParameters} from "./EcgQrsParameters";

export const STATUS_OK = 0;

export const STATUS_NOT_SUPPORTED = 1;
export const STATUS_NOT_ENOUGH_DATA = 2;
export const STATUS_NOISY = 3;

export interface EcgCalculationV3 {
    version: number;
    status: number;
    metadata: EcgCalculationMetadata;
    min: EcgQrsParameters;
    avg: EcgQrsParameters;
    max: EcgQrsParameters;
}