import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useAbortController, useAppSelector} from "../../../../hooks";
import {selectToken} from "../../../../features/account/accountSlice";
import React, {Fragment, useEffect, useState} from "react";
import {DemoRecordsData} from "../../../../models/DemoRecordsData";
import {deleteDemoData, searchDemoData} from "../../../../api/ApiHelper";
import Loader from "../../../Widgets/Loader/Loader";
import {FetchError} from "../../../Widgets/FetchError/FetchError";
import {Breadcrumbs} from "../../../Widgets/Breadcrumbs/Breadcrumbs";
import {Button} from "../../../Widgets/Button/Button";
import {ADMIN_ROUTE} from "../../../../routes";
import {logError} from "../../../../helpers/LogHelper";

function RemoveDemoData() {
    const {t} = useTranslation();
    const history = useHistory();
    const [controller] = useAbortController();
    const token = useAppSelector(selectToken);
    const [isFetching, setFetchingState] = useState(true);
    const [errorMessage, setErrorMessage] = useState("" as string);
    const [initialDemoRecordsData, setInitialDemoRecordsData] = useState(undefined as DemoRecordsData | undefined);
    const [removedDemoRecordsData, setRemovedDemoRecordsData] = useState(undefined as DemoRecordsData | undefined);
    const fetchRecord = () => {
        if (token) {
            setFetchingState(true);
            setRemovedDemoRecordsData(undefined);
            searchDemoData(token.token, controller, data => {
                setInitialDemoRecordsData(data);
                setFetchingState(false);
            }, error => {
                logError("Demo data info fetch error", error);
                setFetchingState(false);
                setErrorMessage(error.Message);
            });
        }
    }
    useEffect(() => {
        fetchRecord();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const removeDemoData = () => {
        if(token){
            setFetchingState(true);
            deleteDemoData(token.token, controller, data => {
                setRemovedDemoRecordsData(data);
                setFetchingState(false);
            }, error => {
                logError("Demo data remove error", error);
                setFetchingState(false);
                setErrorMessage(error.Message);
            });
        }
    };
    return (
        <div>
            <Breadcrumbs/>
            {isFetching && <Loader/>}
            {!isFetching && <Fragment>
                {(errorMessage !== "") && <FetchError message={errorMessage} onRetry={fetchRecord}/>}
                {(errorMessage === "") && <Fragment>
                    <div className="d-flex justify-content-center my-4">
                        <div className="details-table">
                            <table>
                                <tbody>
                                {removedDemoRecordsData &&
                                    <Fragment>
                                        <tr>
                                            <th>Deleted demo patients</th>
                                            <td>{removedDemoRecordsData.totalDemoPatients}</td>
                                        </tr>
                                        <tr>
                                            <th>Deleted demo records</th>
                                            <td>{removedDemoRecordsData.totalDemoRecords}</td>
                                        </tr>
                                    </Fragment>
                                }
                                {!removedDemoRecordsData && initialDemoRecordsData &&
                                    <Fragment>
                                        <tr>
                                            <th>Total demo patients</th>
                                            <td>{initialDemoRecordsData.totalDemoPatients}</td>
                                        </tr>
                                        <tr>
                                            <th>Total demo records</th>
                                            <td>{initialDemoRecordsData.totalDemoRecords}</td>
                                        </tr>
                                    </Fragment>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {!removedDemoRecordsData &&
                        <div className="d-flex justify-content-center mt-4">
                            <Button text={t("ok")} danger={false} onClick={() => history.push(ADMIN_ROUTE)}/>
                        </div>
                    }
                    {!removedDemoRecordsData && initialDemoRecordsData &&
                    <div className="d-flex justify-content-center mt-4">
                        <Button text={t("delete")} danger={true} onClick={removeDemoData}/>
                    </div>
                    }
                </Fragment>}
            </Fragment>}
        </div>
    );
}

export default RemoveDemoData;