interface Point {
    x: number;
    y: number;
}

export function makeSpiroChartData(samplingRate : number, unitsPerLiter: number, volumes : Array<number>) : Array<Point>{
    const data = new Array<Point>();
    let x = 0;
    data.push({x : 0, y: 0});
    for (const value of volumes){
        const dV = value / unitsPerLiter;
        const y = dV * samplingRate;
        data.push({x : x, y: y});
        x += dV;
    }
    return data;
}