import React, {useEffect} from 'react';
import Home from './components/Home/Home';
import * as AppRoutes from './routes';
import {Route, Switch} from 'react-router-dom';
import Account from './components/Account/Account';
import {Device} from "./components/Device/Device";
import {useViewportScale} from "./hooks";
import {MobileApp} from "./components/MobileApp/MobileApp";
import Guest from "./components/Guest/Guest";
import {cleanByTime} from "./local_cache/CacheStorage";
import {logInfo} from "./helpers/LogHelper";

function App() {
    useEffect(() => {
        cleanByTime().then();
    }, []);
    useEffect(() => {
        logInfo("Starting");
    }, []);
    useViewportScale(480);
    return (
        <Switch>
            <Route exact path={`${AppRoutes.DEVICE_ROUTE}/:deviceClass/:deviceNumber`}>
                <Device />
            </Route>
            <Route exact path={AppRoutes.MOBILE_APP_ROUTE}>
                <MobileApp />
            </Route>
            <Route path={AppRoutes.ACCOUNT_ROUTE}>
                <Account />
            </Route>
            <Route path={AppRoutes.GUEST_ROUTE}>
                <Guest />
            </Route>
            <Route>
                <Home />
            </Route>            
        </Switch>
    );
}

export default App;
