import React, {createRef, useRef, useState} from "react";
import * as ApiHelper from "../../../api/ApiHelper";
import * as ErrorCodes from "../../../api/ErrorCodes";
import {useHistory, useLocation} from "react-router";
import {UserToken} from "../../../models/UserToken";
import {useAbortController, useAppDispatch} from "../../../hooks";
import {updateToken} from "../../../features/account/accountSlice";
import ErrorResponse from "../../../models/ErrorResponse";
import * as AppRoutes from '../../../routes';
import {InputBox} from "../../Widgets/InputBox/InputBox";
import {Route, Switch} from "react-router-dom";
import {ClickableText} from "../../Widgets/ClickableText/ClickableText";
import {DoubleButton, DoubleButtonSide} from "../../Widgets/DoubleButton/DoubleButton";
import {GoogleSignInButton} from "../../Widgets/GoogleSignInButton/GoogleSignInButton";
import SvgMailIcon from "../../../assets/svg/MailIcon";
import SvgPersonIcon from "../../../assets/svg/PersonIcon";
import Loader from "../../Widgets/Loader/Loader";
import HeaderLogo from "../../Widgets/Header/HeaderLogo";
import {useTranslation} from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import {GOOGLE_RECAPTHCA_KEY} from "../../../settings";
import {AccountSettings} from "../../Widgets/AccountSettings/AccountSettings";
import {PasswordBox} from "../../Widgets/InputBox/PasswordBox";
import {useGoogleLogin} from "@react-oauth/google";

enum Field {
    SignInEmail,
    SignInPassword,
    SignUpEmail,
    SignUpName,
    SignUpPassword,
    SignUpPasswordConfirm
}

function SignIn() {
    const {t, i18n} = useTranslation();
    const [controller] = useAbortController();
    const location = useLocation();
    const isSignIn = location.pathname === AppRoutes.SIGN_IN_ROUTE
    const history = useHistory();
    const dispatch = useAppDispatch();
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const signInEmailRef = createRef<HTMLInputElement>();
    const signInPasswordRef = createRef<HTMLInputElement>();
    const signUpEmailRef = createRef<HTMLInputElement>();
    const signUpNameRef = createRef<HTMLInputElement>();
    const signUpPasswordRef = createRef<HTMLInputElement>();
    const signUpPasswordConfirmRef = createRef<HTMLInputElement>();
    const [isFetching, setFetchingState] = useState(false);
    const [badField, setBadField] = useState(null as Field | null);
    const [signInErrorMessage, setSignInErrorMessage] = useState(null as string | null);
    const [signUpErrorMessage, setSignUpErrorMessage] = useState(null as string | null);
    //SignIn state
    const [signInEmail, setSignInEmail] = useState('');
    const [signInPassword, setSignInPassword] = useState('');
    const handleSignInEmailChange = (value: string) => setSignInEmail(value);
    const handleSignInPasswordChange = (value: string) => setSignInPassword(value);
    //SignUp state
    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUpUserName, setSignUpUserName] = useState('');
    const [signUpPassword, setSignUpPassword] = useState('');
    const [signUpPasswordConfirm, setSignUpPasswordConfirm] = useState('');
    const handleSignUpEmailChange = (value: string) => setSignUpEmail(value);
    const handleSignUpUserNameChange = (value: string) => setSignUpUserName(value);
    const handleSignUpPasswordChange = (value: string) => setSignUpPassword(value);
    const handleSignUpPasswordConfirmChange = (value: string) => setSignUpPasswordConfirm(value);
    //
    const userTokenHandler = (userToken: UserToken) => {
        setFetchingState(false);
        setSignInErrorMessage(null);
        setSignUpErrorMessage(null);
        dispatch(updateToken(userToken));
        history.replace(AppRoutes.HOME_ROUTE);
    };
    const errorHandler = () => {
        setFetchingState(false);
    };
    const signInErrorHandler = (error: ErrorResponse) => {
        if (error.ResultCode === ErrorCodes.BadCredentials) {
            setSignInErrorMessage(t("wrong_email_ro_password"));
        } else {
            setSignInErrorMessage(t("connection_problem"));
        }
        errorHandler();
    };
    const signUpErrorHandler = (error: ErrorResponse) => {
        if (error.ResultCode === ErrorCodes.BadEmail) {
            setSignUpErrorMessage(t("incorrect_email"));
        } else if (error.ResultCode === ErrorCodes.EmailAlreadyInUse) {
            setSignUpErrorMessage(t("email_already_in_use"));
        } else if (error.ResultCode === ErrorCodes.BadUserName) {
            setSignUpErrorMessage(t("incorrect name"));
        } else if (error.ResultCode === ErrorCodes.BadPassword) {
            setSignUpErrorMessage(t("incorrect_password_full"));
        } else {
            setSignUpErrorMessage(t("connection_problem"));
        }
        errorHandler();
    };
    const googleSignInErrorHandler = (error: ErrorResponse, accessToken: any) => {
        if (error.ResultCode === ErrorCodes.BadCredentials) {
            ApiHelper.registerWithGoogle(accessToken, i18n.language, controller, userTokenHandler, isSignIn ? signInErrorHandler : signUpErrorHandler);
        } else {
            errorHandler();
        }
    };
    const handleSignInClick = () => {
        if (signInEmail.trim() === '') {
            setBadField(Field.SignInEmail);
            setSignInErrorMessage(t("enter_email"));
            return;
        }
        if (signInPassword.trim() === '') {
            setBadField(Field.SignInPassword);
            setSignInErrorMessage(t("enter_password"));
            return;
        }
        setBadField(null);
        setSignInErrorMessage(null);
        recaptchaRef.current?.executeAsync().then(token => {
            if (token) {
                setFetchingState(true);
                ApiHelper.authenticate(signInEmail.trim(), signInPassword.trim(), controller, userTokenHandler, signInErrorHandler);
            }
        });
    };
    const handleSignUpClick = () => {
        if (signUpEmail.trim() === '') {
            setBadField(Field.SignUpEmail);
            setSignUpErrorMessage(t("enter_email"));
            return;
        }
        if (signUpUserName.trim() === '') {
            setBadField(Field.SignUpName);
            setSignUpErrorMessage(t("enter_name"));
            return;
        }
        if (signUpPassword.trim() === '') {
            setBadField(Field.SignUpPassword);
            setSignUpErrorMessage(t("enter_password"));
            return;
        }
        if (signUpPassword.trim() !== signUpPasswordConfirm.trim()) {
            setBadField(Field.SignUpPasswordConfirm);
            setSignUpErrorMessage(t("password_mismatch"));
            return;
        }
        setBadField(null);
        setSignUpErrorMessage(null);
        recaptchaRef.current?.executeAsync().then(token => {
            if (token) {
                setFetchingState(true);
                ApiHelper.register(signUpEmail.trim(), signUpUserName.trim(), signUpPassword.trim(), i18n.language, controller, userTokenHandler, signUpErrorHandler);
            }
        });
    };
    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            ApiHelper.authenticateWithGoogle(tokenResponse.access_token, controller, userTokenHandler, (error) => googleSignInErrorHandler(error, tokenResponse.access_token));
        },
        onError: () => {
            setFetchingState(false);
        }
    });
    const signInWithGoogle = () => {
        setFetchingState(true);
        login();
    };
    const handleSignInWithGoogleClick = () => {
        recaptchaRef.current?.executeAsync().then(token => {
            if (token) {
                signInWithGoogle();
            }
        });
    };
    const handleForgotPasswordClick = () => {
        history.push(AppRoutes.RESTORE_ROUTE);
    };
    const handleDoubleButtonSwitch = (button: DoubleButtonSide) => {
        if (button === DoubleButtonSide.Left) {
            history.push(AppRoutes.SIGN_IN_ROUTE);
            setSignUpEmail('');
            setSignUpUserName('');
            setSignUpPassword('');
            setSignUpPasswordConfirm('');
        }
        if (button === DoubleButtonSide.Right) {
            history.push(AppRoutes.SIGN_UP_ROUTE);
        }
    };
    const handleDoubleButtonClick = (button: DoubleButtonSide) => {
        if (button === DoubleButtonSide.Left) {
            handleSignInClick();
        }
        if (button === DoubleButtonSide.Right) {
            handleSignUpClick();
        }
    };
    if (isFetching) {
        return (
            <Loader/>
        );
    } else {
        return (
            <div className="window-modal-container">
                <div className={`window-modal ${isSignIn ? "sign-in" : "sign-up"}`}>
                    <div className="py-4 d-flex justify-content-center">
                        <HeaderLogo/>
                    </div>
                    <div className="window-modal-error d-flex justify-content-center align-items-center my-2">
                        {isSignIn ? (signInErrorMessage ? signInErrorMessage : "") : (signUpErrorMessage ? signUpErrorMessage : "")}
                    </div>
                    <Switch>
                        <Route path={AppRoutes.SIGN_IN_ROUTE}>
                            <InputBox key="inp-sign-in-email" className="my-2" title={t("email")} inputType="email"
                                      initialValue={signInEmail} hasError={badField === Field.SignInEmail}
                                      ref={signInEmailRef}
                                      onChange={handleSignInEmailChange}
                                      onEnterPress={() => signInPasswordRef.current?.focus()} icon={<SvgMailIcon/>}/>
                            <PasswordBox key="inp-sign-in-password" className="my-2" title={t("password")}
                                         initialValue={signInPassword} hasError={badField === Field.SignInPassword}
                                         ref={signInPasswordRef}
                                         onChange={handleSignInPasswordChange}
                                         onEnterPress={() => handleDoubleButtonClick(DoubleButtonSide.Left)}/>
                            <div className="my-2 ml-4">
                                <ClickableText text={t("forgot_password")} onClick={handleForgotPasswordClick}/>
                            </div>
                        </Route>
                        <Route path={AppRoutes.SIGN_UP_ROUTE}>
                            <InputBox key="inp-sign-up-email" className="my-2" inputType="email" title={t("email")}
                                      initialValue={signUpEmail} hasError={badField === Field.SignUpEmail}
                                      ref={signUpEmailRef}
                                      onChange={handleSignUpEmailChange}
                                      onEnterPress={() => signUpNameRef.current?.focus()} icon={<SvgMailIcon/>}/>
                            <InputBox key="inp-sign-up-name" className="my-2" inputType="text" title={t("name")}
                                      initialValue={signUpUserName} hasError={badField === Field.SignUpName}
                                      ref={signUpNameRef}
                                      onChange={handleSignUpUserNameChange}
                                      onEnterPress={() => signUpPasswordRef.current?.focus()} icon={<SvgPersonIcon/>}/>
                            <PasswordBox key="inp-sign-up-password" className="my-2"
                                         title={t("password")}
                                         initialValue={signUpPassword} hasError={badField === Field.SignUpPassword}
                                         ref={signUpPasswordRef}
                                         onChange={handleSignUpPasswordChange}
                                         onEnterPress={() => signUpPasswordConfirmRef.current?.focus()}/>
                            <PasswordBox key="inp-sign-up-password-confirm" className="my-2"
                                         title={t("confirm_password")}
                                         initialValue={signUpPasswordConfirm}
                                         hasError={badField === Field.SignUpPasswordConfirm}
                                         ref={signUpPasswordConfirmRef}
                                         onChange={handleSignUpPasswordConfirmChange}
                                         onEnterPress={() => handleDoubleButtonClick(DoubleButtonSide.Right)}/>
                        </Route>
                    </Switch>
                    <DoubleButton className="my-4" leftButtonText={t("sign_in")} rightButtonText={t("sign_up")}
                                  activeButton={isSignIn ? DoubleButtonSide.Left : DoubleButtonSide.Right}
                                  onSwitch={handleDoubleButtonSwitch} onClick={handleDoubleButtonClick}/>
                    <GoogleSignInButton className="my-4" text={t("sing_in_with_google")}
                                        onClick={handleSignInWithGoogleClick}/>
                    <AccountSettings/>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={GOOGLE_RECAPTHCA_KEY}/>
                </div>
            </div>
        );
    }
}

export default SignIn;

