import React from "react";

function SvgStethoscopeIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8.518 1.09a1.033 1.033 0 00-.836-.42h-.14V.59a.242.242 0 10-.484 0v.648a.242.242 0 10.484 0v-.08h.14a.566.566 0 01.542.724l-.642 2.261A1.476 1.476 0 016.17 5.214a1.479 1.479 0 01-1.414-1.071l-.64-2.262a.567.567 0 01.542-.724h.14v.081a.242.242 0 10.484 0V.59a.242.242 0 10-.484 0V.67h-.14c-.332 0-.637.153-.837.42-.2.267-.263.604-.172.926l.643 2.26a1.95 1.95 0 00.93 1.178l.706 1.675v.175a.86.86 0 01-.857.862.858.858 0 01-.857-.86v-1.67c0-.77-.623-1.396-1.387-1.396-.765 0-1.388.627-1.388 1.396v.576a1.218 1.218 0 00.242 2.41 1.218 1.218 0 00.242-2.41v-.576c0-.502.405-.909.904-.909.498 0 .903.407.903.909v1.67c0 .743.602 1.348 1.34 1.348.74 0 1.342-.605 1.342-1.348v-.177l.706-1.675a1.95 1.95 0 00.931-1.178l.643-2.26c.09-.322.027-.66-.173-.925zM2.406 7.406a.73.73 0 01-.726.73.73.73 0 010-1.46c.4 0 .726.327.726.73zm3.763-.95l-.331-.783a2.022 2.022 0 00.661 0l-.33.783z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgStethoscopeIcon;
