import React from "react";
import { getWeightUnitsName } from "../../../helpers/FormatHelper";
import { parseWeightUnits, WeightUnits } from "../../../models/WeightUnits";
import {useTranslation} from "react-i18next";

interface WeightUnitsDropdownProps {
    className?: string;
    initialValue: WeightUnits;
    disabled: boolean;
    onChange: (value: WeightUnits) => void;
}

export const WeightUnitsDropdown: React.FC<WeightUnitsDropdownProps> = ({ className = "", initialValue, disabled, onChange }: WeightUnitsDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} disabled={disabled} defaultValue={WeightUnits[initialValue]} onChange={(e) => onChange(parseWeightUnits(e.target.value))}>
            <option value={WeightUnits[WeightUnits.Kilograms]}>{getWeightUnitsName(t, WeightUnits.Kilograms)}</option>
            <option value={WeightUnits[WeightUnits.Grams]}>{getWeightUnitsName(t, WeightUnits.Grams)}</option>
            <option value={WeightUnits[WeightUnits.Pounds]}>{getWeightUnitsName(t, WeightUnits.Pounds)}</option>
        </select>
    );
}