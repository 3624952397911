export class ButterworthFilter {

    private readonly a : number[];
    private readonly b : number[];
    private readonly x : number[];
    private readonly y : number[];
    private readonly size : number;

    constructor(a : number[], b : number[]) {
        this.a = a;
        this.b = b;
        this.x = [];
        this.y = [];
        this.size = Math.min(a.length, b.length);
        for (let i = 0; i < this.size; i++) {
            this.x[i] = 0;
            this.y[i] = 0;
        }
    }

    process(x : number) {
        for (let i = this.size - 1; i > 0; i--) {
            this.x[i] = this.x[i - 1];
            this.y[i] = this.y[i - 1];
        }
        this.x[0] = x;
        this.y[0] = 0;
        for (let i = 0; i < this.size; i++) {
            this.y[0] -= this.b[i] * this.y[i];
            this.y[0] += this.a[i] * this.x[i];
        }
        return this.y[0];
    }
}

export function getBellFilter() {
    return new ButterworthFilter([0.002518525756321, 0, -0.007555577268964, 0, 0.007555577268964, 0, -0.002518525756321], [1.000000000000000, -5.391747433031997, 12.141561643694509, -14.622730115958632, 9.937043536536795, -3.613394854095219, 0.549267313822533]);
}

export function getDiaphragmFilter() {
    return new ButterworthFilter([0.012851218899138, 0, -0.038553656697414, 0, 0.038553656697414, 0, -0.012851218899138], [1.0000000000000, -4.788247753408167, 9.672567171565857, -10.590951840203061, 6.647007236355556, -2.268325576104654, 0.328024194995455]);
}