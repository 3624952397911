import * as React from "react";

function SvgPauseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.374 1.25a.468.468 0 00-.464-.464H7.196a.468.468 0 00-.464.464v10.214c0 .254.21.465.464.465h3.714c.254 0 .464-.21.464-.465V1.25zm-6.5 0A.468.468 0 004.41.786H.696a.468.468 0 00-.464.464v10.214c0 .254.21.465.464.465H4.41c.254 0 .464-.21.464-.465V1.25z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPauseIcon;
