import React from "react";
import {formatOnOffState} from "../../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";

interface OnOffDropdownProps {
    className?: string;
    initialState: boolean;
    disabled: boolean;
    onChange: (value: boolean) => void;
}

export const OnOffDropdown: React.FC<OnOffDropdownProps> = ({ className = "", initialState, disabled, onChange }: OnOffDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} disabled={disabled} defaultValue={initialState ? "t" : "f"} onChange={(e) => onChange(e.target.value === 't')}>
            <option value='t'>{formatOnOffState(t, true)}</option>
            <option value='f'>{formatOnOffState(t, false)}</option>
        </select>
    );
}