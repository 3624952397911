import React from "react";
import './Button.css';

interface ButtonProps {
    text: string;
    onClick: () => void;
    className?: string;
    enabled?: boolean;
    highlighted?: boolean;
    danger?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ text, onClick, className = "", enabled = true, highlighted = false, danger = false }: ButtonProps) => {
    const handleClick = () => {
        if(enabled) {
            onClick();
        }
    }
    return (
        <div className={`regular-button ${className} ${highlighted ? "highlighted" : ""} ${danger ? "dangered" : ""} ${enabled ? "" : "disabled-button"} disable-select`} onClick={handleClick}>{text}</div>
    );
}