import * as React from "react"
import { SVGProps } from "react"

const IconCertificate = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 9 10"
        xmlSpace="preserve"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M4.902 8.418V6.852l.008-.004 3.18-2.375-3.188 3.945zm-.972-3.16.863 1.492-3.117-2.332 2.254.84zm.972-3.309.875 3.278-.875 1.523V1.949zm-.023-.277L3.93 5.207l-2.395-.891-.09-.031 3.41 4.219.016-.012.012.016v-.016l.012.012 3.351-4.149.063-.078-2.489.922-.941-3.527z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="M8.164 7.848a4.291 4.291 0 0 1-6.57 0 4.29 4.29 0 0 1 6.57-5.516"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M4.902 8.418V6.852l.008-.004 3.18-2.375-3.188 3.945zm-.972-3.16.863 1.492-3.117-2.332 2.254.84zm.972-3.309.875 3.278-.875 1.523V1.949zm-.023-.277L3.93 5.207l-2.395-.891-.09-.031 3.41 4.219.016-.012.012.016v-.016l.012.012 3.351-4.149.063-.078-2.489.922-.941-3.527z"
        />
        <path
            fill="none"
            stroke="#161716"
            strokeWidth={0.425}
            d="M8.164 7.848a4.291 4.291 0 0 1-6.57 0 4.29 4.29 0 0 1 6.57-5.516"
        />
    </svg>
)

export default IconCertificate
