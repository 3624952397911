export class WritableCacheFile {

    private readonly fileHandle : FileSystemFileHandle;

    private writer : WritableStreamDefaultWriter | null;


    constructor(fileHandle: FileSystemFileHandle) {
        this.fileHandle = fileHandle;
        this.writer = null;
    }

    public async addData(data: ArrayBuffer) {
        if (!this.writer) {
            const writableFileStream = await this.fileHandle.createWritable();
            this.writer = writableFileStream.getWriter();
        }
        await this.writer.write(data);
    }

    public async close(){
        if (this.writer){
            await this.writer.close()
        }
    }

}