import {Record} from "../../../models/Record";
import React from "react";
import {useTranslation} from "react-i18next";
import {
    formatAgeShort,
    formatGenderShort,
    formatHeight,
    formatPatientName,
    formatWeight
} from "../../../helpers/FormatHelper";
import './PatientInfo.css';
import {Gender} from "../../../models/Gender";

interface Props {
    record: Record;
}

export const PatientInfo: React.FC<Props> = ({record}: Props) => {
    const {t} = useTranslation();
    return (
        <div className='patient-info mt-2'>
            {`${formatPatientName(t, record.patientId === null, record.patientName)} ${record.gender !== Gender.Undefined ? `• ${formatGenderShort(t, record.gender)} ` : ''}• ${formatAgeShort(record.age)} • ${formatHeight(t, record.height, record.heightUnits)} • ${formatWeight(t, record.weight, record.weightUnits)}`}
        </div>
    );
}