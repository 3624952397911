import {parseInt32, parseInt32Array, parseString, parseUint16} from "../helpers/BinaryHelper";

const QRS_DATA_SIZE = 128;
const EVENT_DATA_SIZE = 40;

interface EventsFileHeader {
    formatVersion: string,
    ecgSamplingRate: number;
    samplesInEcgFile: number;
    qrsTimeIndexOffset: number;
    qrsTimeIndexSize: number;
    eventsTimeIndexOffset: number;
    eventsTimeIndexSize: number;
    qrsDataOffset: number;
    qrsDataSize: number;
    qrsVersion: number;
    eventsDataOffset: number;
    eventsDataSize: number;
    eventsVersion: number;
    userDefDataOffset: number;
    userDefDataSize: number;
    userDataVersion: number;
    qrsTimeIndexCrc: number;
    eventsTimeIndexCrc: number;
    qrsDataCrc: number;
    eventsDataCrc: number;
    userDefDataCrc: number;
    headerCrc: number;
}

export interface EventFileData {
    data: ArrayBuffer;
    qrsIndexTable: Array<number>;
    qrsInIndex: number;
    qrsCount: number;
    qrsDataOffset: number;
    eventsIndexTable: Array<number>;
    eventsInIndex: number;
    eventsCount: number;
    eventsDataOffset: number;
}

export interface QrsData {
    samplePosition: number;
    rrValue: number;
    pWaveDuration: number;
    qrsDuration: number;
    qDuration: number;
    qtDuration: number;
    prAxis: number;
    tAxis: number;
    piiAmplitude: number;
    sv1Rv5Amplitude: number;
    qPeakAmplitude: number;
    tWavePositiveAmplitude: number;
    tWaveNegativeAmplitude: number;
    noiseBitmask: number;
    stPqLevel: number[];
    stJpointLevel: number[];
    stJplus80Level: number[];
    rrNextValue: number;
    tempHr: number;
    artifactBitmask: number;
    primaryType: number;
    empty7: number;
    qrsNumber: number;
    pqIntervalDuration: number;
    templateType: number;
    templateNumber: number;
}

export interface EventData {
    x: number;
    cx: number;
    id: number;
    firstQrsNum: number;
    lastQrsNum: number;
    eventHr: number;
    Ci: number;
    Cp: number;
    countRR: number;
    textId: number;
}

export function parseEventFile(data: ArrayBuffer): EventFileData {
    const header = parseFileHeader(data, 0);
    const qrsCount = header.qrsDataSize / QRS_DATA_SIZE;
    const qrsIndexTableSize = header.qrsTimeIndexSize / 4;
    const qrsInIndex = Math.floor(qrsCount / qrsIndexTableSize) + 1;
    const qrsIndexTable = new Array<number>();
    for (let i = 0; i < qrsIndexTableSize; i++) {
        const position = parseInt32(data, header.qrsTimeIndexOffset + i * 4);
        if (i > 0 && position === 0){
            break;
        }
        qrsIndexTable.push(position);
    }
    const eventsCount = header.eventsDataSize / EVENT_DATA_SIZE;
    const eventsIndexTableSize = header.eventsTimeIndexSize / 4;
    const eventsInIndex = Math.floor(eventsCount / eventsIndexTableSize) + 1;
    const eventsIndexTable = new Array<number>();
    for (let i = 0; i < eventsIndexTableSize; i++) {
        const position = parseInt32(data, header.eventsTimeIndexOffset + i * 4);
        if (i > 0 && position === 0){
            break;
        }
        eventsIndexTable.push(position);
    }
    return {
        data: data,
        qrsIndexTable: qrsIndexTable,
        qrsCount : qrsCount,
        qrsInIndex: qrsInIndex,
        qrsDataOffset: header.qrsDataOffset,
        eventsIndexTable: eventsIndexTable,
        eventsCount: eventsCount,
        eventsInIndex: eventsInIndex,
        eventsDataOffset: header.eventsDataOffset,
    };
}

function parseFileHeader(data: ArrayBuffer, offset: number): EventsFileHeader {
    const buffer = data.slice(offset);
    return {
        formatVersion: parseString(buffer, 0, 32),
        ecgSamplingRate: parseInt32(buffer, 32),
        samplesInEcgFile: parseInt32(buffer, 36),
        qrsTimeIndexOffset: parseInt32(buffer, 40),
        qrsTimeIndexSize: parseInt32(buffer, 44),
        eventsTimeIndexOffset: parseInt32(buffer, 48),
        eventsTimeIndexSize: parseInt32(buffer, 52),
        qrsDataOffset: parseInt32(buffer, 56),
        qrsDataSize: parseInt32(buffer, 60),
        qrsVersion: parseInt32(buffer, 64),
        eventsDataOffset: parseInt32(buffer, 68),
        eventsDataSize: parseInt32(buffer, 72),
        eventsVersion: parseInt32(buffer, 76),
        userDefDataOffset: parseInt32(buffer, 80),
        userDefDataSize: parseInt32(buffer, 84),
        userDataVersion: parseInt32(buffer, 88),
        qrsTimeIndexCrc: parseUint16(buffer, 92),
        eventsTimeIndexCrc: parseUint16(buffer, 94),
        qrsDataCrc: parseUint16(buffer, 96),
        eventsDataCrc: parseUint16(buffer, 98),
        userDefDataCrc: parseUint16(buffer, 100),
        headerCrc: parseUint16(buffer, 102)
    }
}

export function parseQrs(data: EventFileData, firstQrs : number, count : number, firstSample : number, lastSample : number) : Array<QrsData>{
    const result = [];
    for (let i = 0; i < count; i++) {
        const qrsData = parseQrsData(data.data, data.qrsDataOffset + (firstQrs + i) * QRS_DATA_SIZE);
        if (qrsData.samplePosition > lastSample){
            break;
        }
        if (qrsData.samplePosition >= firstSample) {
            result.push(qrsData);
        }
    }
    return result;
}

function parseQrsData(data: ArrayBuffer, offset: number): QrsData {
    const buffer = data.slice(offset);
    return {
        samplePosition: parseInt32(buffer, 0),
        rrValue: parseInt32(buffer, 4),
        pWaveDuration: parseInt32(buffer, 8),
        qrsDuration: parseInt32(buffer, 12),
        qDuration: parseInt32(buffer, 16),
        qtDuration: parseInt32(buffer, 20),
        prAxis: parseInt32(buffer, 24),
        tAxis: parseInt32(buffer, 28),
        piiAmplitude: parseInt32(buffer, 32),
        sv1Rv5Amplitude: parseInt32(buffer, 36),
        qPeakAmplitude: parseInt32(buffer, 40),
        tWavePositiveAmplitude: parseInt32(buffer, 44),
        tWaveNegativeAmplitude: parseInt32(buffer, 48),
        noiseBitmask: parseInt32(buffer, 52),
        stPqLevel: parseInt32Array(buffer, 56, 3),
        stJpointLevel: parseInt32Array(buffer, 68, 3),
        stJplus80Level: parseInt32Array(buffer, 80, 3),
        rrNextValue: parseInt32(buffer, 92),
        tempHr: parseInt32(buffer, 96),
        artifactBitmask: parseInt32(buffer, 100),
        primaryType: parseInt32(buffer, 104),
        empty7: parseInt32(buffer, 108),
        qrsNumber: parseInt32(buffer, 112),
        pqIntervalDuration: parseInt32(buffer, 116),
        templateType: parseInt32(buffer, 120),
        templateNumber: parseInt32(buffer, 124)
    }
}

export function parseEventData(data: ArrayBuffer, offset: number): EventData {
    const buffer = data.slice(offset);
    return {
        x: parseInt32(buffer, 0),
        cx: parseInt32(buffer, 4),
        id: parseInt32(buffer, 8),
        firstQrsNum: parseInt32(buffer, 12),
        lastQrsNum: parseInt32(buffer, 16),
        eventHr: parseInt32(buffer, 20),
        Ci: parseInt32(buffer, 24),
        Cp: parseInt32(buffer, 28),
        countRR: parseInt32(buffer, 32),
        textId: parseInt32(buffer, 36)
    }
}