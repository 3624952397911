import * as React from "react";

function SvgLandscape1x12Icon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 35 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M4,2h26v20H4z"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="currentColor"/>
            <path
                fill="currentColor"
                d="M11.4,8.7L11.4,15L9.9,15L9.9,9.9L8.7,9.9L8.7,8.7h2.7zM15.8,15l-1,-1.5 -1.1,1.5h-1.5l1.8,-2.4 -1.8,-2.4h1.6l1,1.4 1,-1.4h1.6l-1.8,2.3 1.8,2.5h-1.6zM20.3,8.7L20.3,15L19,15L19,9.9h-1.3L17.7,8.7h2.7zM26.1,13.8L26.1,15h-4.7v-1l2.4,-2.2 0.5,-0.7 0.2,-0.5c0,-0.3 -0.1,-0.4 -0.3,-0.6l-0.7,-0.2c-0.3,0 -0.6,0 -0.8,0.2 -0.2,0 -0.4,0.2 -0.5,0.4L21,9.8c0.2,-0.4 0.6,-0.7 1,-0.9 0.4,-0.2 1,-0.3 1.5,-0.3s0.9,0 1.2,0.2c0.4,0.2 0.7,0.4 0.8,0.7 0.2,0.3 0.3,0.6 0.3,1 0,0.3 0,0.6 -0.2,1l-0.8,1 -1.5,1.3h2.7z"/>
        </svg>
    );
}

export default SvgLandscape1x12Icon;