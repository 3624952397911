import React from "react";

function SvgEditDateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.285 11.857V9.93h1.928v1.928H1.285zm2.357 0V9.93h2.143v1.928H3.642zM1.285 9.5V7.357h1.928V9.5H1.285zm2.357 0V7.357h2.143V9.5H3.642zM1.285 6.929V5h1.928v1.929H1.285zm4.928 4.928V9.93h2.143v1.928H6.213zM3.642 6.93V5h2.143v1.929H3.642zm5.143 4.928V9.93h1.928v1.928H8.785zM6.213 9.5V7.357h2.143V9.5H6.213zM3.856 3.714a.22.22 0 01-.214.215h-.429A.22.22 0 013 3.714V1.786c0-.114.1-.215.214-.215h.429c.114 0 .214.1.214.215v1.928zM8.785 9.5V7.357h1.928V9.5H8.785zM6.213 6.929V5h2.143v1.929H6.213zm2.572 0V5h1.928v1.929H8.785zm.214-3.215a.22.22 0 01-.214.215h-.429a.22.22 0 01-.214-.215V1.786c0-.114.1-.215.214-.215h.429c.114 0 .214.1.214.215v1.928zm2.572-.428a.863.863 0 00-.858-.857h-.857v-.643c0-.59-.482-1.072-1.071-1.072h-.429c-.589 0-1.071.482-1.071 1.072v.643H4.713v-.643c0-.59-.482-1.072-1.071-1.072h-.429c-.589 0-1.071.482-1.071 1.072v.643h-.857a.863.863 0 00-.857.857v8.571c0 .469.388.857.857.857h9.428a.863.863 0 00.858-.857V3.286z"
                fill="currentColor"
            />
        </svg>        
    );
}

export default SvgEditDateIcon;
