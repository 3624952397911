import './EcgMonitor.css';
import {EcgData} from "../../../models/EcgData";
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {EcgMonitor} from "./EcgMonitor";
import {HorizontalScrollbar} from "../HorizontalScrollbar/HorizontalScrollbar";
import {formatDurationMs, formatTimestamp} from "../../../helpers/FormatHelper";
import SvgLpfIcon from "../../../assets/svg/LpfIcon";
import SvgHpfIcon from "../../../assets/svg/HpfIcon";
import SvgZoomOutVerticalIcon from "../../../assets/svg/ZoomOutVerticalIcon";
import SvgZoomInVerticalIcon from "../../../assets/svg/ZoomInVerticalIcon";
import SvgZoomOutHorizontalIcon from "../../../assets/svg/ZoomOutHorizontalIcon";
import SvgZoomInHorizontalIcon from "../../../assets/svg/ZoomInHorizontalIcon";
import SvgPrintIcon from "../../../assets/svg/PrintIcon";
import SvgSaveIcon from "../../../assets/svg/SaveIcon";
import {useAbortController, useAppDispatch, useAppSelector} from "../../../hooks";
import {selectToken} from "../../../features/account/accountSlice";
import {downloadEcgPdf, printEcgPdf} from "../../../api/ApiHelper";
import {EcgReportSettings} from "../../../models/EcgReportSettings";
import {Toast} from "../Toast/Toast";
import {useTranslation} from "react-i18next";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import Landscape1x7Icon from "../../../assets/svg/Landscape1x7Icon";
import Portrait1x7Icon from "../../../assets/svg/Portrait1x7Icon";
import Landscape1x12Icon from "../../../assets/svg/Landscape1x12Icon";
import Portrait1x12Icon from "../../../assets/svg/Portrait1x12Icon";
import Landscape2x6Icon from "../../../assets/svg/Landscape2x6Icon";
import Portrait2x6Icon from "../../../assets/svg/Portrait2x6Icon";
import Landscape4x3Icon from "../../../assets/svg/Landscape4x3Icon";
import SaveAllIconSvg from "../../../assets/svg/SaveAllIcon";
import SaveSelectedIconSvg from "../../../assets/svg/SaveSelectedIcon";
import CheckIconSvg from "../../../assets/svg/CheckIcon";
import CancelIconSvg from "../../../assets/svg/CancelIcon";
import SvgMoveLeftIcon from "../../../assets/svg/MoveLeftIcon";
import SvgMoveRightIcon from "../../../assets/svg/MoveRightIcon";
import {MV_SCALES, TIME_SCALES} from "../../../ecg/EcgScales";
import {
    ECG_MV_SCALE_INDEX_DEFAULT_VALUE,
    ECG_MV_SCALE_SETTINGS_KEY,
    ECG_REPORT_GRID_INDEX_DEFAULT_VALUE,
    ECG_REPORT_GRID_SETTINGS_KEY,
    ECG_SHOW_CALCULATION_DEFAULT_VALUE,
    ECG_SHOW_CALCULATION_SETTINGS_KEY,
    ECG_TIME_SCALE_INDEX_DEFAULT_VALUE,
    ECG_TIME_SCALE_SETTINGS_KEY,
    usePersistentState
} from "../../../persistance/PersistanceHelper";
import SvgRulerIcon from "../../../assets/svg/RulerIcon";
import {EcgCalculationData} from "../EcgCalculationData/EcgCalculationData";
import SvgCalculationIcon from "../../../assets/svg/CalculationIcon";
import SvgRecalculateIcon from "../../../assets/svg/RecalculateIcon";

export const PX_PER_MM = 3;

const VIEW = 0;
const SAVE = 1;
const PRINT = 2;

const PORTRAIT_1_12 = 0;
const PORTRAIT_1_7 = 1;
const PORTRAIT_2_6 = 2;
const LANDSCAPE_1_12 = 3;
const LANDSCAPE_1_7 = 4;
const LANDSCAPE_2_6 = 5;
const LANDSCAPE_4_3 = 6;

const ALL = 0;
const SELECTED = 1;

const CALCULATION_PANEL_WIDTH = 450;

interface Props {
    ecgData: EcgData;
    recordId: string;
    studyId: string;
    isSupport: boolean;
    recalculationRequest?: () => void;
    interpretationRequest?: () => void;
}

export const EcgViewer: React.FC<Props> = ({ecgData, recordId, studyId, isSupport, recalculationRequest, interpretationRequest}: Props) => {
    const {t} = useTranslation();
    const [controller] = useAbortController();
    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const [gridColor] = usePersistentState(ECG_REPORT_GRID_SETTINGS_KEY, ECG_REPORT_GRID_INDEX_DEFAULT_VALUE);
    const [showCalculation, setShowCalculation] = usePersistentState(ECG_SHOW_CALCULATION_SETTINGS_KEY, ECG_SHOW_CALCULATION_DEFAULT_VALUE);
    const [buttonMode, setButtonMode] = useState(VIEW);
    const [printLayout, setPrintLayout] = useState(ecgData.header.adcChannels === 3 ? LANDSCAPE_1_7 : LANDSCAPE_1_12);
    const isLandscape = useMemo(() => {
        switch (printLayout) {
            case PORTRAIT_1_7:
            case PORTRAIT_1_12:
            case PORTRAIT_2_6:
                return false;
            case LANDSCAPE_1_7:
            case LANDSCAPE_1_12:
            case LANDSCAPE_2_6:
            case LANDSCAPE_4_3:
                return true;
        }
        return false;
    }, [printLayout]); // eslint-disable-line
    const numberOfColumns = useMemo(() => {
        switch (printLayout) {
            case PORTRAIT_1_7:
            case PORTRAIT_1_12:
            case LANDSCAPE_1_7:
            case LANDSCAPE_1_12:
                return 1;
            case PORTRAIT_2_6:
            case LANDSCAPE_2_6:
                return 2;
            case LANDSCAPE_4_3:
                return 4;
        }
        return 1;
    }, [printLayout]); // eslint-disable-line
    const [printMode, setPrintMode] = useState(SELECTED);
    const [savePosition, setSavePosition] = useState(null as number | null);
    const [moveLeft, setMoveLeft] = useState(false);
    const [moveRight, setMoveRight] = useState(false);
    const [pdfGenerationInProgress, setPdfGenerationInProgress] = useState(false);
    const [hasPdfError, setPdfErrorState] = useState(false);
    const numberOfSamples = useMemo(() => ecgData.ecg.map(channel => channel.length).reduce((p, c) => Math.min(p, c), Number.MAX_VALUE), [ecgData]); // eslint-disable-line
    const container = useRef<HTMLDivElement>(null);
    const [parentWidth, setParentWidth] = useState(0);
    const showCalculationInRow = useMemo(() => {
        return parentWidth > 2 * CALCULATION_PANEL_WIDTH;
    }, [parentWidth]);
    const width = useMemo(() => {
        if (showCalculation) {
            if (showCalculationInRow) {
                return Math.max(0, parentWidth - CALCULATION_PANEL_WIDTH - 24);
            }
        }
        return parentWidth;
    }, [parentWidth, showCalculation]);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [defaultMvScaleIndex] = usePersistentState(ECG_MV_SCALE_SETTINGS_KEY, ECG_MV_SCALE_INDEX_DEFAULT_VALUE);
    const [defaultTimeScaleIndex] = usePersistentState(ECG_TIME_SCALE_SETTINGS_KEY, ECG_TIME_SCALE_INDEX_DEFAULT_VALUE);
    const [mvScaleIndex, setMvScaleIndex] = useState(defaultMvScaleIndex);
    const [timeScaleIndex, setTimeScaleIndex] = useState(defaultTimeScaleIndex);
    const mvScale = useMemo(() => MV_SCALES[mvScaleIndex], [mvScaleIndex]); // eslint-disable-line
    const timeScale = useMemo(() => TIME_SCALES[timeScaleIndex], [timeScaleIndex]); // eslint-disable-line
    const [lpf, setLpf] = useState(true);
    const [hpf, setHpf] = useState(true);
    const [ruler, setRuler] = useState(false);
    const samplesPerScreen = useMemo(() => width / PX_PER_MM / timeScale * ecgData.header.sampleRate, [width, timeScale, ecgData]); // eslint-disable-line
    const lastSample = useMemo(() => Math.min(scrollPosition + samplesPerScreen, numberOfSamples - 1), [scrollPosition, samplesPerScreen, numberOfSamples]); // eslint-disable-line
    const firstSample = useMemo(() => Math.max(lastSample - samplesPerScreen, 0), [lastSample, samplesPerScreen]); // eslint-disable-line
    const samplesPerPage = useMemo(() => {
        if (printMode === ALL) {
            return null;
        } else {
            const plotWidth = (isLandscape ? 240 : 190) / numberOfColumns;
            return Math.ceil(plotWidth / timeScale * ecgData.header.sampleRate);
        }

    }, [printMode, isLandscape, numberOfColumns, timeScale]); // eslint-disable-line
    useLayoutEffect(() => {
        setParentWidth(container.current?.clientWidth ?? 0);
    }, [container.current?.clientWidth]); // eslint-disable-line
    const resizeEventListener = () => setParentWidth(container.current?.clientWidth ?? 0);
    useEffect(() => {
        window.addEventListener("resize", resizeEventListener);
        return (() => {
            window.removeEventListener("resize", resizeEventListener)
        });
    });
    const isMaxVerticalZoom = mvScaleIndex === MV_SCALES.length - 1;
    const isMinVerticalZoom = mvScaleIndex === 0;
    const isMaxHorizontalZoom = timeScaleIndex === TIME_SCALES.length - 1;
    const isMinHorizontalZoom = timeScaleIndex === 0;
    const notifyPdfGenerationInProgress = () => {
        setPdfErrorState(false);
        setPdfGenerationInProgress(false);
        setPdfGenerationInProgress(true);
    }
    const pdfErrorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setPdfErrorState(false);
            setPdfGenerationInProgress(false);
            setPdfErrorState(true);
        }
    };
    const applySaveSettings = () => {
        if (token) {
            const settings = {
                recordId: recordId,
                studyId: studyId,
                gridColor: gridColor,
                position: Math.ceil(savePosition ?? -1),
                highPass: hpf,
                lowPass: lpf,
                timeScale: timeScale,
                mvScale: mvScale,
                isLandscape: isLandscape,
                numberOfColumns: numberOfColumns,
                language: t("ui_language")
            } as EcgReportSettings;
            const name = formatTimestamp(Date.now());
            notifyPdfGenerationInProgress();
            if (buttonMode === SAVE) {
                downloadEcgPdf(token.token, settings, name, controller, () => {
                }, pdfErrorHandler);
            }
            if (buttonMode === PRINT) {
                printEcgPdf(token.token, settings, controller, () => {
                }, pdfErrorHandler);
            }
            setButtonMode(VIEW);
        }
    };
    const moveSelectionRegion = (direction: number) => {
        if (direction > 0) {
            if (printMode === SELECTED) {
                setMoveRight(true);
            }
        }
        if (direction < 0) {
            if (printMode === SELECTED) {
                setMoveLeft(true);
            }
        }
        if (direction === 0) {
            setMoveRight(false);
            setMoveLeft(false);
        }
    }
    const recalculate = () => {
        if (recalculationRequest) {
            recalculationRequest();
        }
    }
    const analyze = () => {
        if (interpretationRequest) {
            interpretationRequest();
        }
    }
    return (
        <div className={"ecg-viewer mt-4"}>
            <div className={`${showCalculationInRow ? "d-flex justify-content-between" : ""}`} ref={container}>
                <div style={{width: `${width}px`}}>
                    {width > 0 &&
                        <EcgMonitor numberOfSamples={numberOfSamples} samplesPerScreen={samplesPerScreen}
                                    firstSample={firstSample} lastSample={lastSample}
                                    ecgData={ecgData} width={width} mvScale={mvScale} timeScale={timeScale} lpf={lpf}
                                    hpf={hpf} showSaveRegion={buttonMode !== VIEW} saveRegionWidth={samplesPerPage}
                                    savePositionChangeListener={setSavePosition} moveRegionLeft={moveLeft}
                                    moveRegionRight={moveRight} ruler={ruler}
                                    onScrollPositionChanged={(position) => setScrollPosition(position)}/>}
                    {width > 0 &&
                        <div>
                            <div
                                className="d-flex justify-content-between my-2 ecg-viewer-duration">
                                <span>{formatDurationMs(firstSample / ecgData.header.sampleRate * 1000)}</span>
                                <span>{formatDurationMs(lastSample / ecgData.header.sampleRate * 1000)}</span>
                            </div>
                            {samplesPerScreen < numberOfSamples &&
                                <HorizontalScrollbar width={width} position={firstSample} max={numberOfSamples}
                                                     screen={samplesPerScreen}
                                                     onScrollPositionChanged={(position) => setScrollPosition(position)}/>
                            }
                        </div>}
                </div>
                {width > 0 && showCalculation &&
                    <div style={{
                        width: `${showCalculationInRow ? CALCULATION_PANEL_WIDTH : width}px`,
                        overflow: "hidden"
                    }}>
                        <EcgCalculationData calculation={ecgData.ecgCalculationV3}/>
                    </div>
                }
            </div>
            {width > 0 && buttonMode !== VIEW &&
                <div
                    className="d-flex justify-content-center mt-4 ecg-viewer-instruction">{t(buttonMode === SAVE ? "setup_save_options" : "setup_print_options")}</div>
            }
            {width > 0 &&
                <div className="d-flex justify-content-center my-4">
                    {buttonMode === VIEW &&
                        <div className="d-flex justify-content-center mr-2">
                            <div className={`ecg-viewer-button ${lpf ? "active" : ""}`} onClick={() => setLpf(!lpf)}>
                                <SvgLpfIcon/><span>{t("hpf_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${hpf ? "active" : ""}`} onClick={() => setHpf(!hpf)}>
                                <SvgHpfIcon/><span>{t("lpf_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMaxVerticalZoom ? "active" : ""}`}
                                 onClick={() => setMvScaleIndex(Math.min(MV_SCALES.length - 1, mvScaleIndex + 1))}>
                                <SvgZoomOutVerticalIcon/><span>{t("mv_zoom_out_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMinVerticalZoom ? "active" : ""}`}
                                 onClick={() => setMvScaleIndex(Math.max(0, mvScaleIndex - 1))}>
                                <SvgZoomInVerticalIcon/><span>{t("mv_zoom_in_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMaxHorizontalZoom ? "active" : ""}`}
                                 onClick={() => setTimeScaleIndex(Math.min(TIME_SCALES.length - 1, timeScaleIndex + 1))}>
                                <SvgZoomOutHorizontalIcon/><span>{t("time_zoom_out_tooltip")}</span></div>
                            <div className={`ecg-viewer-button ${isMinHorizontalZoom ? "active" : ""}`}
                                 onClick={() => setTimeScaleIndex(Math.max(0, timeScaleIndex - 1))}>
                                <SvgZoomInHorizontalIcon/><span>{t("time_zoom_in_tooltip")}</span>
                            </div>
                        </div>
                    }
                    {buttonMode === VIEW &&
                        <div className="d-flex justify-content-center mx-2">
                            <div className={`ecg-viewer-button ${ruler ? "active" : ""}`}
                                 onClick={() => setRuler(!ruler)}>
                                <SvgRulerIcon/><span>{t("ruler_tooltip")}</span>
                            </div>
                            <div className={`ecg-viewer-button ${showCalculation ? "active" : ""}`}
                                 onClick={() => setShowCalculation(!showCalculation)}>
                                <SvgCalculationIcon/><span>{t("calculation")}</span></div>
                            {isSupport && recalculationRequest &&
                                <div className={`ecg-viewer-button`}
                                     onClick={() => recalculate()}>
                                    <SvgRecalculateIcon/><span>{t("make_recalculation")}</span></div>
                            }
                            {isSupport && interpretationRequest &&
                                <div className={`ecg-viewer-button`}
                                     onClick={() => analyze()}>
                                    <SvgRecalculateIcon/><span>{"Analyze"}</span></div>
                            }
                        </div>
                    }
                    {buttonMode === VIEW &&
                        <div className="d-flex justify-content-center ml-2">
                            <div className="ecg-viewer-button" onClick={() => setButtonMode(PRINT)}>
                                <SvgPrintIcon/><span>{t("print_tooltip")}</span></div>
                            <div className="ecg-viewer-button" onClick={() => setButtonMode(SAVE)}>
                                <SvgSaveIcon/><span>{t("download_tooltip")}</span>
                            </div>
                        </div>
                    }
                    {buttonMode !== VIEW &&
                        <div className="d-flex justify-content-center">
                            {ecgData.header.adcChannels === 3 &&
                                <div className="d-flex justify-content-center mx-2">
                                    <div
                                        className={`ecg-viewer-button large-icon ${printLayout === LANDSCAPE_1_7 ? "active" : ""}`}
                                        onClick={() => setPrintLayout(LANDSCAPE_1_7)}>
                                        <Landscape1x7Icon/><span>{t("landscape_1_7")}</span></div>
                                    <div
                                        className={`ecg-viewer-button large-icon ${printLayout === PORTRAIT_1_7 ? "active" : ""}`}
                                        onClick={() => setPrintLayout(PORTRAIT_1_7)}>
                                        <Portrait1x7Icon/><span>{t("portrait_1_7")}</span></div>
                                </div>
                            }
                            {ecgData.header.adcChannels === 8 &&
                                <div className="d-flex justify-content-center mx-2">
                                    <div
                                        className={`ecg-viewer-button large-icon ${printLayout === LANDSCAPE_1_12 ? "active" : ""}`}
                                        onClick={() => setPrintLayout(LANDSCAPE_1_12)}>
                                        <Landscape1x12Icon/><span>{t("landscape_1_12")}</span></div>
                                    <div
                                        className={`ecg-viewer-button large-icon ${printLayout === PORTRAIT_1_12 ? "active" : ""}`}
                                        onClick={() => setPrintLayout(PORTRAIT_1_12)}>
                                        <Portrait1x12Icon/><span>{t("portrait_1_12")}</span></div>
                                    <div
                                        className={`ecg-viewer-button large-icon ${printLayout === LANDSCAPE_2_6 ? "active" : ""}`}
                                        onClick={() => setPrintLayout(LANDSCAPE_2_6)}>
                                        <Landscape2x6Icon/><span>{t("landscape_2_6")}</span></div>
                                    <div
                                        className={`ecg-viewer-button large-icon ${printLayout === PORTRAIT_2_6 ? "active" : ""}`}
                                        onClick={() => setPrintLayout(PORTRAIT_2_6)}>
                                        <Portrait2x6Icon/><span>{t("portrait_2_6")}</span></div>
                                    <div
                                        className={`ecg-viewer-button large-icon ${printLayout === LANDSCAPE_4_3 ? "active" : ""}`}
                                        onClick={() => setPrintLayout(LANDSCAPE_4_3)}>
                                        <Landscape4x3Icon/><span>{t("landscape_4_3")}</span></div>
                                </div>
                            }
                            <div className="d-flex justify-content-center mx-2">
                                <div className={`ecg-viewer-button ${printMode === ALL ? "active" : ""}`}
                                     onClick={() => setPrintMode(ALL)}>
                                    <SaveAllIconSvg/><span>{t("whole_record")}</span></div>
                                <div className={`ecg-viewer-button ${printMode === SELECTED ? "active" : ""}`}
                                     onClick={() => setPrintMode(SELECTED)}>
                                    <SaveSelectedIconSvg/><span>{t("selected_fragment")}</span></div>
                                <div
                                    className={`ecg-viewer-button ${printMode === SELECTED ? "" : "disabled"} ${moveLeft ? "active" : ""}`}
                                    onMouseDown={() => moveSelectionRegion(-1)}
                                    onMouseUp={() => moveSelectionRegion(0)}>
                                    <SvgMoveLeftIcon/><span>{t("move_region_left")}</span></div>
                                <div
                                    className={`ecg-viewer-button ${printMode === SELECTED ? "" : "disabled"} ${moveRight ? "active" : ""}`}
                                    onMouseDown={() => moveSelectionRegion(1)} onMouseUp={() => moveSelectionRegion(0)}>
                                    <SvgMoveRightIcon/><span>{t("move_region_right")}</span></div>
                            </div>
                            <div className="d-flex justify-content-center ml-2">
                                <div className={"ecg-viewer-button"} onClick={() => setButtonMode(VIEW)}>
                                    <CancelIconSvg/><span>{t("cancel")}</span></div>
                                <div className={"ecg-viewer-button"} onClick={applySaveSettings}>
                                    <CheckIconSvg/><span>{t("apply")}</span></div>
                            </div>
                        </div>
                    }
                </div>
            }
            {pdfGenerationInProgress &&
                <Toast text={t("pdf_generation_in_progress")} isError={false}/>
            }
            {hasPdfError &&
                <Toast text={t("error_pdf_generation")} isError={true}/>
            }
        </div>
    );
}