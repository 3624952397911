import Footer from "../Widgets/Footer/Footer";
import React from "react";
import {Container} from "react-bootstrap";
import {Redirect, Switch, useLocation} from "react-router-dom";
import {Route} from "react-router";
import * as AppRoutes from "../../routes";
import {GuestRecordDetails} from "./GuestRecordDetails/GuestRecordDetails";
import {GuestEcgView} from "./GuestEcgView/GuestEcgView";
import {GuestLtEcgView} from "./GuestLtEcgView/GuestLtEcgView";
import {GuestSpiroView} from "./GuestSpiroView/GuestSpiroView";
import {GuestStethoscopeView} from "./GuestStethoscopeView/GuestStethoscopeView";
import GuestHeader from "../Widgets/Header/GuestHeader";

function Guest() {
    const location = useLocation();
    return (
        <Container fluid>
            <GuestHeader/>
            <Switch>
                <Route exact path={`${AppRoutes.GUEST_ROUTE}/:token`}>
                    <GuestRecordDetails />
                </Route>
                <Route exact path={`${AppRoutes.GUEST_ROUTE}/:token/ecg`}>
                    <GuestEcgView />
                </Route>
                <Route exact path={`${AppRoutes.GUEST_ROUTE}/:token/lt-ecg`}>
                    <GuestLtEcgView />
                </Route>
                <Route exact path={`${AppRoutes.GUEST_ROUTE}/:token/spiro`}>
                    <Redirect to={`${location.pathname}/0`}/>
                </Route>
                <Route exact path={`${AppRoutes.GUEST_ROUTE}/:token/spiro/:tabIndex`}>
                    <GuestSpiroView />
                </Route>
                <Route exact path={`${AppRoutes.GUEST_ROUTE}/:token/stethoscope`}>
                    <Redirect to={`${location.pathname}/1`}/>
                </Route>
                <Route exact path={`${AppRoutes.GUEST_ROUTE}/:token/stethoscope/:tabIndex`}>
                    <GuestStethoscopeView />
                </Route>
            </Switch>
            <Footer/>
        </Container>
    );
}

export default Guest;