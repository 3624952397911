import React from "react";
import {useTranslation} from "react-i18next";
import {MV_SCALES} from "../../../ecg/EcgScales";

interface EcgMvScaleDropdownProps {
    className?: string;
    initialValue: number;
    onChange: (value: number) => void;
}

export const EcgMvScaleDropdown: React.FC<EcgMvScaleDropdownProps> = ({ className = "", initialValue, onChange }: EcgMvScaleDropdownProps) => {
    const {t} = useTranslation();
    return (
        <select className={className} defaultValue={initialValue} onChange={(e) => onChange(Number(e.target.value))}>
            {MV_SCALES.map((s, i) => <option key={`ecg-mv-${i}`} value={i}>{t("mv_scale_format", {mvScale: s.toFixed(1)})}</option>)}
        </select>
    );
}