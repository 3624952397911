import {WritableCacheFile} from "./WritableCacheFile";
import {CacheFile} from "./CacheFile";
import {
    cleanBySize,
    createCacheStorageRecord,
    removeCacheStorageRecord,
    updateCacheStorageRecord
} from "./CacheStorage";
import {createHash} from "sha1-uint8array";

export const isExist = async (fileName: string, hash? : string): Promise<boolean> => {
    const root = await navigator.storage.getDirectory();
    const cacheDirectory = await root.getDirectoryHandle("cache", {"create": true});
    for await (const handle of cacheDirectory.values()) {
        if (handle.kind === "file" && handle.name === fileName) {
            if (hash) {
                const file = await handle.getFile();
                const data = await file.arrayBuffer();
                if (createHash().update(new Uint8Array(data)).digest("hex") !== hash.toLowerCase()) {
                    await removeCacheStorageRecord(fileName);
                    await cacheDirectory.removeEntry(fileName);
                    return false;
                }
            }
            return true;
        }
    }
    return false;
}

export const createFile = async (fileName: string): Promise<WritableCacheFile> => {
    await cleanBySize();
    const root = await navigator.storage.getDirectory();
    const tempDirectory = await root.getDirectoryHandle("temp", {"create": true});
    const fileHandle = await tempDirectory.getFileHandle(fileName, {"create": true});
    return new WritableCacheFile(fileHandle);
}

export const moveFileFromTempToCache = async (fileName: string): Promise<void> => {
    const root = await navigator.storage.getDirectory();
    const tempDirectory = await root.getDirectoryHandle("temp", {"create": true});
    const tempFileHandle = await tempDirectory.getFileHandle(fileName);
    const tempFile = await tempFileHandle.getFile();
    const data = await tempFile.arrayBuffer();
    const cacheDirectory = await root.getDirectoryHandle("cache", {"create": true});
    const cacheFileHandle = await cacheDirectory.getFileHandle(fileName, {"create": true});
    const cacheFileStream = await cacheFileHandle.createWritable();
    const cacheFileWriter = cacheFileStream.getWriter();
    await cacheFileWriter.write(data);
    await cacheFileWriter.close();
    await tempDirectory.removeEntry(fileName);
    await createCacheStorageRecord(fileName, data.byteLength);
}

export const getFile = async (fileName: string): Promise<CacheFile> => {
    if (await isExist(fileName)) {
        const root = await navigator.storage.getDirectory();
        const cacheDirectory = await root.getDirectoryHandle("cache", {"create": true});
        const fileHandle = await cacheDirectory.getFileHandle(fileName);
        await updateCacheStorageRecord(fileName);
        return new CacheFile(fileHandle);
    }
    throw new Error("File not found")
}

export const deleteFile = async (fileName: string) => {
    const root = await navigator.storage.getDirectory();
    const cacheDirectory = await root.getDirectoryHandle("cache", {"create": true});
    await cacheDirectory.removeEntry(fileName);
}