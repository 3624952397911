import React from "react";
import './GoogleSignInButton.css';

interface GoogleSignInButtonProps {
    text: string;
    onClick: () => void;
    className?: string;
}

export const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({ text, onClick, className = ""}: GoogleSignInButtonProps) => {
    return (
        <div className={`google-sign-in-button ${className}`} onClick={onClick}>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.51562 7.5C3.51562 6.75683 3.72038 6.06065 4.0761 5.46454V2.93599H1.54755C0.543984 4.23935 0 5.8278 0 7.5C0 9.17221 0.543984 10.7607 1.54755 12.064H4.0761V9.53546C3.72038 8.93936 3.51562 8.24318 3.51562 7.5Z" fill="#FBBD00" />
                <path d="M7.5 11.4844L5.74219 13.2422L7.5 15C9.17224 15 10.7607 14.456 12.064 13.4524V10.9266H9.53815C8.93684 11.2836 8.23773 11.4844 7.5 11.4844Z" fill="#0F9D58" />
                <path d="M4.07616 9.53546L1.54761 12.064C1.7463 12.3221 1.96266 12.5692 2.19677 12.8033C3.61333 14.2199 5.49674 15 7.50006 15V11.4844C6.04623 11.4844 4.77202 10.7016 4.07616 9.53546Z" fill="#31AA52" />
                <path d="M15 7.5C15 7.0437 14.9587 6.58655 14.8772 6.1413L14.8112 5.78098H7.5V9.2966H11.0582C10.7126 9.98393 10.1814 10.5447 9.53812 10.9266L12.064 13.4525C12.322 13.2538 12.5692 13.0374 12.8033 12.8033C14.2199 11.3867 15 9.50332 15 7.5Z" fill="#3C79E6" />
                <path d="M10.3174 4.68261L10.6281 4.99333L13.114 2.50743L12.8033 2.19671C11.3868 0.780147 9.50335 0 7.5 0L5.74219 1.75781L7.5 3.51562C8.56424 3.51562 9.56481 3.93006 10.3174 4.68261Z" fill="#CF2D48" />
                <path d="M7.50009 3.51562V0C5.49677 0 3.61336 0.780147 2.19677 2.19668C1.96266 2.43079 1.7463 2.67791 1.54761 2.93599L4.07616 5.46454C4.77205 4.29844 6.04626 3.51562 7.50009 3.51562Z" fill="#EB4132" />
            </svg>
            <span className="ml-2 disable-select">{text}</span>
        </div>
    );
}