import * as React from "react";

function SvgZoomInVerticalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#zoominv_svg__clip0)" fill="currentColor">
        <path d="M12.39 1.163v6.783l1.202-1.203a.66.66 0 01.93 0 .66.66 0 010 .93l-2.325 2.325a.66.66 0 01-.93 0L8.942 7.673a.66.66 0 010-.93.66.66 0 01.93 0l1.203 1.203V1.163a.656.656 0 111.314 0zM11.267 22.089a.657.657 0 01-.191-.465V14.84l-1.204 1.203a.66.66 0 01-.93 0 .66.66 0 010-.93l2.325-2.325a.66.66 0 01.93 0l2.326 2.325a.66.66 0 010 .93.66.66 0 01-.93 0l-1.204-1.203v6.784a.66.66 0 01-1.122.465z" />
      </g>
      <defs>
        <clipPath id="zoominv_svg__clip0">
          <path
            fill="#fff"
            transform="scale(-1 1) rotate(-45 2.306 33.331)"
            d="M0 0h15.784v15.784H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgZoomInVerticalIcon;
