import React, {Fragment, useState} from "react";
import {GuestHeaderFull} from "./GuestHeaderFull";
import {GuestHeaderShort} from "./GuestHeaderShort";

function GuestHeader() {
    const [isShort, setShort] = useState(null as boolean | null);
    const layoutChangeListener = (isEnoughSpace: boolean) => {
        if (!isShort || isShort !== isEnoughSpace) {
            setShort(isEnoughSpace);
        }
    };
    return (
        <Fragment>
            <div style={{position: "absolute", left: 0, top: -1000, width: "100%", padding: "0 15px", opacity: 0}}>
                <GuestHeaderFull layoutChangeListener={layoutChangeListener}/>
            </div>
            {isShort !== null &&
            isShort ? <GuestHeaderShort/> : <GuestHeaderFull/>}
        </Fragment>
    );
}

export default GuestHeader;