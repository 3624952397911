import React from "react";

function SvgPersonIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.616 11.777c0-2.348-.58-5.063-2.92-5.063a3.987 3.987 0 01-5.589 0c-2.34 0-2.92 2.715-2.92 5.063 0 1.303.858 2.366 1.902 2.366h7.625c1.045 0 1.902-1.063 1.902-2.366zM9.33 3.857a3.43 3.43 0 00-6.857 0 3.43 3.43 0 006.857 0z"
                fill="currentColor"
            />
        </svg>
    );
}
export default SvgPersonIcon;
