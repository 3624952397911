import {Attachment} from "../../../models/Attachment";
import {useTranslation} from "react-i18next";
import React from "react";
import './AttachmentTable.css';
import SvgDownloadIcon from "../../../assets/svg/DownloadIcon";
import {downloadAsBlob} from "../../../api/ApiHelper";
import {isClickableAttachment} from "../../../helpers/AttachmentHelper";

interface Props {
    attachments: Array<Attachment>;
    onlyClickable? :boolean;
}

export function AttachmentsTable(props: Props) {
    const {t} = useTranslation();
    const downloadAttachment = (name: string, url: string) => {
        downloadAsBlob(url, name);
    };
    return (
        <div className='d-flex flex-column align-items-center'>
            <h6>{t('attachments')}</h6>
            <div className="d-flex justify-content-center mb-4">
                <div className="details-table attachment-table">
                    <table>
                        <tbody>
                        {props.attachments.filter(a => props.onlyClickable ? isClickableAttachment(a.fileName) : true).map(attachment => {
                            const downloadUrl = attachment.cloudUrl.replace(/generation=\d+&/gi, "")
                            return (
                                <tr key={`att-${attachment.fileId}`}>
                                    <td>
                                        <div className='my-2'>
                                            {isClickableAttachment(attachment.fileName) ?
                                                <a className="attachment-item" href={downloadUrl}
                                                   download={attachment.fileName} target="_blank"
                                                   rel="noopener noreferrer">
                                                    <span className="attachment-name">{attachment.fileName}</span>
                                                </a>
                                                : <div className="attachment-item">
                                                    <span className="attachment-name">{attachment.fileName}</span>
                                                </div>}
                                        </div>
                                    </td>
                                    <td>
                                        <SvgDownloadIcon className='download-icon'
                                                         onClick={() => downloadAttachment(attachment.fileName, downloadUrl)}/>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}