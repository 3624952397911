import {EcgCalculationV3} from "../../../models/EcgCalculationV3";
import {useTranslation} from "react-i18next";
import {getLeadName} from "../../../ecg/EcgHelper";
import {
    LEAD_NOISE,
    PN_AMPL,
    PP_AMPL,
    Q_AMPL,
    Q_DUR,
    R2_AMPL,
    R_AMPL,
    S_AMPL,
    ST_BIG_AMPL,
    ST_END_AMPL,
    ST_MID_AMPL,
    TN_AMPL,
    TP_AMPL
} from "../../../models/EcgQrsParameters";
import React from "react";

const MIN_VALUE = -2147483648;

interface Props {
    calculation: EcgCalculationV3;
}

function getEcgFeatureValue(value: number): string {
    return value !== MIN_VALUE ? `${value}` : "-";
}

function getEcgFeatureBoolean(value: number): string {
    if (value === MIN_VALUE) {
        return "-";
    }
    return value === 0 ? "-" : "+";
}

export function EcgCalculationDetailedData(props: Props) {
    const {t} = useTranslation();
    const titles = [
        "",
        t("qrs_p_amp_pos"),
        t("qrs_p_amp_neg"),
        t("qrs_q_amp"),
        t("qrs_q_dur"),
        t("qrs_r_amp"),
        t("qrs_r2_amp"),
        t("qrs_s_amp"),
        t("qrs_st_amp_begin"),
        t("qrs_st_amp_mid"),
        t("qrs_st_amp_end"),
        t("qrs_t_amp_pos"),
        t("qrs_t_amp_neg"),
        t("qrs_noise"),
        "",
    ];
    const rows = Array(12).fill(0).map((_, i) => {
        const arr = [];
        arr.push(getLeadName(i));
        const paramTable = props.calculation.avg.paramTable;
        arr.push(getEcgFeatureValue(paramTable[i][PP_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][PN_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][Q_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][Q_DUR]));
        arr.push(getEcgFeatureValue(paramTable[i][R_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][R2_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][S_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][ST_BIG_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][ST_MID_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][ST_END_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][TP_AMPL]));
        arr.push(getEcgFeatureValue(paramTable[i][TN_AMPL]));
        arr.push(getEcgFeatureBoolean(paramTable[i][LEAD_NOISE]));
        arr.push(getLeadName(i));
        return arr;
    });
    const descriptions = [
        t("qrs_p_amp_pos_description"),
        t("qrs_p_amp_neg_description"),
        t("qrs_q_amp_description"),
        t("qrs_q_dur_description"),
        t("qrs_r_amp_description"),
        t("qrs_r2_amp_description"),
        t("qrs_s_amp_description"),
        t("qrs_st_amp_begin_description"),
        t("qrs_st_amp_mid_description"),
        t("qrs_st_amp_end_description"),
        t("qrs_t_amp_pos_description"),
        t("qrs_t_amp_neg_description"),
        t("qrs_noise_description")
    ];
    return (
        <div className="d-flex justify-content-center">
            <div className="calculation-table calculation-table-scrollable">
                <table>
                    <thead>
                    <tr>
                        {titles.map((t, i) => {
                            return ((i > 0 && i < titles.length - 1) ?
                                <th key={`title-${i}`}>{t}<div><span>{descriptions[i - 1]}</span></div></th> :
                                <th key={`title-${i}`}></th>);
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((r, i) => (
                        <tr key={`row-${i}`}>
                            {rows[i].map((c, j) => {
                                return ((j > 0 && j < titles.length - 1) ?
                                    <td key={`cell-${i}-${j}`}>{c}</td> :
                                    <th key={`cell-${i}-${j}`}>{c}</th>);
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}