import * as React from "react";

function SvgCompandIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.004 6.735a.503.503 0 00-.504.509v1.679a.503.503 0 101.008 0V7.244a.503.503 0 00-.504-.509zM2.97 6.735a.503.503 0 00-.504.509v1.679a.503.503 0 101.009 0V7.244a.508.508 0 00-.504-.509zM4.93 6.023a.503.503 0 00-.504.509v3.102a.503.503 0 101.008 0V6.532a.503.503 0 00-.504-.51zM6.896 5.18a.503.503 0 00-.504.509v4.788a.503.503 0 101.009 0V5.69a.503.503 0 00-.505-.509zM8.863 3.69a.503.503 0 00-.505.51v7.76a.503.503 0 101.009 0V4.2a.508.508 0 00-.504-.51zM10.829 1c-.281 0-.504.202-.504.455v13.257c0 .253.223.455.504.455.28 0 .504-.202.504-.455V1.455c0-.253-.23-.455-.504-.455zM12.496.167a.503.503 0 00-.505.508v14.816a.503.503 0 101.009 0V.675a.508.508 0 00-.504-.508z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCompandIcon;
