export enum Gender {
    Male = 1,
    Female = 2,
    Undefined = 0
}

export function parseGender(gender: string): Gender {
    if (gender === Gender[Gender.Male]) {
        return Gender.Male;
    }
    if (gender === Gender[Gender.Female]) {
        return Gender.Female;
    }
    return Gender.Undefined;
}