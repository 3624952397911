import React, {useRef, useState} from 'react';
import './InputBox.css';
import TextareaAutosize from 'react-textarea-autosize';

interface TextBoxProps {
    className?: string;
    title: string;
    initialValue: string;
    placeholder?: string;
    enabled? : boolean;
    hasError?: boolean;
    onChange: (value: string) => void;
    onStateChanged?: (isEmpty : boolean) => void;
    icon?: JSX.Element | null;
    children?: JSX.Element | null;
}

export const TextBox: React.FC<TextBoxProps> = ({ className = "", title, initialValue, placeholder, enabled = true, hasError = false, onChange, onStateChanged, icon = null, children = null}: TextBoxProps) => {
    const [isEmpty, setEmpty] = useState(initialValue.length === 0);
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const inputHandler = (e : any) => {
        const newState = (e.target.value?.length ?? 0) === 0;
        if (isEmpty !== newState){
            if (onStateChanged){
                onStateChanged(newState);
            }
            setEmpty(newState);
        }
    };
    const touchHandler = (e : any) => {
        if (!(e.target instanceof HTMLTextAreaElement)){
            e.preventDefault();
            inputRef?.current?.focus();
        }
    }
    return (
        <div className={`input-box ${hasError ? "error" : ""} ${className}`} onMouseDown={touchHandler}>
            <div className="input-box-container py-2">
                <span className="disable-select">{title}</span>
                {children && children}
                <TextareaAutosize ref={inputRef} defaultValue={initialValue} onChange={(e : any) => onChange(e.target.value)} onInput={inputHandler} disabled={!enabled} placeholder={placeholder ?? ""}/>
            </div>
            <div className="input-box-icon">
                {icon ?? icon}
            </div>
        </div>
    );
}