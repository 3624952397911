import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import './AsyncIndicatorWithText.css';

interface AsyncIndicatorWithTextProps {
    text: string | null;
}

export default function AsyncIndicatorWithText(props: AsyncIndicatorWithTextProps) {
    return (
        <div className="d-flex flex-column">
            <AsyncIndicator/>
            {props.text &&
                <span className="async-indicator-text">{props.text}</span>
            }
        </div>
    );
}