import * as React from "react"
import { SVGProps } from "react"

const IconFactoryFill = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        xmlSpace="preserve"
        viewBox="0 0 12 12"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.461 3.152 1.324 6.031v4.758h9.481L10.813 0 8.625.004l-.004 5.371-1-2.188L6.34 5.785 5.082 3.152 3.859 5.75z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#161716"
            d="M2.461 3.152 1.324 6.031v4.758h9.481L10.813 0 8.625.004l-.004 5.371-1-2.188L6.34 5.785 5.082 3.152 3.859 5.75z"
        />
    </svg>
)

export default IconFactoryFill
