import * as React from "react";

function SvgRecalculateIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            {...props}
        >
            <path d="M6 18v-5h5v5H6m1-4v3h3v-3H7m6-6.5h5v2h-5v-2M5 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6.17c-.5-.11-1-.17-1.5-.17H19V5H5v14h8.17c.17.72.46 1.39.83 2H5m6-15v5H6V6h5m-1 4V7H7v3h3m9 2v1.5a4 4 0 0 1 4 4c0 .82-.25 1.58-.67 2.21l-1.09-1.09c.17-.34.26-.72.26-1.12A2.5 2.5 0 0 0 19 15v1.5l-2.25-2.25L19 12m0 11v-1.5a4 4 0 0 1-4-4c0-.82.25-1.58.67-2.21l1.09 1.09c-.17.34-.26.72-.26 1.12A2.5 2.5 0 0 0 19 20v-1.5l2.25 2.25L19 23Z" fill="currentColor"/>
        </svg>
    )
}

export default SvgRecalculateIcon