import * as React from "react"

function SvgMoveLeftIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            {...props}
        >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M17.59 18L19 16.59 14.42 12 19 7.41 17.59 6l-6 6z" fill="currentColor" />
            <path d="M11 18l1.41-1.41L7.83 12l4.58-4.59L11 6l-6 6z" fill="currentColor" />
        </svg>
    )
}

export default SvgMoveLeftIcon
