// const HISTORY_SIZE = 8;
// const A0 = 0.99833;
// const A1 = 1 - A0;
//
// export function filterEcgData(data: Array<number | null>, lpf: boolean, hpf: boolean, from: number = 0, count: number = data.length - from): Array<number | null> {
//     const out = new Array<number | null>();
//     const history: number[] = [];
//     let lowPass = 0;
//     let average = 0;
//     const start = Math.max(0, from - HISTORY_SIZE);
//     let delay = from - start;
//     for (let i = from; i < from + count; i++) {
//         let output = data[i];
//         history.push(output ?? 0);
//         const historyValue = history.length > HISTORY_SIZE ? history.shift() ?? 0 : 0;
//         lowPass = lowPass + (output ?? 0) - historyValue;
//         average = A0 * average + A1 * (output ?? 0);
//         if (output) {
//             if (lpf) {
//                 output = lowPass / HISTORY_SIZE;
//             }
//             if (hpf) {
//                 output = output - Math.round(average);
//             }
//         }
//         delay--;
//         if (delay < 0) {
//             out.push(output);
//         }
//     }
//     return out;
// }

const MAX_ECG_LEADS_COUNT = 8;
const LOW_PASS_BUFFER_LENGTH = 16;

const LOW_PASS_PARAM = 8;
const A0 = 0.99833;
const A1 = 1 - A0;

export function filterEcgData(adcChannels: number, data: (number | null)[][], lpf: boolean, hpf: boolean) {
    const historyBuffer = new Array<Array<number>>();
    for (let i = 0; i < MAX_ECG_LEADS_COUNT; i++) {
        historyBuffer.push(new Array<number>())
        for (let j = 0; j < LOW_PASS_BUFFER_LENGTH; j++) {
            historyBuffer[i].push(0);
        }
    }
    let historyIndex = 0;
    const lowPassVector = new Array<number>();
    const averageVector = new Array<number>();
    for (let i = 0; i < MAX_ECG_LEADS_COUNT; i++) {
        lowPassVector.push(0);
        averageVector.push(0);
    }
    const size = data.length > 0 ? data[0].length : 0;
    const filteredData = new Array<number | null>();
    const filterInput = new Array<number>();
    const filterOutput = new Array<number>();
    for (let i = 0; i < MAX_ECG_LEADS_COUNT; i++) {
        filterInput.push(0);
        filterOutput.push(0);
    }
    let i = 0;
    while (i < size) {
        for (let j = 0; j < adcChannels; j++) {
            let value = data[j][i];
            filterInput[j] = value ?? 0;
        }
        const delayedIndex = (historyIndex + LOW_PASS_BUFFER_LENGTH - LOW_PASS_PARAM) & (LOW_PASS_BUFFER_LENGTH - 1);
        for (let ch = 0; ch < adcChannels; ch++ ) {
            historyBuffer[ch][historyIndex] = filterInput[ch];
            lowPassVector[ch] = lowPassVector[ch] + filterInput[ch] - historyBuffer[ch][delayedIndex];

            averageVector[ch] = (A0 * averageVector[ch] + A1 * filterInput[ch]);
        }
        historyIndex = (historyIndex + 1) & (LOW_PASS_BUFFER_LENGTH - 1);
        for (let ch = 0; ch < adcChannels; ch++) {
            if (lpf) {
                filterOutput[ch] = lowPassVector[ch] / LOW_PASS_PARAM;
            } else {
                filterOutput[ch] = filterInput[ch];
            }

            if (hpf) {
                filterOutput[ch] = filterOutput[ch] - Math.round(averageVector[ch]);
            }
        }

        for (let j = 0; j < adcChannels; j++) {
            let value = data[j][i];
            filteredData.push(value == null ? null : Math.round(filterOutput[j]));
        }
        i++;
    }
    return filteredData;
}