import {EcgCalculationV3} from "../../../models/EcgCalculationV3";
import {useTranslation} from "react-i18next";
import React from "react";
import {LEAD_II, LEAD_V1, LEAD_V5, PP_AMPL, R_AMPL, S_AMPL} from "../../../models/EcgQrsParameters";

interface Props {
    calculation: EcgCalculationV3;
}

export function EcgCalculationBriefData(props: Props) {
    const {t} = useTranslation();
    return (
        <div className="d-flex justify-content-center">
            <div className="calculation-table">
                <table>
                    <thead>
                    <tr>
                        <th>{t("parameter")}</th>
                        <th style={{textAlign: "center"}}>{t("value")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>HR (bpm)</th>
                        <td style={{textAlign: "center"}}>{props.calculation.avg.hr}</td>
                    </tr>
                    <tr>
                        <th>RR (ms)</th>
                        <td>{props.calculation.avg.rr}</td>
                    </tr>
                    <tr>
                        <th>QRS (ms)</th>
                        <td>{props.calculation.avg.qrsDur}</td>
                    </tr>
                    <tr>
                        <th>P/PQ (ms)</th>
                        <td>{props.calculation.avg.pDur} / {props.calculation.avg.pqDur}</td>
                    </tr>
                    <tr>
                        <th>QT/QTc (ms)</th>
                        <td>{props.calculation.avg.qtDur} / {props.calculation.avg.qtC}</td>
                    </tr>
                    <tr>
                        <th>P/R/T axes</th>
                        <td>{props.calculation.avg.pAxis} / {props.calculation.avg.qrsAxis} / {props.calculation.avg.tAxis}</td>
                    </tr>
                    <tr>
                        <th>PII (mkV)</th>
                        <td>{props.calculation.avg.paramTable[LEAD_II][PP_AMPL]}</td>
                    </tr>
                    <tr>
                        <th>SV1/RV5 (mkV)</th>
                        <td>{props.calculation.avg.paramTable[LEAD_V1][S_AMPL]} / {props.calculation.avg.paramTable[LEAD_V5][R_AMPL]}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}