import {DeviceLabel} from "../Widgets/DeviceLabel/DeviceLabel";
import {useParams} from "react-router-dom";
import {useAbortController} from "../../hooks";
import React, {useEffect, useState} from "react";
import * as ApiHelper from "../../api/ApiHelper";
import ErrorResponse from "../../models/ErrorResponse";
import {DeviceLabelInfo} from "../../models/DeviceLabelInfo";
import {Container} from "react-bootstrap";
import {FetchError} from "../Widgets/FetchError/FetchError";
import Loader from "../Widgets/Loader/Loader";
import NotFound from "../Widgets/NotFound/NotFound";
import * as ErrorCodes from "../../api/ErrorCodes";
import {DocumentItem} from "../Widgets/DocumentItem/DocumentItem";
import './Device.css';
import SvgContactUsIcon from "../../assets/svg/ContactUsIcon";
import {logError} from "../../helpers/LogHelper";

const VOLTAGE_UA = "5В";
const POWER_UA = "1,5Вт";
const VOLTAGE_EN = "5V";
const POWER_EN = "1.5W";

const ADDRESS_UA = `Науково-виробнича фірма «Діагностичні системи» - товариство з обмеженою відповідальністю\nМісце реєстрації:\nУкраїна, 61054, м. Харків, вул. Академіка Павлова, 271\nМісце знаходження:\nУкраїна, 61010, м. Харків, вул. Полтавська, 26`
const ADDRESS_EN = `Diagnostic Systems, Ltd.\nPlace of registration:\nUkraine, 61054, Kharkiv, st. Academic Pavlov, 271\nLocation:\nUkraine, 61010, Kharkiv, st. Poltavska, 26`;

interface PathParams {
    deviceClass: string;
    deviceNumber: string;
}

export function Device() {
    let {deviceClass, deviceNumber} = useParams<PathParams>();
    const [controller] = useAbortController();
    const [isFetching, setFetchingState] = useState(true);
    const [notFound, setNotFoundState] = useState(false);
    const [hasError, setErrorState] = useState(false);
    const [data, setData] = useState(null as DeviceLabelInfo | null);
    const errorHandler = (error: ErrorResponse) => {
        logError("Device data fetch error", error);
        setFetchingState(false);
        if (error.ResultCode === ErrorCodes.NotFound) {
            setErrorState(false);
            setNotFoundState(true);
        } else {
            setErrorState(true);
            setNotFoundState(false);
        }
    };
    const dataHandler = (data: DeviceLabelInfo) => {
        setFetchingState(false);
        setErrorState(false);
        setNotFoundState(false);
        setData(data);
    };
    const fetchData = () => {
        if (deviceClass && deviceNumber) {
            setFetchingState(true);
            ApiHelper.getDeviceInfo(deviceClass, deviceNumber, controller, dataHandler, errorHandler);
        } else {
            setErrorState(true);
        }
    };
    useEffect(() => fetchData(), [deviceClass, deviceNumber]);     // eslint-disable-line
    if (isFetching) {
        return <Loader/>
    }
    let licenseTypeUa;
    let licenseTypeEn;
    let licenseValidUntilUa;
    let licenseRecordLeftUa;
    let licenseValidUntilEn;
    let licenseRecordLeftEn;
    if (data) {
        let licenseDescriptionUa = "Відсутня";
        let licenseDescriptionEn = "None";
        switch (data.licenseType ?? 0) {
            case 1000:
                licenseDescriptionUa = "Кількість записів";
                licenseDescriptionEn = "Records count";
                break;
            case 2000:
                licenseDescriptionUa = "Обмеження по часу";
                licenseDescriptionEn = "Time limited";
                break;
            case 3000:
                licenseDescriptionUa = "Необмежена";
                licenseDescriptionEn = "Unlimited";
                break;
        }
        licenseTypeUa = `Тип ліцензії: ${licenseDescriptionUa}`;
        licenseValidUntilUa = data.licenseType === 2000 && data.validUntil !== null ? `Дійсна до: ${data.validUntil}` : null;
        licenseRecordLeftUa = data.licenseType === 1000 && data.recordsLeft !== null ? `Залишилось записів: ${data.recordsLeft}` : null;
        licenseTypeEn = `License type: ${licenseDescriptionEn}`;
        licenseValidUntilEn = data.licenseType === 2000 && data.validUntil !== null ? `Valid until: ${data.validUntil}` : null;
        licenseRecordLeftEn = data.licenseType === 1000 && data.recordsLeft !== null ? `Records left: ${data.recordsLeft}` : null;
    }
    const isOk = !hasError && !notFound && !isFetching;
    return (
        <Container fluid>
            {!isFetching && hasError && <FetchError onRetry={fetchData}/>}
            {!isFetching && notFound && <NotFound/>}
            {isOk && data &&
                <div className="d-flex flex-column w-100 align-items-center">
                    <div className="mt-4 mb-2">
                        <DeviceLabel voltage={VOLTAGE_UA} power={POWER_UA} deviceName={data.nameUa}
                                     deviceNumber={data.number}
                                     address={ADDRESS_UA}
                                     year={data.year}/>
                    </div>
                    <div className="my-2">
                        <DeviceLabel voltage={VOLTAGE_EN} power={POWER_EN} deviceName={data.nameEn}
                                     deviceNumber={data.number}
                                     address={ADDRESS_EN}
                                     year={data.year}/>
                    </div>
                    {data.documents.length > 0 &&
                        <div className="divider w-100 mx-4 my-2"/>
                    }
                    {data.documents.length > 0 &&
                        <div className="documents-container mx-4 my-2 d-flex flex-column align-items-start">
                            <div className="documents-container-title mb-2 align-self-center">Документація / Documents
                            </div>
                            {data.documents.map((d, i) => <DocumentItem key={`doc-link-${i}`} info={d}/>)}
                        </div>
                    }
                    <div className="divider w-100 mx-4 my-2"/>
                    {licenseTypeUa &&
                        <div className="d-flex flex-column align-items-center mx-4 mt-2 mb-2">
                            <span>{licenseTypeUa}</span>
                            {licenseValidUntilUa &&
                                <span>{licenseValidUntilUa}</span>
                            }
                            {licenseRecordLeftUa &&
                                <span>{licenseRecordLeftUa}</span>
                            }
                        </div>
                    }
                    {licenseTypeEn &&
                        <div className="d-flex flex-column align-items-center mx-4 mt-2 mb-2">
                            <span>{licenseTypeEn}</span>
                            {licenseValidUntilEn &&
                                <span>{licenseValidUntilEn}</span>
                            }
                            {licenseRecordLeftEn &&
                                <span>{licenseRecordLeftEn}</span>
                            }
                        </div>
                    }
                    <div className="mx-4 mt-2 mb-4 link-contact-support">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a target="_blank"
                           href={`mailto:suport@binora.com.ua?subject=${data.nameEn} ${data.number}`}><SvgContactUsIcon/> Технічная
                            підтримка / Technical support</a>
                    </div>
                </div>
            }
        </Container>
    );
}