import {DBSchema, openDB} from "idb";
import {deleteFile} from "./LocalCache";

const MS_IN_MINUTE = 1000 * 60;

const MS_IN_HOUR = 60 * MS_IN_MINUTE;

const MS_IN_DAY = 24 * MS_IN_HOUR;

const MAX_STORAGE_TIME_MS = 30 * MS_IN_DAY;

const BYTES_IN_MB = 1024 * 1024;

const MAX_STORAGE_SIZE_MB = 500 * BYTES_IN_MB;

interface CacheDb extends DBSchema {
    files: {
        value: {
            name: string;
            size: number;
            lastRequest: number;
        };
        key: string;
        indexes: { 'by-time': number };
    };
}

function openCacheDb() {
    return openDB<CacheDb>('cache-db', 1, {
        upgrade(db) {

            const productStore = db.createObjectStore('files', {
                keyPath: 'name',
            });
            productStore.createIndex('by-time', 'lastRequest');
        }
    });
}

export async function createCacheStorageRecord(fileName: string, size: number) {
    const db = await openCacheDb();
    await db.put('files', {name: fileName, size: size, lastRequest: Date.now()});
}

export async function updateCacheStorageRecord(fileName: string) {
    const db = await openCacheDb();
    const record = await db.get('files', fileName);
    if (record) {
        record.lastRequest = Date.now();
        await db.put('files', record);
    }
}

export async function removeCacheStorageRecord(fileName: string) {
    const db = await openCacheDb();
    await db.delete('files', fileName);
}

export async function cleanByTime() {
    const maxTime = Date.now() - MAX_STORAGE_TIME_MS;
    const db = await openCacheDb();
    const records = await db.getAllFromIndex('files', 'by-time', IDBKeyRange.upperBound(maxTime));
    if (records) {
        for (const record of records) {
            await deleteFile(record.name);
            await removeCacheStorageRecord(record.name);
        }
    }
}

export async function cleanBySize() {
    const db = await openCacheDb();
    const records = await db.getAllFromIndex('files', 'by-time');
    if (records) {
        let storageSize = records.reduce((sum, current) => sum + current.size, 0);
        while (storageSize > MAX_STORAGE_SIZE_MB && records.length > 0) {
            const recordToRemove = records.shift();
            if (recordToRemove) {
                await deleteFile(recordToRemove.name);
                await removeCacheStorageRecord(recordToRemove.name);
                storageSize -= recordToRemove.size;
            }
        }
    }
}