import React from "react";
import { logout } from "../../../features/account/accountSlice";
import { useAppDispatch } from "../../../hooks";
import { Button } from "../../Widgets/Button/Button";
import {useTranslation} from "react-i18next";
import {AccountSettings} from "../../Widgets/AccountSettings/AccountSettings";
import {SIGN_IN_ROUTE} from "../../../routes";
import {useHistory} from "react-router-dom";

function Blocked() {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const handleContinueClick = () => {
        dispatch(logout());
        history.push(SIGN_IN_ROUTE);
    };
    return (
        <div className="window-modal-container">
            <div className="window-modal">
                <div className="window-modal-title pb-4 d-flex justify-content-center">{t("account_blocked_title")}</div>
                <div className="window-modal-text my-2">{t("account_blocked_description")}</div>
                <div className="d-flex justify-content-center">
                    <div className="window-modal-button">
                        <Button className="mt-4" text={t("continue")} onClick={handleContinueClick} />
                    </div>
                </div>
                <div className='mt-4'/>
                <AccountSettings/>
            </div>
        </div>
    );
}

export default Blocked;