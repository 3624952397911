import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../hooks";
import React, {useEffect, useState} from "react";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {Button} from "../Button/Button";
import ReactCrop, {Crop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import * as ApiHelper from "../../../api/ApiHelper";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import {updatePhotoUrl} from "../../../features/account/accountSlice";

const AVATAR_MIN_SIZE = 180;

interface DialogChangeAvatarProps {
    token: string;
    controller: AbortController;
    cancelButtonClickHandler: () => void;
    src: string;
}

function getCroppedImg(image : HTMLImageElement, crop : Crop) : Promise<Blob | null> {
    const canvas = document.createElement<"canvas">('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = AVATAR_MIN_SIZE;
    canvas.height = AVATAR_MIN_SIZE;
    const context = canvas.getContext('2d');
    if (context) {
        context.drawImage(
            image,
            (crop.x ?? 0) * scaleX,
            (crop.y ?? 0) * scaleY,
            (crop.width ?? 0) * scaleX,
            (crop.height ?? 0) * scaleY,
            0,
            0,
            AVATAR_MIN_SIZE,
            AVATAR_MIN_SIZE,
        );
    }
    return new Promise((resolve) => {
        canvas.toBlob(blob => {
            resolve(blob);
        }, 'image/jpeg', 1);
    });
}

export const DialogChangeAvatar: React.FC<DialogChangeAvatarProps> = ({
                                                                          token,
                                                                          controller,
                                                                          cancelButtonClickHandler,
                                                                          src
                                                                      }: DialogChangeAvatarProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [imageRef, setImageRef] = useState(null as HTMLImageElement | null);
    const [errorMessage, setErrorMessage] = useState(null as string | null);
    const [isFetching, setFetchingState] = useState(false);
    const [dialogTop, setDialogTop] = useState(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    const [crop, setCrop] = useState({aspect: 1} as Crop);
    const [minSize, setMinSize] = useState(0);
    const scrollHandler = () => {
        setDialogTop(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    });
    const errorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setErrorMessage(t("connection_problem"));
            setFetchingState(false);
        }
    };
    const handleChangeButtonClick = () => {
        if (imageRef) {
            setErrorMessage(null);
            setFetchingState(true);
            getCroppedImg(imageRef, crop).then(blob => {
                if (blob) {
                    ApiHelper.changePhoto(token, blob, controller, (url) => {
                        dispatch(updatePhotoUrl(url));
                        cancelButtonClickHandler();
                    }, errorHandler);
                } else {
                    setErrorMessage(t("connection_problem"));
                    setFetchingState(false);
                }
            }, () => {
                setErrorMessage(t("connection_problem"));
                setFetchingState(false);
            });
        }
    };
    const handleImageLoad = (image: HTMLImageElement) => {
        setImageRef(image);
        const scale = image.width / image.naturalWidth;
        const minSize = Math.min(AVATAR_MIN_SIZE, image.naturalWidth, image.naturalHeight) * scale;
        const cropSize = Math.max(minSize, Math.min(image.width, image.height) * 0.75);
        setMinSize(minSize);
        setCrop({unit: "px", x: (image.width - cropSize) / 2, y: (image.height - cropSize) / 2, width: cropSize, height: cropSize, aspect: 1});
        return false;
    };
    const documentHeight = document.documentElement.offsetHeight;
    return (
        <div className="dialog-container" style={{height: documentHeight}}>
            {isFetching ? (
                <div className="window-modal" style={{marginTop: dialogTop}}>
                    <AsyncIndicator/>
                </div>
            ) : (
                <div className="window-modal" style={{marginTop: dialogTop}}>

                    <div
                        className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("change_avatar_title")}</div>
                    <div className="window-modal-error d-flex justify-content-center align-items-center my-2">
                        {errorMessage ? errorMessage : ""}
                    </div>
                    <ReactCrop
                        src={src}
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        minWidth={minSize}
                        minHeight={minSize}
                        keepSelection={true}
                        circularCrop={true}
                        onImageLoaded={handleImageLoad}/>
                    <div className="d-flex justify-content-around">
                        <Button className="mt-4" text={t("change")} danger={true} onClick={handleChangeButtonClick}/>
                        <Button className="mt-4" text={t("cancel")} onClick={cancelButtonClickHandler}/>
                    </div>
                </div>)
            }
        </div>
    );
}