import React from "react";

function SvgSpiroIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#IconSpiro_svg__clip0)">
                <path
                    d="M8.212 2.343a2.099 2.099 0 00-1.385-.504c-.578 0-1.049.436-1.049.973v.83l-.541-.25V.589c0-.134-.117-.243-.263-.243-.145 0-.262.109-.262.243v2.803l-.542.25v-.83c0-.537-.47-.973-1.048-.973-.52 0-1.01.178-1.385.504C.952 3.03.5 3.984.5 5.003v2.92c0 .402.348.73.776.731h.01c.173 0 .348-.013.52-.036C3.18 8.424 4.17 7.278 4.17 5.983V4.185l.804-.37.804.37v1.798c0 1.297.993 2.44 2.365 2.635.17.025.345.036.52.036h.01c.428-.001.776-.33.776-.73v-2.92c0-1.018-.45-1.973-1.237-2.66zM3.646 5.982c0 1.072-.825 1.999-1.921 2.155-.147.02-.297.03-.447.03a.252.252 0 01-.254-.243v-2.92c0-.882.381-1.702 1.073-2.305a1.55 1.55 0 011.025-.373c.288 0 .524.219.524.486v1.07l-.848.39h-.987c-.145 0-.262.11-.262.244 0 .135.117.244.262.244h.729l-.21.973h-.52c-.144 0-.261.109-.261.244 0 .134.117.243.262.243h.414l-.147.683c-.028.132.063.26.206.286.142.026.28-.059.31-.192l.5-2.317.554-.255v1.557h-.002zm5.279 1.942a.252.252 0 01-.254.243c-.15 0-.3-.01-.447-.03-1.096-.156-1.921-1.081-1.921-2.155V4.425l.554.255.5 2.317c.028.133.168.218.307.192.142-.026.235-.154.207-.286l-.147-.683h.414c.145 0 .262-.109.262-.243 0-.135-.117-.244-.262-.244H7.62l-.21-.973h.73c.144 0 .261-.11.261-.244s-.117-.243-.262-.243h-.987l-.848-.391v-1.07c0-.267.236-.486.524-.486.387 0 .75.133 1.024.373.693.605 1.074 1.423 1.074 2.304v2.92z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="IconSpiro_svg__clip0">
                    <path
                        fill="#fff"
                        transform="translate(.5 .346)"
                        d="M0 0h8.949v8.308H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
export default SvgSpiroIcon;
