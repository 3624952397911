import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../hooks";
import ErrorResponse from "../../../models/ErrorResponse";
import handleErrors from "../../../helpers/ErrorHandler";
import AsyncIndicator from "../AsyncIndicator/AsyncIndicator";
import {InputBox} from "../InputBox/InputBox";
import SvgEnvelopeIcon from "../../../assets/svg/EnvelopeIcon";
import {Button} from "../Button/Button";
import * as ApiHelper from "../../../api/ApiHelper";
import {logout} from "../../../features/account/accountSlice";

interface DialogConfirmAccountDeleteProps {
    token: string;
    controller: AbortController;
    closeHandler: () => void;
}

export const DialogConfirmAccountDelete: React.FC<DialogConfirmAccountDeleteProps> = ({
                                                                        token,
                                                                        controller,
                                                                        closeHandler
                                                                    }: DialogConfirmAccountDeleteProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [confirmText, setConfirmText] = useState('');
    const [badInput, setBadInput] = useState(false);
    const handleEConfirmTextChange = (value: string) => setConfirmText(value);
    const [isFetching, setFetchingState] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null as string | null);
    const [dialogTop, setDialogTop] = useState(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    const scrollHandler = () => {
        setDialogTop(document.documentElement.scrollTop + document.documentElement.clientHeight * 0.25);
    };
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler)
        }
    });
    const keyDownHandler = (e : KeyboardEvent) => {
        if (e.key === "Enter"){
            handleConfirmButtonClick();
        }
    };
    useEffect(() => {
        window.addEventListener('keydown', keyDownHandler);
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        }
    });
    const successHandler = () => {
        setFetchingState(false);
        setErrorMessage(null);
        dispatch(logout());
        closeHandler();
    };
    const errorHandler = (error: ErrorResponse) => {
        if (!handleErrors(error, dispatch)) {
            setBadInput(false);
            setErrorMessage(t("connection_problem"));
            setFetchingState(false);
        }
    };
    const handleConfirmButtonClick = () => {
        if (confirmText.trim() !== 'delete') {
            setErrorMessage(t("invalid_confirmation_word_entered"));
            setBadInput(true);
            return;
        }
        setBadInput(false);
        setErrorMessage(null);
        setFetchingState(true);
        ApiHelper.deleteProfile(token, controller, successHandler, errorHandler);
    };
    const documentHeight = document.documentElement.offsetHeight;
    return (
        <div className="dialog-container" style={{height: documentHeight}}>
            {isFetching ? (
                <div className="window-modal" style={{marginTop: dialogTop}}>
                    <AsyncIndicator/>
                </div>
            ) : (
                <div className="window-modal" style={{marginTop: dialogTop}}>
                    <div
                        className="window-modal-title disable-select pb-4 d-flex justify-content-center">{t("account_deletion")}</div>
                    <div className="window-modal-text d-flex justify-content-center align-items-center my-2">{t("warning")}</div>
                    <div className="window-modal-text d-flex justify-content-center align-items-center my-2">{t("account_will_be_deleted_permanently")}</div>
                    <div className="window-modal-text d-flex justify-content-center align-items-center my-2" dangerouslySetInnerHTML={{__html: t("confirm_account_delete")}}/>
                    <div className="window-modal-error d-flex justify-content-center align-items-center my-2">
                        {errorMessage ? errorMessage : ""}
                    </div>
                    <InputBox className="my-2" inputType="email" title={""} initialValue={confirmText}
                              hasError={badInput} onChange={handleEConfirmTextChange} icon={<SvgEnvelopeIcon/>}/>
                    <div className="d-flex justify-content-around">
                        <Button className="mt-4" text={t("continue")} danger={true} onClick={handleConfirmButtonClick}/>
                        <Button className="mt-4" text={t("cancel")} onClick={() => closeHandler()}/>
                    </div>
                </div>)
            }
        </div>
    );
}