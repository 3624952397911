export function parseUint8(buffer: ArrayBuffer, offset: number): number {
    return new Uint8Array(buffer, offset, 1)[0];
}

export function parseUint16(buffer: ArrayBuffer, offset: number, reverse: boolean = false): number {
    let data;
    if (reverse){
        data = new Uint8Array(buffer, offset,2).reverse().buffer;
    } else {
        data = buffer;
    }
    return new Uint16Array(data, offset, 1)[0];
}

export function parseInt16(buffer: ArrayBuffer, offset: number, reverse: boolean = false): number {
    let data;
    if (reverse){
        data = new Uint8Array(buffer, offset,2).reverse().buffer;
    } else {
        data = buffer;
    }
    return new Int16Array(data, offset, 1)[0];
}

export function parseUint32(buffer: ArrayBuffer, offset: number, reverse: boolean = false): number {
    let data;
    if (reverse){
        data = new Uint8Array(buffer, offset,4).reverse().buffer;
    } else {
        data = buffer;
    }
    return new Uint32Array(data, offset, 1)[0];
}

export function parseInt32(buffer: ArrayBuffer, offset: number, reverse: boolean = false): number {
    let data;
    if (reverse){
        data = new Uint8Array(buffer, offset,4).reverse().buffer;
    } else {
        data = buffer;
    }
    return new Int32Array(data, offset, 1)[0];
}

export function parseInt32Array(buffer: ArrayBuffer, offset: number, size : number, reverse: boolean = false): number[]{
    const array = [];
    for (let i = 0; i < size; i++) {
        array.push(parseInt32(buffer, offset + i * 4, reverse));
    }
    return array;
}

export function parseUint64(buffer: ArrayBuffer, offset: number, reverse: boolean = false): number {
    let data;
    if (reverse){
        data = new Uint8Array(buffer, offset,8).reverse().buffer;
    } else {
        data = buffer;
    }
    return Number(new BigUint64Array(data, offset, 1)[0]);
}

export function parseInt64(buffer: ArrayBuffer, offset: number, reverse: boolean = false): number {
    let data;
    if (reverse){
        data = new Uint8Array(buffer, offset,8).reverse().buffer;
    } else {
        data = buffer;
    }
    return Number(new BigInt64Array(data, offset, 1)[0]);
}

export function parseFloat(buffer: ArrayBuffer, offset: number, reverse: boolean = false) : number{
    let data;
    if (reverse){
        data = new Uint8Array(buffer, offset,2).reverse().buffer;
    } else {
        data = buffer;
    }
    return new Float32Array(data, offset, 1)[0];
}

export function parseString(buffer : ArrayBuffer, offset? : number, length? : number) : string{
    const decoder = new TextDecoder();
    const arr = new Uint8Array(buffer, offset ?? 0, length ?? buffer.byteLength);
    return decoder.decode(arr).split('\0')[0];
}
