import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    className?: string;
}

export const UserAccessPlaceholder: React.FC<Props> = ({ className }: Props) => {
    const {t} = useTranslation();
    return (
        <div className={`d-flex justify-content-center align-items-center p-4 flex-grow-1 placeholder-text ${className}`}>{t("no_users")}</div>
    );
};

export default UserAccessPlaceholder;